package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoBackFilled: ImageVector
    get() {
        if (_ruwidoBackFilled != null) {
            return _ruwidoBackFilled!!
        }
        _ruwidoBackFilled = Builder(name = "RuwidoBackFilled", defaultWidth = 48.0.dp, defaultHeight
                = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(24.721f, 16.971f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, -1.049f, -1.702f)
                lineToRelative(-13.465f, 8.297f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 0.049f, 1.741f)
                lineToRelative(14.218f, 8.033f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, 0.984f, -1.742f)
                lineToRelative(-10.932f, -6.175f)
                horizontalLineToRelative(22.818f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 0.0f, -2.0f)
                lineTo(14.251f, 23.423f)
                lineToRelative(10.47f, -6.452f)
                close()
            }
        }
        .build()
        return _ruwidoBackFilled!!
    }

private var _ruwidoBackFilled: ImageVector? = null
