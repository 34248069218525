package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Swap: ImageVector
    get() {
        if (_swap != null) {
            return _swap!!
        }
        _swap = Builder(name = "Swap", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.38f, 28.73f)
                curveToRelative(-0.689f, 0.67f, -1.835f, 0.51f, -2.169f, -0.393f)
                arcToRelative(9.884f, 9.884f, 0.0f, false, true, -0.373f, -1.258f)
                arcToRelative(13.874f, 13.874f, 0.0f, false, true, -0.338f, -3.059f)
                curveToRelative(0.0f, -3.584f, 1.329f, -6.67f, 3.987f, -9.256f)
                curveToRelative(2.658f, -2.586f, 5.829f, -3.88f, 9.513f, -3.88f)
                horizontalLineToRelative(1.814f)
                lineToRelative(-2.53f, -2.46f)
                arcToRelative(1.147f, 1.147f, 0.0f, true, true, 1.6f, -1.645f)
                lineToRelative(4.75f, 4.62f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.0f, 1.434f)
                lineToRelative(-4.729f, 4.601f)
                arcToRelative(1.177f, 1.177f, 0.0f, true, true, -1.641f, -1.687f)
                lineToRelative(2.466f, -2.4f)
                lineTo(24.0f, 13.347f)
                curveToRelative(-3.01f, 0.0f, -5.59f, 1.048f, -7.741f, 3.141f)
                curveToRelative(-2.152f, 2.093f, -3.228f, 4.604f, -3.228f, 7.532f)
                curveToRelative(0.0f, 0.794f, 0.078f, 1.547f, 0.232f, 2.258f)
                curveToRelative(0.107f, 0.49f, 0.23f, 0.962f, 0.37f, 1.414f)
                curveToRelative(0.114f, 0.366f, 0.023f, 0.77f, -0.252f, 1.037f)
                close()
                moveTo(24.632f, 41.22f)
                arcToRelative(1.147f, 1.147f, 0.0f, false, true, -1.6f, 0.0f)
                lineToRelative(-4.75f, -4.62f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.0f, -1.434f)
                lineToRelative(4.75f, -4.621f)
                arcToRelative(1.147f, 1.147f, 0.0f, false, true, 1.6f, 1.645f)
                lineToRelative(-2.53f, 2.461f)
                lineTo(24.0f, 34.651f)
                curveToRelative(3.01f, 0.0f, 5.59f, -1.047f, 7.741f, -3.14f)
                curveToRelative(2.152f, -2.093f, 3.228f, -4.604f, 3.228f, -7.532f)
                curveToRelative(0.0f, -0.794f, -0.07f, -1.547f, -0.211f, -2.258f)
                arcToRelative(9.59f, 9.59f, 0.0f, false, false, -0.383f, -1.39f)
                arcToRelative(1.03f, 1.03f, 0.0f, false, true, 0.23f, -1.09f)
                curveToRelative(0.676f, -0.656f, 1.797f, -0.499f, 2.133f, 0.381f)
                curveToRelative(0.165f, 0.432f, 0.3f, 0.864f, 0.403f, 1.298f)
                curveToRelative(0.24f, 1.0f, 0.359f, 2.019f, 0.359f, 3.059f)
                curveToRelative(0.0f, 3.584f, -1.329f, 6.67f, -3.987f, 9.256f)
                curveToRelative(-2.657f, 2.586f, -5.829f, 3.88f, -9.513f, 3.88f)
                horizontalLineToRelative(-1.898f)
                lineToRelative(2.53f, 2.46f)
                arcToRelative(1.15f, 1.15f, 0.0f, false, true, 0.0f, 1.645f)
                close()
            }
        }
        .build()
        return _swap!!
    }

private var _swap: ImageVector? = null
