package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ArrowRightKeyboard: ImageVector
    get() {
        if (_arrowRightKeyboard != null) {
            return _arrowRightKeyboard!!
        }
        _arrowRightKeyboard = Builder(name = "ArrowRightKeyboard", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(37.0f, 22.253f)
                curveToRelative(1.333f, 0.776f, 1.333f, 2.718f, 0.0f, 3.494f)
                lineToRelative(-24.0f, 13.98f)
                curveToRelative(-1.333f, 0.776f, -3.0f, -0.194f, -3.0f, -1.748f)
                verticalLineTo(10.021f)
                curveToRelative(0.0f, -1.554f, 1.667f, -2.524f, 3.0f, -1.748f)
                lineToRelative(24.0f, 13.98f)
                close()
            }
        }
        .build()
        return _arrowRightKeyboard!!
    }

private var _arrowRightKeyboard: ImageVector? = null
