package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.HomeOutline: ImageVector
    get() {
        if (_homeOutline != null) {
            return _homeOutline!!
        }
        _homeOutline = Builder(name = "HomeOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(23.1815f, 12.2134f)
                lineTo(13.1104f, 19.521f)
                lineTo(13.0387f, 19.5644f)
                curveTo(12.3519f, 19.9797f, 12.0f, 20.6489f, 12.0f, 21.3282f)
                verticalLineTo(34.0645f)
                curveTo(12.0f, 35.1884f, 12.8719f, 36.0f, 13.815f, 36.0f)
                horizontalLineTo(34.185f)
                curveTo(35.1252f, 36.0f, 36.0f, 35.1913f, 36.0f, 34.0645f)
                verticalLineTo(21.3191f)
                curveTo(36.0f, 20.6007f, 35.6242f, 19.9564f, 35.0685f, 19.6251f)
                lineTo(34.9919f, 19.5795f)
                lineTo(24.7484f, 12.1627f)
                curveTo(24.5407f, 12.0568f, 24.3174f, 12.0f, 24.035f, 12.0f)
                curveTo(23.7369f, 12.0f, 23.444f, 12.0709f, 23.1815f, 12.2134f)
                close()
                moveTo(21.066f, 8.8161f)
                curveTo(21.9955f, 8.2602f, 23.027f, 8.0f, 24.035f, 8.0f)
                curveTo(25.0485f, 8.0f, 25.9787f, 8.2536f, 26.8169f, 8.7355f)
                lineTo(26.9079f, 8.7879f)
                lineTo(37.2008f, 16.2404f)
                curveTo(38.9567f, 17.3279f, 40.0f, 19.2719f, 40.0f, 21.3191f)
                verticalLineTo(34.0645f)
                curveTo(40.0f, 37.2883f, 37.4448f, 40.0f, 34.185f, 40.0f)
                horizontalLineTo(13.815f)
                curveTo(10.5406f, 40.0f, 8.0f, 37.2733f, 8.0f, 34.0645f)
                verticalLineTo(21.3282f)
                curveTo(8.0f, 19.2393f, 9.0867f, 17.3159f, 10.8907f, 16.1896f)
                lineTo(20.9898f, 8.8617f)
                lineTo(21.066f, 8.8161f)
                close()
            }
        }
        .build()
        return _homeOutline!!
    }

private var _homeOutline: ImageVector? = null
