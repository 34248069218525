package com.brdgwtr.designsystem.foundation.tv

import com.brdgwtr.designsystem.foundation.BwSpacing
import com.brdgwtr.designsystem.tokens.internal.BwSpacingTokens

internal val BwTvSpacing = BwSpacing(
    xxxs = BwSpacingTokens.spacingTvXxxs,
    xxs = BwSpacingTokens.spacingTvXxs,
    xs = BwSpacingTokens.spacingTvXs,
    s = BwSpacingTokens.spacingTvS,
    m = BwSpacingTokens.spacingTvM,
    l = BwSpacingTokens.spacingTvL,
    xl = BwSpacingTokens.spacingTvXl,
    xxl = BwSpacingTokens.spacingTvXxl,
    xxxl = BwSpacingTokens.spacingTvXxxl,
)
