package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GeoShape10: ImageVector
    get() {
        if (_geoShape10 != null) {
            return _geoShape10!!
        }
        _geoShape10 = Builder(name = "GeoShape10", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.002f, 32.933f)
                lineTo(8.002f, 14.78f)
                curveToRelative(0.093f, -0.047f, 0.19f, -0.09f, 0.28f, -0.14f)
                curveToRelative(5.138f, -2.847f, 10.277f, -5.69f, 15.41f, -8.54f)
                curveToRelative(0.224f, -0.126f, 0.402f, -0.139f, 0.632f, -0.011f)
                curveToRelative(5.106f, 2.842f, 10.216f, 5.68f, 15.33f, 8.51f)
                curveToRelative(0.25f, 0.138f, 0.343f, 0.292f, 0.343f, 0.566f)
                curveToRelative(-0.004f, 5.81f, -0.006f, 11.62f, 0.004f, 17.43f)
                curveToRelative(0.0f, 0.268f, -0.099f, 0.414f, -0.323f, 0.538f)
                curveToRelative(-0.708f, 0.39f, -1.408f, 0.793f, -2.109f, 1.195f)
                curveToRelative(-4.426f, 2.54f, -8.851f, 5.084f, -13.285f, 7.613f)
                arcToRelative(0.66f, 0.66f, 0.0f, false, true, -0.543f, 0.0f)
                curveToRelative(-1.167f, -0.646f, -2.318f, -1.317f, -3.474f, -1.981f)
                curveTo(16.178f, 37.618f, 12.09f, 35.276f, 8.0f, 32.935f)
                lineToRelative(0.002f, -0.002f)
                close()
                moveTo(15.741f, 19.216f)
                lineTo(24.013f, 33.575f)
                lineTo(32.286f, 19.215f)
                lineTo(15.739f, 19.215f)
                horizontalLineToRelative(0.002f)
                close()
                moveTo(38.178f, 31.92f)
                curveToRelative(-1.572f, -3.92f, -3.111f, -7.765f, -4.696f, -11.72f)
                lineTo(25.48f, 34.086f)
                curveToRelative(4.284f, -0.73f, 8.456f, -1.443f, 12.697f, -2.165f)
                close()
                moveTo(9.851f, 31.92f)
                lineTo(22.548f, 34.083f)
                lineTo(14.619f, 20.325f)
                horizontalLineToRelative(-0.123f)
                lineTo(9.85f, 31.92f)
                close()
                moveTo(24.013f, 7.99f)
                curveToRelative(-2.669f, 3.245f, -5.29f, 6.43f, -7.951f, 9.669f)
                horizontalLineToRelative(15.907f)
                lineToRelative(-7.954f, -9.67f)
                horizontalLineToRelative(-0.002f)
                close()
                moveTo(9.566f, 16.569f)
                verticalLineToRelative(11.67f)
                arcToRelative(0.76f, 0.76f, 0.0f, false, false, 0.237f, -0.322f)
                curveToRelative(1.181f, -2.936f, 2.358f, -5.874f, 3.544f, -8.808f)
                curveToRelative(0.1f, -0.246f, 0.061f, -0.366f, -0.182f, -0.501f)
                curveToRelative(-0.907f, -0.503f, -1.798f, -1.034f, -2.697f, -1.551f)
                curveToRelative(-0.274f, -0.158f, -0.556f, -0.302f, -0.904f, -0.49f)
                lineToRelative(0.002f, 0.002f)
                close()
                moveTo(38.434f, 16.588f)
                curveToRelative(-0.182f, 0.088f, -0.304f, 0.14f, -0.418f, 0.206f)
                curveToRelative(-1.027f, 0.589f, -2.048f, 1.193f, -3.084f, 1.765f)
                curveToRelative(-0.306f, 0.17f, -0.34f, 0.323f, -0.215f, 0.633f)
                curveToRelative(1.027f, 2.538f, 2.037f, 5.083f, 3.055f, 7.625f)
                curveToRelative(0.187f, 0.47f, 0.387f, 0.933f, 0.58f, 1.4f)
                lineToRelative(0.084f, -0.023f)
                lineTo(38.436f, 16.588f)
                horizontalLineToRelative(-0.002f)
                close()
                moveTo(27.396f, 9.536f)
                lineToRelative(-0.05f, 0.065f)
                curveToRelative(0.082f, 0.115f, 0.156f, 0.237f, 0.245f, 0.346f)
                curveToRelative(1.993f, 2.427f, 3.992f, 4.85f, 5.978f, 7.286f)
                curveToRelative(0.19f, 0.234f, 0.332f, 0.188f, 0.543f, 0.066f)
                curveToRelative(1.07f, -0.625f, 2.145f, -1.239f, 3.217f, -1.86f)
                curveToRelative(0.095f, -0.056f, 0.18f, -0.129f, 0.315f, -0.225f)
                lineTo(27.398f, 9.538f)
                lineToRelative(-0.002f, -0.002f)
                close()
                moveTo(20.678f, 9.646f)
                lineTo(20.591f, 9.555f)
                lineTo(10.341f, 15.241f)
                lineTo(14.239f, 17.475f)
                lineTo(20.679f, 9.645f)
                verticalLineToRelative(0.001f)
                close()
                moveTo(34.798f, 34.173f)
                lineTo(34.759f, 34.038f)
                curveToRelative(-0.311f, 0.056f, -0.62f, 0.115f, -0.932f, 0.167f)
                curveToRelative(-1.463f, 0.246f, -2.927f, 0.488f, -4.39f, 0.735f)
                curveToRelative(-1.41f, 0.239f, -2.818f, 0.498f, -4.23f, 0.715f)
                curveToRelative(-0.34f, 0.053f, -0.461f, 0.161f, -0.457f, 0.503f)
                curveToRelative(0.015f, 1.096f, 0.004f, 2.193f, 0.006f, 3.29f)
                curveToRelative(0.0f, 0.124f, 0.024f, 0.25f, 0.045f, 0.456f)
                curveToRelative(3.38f, -1.938f, 6.69f, -3.834f, 9.998f, -5.729f)
                verticalLineToRelative(-0.002f)
                close()
                moveTo(13.25f, 34.05f)
                curveToRelative(-0.01f, 0.04f, -0.022f, 0.08f, -0.036f, 0.117f)
                lineToRelative(10.027f, 5.748f)
                curveToRelative(0.0f, -1.4f, 0.005f, -2.692f, -0.012f, -3.985f)
                curveToRelative(0.0f, -0.083f, -0.153f, -0.22f, -0.252f, -0.236f)
                curveToRelative(-1.021f, -0.188f, -2.048f, -0.353f, -3.071f, -0.53f)
                curveToRelative(-1.783f, -0.305f, -3.565f, -0.616f, -5.348f, -0.92f)
                curveToRelative(-0.434f, -0.076f, -0.871f, -0.13f, -1.308f, -0.194f)
                close()
            }
        }
        .build()
        return _geoShape10!!
    }

private var _geoShape10: ImageVector? = null
