package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.WifiLockedBad: ImageVector
    get() {
        if (_wifiLockedBad != null) {
            return _wifiLockedBad!!
        }
        _wifiLockedBad = Builder(name = "WifiLockedBad", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(44.3291f, 32.0735f)
                verticalLineTo(30.6918f)
                curveTo(44.3291f, 28.6693f, 42.6215f, 27.025f, 40.5207f, 27.025f)
                horizontalLineTo(40.2803f)
                curveTo(38.1795f, 27.025f, 36.4719f, 28.6693f, 36.4719f, 30.6918f)
                verticalLineTo(32.0735f)
                curveTo(35.6246f, 32.0735f, 34.9004f, 32.7419f, 34.9004f, 33.5578f)
                verticalLineTo(39.2907f)
                curveTo(34.9004f, 40.1067f, 35.5944f, 40.775f, 36.4417f, 40.775f)
                horizontalLineTo(44.3591f)
                curveTo(45.2064f, 40.775f, 45.9004f, 40.1067f, 45.9004f, 39.2907f)
                verticalLineTo(33.5578f)
                curveTo(45.9005f, 32.7419f, 45.1764f, 32.0735f, 44.3291f, 32.0735f)
                close()
                moveTo(43.0194f, 32.0698f)
                horizontalLineTo(37.7813f)
                verticalLineTo(30.6918f)
                curveTo(37.7813f, 29.2265f, 38.7479f, 28.2149f, 40.2694f, 28.2149f)
                horizontalLineTo(40.4004f)
                horizontalLineTo(40.5313f)
                curveTo(42.0535f, 28.2149f, 43.0188f, 29.2265f, 43.0188f, 30.6918f)
                lineTo(43.0194f, 32.0698f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(9.0195f, 19.8444f)
                curveTo(8.7198f, 20.1367f, 8.327f, 20.2828f, 7.9343f, 20.2828f)
                verticalLineTo(20.2825f)
                curveTo(7.5416f, 20.2825f, 7.1488f, 20.1364f, 6.8491f, 19.8441f)
                curveTo(6.2495f, 19.2597f, 6.2495f, 18.3121f, 6.8491f, 17.7275f)
                curveTo(11.4301f, 13.2602f, 17.5211f, 10.8f, 23.9996f, 10.8f)
                curveTo(30.478f, 10.8f, 36.5688f, 13.2602f, 41.1498f, 17.7278f)
                curveTo(41.7493f, 18.3121f, 41.7493f, 19.2597f, 41.1498f, 19.8444f)
                curveTo(40.5506f, 20.429f, 39.5785f, 20.429f, 38.9793f, 19.8444f)
                curveTo(30.7193f, 11.7895f, 17.2795f, 11.7898f, 9.0195f, 19.8444f)
                close()
                moveTo(14.126f, 24.8237f)
                curveTo(13.8262f, 25.116f, 13.4335f, 25.2621f, 13.0407f, 25.2621f)
                curveTo(12.648f, 25.2621f, 12.2553f, 25.116f, 11.9555f, 24.8237f)
                curveTo(11.356f, 24.2393f, 11.356f, 23.2917f, 11.9555f, 22.7071f)
                curveTo(18.5969f, 16.2306f, 29.403f, 16.2306f, 36.0444f, 22.7071f)
                curveTo(36.6439f, 23.2914f, 36.6439f, 24.239f, 36.0444f, 24.8237f)
                curveTo(35.4452f, 25.4083f, 34.4731f, 25.4083f, 33.8739f, 24.8237f)
                curveTo(28.4294f, 19.5143f, 19.5705f, 19.5143f, 14.126f, 24.8237f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, fillAlpha = 0.5f,
                    strokeLineWidth = 0.0f, strokeLineCap = Butt, strokeLineJoin = Miter,
                    strokeLineMiter = 4.0f, pathFillType = EvenOdd) {
                moveTo(9.0195f, 19.8444f)
                curveTo(8.7198f, 20.1367f, 8.327f, 20.2828f, 7.9343f, 20.2828f)
                verticalLineTo(20.2825f)
                curveTo(7.5416f, 20.2825f, 7.1488f, 20.1364f, 6.8491f, 19.8441f)
                curveTo(6.2495f, 19.2597f, 6.2495f, 18.3121f, 6.8491f, 17.7275f)
                curveTo(11.4301f, 13.2602f, 17.5211f, 10.8f, 23.9996f, 10.8f)
                curveTo(30.478f, 10.8f, 36.5688f, 13.2602f, 41.1498f, 17.7278f)
                curveTo(41.7493f, 18.3121f, 41.7493f, 19.2597f, 41.1498f, 19.8444f)
                curveTo(40.5506f, 20.429f, 39.5785f, 20.429f, 38.9793f, 19.8444f)
                curveTo(30.7193f, 11.7895f, 17.2795f, 11.7898f, 9.0195f, 19.8444f)
                close()
                moveTo(14.126f, 24.8237f)
                curveTo(13.8262f, 25.116f, 13.4335f, 25.2621f, 13.0407f, 25.2621f)
                curveTo(12.648f, 25.2621f, 12.2553f, 25.116f, 11.9555f, 24.8237f)
                curveTo(11.356f, 24.2393f, 11.356f, 23.2917f, 11.9555f, 22.7071f)
                curveTo(18.5969f, 16.2306f, 29.403f, 16.2306f, 36.0444f, 22.7071f)
                curveTo(36.6439f, 23.2914f, 36.6439f, 24.239f, 36.0444f, 24.8237f)
                curveTo(35.4452f, 25.4083f, 34.4731f, 25.4083f, 33.8739f, 24.8237f)
                curveTo(28.4294f, 19.5143f, 19.5705f, 19.5143f, 14.126f, 24.8237f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(23.9985f, 37.2002f)
                curveTo(25.5548f, 37.2002f, 26.8164f, 35.9699f, 26.8164f, 34.4523f)
                curveTo(26.8164f, 32.9346f, 25.5548f, 31.7043f, 23.9985f, 31.7043f)
                curveTo(22.4423f, 31.7043f, 21.1807f, 32.9346f, 21.1807f, 34.4523f)
                curveTo(21.1807f, 35.9699f, 22.4423f, 37.2002f, 23.9985f, 37.2002f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(18.1462f, 30.2412f)
                curveTo(18.5389f, 30.2412f, 18.9317f, 30.095f, 19.2315f, 29.8027f)
                curveTo(20.5051f, 28.5606f, 22.1983f, 27.8768f, 23.9993f, 27.8768f)
                curveTo(25.8004f, 27.8768f, 27.4936f, 28.5609f, 28.7673f, 29.8027f)
                curveTo(29.3665f, 30.3873f, 30.3385f, 30.3873f, 30.9377f, 29.8027f)
                curveTo(31.5373f, 29.2181f, 31.5373f, 28.2704f, 30.9377f, 27.6861f)
                curveTo(29.0844f, 25.8788f, 26.6201f, 24.8833f, 23.9993f, 24.8833f)
                curveTo(21.3786f, 24.8833f, 18.9143f, 25.8785f, 17.061f, 27.6861f)
                curveTo(16.4614f, 28.2707f, 16.4614f, 29.2184f, 17.061f, 29.803f)
                curveTo(17.3607f, 30.0953f, 17.7535f, 30.2412f, 18.1462f, 30.2415f)
                verticalLineTo(30.2412f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, fillAlpha = 0.5f,
                    strokeLineWidth = 0.0f, strokeLineCap = Butt, strokeLineJoin = Miter,
                    strokeLineMiter = 4.0f, pathFillType = NonZero) {
                moveTo(18.1462f, 30.2412f)
                curveTo(18.5389f, 30.2412f, 18.9317f, 30.095f, 19.2315f, 29.8027f)
                curveTo(20.5051f, 28.5606f, 22.1983f, 27.8768f, 23.9993f, 27.8768f)
                curveTo(25.8004f, 27.8768f, 27.4936f, 28.5609f, 28.7673f, 29.8027f)
                curveTo(29.3665f, 30.3873f, 30.3385f, 30.3873f, 30.9377f, 29.8027f)
                curveTo(31.5373f, 29.2181f, 31.5373f, 28.2704f, 30.9377f, 27.6861f)
                curveTo(29.0844f, 25.8788f, 26.6201f, 24.8833f, 23.9993f, 24.8833f)
                curveTo(21.3786f, 24.8833f, 18.9143f, 25.8785f, 17.061f, 27.6861f)
                curveTo(16.4614f, 28.2707f, 16.4614f, 29.2184f, 17.061f, 29.803f)
                curveTo(17.3607f, 30.0953f, 17.7535f, 30.2412f, 18.1462f, 30.2415f)
                verticalLineTo(30.2412f)
                close()
            }
        }
        .build()
        return _wifiLockedBad!!
    }

private var _wifiLockedBad: ImageVector? = null
