package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ArrowUp: ImageVector
    get() {
        if (_arrowUp != null) {
            return _arrowUp!!
        }
        _arrowUp = Builder(name = "ArrowUp", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(22.259f, 7.517f)
                arcToRelative(1.951f, 1.951f, 0.0f, false, true, 2.817f, 0.0f)
                lineTo(37.75f, 20.572f)
                curveToRelative(0.777f, 0.8f, 0.777f, 2.1f, 0.0f, 2.9f)
                arcToRelative(1.951f, 1.951f, 0.0f, false, true, -2.817f, 0.0f)
                lineToRelative(-9.275f, -9.552f)
                reflectiveCurveToRelative(0.175f, 21.497f, 0.175f, 23.997f)
                reflectiveCurveToRelative(-2.0f, 2.5f, -2.0f, 2.5f)
                reflectiveCurveToRelative(-2.0f, 0.0f, -2.0f, -2.5f)
                reflectiveCurveToRelative(-0.158f, -23.997f, -0.158f, -23.997f)
                lineTo(12.4f, 23.473f)
                arcToRelative(1.951f, 1.951f, 0.0f, false, true, -2.817f, 0.0f)
                arcToRelative(2.095f, 2.095f, 0.0f, false, true, 0.0f, -2.901f)
                lineTo(22.259f, 7.517f)
                close()
            }
        }
        .build()
        return _arrowUp!!
    }

private var _arrowUp: ImageVector? = null
