package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.DiscoverFilled: ImageVector
    get() {
        if (_discoverFilled != null) {
            return _discoverFilled!!
        }
        _discoverFilled = Builder(name = "DiscoverFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(4.0f, 24.0f)
                curveTo(4.0f, 12.9555f, 12.9534f, 4.0021f, 23.9979f, 4.0021f)
                curveTo(35.0425f, 4.0021f, 43.9959f, 12.9555f, 43.9959f, 24.0f)
                curveTo(43.9959f, 35.0446f, 35.0425f, 43.998f, 23.9979f, 43.998f)
                curveTo(12.9534f, 43.998f, 4.0f, 35.0446f, 4.0f, 24.0f)
                close()
                moveTo(32.4876f, 15.687f)
                curveTo(32.9118f, 16.1113f, 33.0545f, 16.7415f, 32.8543f, 17.307f)
                lineTo(29.0271f, 28.1193f)
                curveTo(28.8694f, 28.5647f, 28.5179f, 28.9144f, 28.0717f, 29.0698f)
                lineTo(17.3731f, 32.796f)
                curveTo(16.8097f, 32.9922f, 16.1836f, 32.8491f, 15.7614f, 32.4275f)
                curveTo(15.3391f, 32.0059f, 15.1951f, 31.38f, 15.3906f, 30.8162f)
                lineTo(19.1041f, 20.105f)
                curveTo(19.2591f, 19.6579f, 19.6092f, 19.3055f, 20.0554f, 19.1476f)
                lineTo(30.8676f, 15.3204f)
                curveTo(31.4332f, 15.1202f, 32.0634f, 15.2628f, 32.4876f, 15.687f)
                close()
                moveTo(21.7976f, 21.8394f)
                lineTo(19.3848f, 28.7989f)
                lineTo(26.3361f, 26.3779f)
                lineTo(21.7976f, 21.8394f)
                close()
            }
        }
        .build()
        return _discoverFilled!!
    }

private var _discoverFilled: ImageVector? = null
