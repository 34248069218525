package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ArrowDown: ImageVector
    get() {
        if (_arrowDown != null) {
            return _arrowDown!!
        }
        _arrowDown = Builder(name = "ArrowDown", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(25.075f, 39.816f)
                arcToRelative(1.951f, 1.951f, 0.0f, false, true, -2.817f, 0.0f)
                lineTo(9.583f, 26.76f)
                arcToRelative(2.095f, 2.095f, 0.0f, false, true, 0.0f, -2.9f)
                arcToRelative(1.951f, 1.951f, 0.0f, false, true, 2.817f, 0.0f)
                lineToRelative(9.275f, 9.552f)
                reflectiveCurveTo(21.5f, 11.917f, 21.5f, 9.417f)
                reflectiveCurveToRelative(2.0f, -2.5f, 2.0f, -2.5f)
                reflectiveCurveToRelative(2.0f, 0.0f, 2.0f, 2.5f)
                reflectiveCurveToRelative(0.158f, 23.996f, 0.158f, 23.996f)
                lineToRelative(9.275f, -9.552f)
                arcToRelative(1.951f, 1.951f, 0.0f, false, true, 2.817f, 0.0f)
                curveToRelative(0.778f, 0.8f, 0.778f, 2.1f, 0.0f, 2.9f)
                lineTo(25.075f, 39.817f)
                close()
            }
        }
        .build()
        return _arrowDown!!
    }

private var _arrowDown: ImageVector? = null
