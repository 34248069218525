package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RemoteOutline: ImageVector
    get() {
        if (_remoteOutline != null) {
            return _remoteOutline!!
        }
        _remoteOutline = Builder(name = "RemoteOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(15.6963f, 44.0f)
                curveTo(14.9548f, 44.0f, 14.3201f, 43.733f, 13.792f, 43.1989f)
                curveTo(13.264f, 42.6648f, 13.0f, 42.0227f, 13.0f, 41.2727f)
                verticalLineTo(6.7273f)
                curveTo(13.0f, 5.9773f, 13.264f, 5.3352f, 13.792f, 4.8011f)
                curveTo(14.3201f, 4.267f, 14.9548f, 4.0f, 15.6963f, 4.0f)
                horizontalLineTo(31.874f)
                curveTo(32.6154f, 4.0f, 33.2502f, 4.267f, 33.7782f, 4.8011f)
                curveTo(34.3062f, 5.3352f, 34.5702f, 5.9773f, 34.5702f, 6.7273f)
                verticalLineTo(41.2727f)
                curveTo(34.5702f, 42.0227f, 34.3062f, 42.6648f, 33.7782f, 43.1989f)
                curveTo(33.2502f, 43.733f, 32.6154f, 44.0f, 31.874f, 44.0f)
                horizontalLineTo(15.6963f)
                close()
                moveTo(15.6963f, 41.2727f)
                horizontalLineTo(31.874f)
                verticalLineTo(6.7273f)
                horizontalLineTo(15.6963f)
                verticalLineTo(41.2727f)
                close()
                moveTo(23.7904f, 19.9091f)
                curveTo(25.165f, 19.9091f, 26.3316f, 19.4225f, 27.2903f, 18.4492f)
                curveTo(28.249f, 17.4759f, 28.7283f, 16.2941f, 28.7283f, 14.9037f)
                curveTo(28.7283f, 13.5134f, 28.2472f, 12.3333f, 27.285f, 11.3636f)
                curveTo(26.3228f, 10.3939f, 25.1544f, 9.9091f, 23.7798f, 9.9091f)
                curveTo(22.4053f, 9.9091f, 21.2386f, 10.3957f, 20.28f, 11.369f)
                curveTo(19.3213f, 12.3422f, 18.8419f, 13.5241f, 18.8419f, 14.9145f)
                curveTo(18.8419f, 16.3048f, 19.323f, 17.4848f, 20.2853f, 18.4545f)
                curveTo(21.2474f, 19.4242f, 22.4158f, 19.9091f, 23.7904f, 19.9091f)
                close()
                moveTo(23.793f, 17.1818f)
                curveTo(23.1586f, 17.1818f, 22.6242f, 16.9648f, 22.1898f, 16.5307f)
                curveTo(21.7554f, 16.0966f, 21.5382f, 15.5587f, 21.5382f, 14.917f)
                curveTo(21.5382f, 14.2754f, 21.7528f, 13.7348f, 22.182f, 13.2955f)
                curveTo(22.6111f, 12.8561f, 23.1429f, 12.6364f, 23.7773f, 12.6364f)
                curveTo(24.4116f, 12.6364f, 24.946f, 12.8534f, 25.3804f, 13.2875f)
                curveTo(25.8148f, 13.7216f, 26.032f, 14.2594f, 26.032f, 14.9011f)
                curveTo(26.032f, 15.5428f, 25.8174f, 16.0833f, 25.3883f, 16.5227f)
                curveTo(24.9592f, 16.9621f, 24.4274f, 17.1818f, 23.793f, 17.1818f)
                close()
                moveTo(20.1979f, 27.1818f)
                curveTo(20.5822f, 27.1818f, 20.9016f, 27.0504f, 21.1562f, 26.7875f)
                curveTo(21.4109f, 26.5246f, 21.5382f, 26.1988f, 21.5382f, 25.8102f)
                curveTo(21.5382f, 25.4216f, 21.4083f, 25.0985f, 21.1484f, 24.8409f)
                curveTo(20.8885f, 24.5833f, 20.5664f, 24.4545f, 20.1822f, 24.4545f)
                curveTo(19.798f, 24.4545f, 19.4786f, 24.586f, 19.2239f, 24.8489f)
                curveTo(18.9693f, 25.1118f, 18.8419f, 25.4375f, 18.8419f, 25.8261f)
                curveTo(18.8419f, 26.2148f, 18.9719f, 26.5379f, 19.2318f, 26.7955f)
                curveTo(19.4917f, 27.053f, 19.8138f, 27.1818f, 20.1979f, 27.1818f)
                close()
                moveTo(27.388f, 27.1818f)
                curveTo(27.7722f, 27.1818f, 28.0917f, 27.0504f, 28.3463f, 26.7875f)
                curveTo(28.601f, 26.5246f, 28.7283f, 26.1988f, 28.7283f, 25.8102f)
                curveTo(28.7283f, 25.4216f, 28.5984f, 25.0985f, 28.3385f, 24.8409f)
                curveTo(28.0785f, 24.5833f, 27.7565f, 24.4545f, 27.3723f, 24.4545f)
                curveTo(26.9881f, 24.4545f, 26.6686f, 24.586f, 26.414f, 24.8489f)
                curveTo(26.1593f, 25.1118f, 26.032f, 25.4375f, 26.032f, 25.8261f)
                curveTo(26.032f, 26.2148f, 26.162f, 26.5379f, 26.4219f, 26.7955f)
                curveTo(26.6818f, 27.053f, 27.0038f, 27.1818f, 27.388f, 27.1818f)
                close()
                moveTo(20.1979f, 32.6364f)
                curveTo(20.5822f, 32.6364f, 20.9016f, 32.5049f, 21.1562f, 32.242f)
                curveTo(21.4109f, 31.9791f, 21.5382f, 31.6534f, 21.5382f, 31.2648f)
                curveTo(21.5382f, 30.8761f, 21.4083f, 30.553f, 21.1484f, 30.2955f)
                curveTo(20.8885f, 30.0379f, 20.5664f, 29.9091f, 20.1822f, 29.9091f)
                curveTo(19.798f, 29.9091f, 19.4786f, 30.0405f, 19.2239f, 30.3034f)
                curveTo(18.9693f, 30.5663f, 18.8419f, 30.8921f, 18.8419f, 31.2807f)
                curveTo(18.8419f, 31.6693f, 18.9719f, 31.9924f, 19.2318f, 32.25f)
                curveTo(19.4917f, 32.5076f, 19.8138f, 32.6364f, 20.1979f, 32.6364f)
                close()
                moveTo(27.388f, 32.6364f)
                curveTo(27.7722f, 32.6364f, 28.0917f, 32.5049f, 28.3463f, 32.242f)
                curveTo(28.601f, 31.9791f, 28.7283f, 31.6534f, 28.7283f, 31.2648f)
                curveTo(28.7283f, 30.8761f, 28.5984f, 30.553f, 28.3385f, 30.2955f)
                curveTo(28.0785f, 30.0379f, 27.7565f, 29.9091f, 27.3723f, 29.9091f)
                curveTo(26.9881f, 29.9091f, 26.6686f, 30.0405f, 26.414f, 30.3034f)
                curveTo(26.1593f, 30.5663f, 26.032f, 30.8921f, 26.032f, 31.2807f)
                curveTo(26.032f, 31.6693f, 26.162f, 31.9924f, 26.4219f, 32.25f)
                curveTo(26.6818f, 32.5076f, 27.0038f, 32.6364f, 27.388f, 32.6364f)
                close()
                moveTo(20.1979f, 38.0909f)
                curveTo(20.5822f, 38.0909f, 20.9016f, 37.9595f, 21.1562f, 37.6966f)
                curveTo(21.4109f, 37.4337f, 21.5382f, 37.1079f, 21.5382f, 36.7193f)
                curveTo(21.5382f, 36.3307f, 21.4083f, 36.0076f, 21.1484f, 35.75f)
                curveTo(20.8885f, 35.4924f, 20.5664f, 35.3636f, 20.1822f, 35.3636f)
                curveTo(19.798f, 35.3636f, 19.4786f, 35.4951f, 19.2239f, 35.758f)
                curveTo(18.9693f, 36.0209f, 18.8419f, 36.3466f, 18.8419f, 36.7352f)
                curveTo(18.8419f, 37.1239f, 18.9719f, 37.447f, 19.2318f, 37.7045f)
                curveTo(19.4917f, 37.9621f, 19.8138f, 38.0909f, 20.1979f, 38.0909f)
                close()
                moveTo(27.388f, 38.0909f)
                curveTo(27.7722f, 38.0909f, 28.0917f, 37.9595f, 28.3463f, 37.6966f)
                curveTo(28.601f, 37.4337f, 28.7283f, 37.1079f, 28.7283f, 36.7193f)
                curveTo(28.7283f, 36.3307f, 28.5984f, 36.0076f, 28.3385f, 35.75f)
                curveTo(28.0785f, 35.4924f, 27.7565f, 35.3636f, 27.3723f, 35.3636f)
                curveTo(26.9881f, 35.3636f, 26.6686f, 35.4951f, 26.414f, 35.758f)
                curveTo(26.1593f, 36.0209f, 26.032f, 36.3466f, 26.032f, 36.7352f)
                curveTo(26.032f, 37.1239f, 26.162f, 37.447f, 26.4219f, 37.7045f)
                curveTo(26.6818f, 37.9621f, 27.0038f, 38.0909f, 27.388f, 38.0909f)
                close()
            }
        }
        .build()
        return _remoteOutline!!
    }

private var _remoteOutline: ImageVector? = null
