package com.brdgwtr.designsystem.foundation.tv

import com.brdgwtr.designsystem.foundation.BwTypography
import com.brdgwtr.designsystem.tokens.internal.BwTypographyTokens

internal val BwTvTypography = BwTypography(
    display = BwTypographyTokens.typographyTvDisplay,
    headlineLarge = BwTypographyTokens.typographyTvHeadlineLarge,
    headlineMedium = BwTypographyTokens.typographyTvHeadlineMedium,
    titleLarge = BwTypographyTokens.typographyTvTitleLarge,
    titleMedium = BwTypographyTokens.typographyTvTitleMedium,
    titleSmall = BwTypographyTokens.typographyTvTitleSmall,
    bodyLarge = BwTypographyTokens.typographyTvBodyLarge,
    bodyMedium = BwTypographyTokens.typographyTvBodyMedium,
    bodySmall = BwTypographyTokens.typographyTvBodySmall,
    label = BwTypographyTokens.typographyTvLabel,
    labelInteraction = BwTypographyTokens.typographyTvLabelInteraction,
)
