package com.brdgwtr.designsystem.components

import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsFocusedAsState
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material3.ProvideTextStyle
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import com.brdgwtr.designsystem.foundation.BwContainerTemplate
import com.brdgwtr.designsystem.foundation.BwContainerTemplates
import com.brdgwtr.designsystem.foundation.BwTheme

/**
 * Artwork Tile component to display content with metadata (titles and optional subtitle) along with configurable overlays.
 *
 * @param title The title text to display under the artwork.
 * @param subtitle The optional subtitle text displayed below the title.
 * @param image The image slot to display content, mainly artwork.
 * @param imageTemplate The image container template [BwContainerTemplate] to configure the image slot to the correct
 * size and aspect ratio.
 * @param onClick The action to invoke on click.
 * @param modifier The [Modifier] to apply to the artwork tile.
 * @param onLongClick The optional action to perform on long click.
 * @param enabled Whether the artwork is enabled for focus and click events.
 * @param imageBorder The border style applied to the artwork's image.
 * @param imageScale The scale to apply to the artwork's image based on the [BwContainerTemplate].
 * @param imageOverlay An optional overlay composable displayed over the image slot.
 * @param titleAlignment Alignment of titles within the container.
 * @param interactionSource The interaction source for the focusable artwork.
 */
@Composable
@NonRestartableComposable
fun ArtworkTile(
    title: String,
    image: @Composable () -> Unit,
    imageTemplate: BwContainerTemplate,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    onLongClick: (() -> Unit)? = null,
    enabled: Boolean = true,
    subtitle: String? = null,
    imageBorder: InteractiveSurfaceBorder = ArtworkTileDefaults.imageBorder(),
    imageScale: InteractiveSurfaceScale = scaleForContainerTemplate(imageTemplate),
    imageOverlay: @Composable (BoxScope.() -> Unit)? = null,
    titleAlignment: TextAlign = TextAlign.Start,
    interactionSource: MutableInteractionSource? = null,
) {
    ArtworkTile(
        modifier = modifier,
        image = image,
        enabled = enabled,
        imageBorder = imageBorder,
        imageScale = imageScale,
        onClick = onClick,
        onLongClick = onLongClick,
        imageOverlay = imageOverlay,
        imageTemplate = imageTemplate,
        interactionSource = interactionSource,
        title = {
            Text(
                text = title,
                color = BwTheme.colors.onSurfaceBackground,
                modifier = Modifier.fillMaxWidth(),
                textAlign = titleAlignment,
                maxLines = 2,
            )
        },
        subtitle = {
            Text(
                text = subtitle.orEmpty(),
                color = BwTheme.colors.onSurfaceBackground,
                modifier = Modifier.fillMaxWidth(),
                textAlign = titleAlignment,
                maxLines = 2,
            )
        },
    )
}

/**
 * Artwork Tile component to display content with metadata (titles and optional subtitle) along with configurable overlays.
 *
 * @param image The image composable to display content, mainly artwork.
 * @param title The title composable to display under the artwork.
 * @param onClick The action to invoke on click.
 * @param imageTemplate The image container template [BwContainerTemplate] to configure the image slot to the correct
 * size and aspect ratio.
 * @param modifier The [Modifier] to apply to the artwork tile.
 * @param enabled Whether the artwork is enabled for focus and click events.
 * @param imageBorder The border style applied to the artwork's image.
 * @param imageScale The scale to apply to the artwork's image based on the [BwContainerTemplate].
 * @param onLongClick The optional action to perform on long click.
 * @param imageOverlay An optional overlay composable displayed over the image slot.
 * @param subtitle The optional subtitle composable displayed below the title.
 * @param interactionSource The interaction source for the focusable artwork.
 */
@Composable
fun ArtworkTile(
    image: @Composable () -> Unit,
    title: @Composable () -> Unit,
    onClick: (() -> Unit),
    imageTemplate: BwContainerTemplate,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    imageBorder: InteractiveSurfaceBorder = ArtworkTileDefaults.imageBorder(),
    imageScale: InteractiveSurfaceScale = scaleForContainerTemplate(imageTemplate),
    onLongClick: (() -> Unit)? = null,
    imageOverlay: @Composable (BoxScope.() -> Unit)? = null,
    subtitle: (@Composable () -> Unit)? = null,
    interactionSource: MutableInteractionSource? = null,
) {
    @Suppress("NAME_SHADOWING")
    val interactionSource = interactionSource ?: remember { MutableInteractionSource() }
    val focused by interactionSource.collectIsFocusedAsState()

    Box(
        modifier = modifier
            .zIndex(if (focused) 1f else 0f)
            .width(imageTemplate.size.width),
    ) {
        Column {
            Surface(
                interactionSource = interactionSource,
                enabled = enabled,
                shape = InteractiveSurfaceDefaults.shape(imageTemplate.shape),
                scale = imageScale,
                colors = InteractiveSurfaceDefaults.colors(
                    backgroundColor = BwTheme.colors.surfaceContainer,
                    focusedBackgroundColor = BwTheme.colors.surfaceContainer,
                    pressedBackgroundColor = BwTheme.colors.surfaceContainer,
                ),
                border = imageBorder,
                onClick = onClick,
                onLongClick = onLongClick,
                modifier = Modifier
                    .aspectRatio(imageTemplate.aspectRatio)
                    .size(imageTemplate.size),
            ) {
                Box(modifier = Modifier.fillMaxSize()) {
                    image()
                    imageOverlay?.invoke(this)
                }
            }
            Spacer(modifier = Modifier.height(BwTheme.spacing.m))
            ProvideTextStyle(value = BwTheme.typography.bodySmall) {
                title()
                subtitle?.invoke()
            }
        }
    }
}

object ArtworkTileDefaults {
    @Composable
    @ReadOnlyComposable
    fun imageBorder(
        color: Color = Color.Unspecified,
        focusedColor: Color = BwTheme.colors.surfaceInverse,
        inset: Dp = 2.dp,
        width: Dp = 2.dp,
    ): InteractiveSurfaceBorder = InteractiveSurfaceDefaults.border(
        focusedColor = focusedColor,
        color = color,
        inset = inset,
        width = width,
    )
}

@Composable
@ReadOnlyComposable
fun scaleForContainerTemplate(containerTemplate: BwContainerTemplate): InteractiveSurfaceScale =
    BwTheme.containerTemplates.scaleForContainerTemplate(containerTemplate)

@Composable
@ReadOnlyComposable
private fun BwContainerTemplates.scaleForContainerTemplate(
    containerTemplate: BwContainerTemplate,
): InteractiveSurfaceScale {
    val scale: Float = when (containerTemplate) {
        circleXS, circleS, circleM, squareM, portraitM, landscapeXS, landscapeS, landscapeM -> BwTheme.zoomScales.m
        else -> BwTheme.zoomScales.s
    }

    return InteractiveSurfaceDefaults.scale(focusedScale = scale)
}
