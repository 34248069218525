package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoMuteFilled: ImageVector
    get() {
        if (_ruwidoMuteFilled != null) {
            return _ruwidoMuteFilled!!
        }
        _ruwidoMuteFilled = Builder(name = "RuwidoMuteFilled", defaultWidth = 48.0.dp, defaultHeight
                = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(30.397f, 10.105f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.555f, 0.895f)
                verticalLineToRelative(15.367f)
                lineToRelative(6.166f, 6.204f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, true, -1.419f, 1.41f)
                lineToRelative(-4.747f, -4.777f)
                verticalLineToRelative(7.03f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, -1.606f, 0.797f)
                lineToRelative(-8.66f, -6.583f)
                lineTo(14.1f, 30.448f)
                arcToRelative(4.1f, 4.1f, 0.0f, false, true, -4.1f, -4.102f)
                verticalLineToRelative(-5.481f)
                curveToRelative(0.0f, -1.933f, 1.0f, -3.975f, 3.832f, -3.975f)
                horizontalLineToRelative(4.878f)
                lineToRelative(-4.177f, -4.203f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, true, 1.418f, -1.41f)
                lineToRelative(5.133f, 5.164f)
                lineToRelative(8.265f, -6.24f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.048f, -0.096f)
                close()
                moveTo(22.024f, 20.223f)
                verticalLineToRelative(8.73f)
                lineToRelative(6.928f, 5.265f)
                verticalLineToRelative(-7.026f)
                lineToRelative(-6.928f, -6.969f)
                close()
                moveTo(28.952f, 24.355f)
                lineTo(22.507f, 17.873f)
                lineTo(28.952f, 13.008f)
                verticalLineToRelative(11.347f)
                close()
                moveTo(20.023f, 28.448f)
                lineTo(20.023f, 18.76f)
                lineToRelative(-4.148f, 0.003f)
                lineTo(14.1f, 18.763f)
                arcToRelative(2.1f, 2.1f, 0.0f, false, false, -2.1f, 2.102f)
                verticalLineToRelative(5.48f)
                arcToRelative(2.1f, 2.1f, 0.0f, false, false, 2.1f, 2.103f)
                horizontalLineToRelative(5.923f)
                close()
            }
        }
        .build()
        return _ruwidoMuteFilled!!
    }

private var _ruwidoMuteFilled: ImageVector? = null
