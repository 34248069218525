package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.WifiGood: ImageVector
    get() {
        if (_wifiGood != null) {
            return _wifiGood!!
        }
        _wifiGood = Builder(name = "WifiGood", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(14.127f, 24.8236f)
                curveTo(13.8272f, 25.1159f, 13.4345f, 25.2621f, 13.0417f, 25.2621f)
                curveTo(12.649f, 25.2621f, 12.2562f, 25.1159f, 11.9565f, 24.8236f)
                curveTo(11.357f, 24.2392f, 11.357f, 23.2916f, 11.9565f, 22.707f)
                curveTo(18.5979f, 16.2305f, 29.404f, 16.2305f, 36.0454f, 22.707f)
                curveTo(36.6449f, 23.2913f, 36.6449f, 24.2389f, 36.0454f, 24.8236f)
                curveTo(35.4462f, 25.4082f, 34.4741f, 25.4082f, 33.8749f, 24.8236f)
                curveTo(28.4304f, 19.5142f, 19.5715f, 19.5142f, 14.127f, 24.8236f)
                close()
                moveTo(18.1464f, 30.2412f)
                curveTo(18.5391f, 30.2412f, 18.9319f, 30.095f, 19.2317f, 29.8027f)
                curveTo(20.5053f, 28.5607f, 22.1985f, 27.8769f, 23.9995f, 27.8769f)
                curveTo(25.8006f, 27.8769f, 27.4938f, 28.561f, 28.7674f, 29.8027f)
                curveTo(29.3667f, 30.3874f, 30.3387f, 30.3874f, 30.9379f, 29.8027f)
                curveTo(31.5375f, 29.2181f, 31.5375f, 28.2705f, 30.9379f, 27.6861f)
                curveTo(29.0846f, 25.8788f, 26.6203f, 24.8833f, 23.9995f, 24.8833f)
                curveTo(21.3788f, 24.8833f, 18.9145f, 25.8785f, 17.0612f, 27.6861f)
                curveTo(16.4616f, 28.2708f, 16.4616f, 29.2184f, 17.0612f, 29.803f)
                curveTo(17.3609f, 30.0953f, 17.7537f, 30.2412f, 18.1464f, 30.2415f)
                verticalLineTo(30.2412f)
                close()
                moveTo(23.9995f, 37.2001f)
                curveTo(25.5558f, 37.2001f, 26.8174f, 35.9698f, 26.8174f, 34.4522f)
                curveTo(26.8174f, 32.9346f, 25.5558f, 31.7043f, 23.9995f, 31.7043f)
                curveTo(22.4432f, 31.7043f, 21.1816f, 32.9346f, 21.1816f, 34.4522f)
                curveTo(21.1816f, 35.9698f, 22.4432f, 37.2001f, 23.9995f, 37.2001f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(7.9343f, 20.2828f)
                curveTo(8.327f, 20.2828f, 8.7198f, 20.1366f, 9.0195f, 19.8443f)
                curveTo(17.2795f, 11.7897f, 30.7193f, 11.7894f, 38.9793f, 19.8443f)
                curveTo(39.5785f, 20.4289f, 40.5506f, 20.4289f, 41.1498f, 19.8443f)
                curveTo(41.7493f, 19.2597f, 41.7493f, 18.312f, 41.1498f, 17.7277f)
                curveTo(36.5688f, 13.2601f, 30.478f, 10.8f, 23.9996f, 10.8f)
                curveTo(17.5211f, 10.8f, 11.4301f, 13.2601f, 6.8491f, 17.7274f)
                curveTo(6.2495f, 18.312f, 6.2495f, 19.2597f, 6.8491f, 19.844f)
                curveTo(7.1488f, 20.1363f, 7.5416f, 20.2825f, 7.9343f, 20.2825f)
                verticalLineTo(20.2828f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, fillAlpha = 0.5f,
                    strokeLineWidth = 0.0f, strokeLineCap = Butt, strokeLineJoin = Miter,
                    strokeLineMiter = 4.0f, pathFillType = NonZero) {
                moveTo(7.9343f, 20.2828f)
                curveTo(8.327f, 20.2828f, 8.7198f, 20.1366f, 9.0195f, 19.8443f)
                curveTo(17.2795f, 11.7897f, 30.7193f, 11.7894f, 38.9793f, 19.8443f)
                curveTo(39.5785f, 20.4289f, 40.5506f, 20.4289f, 41.1498f, 19.8443f)
                curveTo(41.7493f, 19.2597f, 41.7493f, 18.312f, 41.1498f, 17.7277f)
                curveTo(36.5688f, 13.2601f, 30.478f, 10.8f, 23.9996f, 10.8f)
                curveTo(17.5211f, 10.8f, 11.4301f, 13.2601f, 6.8491f, 17.7274f)
                curveTo(6.2495f, 18.312f, 6.2495f, 19.2597f, 6.8491f, 19.844f)
                curveTo(7.1488f, 20.1363f, 7.5416f, 20.2825f, 7.9343f, 20.2825f)
                verticalLineTo(20.2828f)
                close()
            }
        }
        .build()
        return _wifiGood!!
    }

private var _wifiGood: ImageVector? = null
