package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.SeeMore: ImageVector
    get() {
        if (_seeMore != null) {
            return _seeMore!!
        }
        _seeMore = Builder(name = "SeeMore", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(12.0f, 20.0f)
                curveTo(9.7909f, 20.0f, 8.0f, 21.7909f, 8.0f, 24.0f)
                curveTo(8.0f, 26.2091f, 9.7909f, 28.0f, 12.0f, 28.0f)
                curveTo(14.2091f, 28.0f, 16.0f, 26.2091f, 16.0f, 24.0f)
                curveTo(16.0f, 21.7909f, 14.2091f, 20.0f, 12.0f, 20.0f)
                close()
                moveTo(32.0f, 24.0f)
                curveTo(32.0f, 21.7909f, 33.7909f, 20.0f, 36.0f, 20.0f)
                curveTo(38.2091f, 20.0f, 40.0f, 21.7909f, 40.0f, 24.0f)
                curveTo(40.0f, 26.2091f, 38.2091f, 28.0f, 36.0f, 28.0f)
                curveTo(33.7909f, 28.0f, 32.0f, 26.2091f, 32.0f, 24.0f)
                close()
                moveTo(20.0f, 24.0f)
                curveTo(20.0f, 21.7909f, 21.7909f, 20.0f, 24.0f, 20.0f)
                curveTo(26.2091f, 20.0f, 28.0f, 21.7909f, 28.0f, 24.0f)
                curveTo(28.0f, 26.2091f, 26.2091f, 28.0f, 24.0f, 28.0f)
                curveTo(21.7909f, 28.0f, 20.0f, 26.2091f, 20.0f, 24.0f)
                close()
            }
        }
        .build()
        return _seeMore!!
    }

private var _seeMore: ImageVector? = null
