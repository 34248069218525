package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Pay: ImageVector
    get() {
        if (_pay != null) {
            return _pay!!
        }
        _pay = Builder(name = "Pay", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp, viewportWidth
                = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(22.512f, 43.0f)
                arcToRelative(1.624f, 1.624f, 0.0f, false, true, -1.624f, -1.624f)
                verticalLineToRelative(-2.92f)
                curveToRelative(-1.885f, -0.336f, -3.509f, -1.075f, -4.87f, -2.215f)
                curveToRelative(-1.033f, -0.863f, -1.86f, -1.988f, -2.483f, -3.374f)
                curveToRelative(-0.35f, -0.778f, 0.07f, -1.66f, 0.857f, -1.989f)
                curveToRelative(0.876f, -0.365f, 1.861f, 0.095f, 2.31f, 0.931f)
                curveToRelative(0.493f, 0.92f, 1.092f, 1.672f, 1.798f, 2.255f)
                curveToRelative(1.078f, 0.89f, 2.519f, 1.336f, 4.32f, 1.336f)
                curveToRelative(1.564f, 0.0f, 2.943f, -0.373f, 4.139f, -1.12f)
                curveToRelative(1.195f, -0.745f, 1.793f, -1.928f, 1.793f, -3.549f)
                curveToRelative(0.0f, -1.405f, -0.45f, -2.528f, -1.348f, -3.367f)
                curveToRelative(-0.898f, -0.84f, -2.756f, -1.716f, -5.575f, -2.63f)
                curveToRelative(-2.951f, -0.935f, -5.0f, -2.029f, -6.149f, -3.281f)
                curveToRelative(-1.148f, -1.253f, -1.722f, -2.829f, -1.722f, -4.727f)
                curveToRelative(0.0f, -2.186f, 0.765f, -3.905f, 2.295f, -5.16f)
                curveToRelative(1.53f, -1.254f, 3.075f, -1.95f, 4.635f, -2.09f)
                verticalLineTo(6.624f)
                arcToRelative(1.624f, 1.624f, 0.0f, true, true, 3.249f, 0.0f)
                verticalLineToRelative(2.852f)
                curveToRelative(1.652f, 0.22f, 3.04f, 0.76f, 4.165f, 1.616f)
                arcToRelative(8.317f, 8.317f, 0.0f, false, true, 1.829f, 1.93f)
                curveToRelative(0.507f, 0.741f, 0.134f, 1.719f, -0.683f, 2.093f)
                curveToRelative(-0.82f, 0.377f, -1.77f, -0.01f, -2.34f, -0.71f)
                arcToRelative(5.327f, 5.327f, 0.0f, false, false, -1.134f, -1.045f)
                curveToRelative(-0.85f, -0.58f, -1.968f, -0.869f, -3.353f, -0.869f)
                curveToRelative(-1.63f, 0.0f, -2.94f, 0.398f, -3.93f, 1.195f)
                curveToRelative(-0.99f, 0.796f, -1.485f, 1.81f, -1.485f, 3.04f)
                curveToRelative(0.0f, 1.264f, 0.507f, 2.274f, 1.52f, 3.031f)
                curveToRelative(1.014f, 0.758f, 2.898f, 1.551f, 5.652f, 2.38f)
                curveToRelative(2.56f, 0.78f, 4.47f, 1.89f, 5.731f, 3.333f)
                curveTo(31.37f, 26.912f, 32.0f, 28.644f, 32.0f, 30.665f)
                curveToRelative(0.0f, 2.374f, -0.762f, 4.217f, -2.285f, 5.528f)
                curveToRelative(-1.522f, 1.312f, -3.382f, 2.088f, -5.578f, 2.33f)
                verticalLineToRelative(2.853f)
                curveToRelative(0.0f, 0.897f, -0.727f, 1.624f, -1.625f, 1.624f)
                close()
            }
        }
        .build()
        return _pay!!
    }

private var _pay: ImageVector? = null
