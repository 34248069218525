package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.FavoriteOutline: ImageVector
    get() {
        if (_favoriteOutline != null) {
            return _favoriteOutline!!
        }
        _favoriteOutline = Builder(name = "FavoriteOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(32.4f, 4.0f)
                curveTo(29.2f, 4.0f, 26.2f, 5.4f, 24.0f, 7.7f)
                curveTo(21.8f, 5.4f, 18.8f, 4.0f, 15.6f, 4.0f)
                curveTo(9.2f, 4.0f, 4.0f, 9.3f, 4.0f, 15.8f)
                curveTo(4.0f, 20.9f, 7.0f, 26.6f, 12.5f, 32.8f)
                curveTo(16.8f, 37.6f, 21.5f, 41.3f, 22.8f, 42.3f)
                lineTo(24.0f, 43.2f)
                lineTo(25.2f, 42.3f)
                curveTo(26.5f, 41.3f, 31.2f, 37.6f, 35.5f, 32.8f)
                curveTo(41.1f, 26.6f, 44.0f, 20.9f, 44.0f, 15.8f)
                curveTo(44.0f, 9.3f, 38.8f, 4.0f, 32.4f, 4.0f)
                close()
                moveTo(15.6f, 8.0f)
                curveTo(18.4f, 8.0f, 20.9f, 9.5f, 22.3f, 12.0f)
                lineTo(24.0f, 15.2f)
                lineTo(25.7f, 12.0f)
                curveTo(27.1f, 9.5f, 29.6f, 8.0f, 32.4f, 8.0f)
                curveTo(36.6f, 8.0f, 40.1f, 11.5f, 40.1f, 15.8f)
                curveTo(40.1f, 19.6f, 37.6f, 24.5f, 32.6f, 30.1f)
                curveTo(29.4f, 33.7f, 25.9f, 36.6f, 24.0f, 38.1f)
                curveTo(22.1f, 36.6f, 18.6f, 33.6f, 15.4f, 30.1f)
                curveTo(10.4f, 24.6f, 7.9f, 19.6f, 7.9f, 15.8f)
                curveTo(7.9f, 11.5f, 11.4f, 8.0f, 15.6f, 8.0f)
                close()
            }
        }
        .build()
        return _favoriteOutline!!
    }

private var _favoriteOutline: ImageVector? = null
