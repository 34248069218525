package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GeoShape4: ImageVector
    get() {
        if (_geoShape4 != null) {
            return _geoShape4!!
        }
        _geoShape4 = Builder(name = "GeoShape4", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(40.794f, 23.815f)
                curveToRelative(0.0f, 3.017f, -0.017f, 6.034f, 0.013f, 9.052f)
                curveToRelative(0.007f, 0.664f, -0.24f, 1.032f, -0.821f, 1.327f)
                arcToRelative(2585.254f, 2585.254f, 0.0f, false, false, -14.723f, 7.57f)
                curveToRelative(-0.586f, 0.303f, -1.084f, 0.322f, -1.68f, 0.011f)
                arcToRelative(1730.373f, 1730.373f, 0.0f, false, false, -14.577f, -7.483f)
                curveToRelative(-0.736f, -0.373f, -1.011f, -0.827f, -1.006f, -1.657f)
                curveToRelative(0.033f, -5.864f, 0.03f, -11.727f, 0.002f, -17.591f)
                curveToRelative(-0.004f, -0.76f, 0.244f, -1.189f, 0.93f, -1.526f)
                arcTo(2053.26f, 2053.26f, 0.0f, false, false, 23.6f, 6.22f)
                curveToRelative(0.562f, -0.28f, 1.034f, -0.303f, 1.605f, -0.016f)
                arcToRelative(2292.24f, 2292.24f, 0.0f, false, false, 14.747f, 7.336f)
                curveToRelative(0.62f, 0.307f, 0.863f, 0.697f, 0.856f, 1.393f)
                curveToRelative(-0.032f, 2.96f, -0.013f, 5.92f, -0.013f, 8.88f)
                horizontalLineToRelative(-0.002f)
                close()
                moveTo(23.834f, 40.02f)
                curveToRelative(0.024f, -0.32f, 0.053f, -0.537f, 0.053f, -0.753f)
                curveToRelative(0.004f, -5.434f, -0.005f, -10.869f, 0.015f, -16.303f)
                curveToRelative(0.002f, -0.541f, -0.198f, -0.793f, -0.67f, -1.019f)
                curveToRelative(-4.257f, -2.035f, -8.501f, -4.097f, -12.75f, -6.15f)
                curveToRelative(-0.238f, -0.116f, -0.486f, -0.213f, -0.787f, -0.344f)
                curveToRelative(-0.024f, 0.28f, -0.046f, 0.42f, -0.046f, 0.559f)
                curveToRelative(-0.002f, 5.377f, 0.008f, 10.754f, -0.018f, 16.131f)
                curveToRelative(-0.002f, 0.575f, 0.279f, 0.784f, 0.71f, 1.005f)
                curveToRelative(4.228f, 2.16f, 8.448f, 4.334f, 12.672f, 6.503f)
                curveToRelative(0.243f, 0.125f, 0.496f, 0.226f, 0.821f, 0.373f)
                verticalLineToRelative(-0.002f)
                close()
                moveTo(10.542f, 14.559f)
                curveToRelative(0.189f, 0.127f, 0.297f, 0.22f, 0.42f, 0.279f)
                curveToRelative(4.377f, 2.12f, 8.75f, 4.246f, 13.142f, 6.33f)
                curveToRelative(0.288f, 0.136f, 0.773f, 0.055f, 1.087f, -0.093f)
                curveToRelative(4.21f, -2.006f, 8.406f, -4.04f, 12.602f, -6.072f)
                curveToRelative(0.191f, -0.091f, 0.36f, -0.226f, 0.575f, -0.361f)
                curveToRelative(-0.136f, -0.1f, -0.198f, -0.158f, -0.272f, -0.195f)
                curveToRelative(-4.402f, -2.197f, -8.8f, -4.4f, -13.217f, -6.564f)
                curveToRelative(-0.285f, -0.14f, -0.773f, -0.079f, -1.078f, 0.072f)
                curveToRelative(-3.906f, 1.915f, -7.796f, 3.862f, -11.69f, 5.803f)
                curveToRelative(-0.504f, 0.252f, -1.004f, 0.513f, -1.567f, 0.8f)
                horizontalLineToRelative(-0.002f)
                close()
                moveTo(24.954f, 26.149f)
                horizontalLineToRelative(14.19f)
                lineTo(39.144f, 24.63f)
                horizontalLineToRelative(-14.19f)
                verticalLineToRelative(1.519f)
                close()
                moveTo(39.143f, 30.619f)
                lineTo(24.98f, 30.619f)
                verticalLineToRelative(1.56f)
                horizontalLineToRelative(14.163f)
                verticalLineToRelative(-1.56f)
                close()
                moveTo(24.949f, 30.171f)
                horizontalLineToRelative(14.176f)
                verticalLineToRelative(-1.537f)
                lineTo(24.949f, 28.634f)
                verticalLineToRelative(1.537f)
                close()
                moveTo(39.152f, 22.625f)
                horizontalLineToRelative(-14.18f)
                verticalLineToRelative(1.55f)
                horizontalLineToRelative(14.18f)
                verticalLineToRelative(-1.55f)
                close()
                moveTo(24.967f, 28.125f)
                lineTo(39.12f, 28.125f)
                verticalLineToRelative(-1.49f)
                lineTo(24.967f, 26.635f)
                verticalLineToRelative(1.49f)
                close()
                moveTo(25.542f, 22.149f)
                horizontalLineToRelative(13.575f)
                verticalLineToRelative(-1.607f)
                curveToRelative(-2.854f, 0.0f, -5.643f, 0.112f, -8.418f, -0.039f)
                curveToRelative(-1.9f, -0.102f, -3.38f, 0.726f, -5.157f, 1.646f)
                close()
                moveTo(39.036f, 32.661f)
                lineTo(24.99f, 32.661f)
                verticalLineToRelative(1.561f)
                horizontalLineToRelative(0.913f)
                curveToRelative(3.133f, 0.0f, 6.268f, -0.052f, 9.399f, 0.02f)
                curveToRelative(1.519f, 0.035f, 2.55f, -0.889f, 3.735f, -1.581f)
                close()
                moveTo(35.341f, 34.633f)
                lineTo(24.995f, 34.633f)
                verticalLineToRelative(1.602f)
                curveToRelative(1.693f, 0.0f, 3.32f, -0.101f, 4.93f, 0.025f)
                curveToRelative(1.973f, 0.155f, 3.696f, -0.354f, 5.415f, -1.627f)
                horizontalLineToRelative(0.001f)
                close()
                moveTo(30.0f, 20.155f)
                horizontalLineToRelative(9.133f)
                verticalLineToRelative(-1.598f)
                curveToRelative(-1.438f, 0.0f, -2.841f, 0.121f, -4.213f, -0.03f)
                curveToRelative(-1.897f, -0.207f, -3.397f, 0.643f, -4.92f, 1.628f)
                close()
                moveTo(25.033f, 36.595f)
                curveToRelative(-0.287f, 1.649f, -0.278f, 1.662f, 1.199f, 1.66f)
                curveToRelative(0.455f, 0.0f, 0.91f, -0.022f, 1.366f, 0.003f)
                curveToRelative(1.53f, 0.083f, 2.555f, -0.977f, 3.76f, -1.662f)
                horizontalLineToRelative(-6.325f)
                verticalLineToRelative(-0.001f)
                close()
                moveTo(34.046f, 18.175f)
                horizontalLineToRelative(5.087f)
                verticalLineToRelative(-1.526f)
                curveToRelative(-2.033f, -0.573f, -3.502f, 0.66f, -5.087f, 1.526f)
                close()
                moveTo(27.48f, 38.789f)
                arcToRelative(8.102f, 8.102f, 0.0f, false, false, -0.046f, -0.213f)
                horizontalLineToRelative(-1.421f)
                curveToRelative(-1.214f, 0.0f, -1.322f, 0.156f, -0.916f, 1.429f)
                lineToRelative(2.382f, -1.218f)
                lineToRelative(0.001f, 0.002f)
                close()
            }
        }
        .build()
        return _geoShape4!!
    }

private var _geoShape4: ImageVector? = null
