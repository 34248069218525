package com.brdgwtr.designsystem.tokens.internal

import androidx.compose.ui.graphics.Color

internal object BwColorTokens {
    val baseBlack = Color(0xff000000)
    val baseWhite = Color(0xffffffff)
    val baseYellow500 = Color(0xffffbb0b)
    val baseRed500 = Color(0xffe33223)
    val baseGreen500 = Color(0xff54d334)
    val baseGrey50 = Color(0xfff2f2f2)
    val baseGrey900 = Color(0xff151617)
    val baseBlueGrey900 = Color(0xff292a30)
    val baseBlue700 = Color(0xff262b3c)
    val baseBlue800 = Color(0xff161825)
    val baseBlue900 = Color(0xff0e1019)
    val baseLightBlue300 = Color(0xffb3daff)
    val baseLightBlue500 = Color(0xff6db9ff)
    val baseLightBlue700 = Color(0xff90a6b9)
    val defaultDimmerHigh = Color(0x80000000)
    val defaultDimmerLow = Color(0x1a000000)
    val defaultOnStatusWarning = baseBlack
    val defaultOnStatusError = baseGrey50
    val defaultOnStatusSuccess = baseBlack
    val defaultStatusWarning = baseYellow500
    val defaultStatusError = baseRed500
    val defaultStatusSuccess = baseGreen500
    val defaultOnSecondary = baseBlack
    val defaultSecondary = baseLightBlue700
    val defaultOnPrimary = baseBlack
    val defaultPrimary = baseLightBlue300
    val defaultOnSurfaceInverse = baseBlack
    val defaultOnSurfaceContainer = baseGrey50
    val defaultOnSurfaceEmphasized = baseGrey50
    val defaultOnSurfaceBackground = baseGrey50
    val defaultSurfaceInverse = baseGrey50
    val defaultSurfaceContainer = baseBlueGrey900
    val defaultSurfaceEmphasized = baseBlack
    val defaultSurfaceBackground = baseGrey900
}
