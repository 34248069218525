package com.brdgwtr.designsystem.tokens.internal

import androidx.compose.ui.unit.dp

internal object BwRadiiTokens {
    val radiiXs = 1.00.dp
    val radiiS = 2.00.dp
    val radiiM = 4.00.dp
    val radiiL = 8.00.dp
    val radiiXl = 16.00.dp
    val radiiXxl = 18.00.dp
}
