package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ArrowLeftKeyboard: ImageVector
    get() {
        if (_arrowLeftKeyboard != null) {
            return _arrowLeftKeyboard!!
        }
        _arrowLeftKeyboard = Builder(name = "ArrowLeftKeyboard", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(11.0f, 25.747f)
                curveToRelative(-1.333f, -0.776f, -1.333f, -2.718f, 0.0f, -3.494f)
                lineToRelative(24.0f, -13.98f)
                curveToRelative(1.333f, -0.776f, 3.0f, 0.194f, 3.0f, 1.748f)
                verticalLineToRelative(27.958f)
                curveToRelative(0.0f, 1.553f, -1.667f, 2.524f, -3.0f, 1.748f)
                lineToRelative(-24.0f, -13.98f)
                close()
            }
        }
        .build()
        return _arrowLeftKeyboard!!
    }

private var _arrowLeftKeyboard: ImageVector? = null
