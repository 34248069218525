package com.brdgwtr.designsystem.components

import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons
import com.brdgwtr.designsystem.foundation.BwTheme
import com.brdgwtr.designsystem.foundation.contentColorFor
import com.brdgwtr.designsystem.icons.Checkmark

/**
 * Checkbox component.
 *
 * @param checked Whether the checkbox should be toggled or not.
 * @param modifier Modifier for the checkbox.
 * @param onCheckChange Optional callback for when the checkbox is selected. Setting to null will make the CheckBox
 * un-focusable.
 * @param checkedIcon Optional icon to display as the checked symbol.
 * @param enabled Whether the checkbox can be interacted with.
 * @param colors Colors to use for the checkbox.
 * @param interactionSource Interaction source for the state of the check mark.
 */
@Composable
@NonRestartableComposable
fun CheckBox(
    checked: Boolean,
    modifier: Modifier = Modifier,
    onCheckChange: ((Boolean) -> Unit)? = null,
    checkedIcon: ImageVector = Icons.Checkmark,
    enabled: Boolean = true,
    colors: SelectorColors = CheckBoxDefaults.colors(),
    interactionSource: MutableInteractionSource? = null,
) {
    Selector(
        modifier = modifier
            .semantics {
                role = Role.Checkbox
            }
            .size(CheckBoxDefaults.defaultSize),
        selectedIcon = checkedIcon,
        enabled = enabled,
        colors = colors,
        selected = checked,
        interactionSource = interactionSource,
        onSelectedChange = onCheckChange,
    )
}

object CheckBoxDefaults {
    val defaultSize: Dp = 32.dp

    @Composable
    @ReadOnlyComposable
    fun colors(
        checkedBackgroundColor: Color = BwTheme.colors.secondary,
        checkColor: Color = contentColorFor(checkedBackgroundColor),
        backgroundColor: Color = Color.Transparent,
        borderColor: Color = checkedBackgroundColor,
        checkedBorderColor: Color = checkedBackgroundColor,
        focusBackgroundColor: Color = BwTheme.colors.onSurfaceInverse,
        focusBorderColor: Color = BwTheme.colors.surfaceInverse,
        focusCheckedColor: Color = BwTheme.colors.surfaceInverse,
        focusCheckedBackgroundColor: Color = BwTheme.colors.onSurfaceInverse,
    ): SelectorColors {
        return SelectorColors(
            checkColor = checkColor,
            backgroundColor = backgroundColor,
            borderColor = borderColor,
            selectedBackgroundColor = checkedBackgroundColor,
            selectedBorderColor = checkedBorderColor,
            focusBackgroundColor = focusBackgroundColor,
            focusBorderColor = focusBorderColor,
            focusSelectedColor = focusCheckedColor,
            focusSelectedBackgroundColor = focusCheckedBackgroundColor,
        )
    }
}
