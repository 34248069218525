package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ThumbsDownFilled: ImageVector
    get() {
        if (_thumbsDownFilled != null) {
            return _thumbsDownFilled!!
        }
        _thumbsDownFilled = Builder(name = "ThumbsDownFilled", defaultWidth = 48.0.dp, defaultHeight
                = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(20.5549f, 38.9045f)
                curveTo(22.5588f, 41.2047f, 24.9634f, 41.1047f, 25.8651f, 40.9046f)
                curveTo(28.7707f, 40.4046f, 29.3719f, 37.2045f, 28.7707f, 35.5044f)
                curveTo(28.6705f, 35.3044f, 28.5f, 35.0f, 28.4701f, 34.3043f)
                curveTo(28.5703f, 30.6041f, 29.7726f, 28.904f, 32.0771f, 27.504f)
                curveTo(32.6782f, 28.004f, 33.4798f, 28.304f, 34.3815f, 28.304f)
                lineTo(40.1927f, 28.304f)
                curveTo(42.2967f, 28.304f, 44.0f, 26.5039f, 44.0f, 24.4038f)
                lineTo(44.0f, 10.7031f)
                curveTo(44.0f, 8.803f, 42.2967f, 7.0029f, 40.1927f, 7.0029f)
                lineTo(34.3815f, 7.0029f)
                curveTo(33.079f, 7.0029f, 32.0771f, 7.6029f, 31.3757f, 8.603f)
                curveTo(29.973f, 7.7029f, 28.7707f, 7.2029f, 27.0674f, 7.0029f)
                curveTo(26.867f, 7.0029f, 12.1387f, 7.0029f, 12.1387f, 7.0029f)
                curveTo(9.7341f, 6.9029f, 7.2293f, 9.403f, 8.131f, 12.2032f)
                curveTo(6.5279f, 13.1032f, 5.7264f, 15.3033f, 6.4277f, 17.2034f)
                curveTo(4.5241f, 18.2035f, 3.7225f, 21.2036f, 5.1252f, 23.2037f)
                curveTo(2.6204f, 26.0039f, 4.6242f, 30.6041f, 7.9306f, 30.8041f)
                curveTo(8.0308f, 30.8041f, 18.3507f, 30.8041f, 18.8516f, 30.9041f)
                curveTo(18.4508f, 34.4043f, 18.9518f, 37.1044f, 20.5549f, 38.9045f)
                close()
                moveTo(40.6936f, 24.5038f)
                curveTo(40.6936f, 24.8038f, 40.4933f, 25.0038f, 40.1927f, 25.0038f)
                lineTo(34.3815f, 25.0038f)
                curveTo(34.1811f, 25.0038f, 33.8805f, 24.8038f, 33.8805f, 24.5038f)
                lineTo(33.8805f, 10.8031f)
                curveTo(33.8805f, 10.5031f, 34.0809f, 10.3031f, 34.3815f, 10.3031f)
                lineTo(40.1927f, 10.3031f)
                curveTo(40.3931f, 10.3031f, 40.6936f, 10.5031f, 40.6936f, 10.8031f)
                lineTo(40.6936f, 24.5038f)
                close()
            }
        }
        .build()
        return _thumbsDownFilled!!
    }

private var _thumbsDownFilled: ImageVector? = null
