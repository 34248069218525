package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.NotificationOutline: ImageVector
    get() {
        if (_notificationOutline != null) {
            return _notificationOutline!!
        }
        _notificationOutline = Builder(name = "NotificationOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.5f, 38.0f)
                arcToRelative(1.5f, 1.5f, 0.0f, false, true, 0.0f, -3.0f)
                lineTo(12.0f, 35.0f)
                lineTo(12.0f, 19.8f)
                curveToRelative(0.0f, -2.8f, 0.825f, -5.308f, 2.475f, -7.525f)
                curveTo(16.125f, 10.058f, 18.3f, 8.667f, 21.0f, 8.1f)
                lineTo(21.0f, 7.0f)
                curveToRelative(0.0f, -0.833f, 0.292f, -1.542f, 0.875f, -2.125f)
                arcTo(2.893f, 2.893f, 0.0f, false, true, 24.0f, 4.0f)
                curveToRelative(0.833f, 0.0f, 1.542f, 0.292f, 2.125f, 0.875f)
                reflectiveCurveTo(27.0f, 6.167f, 27.0f, 7.0f)
                verticalLineToRelative(1.1f)
                curveToRelative(2.7f, 0.567f, 4.875f, 1.958f, 6.525f, 4.175f)
                curveTo(35.175f, 14.492f, 36.0f, 17.0f, 36.0f, 19.8f)
                lineTo(36.0f, 35.0f)
                horizontalLineToRelative(2.5f)
                arcToRelative(1.5f, 1.5f, 0.0f, false, true, 0.0f, 3.0f)
                horizontalLineToRelative(-29.0f)
                close()
                moveTo(24.0f, 44.0f)
                curveToRelative(-1.1f, 0.0f, -2.042f, -0.392f, -2.825f, -1.175f)
                curveTo(20.392f, 42.042f, 20.0f, 41.1f, 20.0f, 40.0f)
                horizontalLineToRelative(8.0f)
                curveToRelative(0.0f, 1.1f, -0.392f, 2.042f, -1.175f, 2.825f)
                curveTo(26.042f, 43.608f, 25.1f, 44.0f, 24.0f, 44.0f)
                close()
                moveTo(15.0f, 35.0f)
                horizontalLineToRelative(18.0f)
                lineTo(33.0f, 19.8f)
                curveToRelative(0.0f, -2.5f, -0.875f, -4.625f, -2.625f, -6.375f)
                reflectiveCurveTo(26.5f, 10.8f, 24.0f, 10.8f)
                reflectiveCurveToRelative(-4.625f, 0.875f, -6.375f, 2.625f)
                reflectiveCurveTo(15.0f, 17.3f, 15.0f, 19.8f)
                lineTo(15.0f, 35.0f)
                close()
            }
        }
        .build()
        return _notificationOutline!!
    }

private var _notificationOutline: ImageVector? = null
