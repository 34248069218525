package com.brdgwtr.designsystem.foundation.mobile

import com.brdgwtr.designsystem.foundation.BwSpacing
import com.brdgwtr.designsystem.tokens.internal.BwSpacingTokens

internal val BwMobileSpacing = BwSpacing(
    xxxs = BwSpacingTokens.spacingMobileXxxs,
    xxs = BwSpacingTokens.spacingMobileXxs,
    xs = BwSpacingTokens.spacingMobileXs,
    s = BwSpacingTokens.spacingMobileS,
    m = BwSpacingTokens.spacingMobileM,
    l = BwSpacingTokens.spacingMobileL,
    xl = BwSpacingTokens.spacingMobileXl,
    xxl = BwSpacingTokens.spacingMobileXxl,
    xxxl = BwSpacingTokens.spacingMobileXxxl,
)
