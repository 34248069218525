package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RtVerifiedHot: ImageVector
    get() {
        if (_rtVerifiedHot != null) {
            return _rtVerifiedHot!!
        }
        _rtVerifiedHot = Builder(name = "RtVerifiedHot", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFF4CBFB4)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(37.1107f, 32.8197f)
                curveTo(37.1107f, 32.8197f, 37.1212f, 32.8091f, 37.1318f, 32.7985f)
                curveTo(37.1953f, 32.7456f, 37.2693f, 32.6822f, 37.3328f, 32.6293f)
                curveTo(37.3328f, 32.6293f, 37.3433f, 32.6187f, 37.3539f, 32.6081f)
                curveTo(37.4174f, 32.5447f, 37.4808f, 32.4918f, 37.5443f, 32.4284f)
                curveTo(37.5443f, 32.4284f, 37.5548f, 32.4178f, 37.5654f, 32.4072f)
                curveTo(37.6289f, 32.3437f, 37.6817f, 32.2803f, 37.7452f, 32.2063f)
                curveTo(37.7452f, 32.2063f, 37.7452f, 32.2063f, 37.7558f, 32.1957f)
                curveTo(37.8087f, 32.1322f, 37.8615f, 32.0688f, 37.9144f, 31.9948f)
                curveTo(37.9144f, 31.9948f, 37.925f, 31.9736f, 37.9356f, 31.9736f)
                curveTo(37.9884f, 31.8996f, 38.0413f, 31.8361f, 38.0836f, 31.7621f)
                curveTo(38.0836f, 31.7621f, 38.0836f, 31.7515f, 38.0942f, 31.7409f)
                curveTo(38.1365f, 31.6669f, 38.1788f, 31.6035f, 38.2211f, 31.5294f)
                curveTo(38.2211f, 31.5188f, 38.2317f, 31.5083f, 38.2423f, 31.4977f)
                curveTo(38.2846f, 31.4237f, 38.3163f, 31.3496f, 38.3586f, 31.2756f)
                curveTo(38.3586f, 31.265f, 38.3692f, 31.2545f, 38.3797f, 31.2439f)
                curveTo(38.4115f, 31.1804f, 38.4432f, 31.1064f, 38.4749f, 31.0324f)
                curveTo(38.4749f, 31.0112f, 38.4961f, 31.0006f, 38.4961f, 30.9795f)
                curveTo(38.5278f, 30.916f, 38.5489f, 30.842f, 38.5701f, 30.768f)
                curveTo(38.5701f, 30.7468f, 38.5807f, 30.7257f, 38.5913f, 30.7045f)
                curveTo(38.6124f, 30.6305f, 38.6336f, 30.567f, 38.6547f, 30.493f)
                curveTo(38.6547f, 30.4719f, 38.6653f, 30.4507f, 38.6759f, 30.4296f)
                curveTo(38.697f, 30.3555f, 38.7076f, 30.2815f, 38.7287f, 30.2075f)
                curveTo(38.7287f, 30.1863f, 38.7393f, 30.1652f, 38.7393f, 30.144f)
                curveTo(38.7499f, 30.07f, 38.771f, 29.996f, 38.7816f, 29.9114f)
                curveTo(38.7816f, 29.8902f, 38.7816f, 29.8691f, 38.7816f, 29.8585f)
                curveTo(38.7922f, 29.7739f, 38.8028f, 29.6893f, 38.8028f, 29.6047f)
                curveTo(38.8028f, 29.5941f, 38.8028f, 29.5729f, 38.8028f, 29.5624f)
                curveTo(38.8028f, 29.4672f, 38.8028f, 29.3614f, 38.8028f, 29.2662f)
                verticalLineTo(16.1842f)
                curveTo(38.8028f, 14.4075f, 37.7558f, 12.7789f, 36.1377f, 12.0491f)
                lineTo(25.3823f, 7.1421f)
                curveTo(24.7795f, 6.8671f, 24.145f, 6.7296f, 23.4998f, 6.7296f)
                curveTo(22.8547f, 6.7296f, 22.2096f, 6.8671f, 21.6174f, 7.1421f)
                lineTo(10.8408f, 12.0491f)
                curveTo(9.2228f, 12.7894f, 8.1758f, 14.4075f, 8.1758f, 16.1842f)
                verticalLineTo(29.2662f)
                curveTo(8.1758f, 29.4672f, 8.1864f, 29.6575f, 8.2181f, 29.8585f)
                curveTo(8.2181f, 29.8902f, 8.2181f, 29.9114f, 8.2287f, 29.9431f)
                curveTo(8.2287f, 30.0065f, 8.2498f, 30.0806f, 8.2604f, 30.144f)
                curveTo(8.2604f, 30.1757f, 8.271f, 30.2075f, 8.2815f, 30.2392f)
                curveTo(8.2921f, 30.3027f, 8.3133f, 30.3661f, 8.3238f, 30.4296f)
                curveTo(8.3238f, 30.4507f, 8.3344f, 30.4824f, 8.345f, 30.5036f)
                curveTo(8.3661f, 30.567f, 8.3873f, 30.6411f, 8.4084f, 30.7045f)
                curveTo(8.4084f, 30.7257f, 8.4296f, 30.7468f, 8.4296f, 30.768f)
                curveTo(8.4508f, 30.8314f, 8.4825f, 30.9055f, 8.5036f, 30.9689f)
                curveTo(8.5142f, 31.0006f, 8.5248f, 31.0218f, 8.5459f, 31.0535f)
                curveTo(8.5671f, 31.117f, 8.5988f, 31.1699f, 8.6305f, 31.2333f)
                curveTo(8.6305f, 31.2545f, 8.6517f, 31.2756f, 8.6623f, 31.2968f)
                curveTo(8.694f, 31.3602f, 8.7257f, 31.4237f, 8.7574f, 31.4871f)
                curveTo(8.768f, 31.5083f, 8.7786f, 31.5294f, 8.7892f, 31.5506f)
                curveTo(8.8209f, 31.614f, 8.8632f, 31.6775f, 8.9055f, 31.7409f)
                curveTo(8.9055f, 31.7515f, 8.9267f, 31.7727f, 8.9372f, 31.7832f)
                curveTo(8.9795f, 31.8467f, 9.0218f, 31.9101f, 9.0641f, 31.9736f)
                curveTo(9.0641f, 31.9842f, 9.0853f, 31.9948f, 9.0853f, 32.0053f)
                curveTo(9.1382f, 32.0688f, 9.1805f, 32.1322f, 9.2333f, 32.1957f)
                curveTo(9.2333f, 32.2063f, 9.2545f, 32.2168f, 9.2651f, 32.2274f)
                curveTo(9.318f, 32.2909f, 9.3708f, 32.3543f, 9.4343f, 32.4072f)
                curveTo(9.4343f, 32.4072f, 9.4554f, 32.4284f, 9.466f, 32.4389f)
                curveTo(9.5189f, 32.5024f, 9.5823f, 32.5553f, 9.6458f, 32.6081f)
                curveTo(9.6564f, 32.6187f, 9.6669f, 32.6293f, 9.6881f, 32.6399f)
                curveTo(9.7515f, 32.6927f, 9.8044f, 32.7456f, 9.8679f, 32.7985f)
                curveTo(9.8785f, 32.7985f, 9.889f, 32.8197f, 9.8996f, 32.8302f)
                curveTo(9.9631f, 32.8831f, 10.0265f, 32.9254f, 10.09f, 32.9783f)
                curveTo(10.1005f, 32.9889f, 10.1217f, 32.9994f, 10.1323f, 33.01f)
                curveTo(10.1957f, 33.0523f, 10.2592f, 33.0946f, 10.3332f, 33.1369f)
                curveTo(10.7034f, 33.0735f, 11.0735f, 33.0206f, 11.4436f, 32.9571f)
                horizontalLineTo(35.5349f)
                curveTo(35.905f, 33.01f, 36.2752f, 33.0735f, 36.6559f, 33.1369f)
                curveTo(36.7299f, 33.0946f, 36.804f, 33.0417f, 36.8674f, 32.9889f)
                curveTo(36.8674f, 32.9889f, 36.8886f, 32.9783f, 36.8992f, 32.9677f)
                curveTo(36.9732f, 32.9148f, 37.0366f, 32.862f, 37.1107f, 32.8091f)
                verticalLineTo(32.8197f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(36.4656f, 11.3724f)
                lineTo(25.6891f, 6.4759f)
                curveTo(24.2931f, 5.8414f, 22.6962f, 5.8414f, 21.3108f, 6.4759f)
                lineTo(10.5342f, 11.3724f)
                curveTo(8.6517f, 12.229f, 7.4356f, 14.1221f, 7.4356f, 16.1843f)
                verticalLineTo(29.2664f)
                curveTo(7.4356f, 30.8421f, 8.1441f, 32.3227f, 9.3286f, 33.3168f)
                curveTo(9.667f, 33.2534f, 10.0054f, 33.2005f, 10.3333f, 33.137f)
                curveTo(10.2698f, 33.0947f, 10.2064f, 33.0524f, 10.1429f, 33.0101f)
                curveTo(10.1323f, 32.9995f, 10.1112f, 32.989f, 10.1006f, 32.9784f)
                curveTo(10.0371f, 32.9361f, 9.9737f, 32.8832f, 9.9102f, 32.8303f)
                curveTo(9.8997f, 32.8303f, 9.8891f, 32.8092f, 9.8785f, 32.7986f)
                curveTo(9.8151f, 32.7457f, 9.7516f, 32.6929f, 9.6987f, 32.64f)
                curveTo(9.6882f, 32.6294f, 9.6776f, 32.6188f, 9.6564f, 32.6082f)
                curveTo(9.593f, 32.5554f, 9.5401f, 32.4919f, 9.4766f, 32.439f)
                curveTo(9.4766f, 32.439f, 9.4555f, 32.4179f, 9.4449f, 32.4073f)
                curveTo(9.392f, 32.3439f, 9.3392f, 32.291f, 9.2757f, 32.2275f)
                curveTo(9.2757f, 32.2275f, 9.2545f, 32.2064f, 9.244f, 32.1958f)
                curveTo(9.1911f, 32.1323f, 9.1382f, 32.0689f, 9.0959f, 32.0054f)
                curveTo(9.0959f, 32.0054f, 9.0748f, 31.9843f, 9.0748f, 31.9737f)
                curveTo(9.0325f, 31.9103f, 8.9902f, 31.8468f, 8.9479f, 31.7833f)
                curveTo(8.9479f, 31.7728f, 8.9267f, 31.7516f, 8.9161f, 31.741f)
                curveTo(8.8738f, 31.6776f, 8.8421f, 31.6141f, 8.7998f, 31.5507f)
                curveTo(8.7892f, 31.5295f, 8.7787f, 31.5084f, 8.7681f, 31.4872f)
                curveTo(8.7364f, 31.4238f, 8.7046f, 31.3603f, 8.6729f, 31.2969f)
                curveTo(8.6729f, 31.2757f, 8.6517f, 31.2546f, 8.6412f, 31.2334f)
                curveTo(8.6094f, 31.17f, 8.5883f, 31.1171f, 8.5566f, 31.0536f)
                curveTo(8.546f, 31.0219f, 8.5354f, 31.0008f, 8.5143f, 30.969f)
                curveTo(8.4825f, 30.9056f, 8.4614f, 30.8315f, 8.4402f, 30.7681f)
                curveTo(8.4402f, 30.7469f, 8.4191f, 30.7258f, 8.4191f, 30.7046f)
                curveTo(8.3979f, 30.6412f, 8.3768f, 30.5672f, 8.3556f, 30.5037f)
                curveTo(8.3556f, 30.4825f, 8.345f, 30.4508f, 8.3345f, 30.4297f)
                curveTo(8.3133f, 30.3662f, 8.3027f, 30.3028f, 8.2922f, 30.2393f)
                curveTo(8.2922f, 30.2076f, 8.2816f, 30.1759f, 8.271f, 30.1441f)
                curveTo(8.2604f, 30.0807f, 8.2499f, 30.0066f, 8.2393f, 29.9432f)
                curveTo(8.2393f, 29.9115f, 8.2393f, 29.8903f, 8.2287f, 29.8586f)
                curveTo(8.2076f, 29.6682f, 8.1864f, 29.4673f, 8.1864f, 29.2664f)
                verticalLineTo(16.1843f)
                curveTo(8.1864f, 14.4076f, 9.2334f, 12.779f, 10.8515f, 12.0493f)
                lineTo(21.6174f, 7.1422f)
                curveTo(22.2203f, 6.8672f, 22.8548f, 6.7297f, 23.4999f, 6.7297f)
                curveTo(24.145f, 6.7297f, 24.7901f, 6.8672f, 25.3824f, 7.1422f)
                lineTo(36.1589f, 12.0493f)
                curveTo(37.777f, 12.7895f, 38.824f, 14.4076f, 38.824f, 16.1843f)
                verticalLineTo(29.2664f)
                curveTo(38.824f, 29.3615f, 38.824f, 29.4673f, 38.824f, 29.5625f)
                curveTo(38.824f, 29.573f, 38.824f, 29.5942f, 38.824f, 29.6048f)
                curveTo(38.824f, 29.6894f, 38.8134f, 29.774f, 38.8028f, 29.8586f)
                curveTo(38.8028f, 29.8797f, 38.8028f, 29.9009f, 38.8028f, 29.9115f)
                curveTo(38.7922f, 29.9855f, 38.7817f, 30.0701f, 38.7605f, 30.1441f)
                curveTo(38.7605f, 30.1653f, 38.7605f, 30.1864f, 38.7499f, 30.2076f)
                curveTo(38.7394f, 30.2816f, 38.7182f, 30.3556f, 38.6971f, 30.4297f)
                curveTo(38.6971f, 30.4508f, 38.6865f, 30.472f, 38.6759f, 30.4931f)
                curveTo(38.6548f, 30.5672f, 38.6336f, 30.6306f, 38.6125f, 30.7046f)
                curveTo(38.6125f, 30.7258f, 38.6019f, 30.7469f, 38.5913f, 30.7681f)
                curveTo(38.5702f, 30.8421f, 38.5384f, 30.9056f, 38.5173f, 30.9796f)
                curveTo(38.5173f, 31.0008f, 38.4961f, 31.0113f, 38.4961f, 31.0325f)
                curveTo(38.4644f, 31.1065f, 38.4327f, 31.17f, 38.4009f, 31.244f)
                curveTo(38.4009f, 31.2546f, 38.3904f, 31.2651f, 38.3798f, 31.2757f)
                curveTo(38.3481f, 31.3497f, 38.3058f, 31.4238f, 38.2635f, 31.4978f)
                curveTo(38.2635f, 31.5084f, 38.2529f, 31.519f, 38.2423f, 31.5295f)
                curveTo(38.2f, 31.6036f, 38.1577f, 31.6776f, 38.1154f, 31.741f)
                curveTo(38.1154f, 31.741f, 38.1154f, 31.7516f, 38.1048f, 31.7622f)
                curveTo(38.0625f, 31.8362f, 38.0096f, 31.9103f, 37.9568f, 31.9737f)
                curveTo(37.9568f, 31.9737f, 37.9462f, 31.9949f, 37.9356f, 31.9949f)
                curveTo(37.8827f, 32.0583f, 37.8299f, 32.1323f, 37.777f, 32.1958f)
                curveTo(37.777f, 32.1958f, 37.777f, 32.1958f, 37.7664f, 32.2064f)
                curveTo(37.7135f, 32.2698f, 37.6501f, 32.3439f, 37.5866f, 32.4073f)
                curveTo(37.5866f, 32.4073f, 37.576f, 32.4179f, 37.5655f, 32.4285f)
                curveTo(37.502f, 32.4919f, 37.4386f, 32.5554f, 37.3751f, 32.6082f)
                curveTo(37.3751f, 32.6082f, 37.3645f, 32.6188f, 37.354f, 32.6294f)
                curveTo(37.2905f, 32.6929f, 37.227f, 32.7457f, 37.153f, 32.7986f)
                curveTo(37.153f, 32.7986f, 37.1424f, 32.8092f, 37.1319f, 32.8198f)
                curveTo(37.0684f, 32.8726f, 36.9944f, 32.9255f, 36.9204f, 32.9784f)
                curveTo(36.9204f, 32.9784f, 36.8992f, 32.989f, 36.8886f, 32.9995f)
                curveTo(36.8252f, 33.0524f, 36.7511f, 33.0947f, 36.6771f, 33.137f)
                curveTo(37.0155f, 33.1899f, 37.3434f, 33.2534f, 37.6818f, 33.3168f)
                curveTo(38.8557f, 32.3227f, 39.5643f, 30.8527f, 39.5643f, 29.2769f)
                verticalLineTo(16.1949f)
                curveTo(39.5643f, 14.1326f, 38.3481f, 12.2396f, 36.4656f, 11.383f)
                verticalLineTo(11.3724f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.2819f, 30.2393f)
                curveTo(8.2819f, 30.2393f, 8.2713f, 30.1759f, 8.2607f, 30.1442f)
                curveTo(8.2607f, 30.1759f, 8.2713f, 30.2076f, 8.2819f, 30.2393f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.8971f, 32.8285f)
                curveTo(9.8971f, 32.8285f, 9.8772f, 32.8086f, 9.8672f, 32.7986f)
                curveTo(9.8772f, 32.7986f, 9.8871f, 32.8185f, 9.8971f, 32.8285f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.6888f, 32.6505f)
                curveTo(9.6888f, 32.6505f, 9.6676f, 32.6294f, 9.6465f, 32.6188f)
                curveTo(9.6571f, 32.6294f, 9.6676f, 32.6399f, 9.6888f, 32.6505f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.2283f, 29.9432f)
                curveTo(8.2283f, 29.9432f, 8.2283f, 29.8904f, 8.2178f, 29.8586f)
                curveTo(8.2178f, 29.8904f, 8.2178f, 29.9115f, 8.2283f, 29.9432f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.3551f, 30.5037f)
                curveTo(8.3551f, 30.5037f, 8.3446f, 30.4508f, 8.334f, 30.4297f)
                curveTo(8.334f, 30.4508f, 8.3446f, 30.4826f, 8.3551f, 30.5037f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.8003f, 31.5506f)
                curveTo(8.8003f, 31.5506f, 8.7791f, 31.5083f, 8.7685f, 31.4872f)
                curveTo(8.7791f, 31.5083f, 8.7897f, 31.5295f, 8.8003f, 31.5506f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.937f, 31.7833f)
                curveTo(8.937f, 31.7833f, 8.9158f, 31.7515f, 8.9053f, 31.741f)
                curveTo(8.9053f, 31.7515f, 8.9264f, 31.7727f, 8.937f, 31.7833f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.5452f, 31.0642f)
                curveTo(8.5452f, 31.0642f, 8.5241f, 31.0113f, 8.5029f, 30.9796f)
                curveTo(8.5135f, 31.0113f, 8.5241f, 31.0325f, 8.5452f, 31.0642f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.6616f, 31.3073f)
                curveTo(8.6616f, 31.3073f, 8.6405f, 31.265f, 8.6299f, 31.2439f)
                curveTo(8.6299f, 31.265f, 8.651f, 31.2862f, 8.6616f, 31.3073f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.4635f, 32.4373f)
                curveTo(9.4635f, 32.4373f, 9.4436f, 32.4173f, 9.4336f, 32.4073f)
                curveTo(9.4336f, 32.4073f, 9.4535f, 32.4273f, 9.4635f, 32.4373f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.0942f, 32.0035f)
                curveTo(9.0942f, 32.0035f, 9.0742f, 31.9836f, 9.0742f, 31.9736f)
                curveTo(9.0742f, 31.9836f, 9.0942f, 31.9936f, 9.0942f, 32.0035f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.275f, 32.2363f)
                curveTo(9.275f, 32.2363f, 9.2551f, 32.2164f, 9.2451f, 32.2064f)
                curveTo(9.2451f, 32.2164f, 9.2651f, 32.2264f, 9.275f, 32.2363f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.4411f, 30.7787f)
                curveTo(8.4411f, 30.7787f, 8.4199f, 30.7364f, 8.4199f, 30.7152f)
                curveTo(8.4199f, 30.7364f, 8.4411f, 30.7575f, 8.4411f, 30.7787f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(37.142f, 32.7985f)
                curveTo(37.142f, 32.7985f, 37.132f, 32.8084f, 37.1221f, 32.8184f)
                curveTo(37.1221f, 32.8184f, 37.132f, 32.8084f, 37.142f, 32.7985f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(37.7551f, 32.1957f)
                curveTo(37.7551f, 32.1957f, 37.7551f, 32.1957f, 37.7451f, 32.2057f)
                curveTo(37.7451f, 32.2057f, 37.7451f, 32.2057f, 37.7551f, 32.1957f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(37.9233f, 31.9736f)
                curveTo(37.9233f, 31.9736f, 37.9133f, 31.9936f, 37.9033f, 31.9936f)
                curveTo(37.9033f, 31.9936f, 37.9133f, 31.9736f, 37.9233f, 31.9736f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(38.0949f, 31.741f)
                curveTo(38.0949f, 31.741f, 38.0949f, 31.7509f, 38.085f, 31.7609f)
                curveTo(38.085f, 31.7609f, 38.085f, 31.7509f, 38.0949f, 31.741f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(37.5551f, 32.4072f)
                curveTo(37.5551f, 32.4072f, 37.5451f, 32.4172f, 37.5352f, 32.4272f)
                curveTo(37.5352f, 32.4272f, 37.5451f, 32.4172f, 37.5551f, 32.4072f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(36.8779f, 32.9982f)
                curveTo(36.8779f, 32.9982f, 36.8979f, 32.9882f, 36.9078f, 32.9783f)
                curveTo(36.9078f, 32.9783f, 36.8879f, 32.9882f, 36.8779f, 32.9982f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(10.1439f, 33.0102f)
                curveTo(10.1439f, 33.0102f, 10.1121f, 32.9891f, 10.1016f, 32.9785f)
                curveTo(10.1121f, 32.9891f, 10.1333f, 32.9997f, 10.1439f, 33.0102f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(37.353f, 32.6083f)
                curveTo(37.353f, 32.6083f, 37.343f, 32.6182f, 37.333f, 32.6282f)
                curveTo(37.333f, 32.6282f, 37.343f, 32.6182f, 37.353f, 32.6083f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(38.5915f, 30.7046f)
                curveTo(38.5915f, 30.7046f, 38.5809f, 30.7469f, 38.5703f, 30.768f)
                curveTo(38.5703f, 30.7469f, 38.5809f, 30.7257f, 38.5915f, 30.7046f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(38.7401f, 30.144f)
                curveTo(38.7401f, 30.144f, 38.7401f, 30.1863f, 38.7295f, 30.2075f)
                curveTo(38.7295f, 30.1863f, 38.7401f, 30.1652f, 38.7401f, 30.144f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(38.6647f, 30.4297f)
                curveTo(38.6647f, 30.4297f, 38.6541f, 30.472f, 38.6436f, 30.4931f)
                curveTo(38.6436f, 30.472f, 38.6541f, 30.4508f, 38.6647f, 30.4297f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(38.2416f, 31.4872f)
                curveTo(38.2416f, 31.4872f, 38.2317f, 31.5071f, 38.2217f, 31.5171f)
                curveTo(38.2217f, 31.5071f, 38.2317f, 31.4972f, 38.2416f, 31.4872f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(38.3676f, 31.2334f)
                curveTo(38.3676f, 31.2334f, 38.3576f, 31.2533f, 38.3477f, 31.2633f)
                curveTo(38.3477f, 31.2533f, 38.3576f, 31.2434f, 38.3676f, 31.2334f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(38.486f, 30.9796f)
                curveTo(38.486f, 30.9796f, 38.4648f, 31.0113f, 38.4648f, 31.0325f)
                curveTo(38.4648f, 31.0113f, 38.486f, 31.0008f, 38.486f, 30.9796f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFD700)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(26.4291f, 37.1663f)
                curveTo(26.3127f, 37.1874f, 26.1964f, 37.198f, 26.0801f, 37.2191f)
                curveTo(26.1858f, 37.1874f, 26.3022f, 37.1663f, 26.4291f, 37.1663f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFD215)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.855f, 40.2438f)
                curveTo(11.9514f, 40.3601f, 10.3122f, 41.2908f, 9.3604f, 42.5704f)
                curveTo(9.3287f, 42.6022f, 9.2864f, 42.6022f, 9.2652f, 42.5704f)
                curveTo(8.9374f, 41.2485f, 8.8422f, 39.8314f, 9.0114f, 38.4037f)
                curveTo(9.0114f, 38.3931f, 9.0008f, 38.3825f, 8.9797f, 38.3719f)
                curveTo(8.0808f, 38.0864f, 7.0866f, 37.9383f, 6.0397f, 37.9489f)
                curveTo(5.9868f, 37.9489f, 5.9868f, 37.896f, 6.0397f, 37.8431f)
                curveTo(7.1607f, 36.7539f, 8.5143f, 35.9078f, 10.0161f, 35.379f)
                curveTo(10.5343f, 36.8385f, 11.0419f, 38.3085f, 11.539f, 39.7785f)
                curveTo(11.539f, 39.8102f, 11.5919f, 39.8208f, 11.6236f, 39.8208f)
                curveTo(11.6236f, 39.8208f, 12.8504f, 39.6093f, 13.7704f, 39.4824f)
                lineTo(13.8445f, 40.2544f)
                lineTo(13.855f, 40.2438f)
                close()
                moveTo(40.9498f, 37.8431f)
                curveTo(39.8287f, 36.7539f, 38.4751f, 35.9078f, 36.9733f, 35.379f)
                curveTo(36.4551f, 36.849f, 35.9475f, 38.3085f, 35.4504f, 39.7785f)
                curveTo(35.4504f, 39.8102f, 35.3976f, 39.8208f, 35.3658f, 39.8208f)
                curveTo(35.3658f, 39.8208f, 34.1285f, 39.5987f, 33.219f, 39.4824f)
                curveTo(33.1873f, 39.8419f, 33.145f, 40.2544f, 33.145f, 40.2544f)
                curveTo(35.0486f, 40.3707f, 36.6878f, 41.3014f, 37.6396f, 42.581f)
                curveTo(37.6713f, 42.6127f, 37.7136f, 42.6127f, 37.7348f, 42.581f)
                curveTo(38.0626f, 41.2591f, 38.1578f, 39.8419f, 37.9886f, 38.4142f)
                curveTo(37.9886f, 38.4037f, 37.9992f, 38.3931f, 38.0203f, 38.3825f)
                curveTo(38.9192f, 38.097f, 39.9134f, 37.9489f, 40.9603f, 37.9595f)
                curveTo(41.0132f, 37.9595f, 41.0132f, 37.9066f, 40.9603f, 37.8537f)
                lineTo(40.9498f, 37.8431f)
                close()
            }
            path(fill = SolidColor(Color(0xFFE09B00)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(35.3769f, 39.8102f)
                lineTo(33.1454f, 40.2438f)
                lineTo(33.2194f, 39.4718f)
                curveTo(33.928f, 39.5776f, 34.6683f, 39.6939f, 35.3769f, 39.8102f)
                close()
                moveTo(11.624f, 39.8102f)
                lineTo(13.8555f, 40.2438f)
                lineTo(13.7814f, 39.4718f)
                curveTo(13.0729f, 39.5776f, 12.3326f, 39.6939f, 11.624f, 39.8102f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFEC00)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(11.5599f, 39.8103f)
                lineTo(9.2861f, 33.3274f)
                curveTo(17.7149f, 31.794f, 26.535f, 31.2969f, 37.7134f, 33.3274f)
                lineTo(35.4396f, 39.8209f)
                curveTo(27.656f, 38.5729f, 20.528f, 38.3191f, 11.5599f, 39.8209f)
                verticalLineTo(39.8103f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFA320A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(32.9336f, 19.1454f)
                curveTo(32.9548f, 19.2617f, 32.9654f, 19.3675f, 32.9654f, 19.4944f)
                curveTo(32.9654f, 19.6107f, 32.9548f, 19.727f, 32.9336f, 19.8434f)
                curveTo(33.7691f, 19.9809f, 34.4142f, 20.7f, 34.4142f, 21.5778f)
                curveTo(34.4142f, 21.6307f, 34.4142f, 21.6835f, 34.4142f, 21.7364f)
                curveTo(34.8372f, 21.7893f, 35.218f, 22.0008f, 35.4929f, 22.3075f)
                curveTo(34.2873f, 24.1371f, 29.3802f, 25.5225f, 23.5002f, 25.5542f)
                curveTo(16.7635f, 25.5965f, 11.2854f, 23.841f, 11.1796f, 21.6307f)
                curveTo(11.1902f, 21.6095f, 11.2113f, 21.5989f, 11.2219f, 21.5778f)
                curveTo(11.1161f, 21.398f, 11.0421f, 21.2076f, 11.0104f, 20.9961f)
                curveTo(10.3653f, 21.6095f, 9.974f, 22.1066f, 10.048f, 22.8257f)
                curveTo(10.048f, 22.868f, 10.7566f, 27.9126f, 11.4546f, 32.9783f)
                curveTo(11.8036f, 32.9254f, 12.1526f, 32.8725f, 12.5015f, 32.8197f)
                lineTo(11.6238f, 26.1253f)
                curveTo(12.4487f, 26.8233f, 13.5697f, 27.3098f, 14.7119f, 27.6799f)
                lineTo(15.1878f, 32.4707f)
                curveTo(15.8434f, 32.3966f, 16.4991f, 32.3332f, 17.1654f, 32.2803f)
                lineTo(16.8481f, 28.1981f)
                curveTo(18.6143f, 28.5788f, 20.6871f, 28.7903f, 22.2946f, 28.8432f)
                lineTo(22.3369f, 32.0159f)
                curveTo(23.1089f, 32.0053f, 23.8915f, 32.0053f, 24.6952f, 32.0159f)
                lineTo(24.7375f, 28.8432f)
                curveTo(26.345f, 28.8009f, 28.4178f, 28.5788f, 30.184f, 28.1981f)
                lineTo(29.8561f, 32.2803f)
                curveTo(30.5118f, 32.3332f, 31.1675f, 32.3966f, 31.8338f, 32.4707f)
                lineTo(32.3097f, 27.6799f)
                curveTo(33.4518f, 27.3098f, 34.5729f, 26.8233f, 35.3978f, 26.1253f)
                lineTo(34.52f, 32.8091f)
                curveTo(34.869f, 32.862f, 35.218f, 32.9148f, 35.567f, 32.9677f)
                curveTo(36.2755f, 27.902f, 36.9735f, 22.868f, 36.9735f, 22.8151f)
                curveTo(37.111f, 21.3768f, 35.4295f, 20.076f, 32.9548f, 19.1454f)
                horizontalLineTo(32.9336f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(14.7111f, 27.6695f)
                curveTo(13.569f, 27.2993f, 12.4479f, 26.8129f, 11.623f, 26.1149f)
                lineTo(12.5008f, 32.8092f)
                curveTo(13.3892f, 32.6823f, 14.2775f, 32.566f, 15.187f, 32.4602f)
                lineTo(14.7111f, 27.6695f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(22.2843f, 28.8325f)
                curveTo(20.6768f, 28.7902f, 18.604f, 28.5681f, 16.8379f, 28.1874f)
                lineTo(17.1552f, 32.2696f)
                curveTo(18.8367f, 32.1215f, 20.5499f, 32.0369f, 22.3161f, 32.0052f)
                lineTo(22.2738f, 28.8325f)
                horizontalLineTo(22.2843f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(30.1626f, 28.1875f)
                curveTo(28.3964f, 28.5682f, 26.3236f, 28.7797f, 24.7161f, 28.8326f)
                lineTo(24.6738f, 32.0053f)
                curveTo(26.3448f, 32.037f, 28.058f, 32.1216f, 29.8347f, 32.2697f)
                lineTo(30.1626f, 28.1875f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(35.3765f, 26.1147f)
                curveTo(34.5516f, 26.8127f, 33.4306f, 27.2992f, 32.2884f, 27.6694f)
                lineTo(31.8125f, 32.4601f)
                curveTo(32.6903f, 32.5553f, 33.5892f, 32.6716f, 34.4987f, 32.7985f)
                lineTo(35.3765f, 26.1147f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFD700)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(11.1801f, 21.62f)
                curveTo(11.2859f, 23.8303f, 16.764f, 25.5859f, 23.5007f, 25.5436f)
                curveTo(29.3807f, 25.5119f, 34.2984f, 24.1159f, 35.4934f, 22.2969f)
                curveTo(35.2185f, 21.9902f, 34.8377f, 21.7787f, 34.4147f, 21.7258f)
                curveTo(34.4147f, 21.6729f, 34.4147f, 21.62f, 34.4147f, 21.5672f)
                curveTo(34.4147f, 20.6894f, 33.7696f, 19.9702f, 32.9341f, 19.8328f)
                curveTo(32.9553f, 19.7164f, 32.9659f, 19.6001f, 32.9659f, 19.4838f)
                curveTo(32.9659f, 18.5108f, 32.1621f, 17.7282f, 31.1892f, 17.7282f)
                curveTo(31.168f, 17.7282f, 31.1469f, 17.7282f, 31.1257f, 17.7282f)
                curveTo(31.1892f, 17.5484f, 31.2209f, 17.3475f, 31.2209f, 17.1466f)
                curveTo(31.2209f, 16.1736f, 30.4171f, 15.391f, 29.4442f, 15.391f)
                curveTo(29.2221f, 15.391f, 29.0106f, 15.4333f, 28.8096f, 15.5179f)
                curveTo(28.5347f, 14.9891f, 27.9953f, 14.6296f, 27.3714f, 14.5873f)
                curveTo(27.2656f, 13.7095f, 26.5147f, 13.0326f, 25.6158f, 13.0432f)
                curveTo(25.0447f, 13.0432f, 24.5477f, 13.3182f, 24.2198f, 13.7412f)
                curveTo(23.892f, 13.3922f, 23.4267f, 13.1701f, 22.9085f, 13.1701f)
                curveTo(21.9355f, 13.1701f, 21.1529f, 13.9739f, 21.1529f, 14.9468f)
                curveTo(21.1529f, 15.1372f, 21.1846f, 15.317f, 21.2375f, 15.4756f)
                curveTo(20.8356f, 15.5496f, 20.4866f, 15.7611f, 20.2328f, 16.0467f)
                curveTo(20.0319f, 15.2958f, 19.3445f, 14.7353f, 18.5196f, 14.7353f)
                curveTo(17.7581f, 14.7353f, 17.1024f, 15.2324f, 16.8698f, 15.9198f)
                curveTo(16.2247f, 16.1842f, 15.7593f, 16.8187f, 15.7699f, 17.5696f)
                curveTo(15.7699f, 17.7388f, 15.7911f, 17.8974f, 15.8439f, 18.0455f)
                curveTo(15.6219f, 17.9503f, 15.3786f, 17.8974f, 15.1248f, 17.8974f)
                curveTo(14.3528f, 17.8974f, 13.7077f, 18.3945f, 13.4644f, 19.0819f)
                curveTo(13.2318f, 18.9761f, 12.978f, 18.9127f, 12.7136f, 18.9127f)
                curveTo(11.7406f, 18.9127f, 10.958f, 19.7164f, 10.958f, 20.6894f)
                curveTo(10.958f, 21.0067f, 11.0532f, 21.3133f, 11.2012f, 21.5672f)
                curveTo(11.1907f, 21.5777f, 11.1695f, 21.5989f, 11.1589f, 21.62f)
                horizontalLineTo(11.1801f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFD700)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(26.4291f, 37.1874f)
                curveTo(26.3127f, 37.2085f, 26.1964f, 37.2191f, 26.0801f, 37.2403f)
                curveTo(26.1858f, 37.2085f, 26.3022f, 37.1874f, 26.4291f, 37.1874f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFE600)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(11.5599f, 39.8314f)
                lineTo(9.2861f, 33.3485f)
                curveTo(17.7149f, 31.8151f, 26.535f, 31.318f, 37.7134f, 33.3485f)
                lineTo(35.4396f, 39.842f)
                curveTo(27.656f, 38.594f, 20.528f, 38.3402f, 11.5599f, 39.842f)
                verticalLineTo(39.8314f)
                close()
            }
        }
        .build()
        return _rtVerifiedHot!!
    }

private var _rtVerifiedHot: ImageVector? = null
