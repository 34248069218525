package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.KeyboardShiftSelected: ImageVector
    get() {
        if (_keyboardShiftSelected != null) {
            return _keyboardShiftSelected!!
        }
        _keyboardShiftSelected = Builder(name = "KeyboardShiftSelected", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(24.418f, 4.29f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, false, -1.418f, 0.0f)
                lineTo(9.304f, 17.773f)
                curveToRelative(-0.645f, 0.634f, -0.196f, 1.73f, 0.708f, 1.73f)
                horizontalLineToRelative(5.76f)
                curveToRelative(0.558f, 0.0f, 1.01f, 0.452f, 1.01f, 1.01f)
                verticalLineToRelative(13.279f)
                curveToRelative(0.0f, 0.558f, 0.452f, 1.01f, 1.01f, 1.01f)
                horizontalLineToRelative(11.834f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, false, 1.01f, -1.01f)
                lineTo(30.636f, 20.513f)
                curveToRelative(0.0f, -0.558f, 0.453f, -1.01f, 1.01f, -1.01f)
                horizontalLineToRelative(5.76f)
                curveToRelative(0.904f, 0.0f, 1.353f, -1.096f, 0.709f, -1.73f)
                lineTo(24.418f, 4.29f)
                close()
                moveTo(17.648f, 38.949f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, false, -1.01f, 1.01f)
                verticalLineToRelative(3.03f)
                curveToRelative(0.0f, 0.559f, 0.452f, 1.01f, 1.01f, 1.01f)
                lineTo(29.77f, 43.999f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, false, 1.01f, -1.01f)
                verticalLineToRelative(-3.03f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, false, -1.01f, -1.01f)
                lineTo(17.648f, 38.949f)
                close()
            }
        }
        .build()
        return _keyboardShiftSelected!!
    }

private var _keyboardShiftSelected: ImageVector? = null
