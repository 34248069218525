package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.DeleteOutline: ImageVector
    get() {
        if (_deleteOutline != null) {
            return _deleteOutline!!
        }
        _deleteOutline = Builder(name = "DeleteOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(27.36f, 7.23f)
                curveToRelative(0.291f, 0.68f, 0.91f, 1.468f, 1.842f, 1.829f)
                lineTo(18.796f, 9.059f)
                curveToRelative(0.933f, -0.36f, 1.551f, -1.148f, 1.844f, -1.828f)
                curveTo(20.907f, 6.607f, 21.687f, 6.0f, 22.782f, 6.0f)
                horizontalLineToRelative(2.435f)
                curveToRelative(1.095f, 0.0f, 1.875f, 0.607f, 2.142f, 1.23f)
                close()
                moveTo(29.197f, 6.441f)
                curveToRelative(0.194f, 0.452f, 0.599f, 0.817f, 1.09f, 0.817f)
                horizontalLineToRelative(3.452f)
                curveToRelative(1.97f, 0.0f, 3.629f, 1.194f, 4.116f, 2.815f)
                curveToRelative(0.159f, 0.529f, -0.303f, 0.986f, -0.856f, 0.986f)
                lineTo(11.0f, 11.059f)
                curveToRelative(-0.552f, 0.0f, -1.014f, -0.457f, -0.855f, -0.986f)
                curveToRelative(0.487f, -1.621f, 2.145f, -2.815f, 4.116f, -2.815f)
                horizontalLineToRelative(3.452f)
                curveToRelative(0.491f, 0.0f, 0.896f, -0.365f, 1.09f, -0.817f)
                curveTo(19.415f, 5.014f, 20.966f, 4.0f, 22.782f, 4.0f)
                horizontalLineToRelative(2.435f)
                curveToRelative(1.816f, 0.0f, 3.367f, 1.014f, 3.98f, 2.441f)
                close()
                moveTo(34.209f, 37.827f)
                verticalLineToRelative(-0.074f)
                lineToRelative(0.006f, -0.073f)
                lineToRelative(1.632f, -22.268f)
                horizontalLineToRelative(-23.65f)
                lineToRelative(2.087f, 22.227f)
                lineToRelative(0.009f, 0.094f)
                verticalLineToRelative(0.094f)
                curveToRelative(0.0f, 0.488f, 0.375f, 1.608f, 1.208f, 2.688f)
                curveToRelative(0.86f, 1.113f, 1.655f, 1.485f, 2.042f, 1.485f)
                horizontalLineToRelative(14.0f)
                curveToRelative(0.31f, 0.0f, 0.953f, -0.282f, 1.661f, -1.361f)
                curveToRelative(0.674f, -1.026f, 1.005f, -2.193f, 1.005f, -2.812f)
                close()
                moveTo(10.102f, 14.505f)
                lineToRelative(2.19f, 23.322f)
                curveToRelative(0.0f, 2.196f, 2.585f, 6.173f, 5.25f, 6.173f)
                horizontalLineToRelative(14.0f)
                curveToRelative(2.667f, 0.0f, 4.667f, -3.977f, 4.667f, -6.173f)
                lineToRelative(1.712f, -23.342f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -0.998f, -1.073f)
                lineTo(11.098f, 13.412f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -0.996f, 1.093f)
                close()
            }
        }
        .build()
        return _deleteOutline!!
    }

private var _deleteOutline: ImageVector? = null
