package com.brdgwtr.designsystem.components

import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsFocusedAsState
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.interaction.collectIsPressedAsState
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.material3.ripple
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.foundation.BwTheme
import com.brdgwtr.designsystem.foundation.contentColorFor
import io.daio.wild.content.ProvidesContentColor
import io.daio.wild.foundation.clickable
import io.daio.wild.style.Borders
import io.daio.wild.style.Colors
import io.daio.wild.style.Scale
import io.daio.wild.style.Shapes
import io.daio.wild.style.StyleDefaults
import io.daio.wild.style.interactable
import io.daio.wild.style.interactionStyle

/**
 * Base Surface component used as a building block layer for any container or background.
 *
 * @param modifier Modifier to be applied to the layout corresponding to the surface.
 * @param color The background color of the surface. Defaults to [Color.Unspecified] if not set.
 * @param contentColor The color of the content displayed on the surface. Defaults to [Color.Unspecified].
 * @param shape The shape of the surface, such as a rectangle or rounded corners. Defaults to [RectangleShape].
 * @param border The border appearance for the surface. Defaults to [Border.None].
 * @param onClick Optional onClick callback triggered when the surface is clicked.
 * @param onLongClick Optional onLongClick callback triggered when the surface is long-pressed.
 * @param enabled Whether the surface is enabled for interactions. Defaults to `true`.
 * @param content Composable content to be displayed within the surface.
 */
@Composable
@NonRestartableComposable
fun Surface(
    modifier: Modifier = Modifier,
    color: Color = BwTheme.colors.surfaceBackground,
    contentColor: Color = contentColorFor(color),
    shape: Shape = RectangleShape,
    border: Border = Border.None,
    onClick: (() -> Unit)? = null,
    onLongClick: (() -> Unit)? = null,
    enabled: Boolean = true,
    content: @Composable () -> Unit,
) {
    val internalModifier = if (onClick != null || onLongClick != null) {
        modifier.clickable(
            enabled = enabled,
            indication = ripple(),
            onClick = onClick ?: {},
            onLongClick = onLongClick,
        )
    } else {
        modifier
    }

    Box(
        modifier = internalModifier
            .interactionStyle(
                style = StyleDefaults.style(
                    colors = StyleDefaults.colors(backgroundColor = color, contentColor = contentColor),
                    shapes = StyleDefaults.shapes(shape = shape),
                    borders = StyleDefaults.borders(border = border.toWildBorder()),
                ),
                interactionSource = null,
                enabled = enabled,
            ),
        propagateMinConstraints = true,
        content = {
            ProvidesContentColor(contentColor) {
                content()
            }
        },
    )
}

/**
 * Base interactive Surface component used as a building block layer for any container or background. This version
 * supports customisation based on the state of the component.
 *
 * @param onClick Optional onClick callback triggered when the surface is clicked.
 * @param modifier Modifier to be applied to the layout corresponding to the surface.
 * @param shape The shape of the surface.
 * @param onLongClick Optional onLongClick callback triggered when the surface is long-pressed.
 * @param colors [InteractiveSurfaceColors] defining the background and content colors for various states.
 * @param scale [InteractiveSurfaceScale] defining the scale transformations for various states.
 * @param border [InteractiveSurfaceBorder] defining the border appearance for the surface.
 * @param enabled Enable/disable click and interaction handling for the surface.
 * @param selected Optional state indicating whether the surface is selected.
 * @param interactionSource Optional [MutableInteractionSource] for handling interaction events.
 * @param content Composable content to be displayed within the surface.
 */
@Composable
fun Surface(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    onLongClick: (() -> Unit)? = null,
    shape: InteractiveSurfaceShape = InteractiveSurfaceDefaults.shape(),
    colors: InteractiveSurfaceColors = InteractiveSurfaceDefaults.colors(),
    scale: InteractiveSurfaceScale = InteractiveSurfaceDefaults.scale(),
    border: InteractiveSurfaceBorder = InteractiveSurfaceDefaults.border(),
    enabled: Boolean = true,
    selected: Boolean? = null,
    interactionSource: MutableInteractionSource? = null,
    content: @Composable BoxScope.() -> Unit,
) {
    @Suppress("NAME_SHADOWING")
    val interactionSource = interactionSource ?: remember { MutableInteractionSource() }
    val color = colors.toWildColors()

    Box(
        modifier =
        modifier.interactable(
            enabled = enabled,
            selected = selected,
            onClick = onClick,
            onLongClick = onLongClick,
            interactionSource = interactionSource,
            style = StyleDefaults.style(
                colors = color,
                scale = scale.toWildScale(),
                borders = border.toWildBorders(),
                shapes = shape.toWildShape(),
            ),
        ),
        propagateMinConstraints = true,
        content = {
            val focused by interactionSource.collectIsFocusedAsState()
            val pressed by interactionSource.collectIsPressedAsState()
            val hovered by interactionSource.collectIsHoveredAsState()
            ProvidesContentColor(
                color.contentColorFor(
                    enabled = enabled,
                    focused = focused,
                    pressed = pressed,
                    hovered = hovered,
                    selected = selected == true,
                ),
            ) {
                content()
            }
        },
    )
}

/**
 * Base Surface component used as a building block layer for any container or background.
 *
 * @param modifier - Modifier to be applied to the layout corresponding to the surface.
 * @param shape - The shape of the surface.
 * @param onClick - Optional onClick callback.
 * @param enabled - Enable/disable click handling for the surface.
 * @param border - Optional border for the surface.
 * @param onClick - Optional onClick callback.
 * @param enabled - Enable/disable click handling for the surface.
 * @param content - Composable content to tp be displayed in the surface.
 */
@Composable
@NonRestartableComposable
fun ContainerSurface(
    modifier: Modifier = Modifier,
    shape: Shape = RectangleShape,
    border: Border = Border.None,
    onClick: (() -> Unit)? = null,
    onLongClick: (() -> Unit)? = null,
    enabled: Boolean = true,
    content: @Composable () -> Unit,
) {
    Surface(
        enabled = enabled,
        modifier = modifier,
        shape = shape,
        onClick = onClick,
        onLongClick = onLongClick,
        color = BwTheme.colors.surfaceContainer,
        border = border,
        content = content,
    )
}

/**
 * Base Surface component used as a building block layer for any container or background.
 *
 * @param modifier - Modifier to be applied to the layout corresponding to the surface.
 * @param shape - The shape of the surface.
 * @param onClick - Optional onClick callback.
 * @param enabled - Enable/disable click handling for the surface.
 * @param border - Optional border for the surface.
 * @param onClick - Optional onClick callback.
 * @param enabled - Enable/disable click handling for the surface.
 * @param content - Composable content to tp be displayed in the surface.
 */
@Composable
@NonRestartableComposable
fun EmphasizedSurface(
    modifier: Modifier = Modifier,
    shape: Shape = RectangleShape,
    border: Border = Border.None,
    onClick: (() -> Unit)? = null,
    onLongClick: (() -> Unit)? = null,
    enabled: Boolean = true,
    content: @Composable () -> Unit,
) {
    Surface(
        modifier = modifier,
        onClick = onClick,
        onLongClick = onLongClick,
        enabled = enabled,
        shape = shape,
        color = BwTheme.colors.surfaceEmphasized,
        border = border,
        content = content,
    )
}

object InteractiveSurfaceDefaults {
    /**
     * Creates [InteractiveSurfaceColors] for each provided state.
     *
     * @param backgroundColor - The main background color.
     * @param contentColor - The main content color used for items displayed on [backgroundColor].
     * @param focusedBackgroundColor - The background color used in the focused state.
     * @param focusedContentColor - The focused content color used for items displayed on [focusedBackgroundColor].
     * @param selectedBackgroundColor - The background color used in the selected state.
     * @param selectedContentColor - The content color used for items displayed on [selectedBackgroundColor].
     * @param pressedBackgroundColor - The background color used in the pressed state.
     * @param pressedContentColor - The pressed content color used for items displayed on [pressedBackgroundColor].
     * @param disabledBackgroundColor - The background color used in the disabled state.
     * @param disabledContentColor - The disabled content color used for items displayed on [disabledBackgroundColor].
     * @param disabledFocusedBackgroundColor - The background color used when focused and disabled.
     * @param disabledFocusedContentColor - The disabled content color used for items displayed on
     * [disabledFocusedBackgroundColor].
     */
    @Stable
    @Composable
    @ReadOnlyComposable
    fun colors(
        backgroundColor: Color = BwTheme.colors.surfaceContainer,
        contentColor: Color = contentColorFor(backgroundColor = backgroundColor),
        focusedBackgroundColor: Color = BwTheme.colors.surfaceInverse,
        focusedContentColor: Color = contentColorFor(backgroundColor = focusedBackgroundColor),
        selectedBackgroundColor: Color = backgroundColor,
        selectedContentColor: Color = contentColor,
        focusedSelectedBackgroundColor: Color = backgroundColor,
        focusedSelectedContentColor: Color = contentColor,
        pressedBackgroundColor: Color = focusedBackgroundColor.copy(alpha = .6f),
        pressedContentColor: Color = contentColorFor(backgroundColor = focusedBackgroundColor),
        disabledBackgroundColor: Color = backgroundColor.copy(alpha = 0.4f),
        disabledContentColor: Color = contentColorFor(backgroundColor = backgroundColor),
        disabledFocusedBackgroundColor: Color = focusedBackgroundColor.copy(alpha = 0.4f),
        disabledFocusedContentColor: Color = contentColorFor(backgroundColor = focusedBackgroundColor),
    ): InteractiveSurfaceColors = InteractiveSurfaceColors(
        backgroundColor = backgroundColor,
        contentColor = contentColor,
        focusedContentColor = focusedContentColor,
        focusedBackgroundColor = focusedBackgroundColor,
        selectedContentColor = selectedContentColor,
        selectedBackgroundColor = selectedBackgroundColor,
        focusedSelectedBackgroundColor = focusedSelectedBackgroundColor,
        focusedSelectedContentColor = focusedSelectedContentColor,
        pressedBackgroundColor = pressedBackgroundColor,
        pressedContentColor = pressedContentColor,
        disabledBackgroundColor = disabledBackgroundColor,
        disabledContentColor = disabledContentColor,
        disabledFocusedBackgroundColor = disabledFocusedBackgroundColor,
        disabledFocusedContentColor = disabledFocusedContentColor,
    )

    /**
     * Creates [InteractiveSurfaceScale] for each provided state.
     *
     * @param scale - The default scale of the surface.
     * @param focusedScale - The scale when the surface is focused.
     * @param disabledScale - The scale when the surface is disabled.
     * @param pressedScale - The scale when the surface is pressed.
     * @param focusedDisabledScale - The scale when the surface is both focused and disabled.
     */
    @Stable
    fun scale(
        scale: Float = 1f,
        focusedScale: Float = scale,
        disabledScale: Float = scale,
        pressedScale: Float = scale,
        focusedDisabledScale: Float = focusedScale,
    ): InteractiveSurfaceScale = InteractiveSurfaceScale(
        scale = scale,
        focusedScale = focusedScale,
        disabledScale = disabledScale,
        pressedScale = pressedScale,
        focusedDisabledScale = focusedDisabledScale,
    )

    /**
     * Creates [InteractiveSurfaceShape] for each provided state.
     *
     * @param shape - The default shape of the surface.
     * @param focusedShape - The shape when the surface is focused.
     * @param disabledShape - The shape when the surface is disabled.
     * @param pressedShape - The shape when the surface is pressed.
     * @param focusedDisabledShape - The shape when the surface is both focused and disabled.
     */
    @Stable
    fun shape(
        shape: Shape = RectangleShape,
        focusedShape: Shape = shape,
        disabledShape: Shape = shape,
        pressedShape: Shape = shape,
        focusedDisabledShape: Shape = shape,
    ): InteractiveSurfaceShape = InteractiveSurfaceShape(
        shape = shape,
        focusedShape = focusedShape,
        disabledShape = disabledShape,
        pressedShape = pressedShape,
        focusedDisabledShape = focusedDisabledShape,
    )

    /**
     * Creates [InteractiveSurfaceBorder] for each provided state.
     *
     * @param color The default color border when the surface is unfocused.
     * @param focusedColor The border color when the surface is focused.
     * @param pressedColor The border color when the surface is pressed.
     * @param disabledColor The border color when the surface is disabled.
     * @param focusedDisabledColor The border color when the surface is focused but disabled.
     * @param width - The width of the border.
     * @param inset - The inset of the border.
     */
    @Stable
    fun border(
        color: Color = Color.Unspecified,
        focusedColor: Color = Color.Unspecified,
        pressedColor: Color = focusedColor,
        disabledColor: Color = color.copy(alpha = 0.4f),
        focusedDisabledColor: Color = focusedColor.copy(alpha = 0.4f),
        shape: Shape = BorderDefaults.DefaultShape,
        width: Dp = 2.dp,
        inset: Dp = 0.dp,
    ): InteractiveSurfaceBorder = border(
        border = BorderDefaults.border(
            color = color,
            width = width,
            inset = inset,
            shape = shape,
        ),
        focusedBorder = BorderDefaults.border(
            color = focusedColor,
            width = width,
            inset = inset,
            shape = shape,
        ),
        pressedBorder = BorderDefaults.border(
            color = pressedColor,
            width = width,
            inset = inset,
            shape = shape,
        ),
        disabledBorder = BorderDefaults.border(
            color = disabledColor,
            width = width,
            inset = inset,
            shape = shape,
        ),
        focusedDisabledBorder = BorderDefaults.border(
            color = focusedDisabledColor,
            width = width,
            inset = inset,
            shape = shape,
        ),
    )

    /**
     * Creates [InteractiveSurfaceBorder] for each provided state.
     *
     * @param border The default border when the surface is unfocused.
     * @param focusedBorder The border when the surface is focused.
     * @param pressedBorder The border when the surface is pressed.
     * @param disabledBorder The border when the surface is disabled.
     * @param focusedDisabledBorder The border when the surface is focused but disabled.
     */
    @Stable
    fun border(
        border: Border = Border.None,
        focusedBorder: Border = Border.None,
        pressedBorder: Border = Border.None,
        disabledBorder: Border = Border.None,
        focusedDisabledBorder: Border = Border.None,
    ): InteractiveSurfaceBorder = InteractiveSurfaceBorder(
        border = border,
        focusedBorder = focusedBorder,
        pressedBorder = pressedBorder,
        disabledBorder = disabledBorder,
        focusedDisabledBorder = focusedDisabledBorder,
    )
}

@Immutable
data class InteractiveSurfaceColors(
    val backgroundColor: Color = Color.Unspecified,
    val contentColor: Color = Color.Unspecified,
    val focusedBackgroundColor: Color = Color.Unspecified,
    val focusedContentColor: Color = Color.Unspecified,
    val disabledBackgroundColor: Color = Color.Unspecified,
    val disabledContentColor: Color = Color.Unspecified,
    val pressedContentColor: Color = Color.Unspecified,
    val pressedBackgroundColor: Color = Color.Unspecified,
    val disabledFocusedBackgroundColor: Color = Color.Unspecified,
    val disabledFocusedContentColor: Color = Color.Unspecified,
    val selectedBackgroundColor: Color = Color.Unspecified,
    val selectedContentColor: Color = Color.Unspecified,
    val focusedSelectedBackgroundColor: Color = Color.Unspecified,
    val focusedSelectedContentColor: Color = Color.Unspecified,
    val pressedSelectedBackgroundColor: Color = Color.Unspecified,
    val pressedSelectedContentColor: Color = Color.Unspecified,
)

@Immutable
data class InteractiveSurfaceScale(
    val scale: Float = 1f,
    val focusedScale: Float = scale,
    val pressedScale: Float = scale,
    val disabledScale: Float = scale,
    val focusedDisabledScale: Float = scale,
)

@Immutable
data class InteractiveSurfaceShape(
    val shape: Shape = RectangleShape,
    val focusedShape: Shape = shape,
    val pressedShape: Shape = shape,
    val disabledShape: Shape = shape,
    val focusedDisabledShape: Shape = shape,
)

@Immutable
data class InteractiveSurfaceBorder(
    val border: Border = Border.None,
    val focusedBorder: Border = Border.None,
    val pressedBorder: Border = Border.None,
    val disabledBorder: Border = Border.None,
    val focusedDisabledBorder: Border = Border.None,
)

@Stable
private fun InteractiveSurfaceColors.toWildColors(): Colors = StyleDefaults.colors(
    backgroundColor = backgroundColor,
    contentColor = contentColor,
    focusedBackgroundColor = focusedBackgroundColor,
    focusedContentColor = focusedContentColor,
    focusedDisabledBackgroundColor = disabledFocusedBackgroundColor,
    disabledBackgroundColor = disabledBackgroundColor,
    focusedDisabledContentColor = disabledFocusedContentColor,
    disabledContentColor = disabledContentColor,
    pressedBackgroundColor = pressedBackgroundColor,
    pressedContentColor = pressedContentColor,
    selectedContentColor = selectedContentColor,
    selectedBackgroundColor = selectedBackgroundColor,
    focusedSelectedContentColor = focusedSelectedContentColor,
    focusedSelectedBackgroundColor = focusedSelectedBackgroundColor,
    pressedSelectedContentColor = pressedSelectedContentColor,
    pressedSelectedBackgroundColor = pressedSelectedBackgroundColor,
    hoveredBackgroundColor = focusedBackgroundColor,
    hoveredContentColor = focusedContentColor,
    hoveredSelectedContentColor = focusedSelectedContentColor,
    hoveredSelectedBackgroundColor = focusedSelectedBackgroundColor,
)

@Stable
private fun InteractiveSurfaceScale.toWildScale(): Scale = StyleDefaults.scale(
    scale = scale,
    focusedScale = focusedScale,
    disabledScale = disabledScale,
    pressedScale = pressedScale,
    focusedDisabledScale = focusedDisabledScale,
)

@Stable
private fun InteractiveSurfaceBorder.toWildBorders(): Borders = StyleDefaults.borders(
    border = border.toWildBorder(),
    focusedBorder = focusedBorder.toWildBorder(),
    disabledBorder = disabledBorder.toWildBorder(),
    pressedBorder = pressedBorder.toWildBorder(),
    focusedDisabledBorder = focusedDisabledBorder.toWildBorder(),
)

@Stable
private fun InteractiveSurfaceShape.toWildShape(): Shapes = StyleDefaults.shapes(
    shape = shape,
    focusedShape = focusedShape,
    disabledShape = disabledShape,
    pressedShape = pressedShape,
    focusedDisabledShape = focusedDisabledShape,
)
