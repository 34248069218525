package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Talent: ImageVector
    get() {
        if (_talent != null) {
            return _talent!!
        }
        _talent = Builder(name = "Talent", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(31.0f, 14.36f)
                curveToRelative(0.0f, 4.643f, -3.134f, 8.408f, -7.0f, 8.408f)
                reflectiveCurveToRelative(-7.0f, -3.765f, -7.0f, -8.409f)
                curveTo(17.0f, 9.714f, 20.134f, 7.0f, 24.0f, 7.0f)
                reflectiveCurveToRelative(7.0f, 2.714f, 7.0f, 7.36f)
                close()
                moveTo(39.0f, 37.55f)
                reflectiveCurveTo(39.0f, 41.0f, 25.0f, 41.0f)
                horizontalLineToRelative(-2.0f)
                curveTo(9.05f, 41.0f, 9.0f, 37.55f, 9.0f, 37.55f)
                curveToRelative(0.0f, -6.898f, 6.0f, -11.825f, 13.0f, -11.825f)
                horizontalLineToRelative(4.0f)
                curveToRelative(7.0f, 0.0f, 13.0f, 4.927f, 13.0f, 11.826f)
                close()
            }
        }
        .build()
        return _talent!!
    }

private var _talent: ImageVector? = null
