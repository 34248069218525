package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.TopPicksOutline: ImageVector
    get() {
        if (_topPicksOutline != null) {
            return _topPicksOutline!!
        }
        _topPicksOutline = Builder(name = "TopPicksOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(19.5792f, 19.2862f)
                lineTo(8.9425f, 20.7178f)
                lineTo(16.7535f, 28.4257f)
                lineTo(14.8112f, 39.2145f)
                lineTo(24.0f, 33.961f)
                lineTo(33.1888f, 39.2145f)
                lineTo(31.2465f, 28.4257f)
                lineTo(39.0575f, 20.7178f)
                lineTo(28.4208f, 19.2862f)
                lineTo(24.0f, 9.5229f)
                lineTo(19.5792f, 19.2862f)
                close()
                moveTo(22.0841f, 5.2689f)
                curveTo(22.435f, 4.4941f, 23.18f, 4.0f, 23.9998f, 4.0f)
                curveTo(24.8197f, 4.0f, 25.565f, 4.4941f, 25.9159f, 5.2689f)
                lineTo(30.8094f, 16.0761f)
                lineTo(42.1501f, 17.6024f)
                curveTo(42.963f, 17.712f, 43.643f, 18.3017f, 43.8958f, 19.1171f)
                curveTo(44.1496f, 19.9314f, 43.9312f, 20.8265f, 43.3348f, 21.4141f)
                lineTo(35.018f, 29.6212f)
                lineTo(37.1336f, 41.3728f)
                curveTo(37.2849f, 42.2149f, 36.9585f, 43.0733f, 36.296f, 43.5764f)
                curveTo(35.6331f, 44.0805f, 34.7515f, 44.1391f, 34.0326f, 43.7285f)
                lineTo(24.0f, 37.9927f)
                lineTo(13.9674f, 43.7285f)
                curveTo(13.2485f, 44.1391f, 12.3669f, 44.0805f, 11.704f, 43.5764f)
                curveTo(11.0415f, 43.0732f, 10.7152f, 42.2149f, 10.8664f, 41.3728f)
                lineTo(12.982f, 29.6212f)
                lineTo(4.6652f, 21.4141f)
                curveTo(4.0689f, 20.8265f, 3.8504f, 19.9315f, 4.1042f, 19.1171f)
                curveTo(4.357f, 18.3016f, 5.037f, 17.7119f, 5.8499f, 17.6024f)
                lineTo(17.1906f, 16.0761f)
                lineTo(22.0841f, 5.2689f)
                close()
            }
        }
        .build()
        return _topPicksOutline!!
    }

private var _topPicksOutline: ImageVector? = null
