package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Bluetooth: ImageVector
    get() {
        if (_bluetooth != null) {
            return _bluetooth!!
        }
        _bluetooth = Builder(name = "Bluetooth", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(23.3566f, 4.1651f)
                curveTo(23.8f, 3.9223f, 24.338f, 3.9489f, 24.7565f, 4.2344f)
                lineTo(38.3928f, 13.5367f)
                curveTo(38.7721f, 13.7955f, 39.0f, 14.2311f, 39.0f, 14.6977f)
                curveTo(39.0f, 15.1642f, 38.7721f, 15.5999f, 38.3928f, 15.8587f)
                lineTo(26.4584f, 24.0f)
                lineTo(38.3928f, 32.1413f)
                curveTo(38.7721f, 32.4001f, 39.0f, 32.8358f, 39.0f, 33.3023f)
                curveTo(39.0f, 33.7689f, 38.7721f, 34.2045f, 38.3928f, 34.4633f)
                lineTo(24.7565f, 43.7656f)
                curveTo(24.338f, 44.0511f, 23.8f, 44.0777f, 23.3566f, 43.8349f)
                curveTo(22.9132f, 43.5921f, 22.6364f, 43.1192f, 22.6364f, 42.6047f)
                verticalLineTo(26.6072f)
                lineTo(11.1202f, 34.4633f)
                curveTo(10.4936f, 34.8908f, 9.6469f, 34.7175f, 9.2292f, 34.0763f)
                curveTo(8.8114f, 33.4351f, 8.9807f, 32.5688f, 9.6074f, 32.1413f)
                lineTo(21.5418f, 24.0f)
                lineTo(9.6074f, 15.8587f)
                curveTo(8.9807f, 15.4312f, 8.8114f, 14.5649f, 9.2292f, 13.9237f)
                curveTo(9.6469f, 13.2825f, 10.4936f, 13.1092f, 11.1202f, 13.5367f)
                lineTo(22.6364f, 21.3928f)
                verticalLineTo(5.3953f)
                curveTo(22.6364f, 4.8808f, 22.9132f, 4.4079f, 23.3566f, 4.1651f)
                close()
                moveTo(25.3637f, 26.6072f)
                lineTo(35.1781f, 33.3023f)
                lineTo(25.3637f, 39.9974f)
                verticalLineTo(26.6072f)
                close()
                moveTo(25.3637f, 21.3928f)
                verticalLineTo(8.0026f)
                lineTo(35.1781f, 14.6977f)
                lineTo(25.3637f, 21.3928f)
                close()
            }
        }
        .build()
        return _bluetooth!!
    }

private var _bluetooth: ImageVector? = null
