package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Accessibility: ImageVector
    get() {
        if (_accessibility != null) {
            return _accessibility!!
        }
        _accessibility = Builder(name = "Accessibility", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(41.5f, 13.98f)
                curveToRelative(-0.28f, -1.1f, -1.38f, -1.74f, -2.48f, -1.5f)
                curveTo(34.26f, 13.54f, 28.96f, 14.0f, 24.0f, 14.0f)
                curveToRelative(-4.96f, 0.0f, -10.26f, -0.46f, -15.02f, -1.52f)
                curveToRelative(-1.1f, -0.24f, -2.2f, 0.4f, -2.48f, 1.5f)
                curveToRelative(-0.28f, 1.12f, 0.4f, 2.26f, 1.5f, 2.52f)
                curveToRelative(3.22f, 0.72f, 6.7f, 1.22f, 10.0f, 1.5f)
                verticalLineToRelative(24.0f)
                curveToRelative(0.0f, 1.1f, 0.9f, 2.0f, 2.0f, 2.0f)
                reflectiveCurveToRelative(2.0f, -0.9f, 2.0f, -2.0f)
                verticalLineTo(32.0f)
                horizontalLineToRelative(4.0f)
                verticalLineToRelative(10.0f)
                curveToRelative(0.0f, 1.1f, 0.9f, 2.0f, 2.0f, 2.0f)
                reflectiveCurveToRelative(2.0f, -0.9f, 2.0f, -2.0f)
                verticalLineTo(18.0f)
                curveToRelative(3.3f, -0.28f, 6.78f, -0.78f, 9.98f, -1.5f)
                curveToRelative(1.12f, -0.26f, 1.8f, -1.4f, 1.52f, -2.52f)
                close()
                moveTo(24.0f, 12.0f)
                curveToRelative(2.2f, 0.0f, 4.0f, -1.8f, 4.0f, -4.0f)
                reflectiveCurveToRelative(-1.8f, -4.0f, -4.0f, -4.0f)
                reflectiveCurveToRelative(-4.0f, 1.8f, -4.0f, 4.0f)
                reflectiveCurveToRelative(1.8f, 4.0f, 4.0f, 4.0f)
                close()
            }
        }
        .build()
        return _accessibility!!
    }

private var _accessibility: ImageVector? = null
