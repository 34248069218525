package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoSettingsOutline: ImageVector
    get() {
        if (_ruwidoSettingsOutline != null) {
            return _ruwidoSettingsOutline!!
        }
        _ruwidoSettingsOutline = Builder(name = "RuwidoSettingsOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(2.0f, 24.0f)
                curveTo(2.0f, 11.848f, 11.848f, 2.0f, 24.0f, 2.0f)
                reflectiveCurveToRelative(22.0f, 9.848f, 22.0f, 22.0f)
                reflectiveCurveToRelative(-9.848f, 22.0f, -22.0f, 22.0f)
                reflectiveCurveTo(2.0f, 36.152f, 2.0f, 24.0f)
                close()
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(37.96f, 27.434f)
                arcToRelative(0.932f, 0.932f, 0.0f, false, false, -0.454f, -0.592f)
                lineToRelative(-2.429f, -1.384f)
                curveToRelative(0.064f, -0.465f, 0.127f, -0.94f, 0.127f, -1.437f)
                curveToRelative(0.0f, -0.486f, -0.06f, -0.953f, -0.123f, -1.428f)
                lineToRelative(-0.004f, -0.03f)
                lineToRelative(2.44f, -1.384f)
                curveToRelative(0.222f, -0.137f, 0.39f, -0.37f, 0.454f, -0.634f)
                arcToRelative(0.941f, 0.941f, 0.0f, false, false, -0.106f, -0.719f)
                lineToRelative(-3.273f, -5.631f)
                curveToRelative(-0.243f, -0.455f, -0.887f, -0.634f, -1.363f, -0.38f)
                lineToRelative(-2.428f, 1.405f)
                arcToRelative(10.849f, 10.849f, 0.0f, false, false, -2.524f, -1.459f)
                verticalLineToRelative(-2.789f)
                curveToRelative(0.0f, -0.507f, -0.465f, -0.972f, -0.982f, -0.972f)
                horizontalLineToRelative(-6.558f)
                curveToRelative(-0.55f, 0.0f, -0.982f, 0.528f, -0.982f, 0.972f)
                verticalLineToRelative(2.79f)
                curveToRelative(-0.898f, 0.38f, -1.742f, 0.866f, -2.524f, 1.458f)
                lineToRelative(-2.44f, -1.416f)
                curveToRelative(-0.453f, -0.243f, -1.066f, -0.074f, -1.351f, 0.38f)
                lineToRelative(-3.305f, 5.653f)
                arcToRelative(1.015f, 1.015f, 0.0f, false, false, 0.359f, 1.342f)
                lineToRelative(2.429f, 1.384f)
                curveToRelative(-0.064f, 0.465f, -0.127f, 0.94f, -0.127f, 1.458f)
                reflectiveCurveToRelative(0.063f, 0.983f, 0.127f, 1.437f)
                lineToRelative(-2.44f, 1.384f)
                curveToRelative(-0.222f, 0.138f, -0.39f, 0.37f, -0.454f, 0.634f)
                arcToRelative(0.942f, 0.942f, 0.0f, false, false, 0.106f, 0.719f)
                lineToRelative(3.284f, 5.642f)
                curveToRelative(0.285f, 0.454f, 0.908f, 0.613f, 1.352f, 0.349f)
                lineTo(17.21f, 32.8f)
                arcToRelative(10.771f, 10.771f, 0.0f, false, false, 2.524f, 1.438f)
                verticalLineToRelative(2.789f)
                curveToRelative(0.0f, 0.507f, 0.464f, 0.972f, 0.982f, 0.972f)
                horizontalLineToRelative(6.558f)
                curveToRelative(0.517f, 0.0f, 0.982f, -0.465f, 0.982f, -0.972f)
                verticalLineToRelative(-2.79f)
                curveToRelative(0.982f, -0.412f, 1.816f, -0.887f, 2.523f, -1.437f)
                lineToRelative(2.44f, 1.385f)
                curveToRelative(0.475f, 0.253f, 1.077f, 0.095f, 1.352f, -0.349f)
                lineToRelative(3.273f, -5.632f)
                arcToRelative(0.955f, 0.955f, 0.0f, false, false, 0.106f, -0.76f)
                lineToRelative(0.01f, -0.011f)
                close()
                moveTo(21.043f, 32.664f)
                curveToRelative(-1.15f, -0.401f, -2.228f, -1.014f, -3.115f, -1.807f)
                curveToRelative(-0.306f, -0.264f, -0.77f, -0.317f, -1.151f, -0.137f)
                lineToRelative(-2.154f, 1.215f)
                lineToRelative(-2.292f, -3.91f)
                lineToRelative(2.154f, -1.214f)
                curveToRelative(0.349f, -0.212f, 0.56f, -0.645f, 0.486f, -1.046f)
                arcToRelative(8.236f, 8.236f, 0.0f, false, true, -0.169f, -1.776f)
                curveToRelative(0.0f, -0.665f, 0.095f, -1.32f, 0.18f, -1.806f)
                arcToRelative(1.028f, 1.028f, 0.0f, false, false, -0.486f, -1.036f)
                lineToRelative(-2.154f, -1.215f)
                lineToRelative(2.291f, -3.91f)
                lineToRelative(2.155f, 1.216f)
                curveToRelative(0.359f, 0.211f, 0.844f, 0.169f, 1.15f, -0.106f)
                arcToRelative(9.452f, 9.452f, 0.0f, false, true, 3.116f, -1.807f)
                curveToRelative(0.39f, -0.137f, 0.665f, -0.528f, 0.665f, -0.93f)
                verticalLineToRelative(-2.42f)
                horizontalLineToRelative(4.572f)
                verticalLineToRelative(2.431f)
                curveToRelative(0.0f, 0.412f, 0.275f, 0.792f, 0.666f, 0.93f)
                curveToRelative(1.15f, 0.401f, 2.228f, 1.014f, 3.115f, 1.807f)
                curveToRelative(0.306f, 0.274f, 0.792f, 0.317f, 1.151f, 0.105f)
                lineToRelative(2.154f, -1.215f)
                lineToRelative(2.292f, 3.91f)
                lineToRelative(-2.154f, 1.215f)
                curveToRelative(-0.349f, 0.211f, -0.56f, 0.644f, -0.486f, 1.046f)
                arcTo(8.44f, 8.44f, 0.0f, false, true, 33.198f, 24.0f)
                curveToRelative(0.0f, 0.528f, -0.053f, 1.099f, -0.18f, 1.786f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, false, 0.486f, 1.035f)
                lineToRelative(2.144f, 1.215f)
                lineToRelative(-2.292f, 3.91f)
                lineToRelative(-2.154f, -1.215f)
                curveToRelative(-0.349f, -0.212f, -0.834f, -0.148f, -1.151f, 0.126f)
                arcToRelative(9.458f, 9.458f, 0.0f, false, true, -3.115f, 1.807f)
                arcToRelative(1.013f, 1.013f, 0.0f, false, false, -0.666f, 0.93f)
                verticalLineToRelative(2.43f)
                horizontalLineToRelative(-4.572f)
                verticalLineToRelative(-2.43f)
                curveToRelative(0.0f, -0.412f, -0.275f, -0.793f, -0.665f, -0.93f)
                horizontalLineToRelative(0.01f)
                close()
                moveTo(28.828f, 24.0f)
                curveToRelative(0.0f, -2.642f, -2.165f, -4.786f, -4.826f, -4.786f)
                curveToRelative(-2.661f, 0.0f, -4.826f, 2.145f, -4.826f, 4.786f)
                curveToRelative(0.0f, 2.642f, 2.165f, 4.787f, 4.826f, 4.787f)
                curveToRelative(2.66f, 0.0f, 4.826f, -2.145f, 4.826f, -4.787f)
                close()
                moveTo(24.002f, 21.168f)
                arcToRelative(2.87f, 2.87f, 0.0f, false, true, 2.883f, 2.864f)
                curveToRelative(0.0f, 1.585f, -1.3f, 2.863f, -2.883f, 2.863f)
                curveToRelative(-1.584f, 0.0f, -2.883f, -1.278f, -2.883f, -2.863f)
                reflectiveCurveToRelative(1.299f, -2.864f, 2.883f, -2.864f)
                close()
            }
        }
        .build()
        return _ruwidoSettingsOutline!!
    }

private var _ruwidoSettingsOutline: ImageVector? = null
