package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.TrailerOutline: ImageVector
    get() {
        if (_trailerOutline != null) {
            return _trailerOutline!!
        }
        _trailerOutline = Builder(name = "TrailerOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(10.0639f, 4.0001f)
                horizontalLineTo(10.9272f)
                curveTo(11.2358f, 4.0001f, 11.534f, 4.1211f, 11.7529f, 4.3364f)
                curveTo(11.9719f, 4.5516f, 12.0949f, 4.8448f, 12.0949f, 5.1483f)
                verticalLineTo(5.8474f)
                curveTo(12.0949f, 5.9929f, 12.2158f, 6.1118f, 12.3639f, 6.1118f)
                horizontalLineTo(13.5315f)
                curveTo(13.6796f, 6.1118f, 13.8005f, 5.9929f, 13.8005f, 5.8474f)
                verticalLineTo(5.1483f)
                curveTo(13.8005f, 4.8448f, 13.9235f, 4.5516f, 14.1425f, 4.3364f)
                curveTo(14.3614f, 4.1211f, 14.6596f, 4.0001f, 14.9682f, 4.0001f)
                horizontalLineTo(33.0672f)
                curveTo(33.3759f, 4.0001f, 33.674f, 4.1211f, 33.893f, 4.3364f)
                curveTo(34.1119f, 4.5516f, 34.2349f, 4.8448f, 34.2349f, 5.1483f)
                verticalLineTo(5.8474f)
                curveTo(34.2349f, 5.9929f, 34.3559f, 6.1118f, 34.5039f, 6.1118f)
                horizontalLineTo(35.6716f)
                curveTo(35.8196f, 6.1118f, 35.9406f, 5.9929f, 35.9406f, 5.8474f)
                verticalLineTo(5.1483f)
                curveTo(35.9406f, 4.8448f, 36.0636f, 4.5516f, 36.2825f, 4.3364f)
                curveTo(36.5015f, 4.1211f, 36.7997f, 4.0001f, 37.1083f, 4.0001f)
                horizontalLineTo(37.9715f)
                curveTo(39.0391f, 4.0001f, 40.065f, 4.4184f, 40.8198f, 5.1606f)
                curveTo(41.5746f, 5.9027f, 42.0f, 6.9114f, 42.0f, 7.9612f)
                verticalLineTo(40.1086f)
                curveTo(41.9812f, 41.1522f, 41.5454f, 42.1445f, 40.7864f, 42.8723f)
                curveTo(40.0275f, 43.6001f, 39.0078f, 44.0061f, 37.9465f, 43.9999f)
                horizontalLineTo(37.1166f)
                curveTo(36.4723f, 43.9999f, 35.9489f, 43.4853f, 35.9489f, 42.8518f)
                verticalLineTo(42.1527f)
                curveTo(35.9427f, 42.0112f, 35.8238f, 41.9005f, 35.6799f, 41.9005f)
                horizontalLineTo(34.5123f)
                curveTo(34.3684f, 41.9005f, 34.2495f, 42.0112f, 34.2433f, 42.1527f)
                verticalLineTo(42.8518f)
                curveTo(34.2454f, 43.4853f, 33.7241f, 43.9999f, 33.0777f, 43.9999f)
                horizontalLineTo(14.9328f)
                curveTo(14.2884f, 43.9999f, 13.7651f, 43.4853f, 13.7651f, 42.8518f)
                verticalLineTo(42.1527f)
                curveTo(13.7588f, 42.0112f, 13.64f, 41.9005f, 13.4961f, 41.9005f)
                horizontalLineTo(12.3284f)
                curveTo(12.1845f, 41.9005f, 12.0657f, 42.0112f, 12.0594f, 42.1527f)
                verticalLineTo(42.8518f)
                curveTo(12.0594f, 43.4853f, 11.5361f, 43.9999f, 10.8917f, 43.9999f)
                horizontalLineTo(10.0285f)
                curveTo(8.9609f, 43.9999f, 7.935f, 43.5817f, 7.1802f, 42.8395f)
                curveTo(6.4254f, 42.0973f, 6.0f, 41.0886f, 6.0f, 40.0389f)
                verticalLineTo(7.8914f)
                curveTo(6.0188f, 6.8479f, 6.4566f, 5.8515f, 7.2177f, 5.1237f)
                curveTo(7.9788f, 4.3958f, 9.0005f, 3.9919f, 10.0639f, 4.0001f)
                close()
                moveTo(16.065f, 6.2964f)
                curveTo(15.844f, 7.5163f, 14.768f, 8.406f, 13.5065f, 8.4081f)
                horizontalLineTo(12.3388f)
                curveTo(11.0877f, 8.4081f, 10.0118f, 7.5286f, 9.7824f, 6.3189f)
                curveTo(8.9567f, 6.444f, 8.3479f, 7.1411f, 8.3458f, 7.9612f)
                verticalLineTo(40.1086f)
                curveTo(8.3708f, 40.9143f, 8.9818f, 41.5868f, 9.795f, 41.7057f)
                curveTo(10.0264f, 40.4941f, 11.1003f, 39.6166f, 12.3534f, 39.6145f)
                horizontalLineTo(13.5211f)
                curveTo(14.7826f, 39.6043f, 15.869f, 40.4859f, 16.1025f, 41.7057f)
                horizontalLineTo(31.983f)
                curveTo(32.2082f, 40.49f, 33.2841f, 39.6063f, 34.5394f, 39.6043f)
                horizontalLineTo(35.707f)
                curveTo(36.9456f, 39.6289f, 37.9944f, 40.5064f, 38.2176f, 41.7057f)
                curveTo(39.0433f, 41.5807f, 39.6521f, 40.8836f, 39.6542f, 40.0635f)
                verticalLineTo(7.916f)
                curveTo(39.6542f, 7.096f, 39.0433f, 6.3989f, 38.2176f, 6.2738f)
                curveTo(38.007f, 7.5019f, 36.9268f, 8.404f, 35.6591f, 8.4081f)
                horizontalLineTo(34.4914f)
                curveTo(33.2361f, 8.4081f, 32.1602f, 7.5224f, 31.935f, 6.3066f)
                lineTo(16.0671f, 6.2943f)
                lineTo(16.065f, 6.2964f)
                close()
                moveTo(15.3414f, 26.53f)
                curveTo(15.3414f, 26.2327f, 15.2205f, 25.9477f, 15.0078f, 25.7366f)
                curveTo(14.7931f, 25.5254f, 14.5032f, 25.4085f, 14.2009f, 25.4085f)
                horizontalLineTo(11.8488f)
                curveTo(11.217f, 25.4085f, 10.7062f, 25.9108f, 10.7062f, 26.5321f)
                verticalLineTo(29.144f)
                curveTo(10.7062f, 29.7652f, 11.217f, 30.2676f, 11.8488f, 30.2676f)
                horizontalLineTo(14.1341f)
                curveTo(14.7659f, 30.2676f, 15.3435f, 29.796f, 15.3435f, 29.1748f)
                verticalLineTo(26.5321f)
                lineTo(15.3414f, 26.53f)
                close()
                moveTo(15.3414f, 18.8827f)
                curveTo(15.3414f, 18.5854f, 15.2205f, 18.3004f, 15.0078f, 18.0892f)
                curveTo(14.7931f, 17.8781f, 14.5032f, 17.7612f, 14.2009f, 17.7612f)
                horizontalLineTo(11.8488f)
                curveTo(11.217f, 17.7612f, 10.7062f, 18.2635f, 10.7062f, 18.8847f)
                verticalLineTo(21.4967f)
                curveTo(10.7062f, 22.1179f, 11.217f, 22.6202f, 11.8488f, 22.6202f)
                horizontalLineTo(14.1341f)
                curveTo(14.7659f, 22.6202f, 15.3435f, 22.1487f, 15.3435f, 21.5275f)
                verticalLineTo(18.8847f)
                lineTo(15.3414f, 18.8827f)
                close()
                moveTo(15.3414f, 11.2374f)
                curveTo(15.3414f, 10.9401f, 15.2205f, 10.6551f, 15.0078f, 10.444f)
                curveTo(14.7931f, 10.2328f, 14.5032f, 10.1159f, 14.2009f, 10.1159f)
                horizontalLineTo(11.8488f)
                curveTo(11.217f, 10.1159f, 10.7062f, 10.6182f, 10.7062f, 11.2395f)
                verticalLineTo(13.8514f)
                curveTo(10.7062f, 14.4727f, 11.217f, 14.975f, 11.8488f, 14.975f)
                horizontalLineTo(14.1341f)
                curveTo(14.7659f, 14.975f, 15.3435f, 14.5034f, 15.3435f, 13.8822f)
                verticalLineTo(11.2395f)
                lineTo(15.3414f, 11.2374f)
                close()
                moveTo(15.3414f, 34.1876f)
                curveTo(15.3414f, 33.8903f, 15.2205f, 33.6053f, 15.0078f, 33.3941f)
                curveTo(14.7931f, 33.183f, 14.5032f, 33.0661f, 14.2009f, 33.0661f)
                horizontalLineTo(11.8488f)
                curveTo(11.217f, 33.0661f, 10.7062f, 33.5684f, 10.7062f, 34.1896f)
                verticalLineTo(36.8016f)
                curveTo(10.7062f, 37.4228f, 11.217f, 37.9251f, 11.8488f, 37.9251f)
                horizontalLineTo(14.1341f)
                curveTo(14.7659f, 37.9251f, 15.3435f, 37.4536f, 15.3435f, 36.8324f)
                verticalLineTo(34.1896f)
                lineTo(15.3414f, 34.1876f)
                close()
                moveTo(37.5107f, 26.53f)
                curveTo(37.5107f, 26.2327f, 37.3898f, 25.9477f, 37.1771f, 25.7366f)
                curveTo(36.9623f, 25.5254f, 36.6725f, 25.4085f, 36.3701f, 25.4085f)
                horizontalLineTo(34.0181f)
                curveTo(33.3863f, 25.4085f, 32.8754f, 25.9108f, 32.8754f, 26.5321f)
                verticalLineTo(29.144f)
                curveTo(32.8754f, 29.7652f, 33.3863f, 30.2676f, 34.0181f, 30.2676f)
                horizontalLineTo(36.3034f)
                curveTo(36.9352f, 30.2676f, 37.5128f, 29.796f, 37.5128f, 29.1748f)
                verticalLineTo(26.5321f)
                lineTo(37.5107f, 26.53f)
                close()
                moveTo(37.5107f, 18.8827f)
                curveTo(37.5107f, 18.5854f, 37.3898f, 18.3004f, 37.1771f, 18.0892f)
                curveTo(36.9623f, 17.8781f, 36.6725f, 17.7612f, 36.3701f, 17.7612f)
                horizontalLineTo(34.0181f)
                curveTo(33.3863f, 17.7612f, 32.8754f, 18.2635f, 32.8754f, 18.8847f)
                verticalLineTo(21.4967f)
                curveTo(32.8754f, 22.1179f, 33.3863f, 22.6202f, 34.0181f, 22.6202f)
                horizontalLineTo(36.3034f)
                curveTo(36.9352f, 22.6202f, 37.5128f, 22.1487f, 37.5128f, 21.5275f)
                verticalLineTo(18.8847f)
                lineTo(37.5107f, 18.8827f)
                close()
                moveTo(37.5107f, 11.2374f)
                curveTo(37.5107f, 10.9401f, 37.3898f, 10.6551f, 37.1771f, 10.444f)
                curveTo(36.9623f, 10.2328f, 36.6725f, 10.1159f, 36.3701f, 10.1159f)
                horizontalLineTo(34.0181f)
                curveTo(33.3863f, 10.1159f, 32.8754f, 10.6182f, 32.8754f, 11.2395f)
                verticalLineTo(13.8514f)
                curveTo(32.8754f, 14.4727f, 33.3863f, 14.975f, 34.0181f, 14.975f)
                horizontalLineTo(36.3034f)
                curveTo(36.9352f, 14.975f, 37.5128f, 14.5034f, 37.5128f, 13.8822f)
                verticalLineTo(11.2395f)
                lineTo(37.5107f, 11.2374f)
                close()
                moveTo(37.5107f, 34.1876f)
                curveTo(37.5107f, 33.8903f, 37.3898f, 33.6053f, 37.1771f, 33.3941f)
                curveTo(36.9623f, 33.183f, 36.6725f, 33.0661f, 36.3701f, 33.0661f)
                horizontalLineTo(34.0181f)
                curveTo(33.3863f, 33.0661f, 32.8754f, 33.5684f, 32.8754f, 34.1896f)
                verticalLineTo(36.8016f)
                curveTo(32.8754f, 37.4228f, 33.3863f, 37.9251f, 34.0181f, 37.9251f)
                horizontalLineTo(36.3034f)
                curveTo(36.9352f, 37.9251f, 37.5128f, 37.4536f, 37.5128f, 36.8324f)
                verticalLineTo(34.1896f)
                lineTo(37.5107f, 34.1876f)
                close()
            }
        }
        .build()
        return _trailerOutline!!
    }

private var _trailerOutline: ImageVector? = null
