package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GenreOutline: ImageVector
    get() {
        if (_genreOutline != null) {
            return _genreOutline!!
        }
        _genreOutline = Builder(name = "GenreOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(31.0909f, 25.4734f)
                curveTo(31.0909f, 29.9403f, 30.0463f, 31.7377f, 29.1398f, 32.5375f)
                curveTo(28.1713f, 33.3921f, 26.6149f, 33.7952f, 24.0f, 33.7952f)
                curveTo(21.3851f, 33.7952f, 19.8287f, 33.3921f, 18.8602f, 32.5375f)
                curveTo(17.9537f, 31.7377f, 16.9091f, 29.9403f, 16.9091f, 25.4734f)
                curveTo(16.9091f, 21.2448f, 18.195f, 17.0897f, 19.9971f, 13.6169f)
                lineTo(28.2268f, 13.6169f)
                curveTo(29.9093f, 16.9536f, 31.0909f, 21.0614f, 31.0909f, 25.4734f)
                close()
                moveTo(26.5213f, 10.7257f)
                curveTo(25.7362f, 9.5827f, 24.9034f, 8.5917f, 24.0688f, 7.7839f)
                curveTo(23.3166f, 8.5656f, 22.5099f, 9.5555f, 21.716f, 10.7257f)
                lineTo(26.5213f, 10.7257f)
                close()
                moveTo(14.0f, 25.4734f)
                curveTo(14.0f, 15.8204f, 19.8824f, 6.9055f, 24.0f, 4.0f)
                curveTo(28.902f, 7.2687f, 34.0f, 15.8204f, 34.0f, 25.4734f)
                curveTo(34.0f, 26.7795f, 33.918f, 27.9374f, 33.7626f, 28.9633f)
                curveTo(33.9006f, 28.7073f, 33.9007f, 28.7074f, 33.9008f, 28.7074f)
                lineTo(33.9025f, 28.7084f)
                lineTo(33.9061f, 28.7103f)
                lineTo(33.9174f, 28.7163f)
                lineTo(33.9565f, 28.7375f)
                curveTo(33.9896f, 28.7555f, 34.0366f, 28.7812f, 34.0958f, 28.8142f)
                curveTo(34.214f, 28.8801f, 34.3814f, 28.9749f, 34.5838f, 29.0941f)
                curveTo(34.9871f, 29.3316f, 35.5372f, 29.6702f, 36.1192f, 30.0725f)
                curveTo(36.6972f, 30.472f, 37.3306f, 30.9508f, 37.8887f, 31.4707f)
                curveTo(38.4266f, 31.9719f, 38.9928f, 32.6005f, 39.3216f, 33.3135f)
                curveTo(39.7383f, 34.2173f, 39.9095f, 35.5555f, 39.9712f, 36.7724f)
                curveTo(40.0361f, 38.0507f, 39.9897f, 39.4432f, 39.8481f, 40.593f)
                curveTo(39.7777f, 41.1643f, 39.679f, 41.717f, 39.5429f, 42.1821f)
                curveTo(39.4751f, 42.4138f, 39.3878f, 42.6595f, 39.2712f, 42.8885f)
                curveTo(39.1665f, 43.0944f, 38.9755f, 43.4139f, 38.6418f, 43.6607f)
                curveTo(38.2356f, 43.9611f, 37.653f, 44.1159f, 37.0506f, 43.8976f)
                curveTo(36.5665f, 43.7221f, 36.271f, 43.3733f, 36.1154f, 43.1558f)
                curveTo(35.7935f, 42.7055f, 35.5527f, 42.0703f, 35.3469f, 41.3693f)
                curveTo(34.6081f, 38.8529f, 33.9015f, 38.0636f, 33.2862f, 37.5577f)
                curveTo(33.1421f, 37.4391f, 32.9505f, 37.3053f, 32.726f, 37.1485f)
                curveTo(32.1128f, 36.7204f, 31.2537f, 36.1206f, 30.4421f, 35.1891f)
                curveTo(30.0201f, 35.4776f, 29.5683f, 35.7142f, 29.0909f, 35.9072f)
                verticalLineTo(39.2011f)
                verticalLineTo(40.6469f)
                horizontalLineTo(27.6364f)
                horizontalLineTo(20.3636f)
                horizontalLineTo(18.9091f)
                verticalLineTo(39.2011f)
                verticalLineTo(35.9072f)
                curveTo(18.4317f, 35.7142f, 17.9799f, 35.4776f, 17.5579f, 35.1891f)
                curveTo(16.7463f, 36.1206f, 15.8872f, 36.7204f, 15.274f, 37.1485f)
                curveTo(15.0495f, 37.3053f, 14.8579f, 37.4391f, 14.7138f, 37.5577f)
                curveTo(14.0985f, 38.0636f, 13.3919f, 38.8529f, 12.6531f, 41.3693f)
                curveTo(12.4473f, 42.0703f, 12.2065f, 42.7055f, 11.8846f, 43.1558f)
                curveTo(11.729f, 43.3733f, 11.4335f, 43.7221f, 10.9494f, 43.8976f)
                curveTo(10.347f, 44.1159f, 9.7644f, 43.9611f, 9.3582f, 43.6607f)
                curveTo(9.0245f, 43.4139f, 8.8335f, 43.0944f, 8.7288f, 42.8885f)
                curveTo(8.6122f, 42.6595f, 8.525f, 42.4138f, 8.4571f, 42.1821f)
                curveTo(8.321f, 41.717f, 8.2223f, 41.1643f, 8.1519f, 40.593f)
                curveTo(8.0103f, 39.4432f, 7.9639f, 38.0507f, 8.0288f, 36.7724f)
                curveTo(8.0906f, 35.5555f, 8.2617f, 34.2173f, 8.6784f, 33.3135f)
                curveTo(9.0072f, 32.6005f, 9.5734f, 31.9719f, 10.1113f, 31.4707f)
                curveTo(10.6694f, 30.9508f, 11.3028f, 30.472f, 11.8808f, 30.0725f)
                curveTo(12.4628f, 29.6702f, 13.0129f, 29.3316f, 13.4162f, 29.0941f)
                curveTo(13.6186f, 28.9749f, 13.786f, 28.8801f, 13.9042f, 28.8142f)
                curveTo(13.9634f, 28.7812f, 14.0104f, 28.7555f, 14.0435f, 28.7375f)
                lineTo(14.0826f, 28.7163f)
                lineTo(14.0939f, 28.7103f)
                lineTo(14.0975f, 28.7084f)
                lineTo(14.0987f, 28.7077f)
                curveTo(14.0988f, 28.7076f, 14.0994f, 28.7073f, 14.2374f, 28.9633f)
                curveTo(14.082f, 27.9374f, 14.0f, 26.7795f, 14.0f, 25.4734f)
                close()
                moveTo(21.8182f, 36.5843f)
                verticalLineTo(37.7553f)
                horizontalLineTo(26.1818f)
                verticalLineTo(36.5843f)
                curveTo(25.4795f, 36.658f, 24.7494f, 36.6868f, 24.0f, 36.6868f)
                curveTo(23.2506f, 36.6868f, 22.5205f, 36.658f, 21.8182f, 36.5843f)
                close()
                moveTo(32.4297f, 33.0396f)
                curveTo(32.6857f, 32.6065f, 32.9139f, 32.1245f, 33.1118f, 31.5882f)
                curveTo(33.4721f, 31.8007f, 33.9549f, 32.0984f, 34.4582f, 32.4464f)
                curveTo(34.9706f, 32.8005f, 35.4806f, 33.1904f, 35.8992f, 33.5804f)
                curveTo(36.338f, 33.9892f, 36.5819f, 34.3119f, 36.677f, 34.5181f)
                curveTo(36.8436f, 34.8795f, 37.0056f, 35.731f, 37.0658f, 36.9181f)
                curveTo(37.0807f, 37.2111f, 37.0888f, 37.5108f, 37.0906f, 37.8111f)
                curveTo(36.508f, 36.6666f, 35.867f, 35.9272f, 35.1408f, 35.3299f)
                curveTo(34.7179f, 34.9821f, 34.3748f, 34.7458f, 34.0672f, 34.534f)
                curveTo(33.4937f, 34.139f, 33.0436f, 33.829f, 32.4297f, 33.0396f)
                close()
                moveTo(15.5703f, 33.0396f)
                curveTo(15.3143f, 32.6066f, 15.0861f, 32.1245f, 14.8882f, 31.5882f)
                curveTo(14.5279f, 31.8007f, 14.0451f, 32.0984f, 13.5418f, 32.4464f)
                curveTo(13.0294f, 32.8005f, 12.5194f, 33.1904f, 12.1008f, 33.5804f)
                curveTo(11.662f, 33.9892f, 11.4181f, 34.3119f, 11.323f, 34.5181f)
                curveTo(11.1564f, 34.8795f, 10.9944f, 35.731f, 10.9342f, 36.9181f)
                curveTo(10.9193f, 37.2111f, 10.9112f, 37.5108f, 10.9094f, 37.8111f)
                curveTo(11.492f, 36.6666f, 12.133f, 35.9272f, 12.8592f, 35.3299f)
                curveTo(13.2821f, 34.9821f, 13.6252f, 34.7458f, 13.9328f, 34.534f)
                curveTo(14.5063f, 34.139f, 14.9564f, 33.829f, 15.5703f, 33.0396f)
                close()
                moveTo(24.0f, 24.2406f)
                curveTo(24.5355f, 24.2406f, 24.9697f, 23.8091f, 24.9697f, 23.2768f)
                curveTo(24.9697f, 22.7445f, 24.5355f, 22.313f, 24.0f, 22.313f)
                curveTo(23.4645f, 22.313f, 23.0303f, 22.7445f, 23.0303f, 23.2768f)
                curveTo(23.0303f, 23.8091f, 23.4645f, 24.2406f, 24.0f, 24.2406f)
                close()
                moveTo(24.0f, 27.1322f)
                curveTo(26.1422f, 27.1322f, 27.8788f, 25.4061f, 27.8788f, 23.2768f)
                curveTo(27.8788f, 21.1475f, 26.1422f, 19.4214f, 24.0f, 19.4214f)
                curveTo(21.8578f, 19.4214f, 20.1212f, 21.1475f, 20.1212f, 23.2768f)
                curveTo(20.1212f, 25.4061f, 21.8578f, 27.1322f, 24.0f, 27.1322f)
                close()
                moveTo(11.3247f, 41.5835f)
                lineTo(11.3229f, 41.5799f)
                curveTo(11.3297f, 41.5915f, 11.3299f, 41.5938f, 11.3247f, 41.5835f)
                close()
                moveTo(36.6753f, 41.5835f)
                lineTo(36.6771f, 41.5799f)
                curveTo(36.6703f, 41.5915f, 36.6701f, 41.5938f, 36.6753f, 41.5835f)
                close()
            }
        }
        .build()
        return _genreOutline!!
    }

private var _genreOutline: ImageVector? = null
