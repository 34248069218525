package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RtRottenSplat: ImageVector
    get() {
        if (_rtRottenSplat != null) {
            return _rtRottenSplat!!
        }
        _rtRottenSplat = Builder(name = "RtRottenSplat", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFF0AC855)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(37.4705f, 38.217f)
                curveTo(31.0239f, 38.576f, 29.7073f, 30.7452f, 27.179f, 30.8008f)
                curveTo(26.1018f, 30.8246f, 25.252f, 32.0207f, 25.6245f, 33.4153f)
                curveTo(25.8295f, 34.1809f, 26.398f, 35.3055f, 26.7571f, 36.0028f)
                curveTo(28.0212f, 38.4648f, 26.1526f, 41.2492f, 23.9654f, 41.4843f)
                curveTo(20.3299f, 41.8766f, 18.8128f, 39.637f, 18.9071f, 37.345f)
                curveTo(19.0133f, 34.7718f, 21.0674f, 32.143f, 18.9595f, 31.0248f)
                curveTo(16.7512f, 29.8525f, 14.9559f, 34.4366f, 12.842f, 35.4596f)
                curveTo(10.9285f, 36.3856f, 8.2729f, 35.6676f, 7.3289f, 33.4121f)
                curveTo(6.6661f, 31.8269f, 6.7858f, 28.7756f, 9.7391f, 27.6113f)
                curveTo(11.5838f, 26.8838f, 15.692f, 28.5628f, 15.903f, 26.4359f)
                curveTo(16.1453f, 23.985f, 11.5853f, 23.7785f, 10.2134f, 23.1908f)
                curveTo(7.7837f, 22.152f, 6.3505f, 19.9267f, 7.474f, 17.5409f)
                curveTo(8.3163f, 15.7508f, 10.7968f, 15.0234f, 12.6894f, 15.8064f)
                curveTo(14.9574f, 16.7452f, 15.321f, 19.2421f, 16.4834f, 20.2809f)
                curveTo(17.4843f, 21.1752f, 18.8547f, 21.288f, 19.7494f, 20.6733f)
                curveTo(20.4107f, 20.219f, 20.6306f, 19.2231f, 20.3807f, 18.3113f)
                curveTo(20.0501f, 17.1026f, 19.1734f, 16.3481f, 18.3161f, 15.6079f)
                curveTo(16.7931f, 14.2927f, 14.6418f, 13.1618f, 15.9434f, 9.5736f)
                curveTo(17.0101f, 6.6367f, 20.1384f, 6.5302f, 20.1384f, 6.5302f)
                curveTo(21.3816f, 6.3825f, 22.4932f, 6.7796f, 23.3998f, 7.6405f)
                curveTo(24.6117f, 8.7905f, 24.8481f, 10.3265f, 24.6446f, 11.9673f)
                curveTo(24.4591f, 13.4636f, 23.9609f, 14.7756f, 23.7006f, 16.2575f)
                curveTo(23.3983f, 17.9793f, 24.2646f, 19.7139f, 25.9133f, 19.7822f)
                curveTo(28.0811f, 19.8711f, 28.7319f, 18.1017f, 28.9967f, 16.9802f)
                curveTo(29.3857f, 15.3394f, 29.8943f, 13.8162f, 31.3291f, 12.8568f)
                curveTo(33.3877f, 11.4797f, 36.2467f, 11.7815f, 37.5737f, 14.4277f)
                curveTo(38.6225f, 16.5212f, 38.2859f, 19.4041f, 36.6761f, 20.9782f)
                curveTo(35.9535f, 21.6835f, 35.0858f, 21.9328f, 34.1462f, 21.9408f)
                curveTo(32.7997f, 21.9503f, 31.4533f, 21.9154f, 30.2025f, 22.5857f)
                curveTo(29.3513f, 23.0415f, 28.9802f, 23.7833f, 28.9802f, 24.7776f)
                curveTo(28.9802f, 25.7466f, 29.456f, 26.3803f, 30.225f, 26.7917f)
                curveTo(31.6762f, 27.5684f, 33.277f, 27.7273f, 34.8434f, 28.018f)
                curveTo(37.1159f, 28.4405f, 39.1132f, 29.2903f, 40.3969f, 31.5299f)
                curveTo(40.4088f, 31.5489f, 40.4193f, 31.5696f, 40.4298f, 31.5886f)
                curveTo(41.9034f, 34.2397f, 40.3624f, 38.0566f, 37.4675f, 38.217f)
                horizontalLineTo(37.4705f)
                close()
            }
        }
        .build()
        return _rtRottenSplat!!
    }

private var _rtRottenSplat: ImageVector? = null
