package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoInputFilled: ImageVector
    get() {
        if (_ruwidoInputFilled != null) {
            return _ruwidoInputFilled!!
        }
        _ruwidoInputFilled = Builder(name = "RuwidoInputFilled", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(11.281f, 15.0f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -1.0f, 1.0f)
                verticalLineToRelative(3.708f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 2.0f, 0.0f)
                lineTo(12.281f, 17.0f)
                horizontalLineToRelative(23.992f)
                verticalLineToRelative(13.952f)
                lineTo(12.281f, 30.952f)
                verticalLineToRelative(-2.638f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, -2.0f, 0.0f)
                verticalLineToRelative(3.638f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 1.0f, 1.0f)
                horizontalLineToRelative(25.992f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 1.0f, -1.0f)
                lineTo(38.273f, 16.0f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -1.0f, -1.0f)
                lineTo(11.281f, 15.0f)
                close()
                moveTo(21.193f, 19.958f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.403f, -0.177f)
                lineToRelative(4.36f, 3.382f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.022f, 1.562f)
                lineToRelative(-4.616f, 3.801f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, true, -1.271f, -1.544f)
                lineToRelative(2.4f, -1.976f)
                lineTo(11.0f, 25.006f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.0f, -2.0f)
                horizontalLineToRelative(12.49f)
                lineToRelative(-2.12f, -1.645f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, -0.177f, -1.403f)
                close()
            }
        }
        .build()
        return _ruwidoInputFilled!!
    }

private var _ruwidoInputFilled: ImageVector? = null
