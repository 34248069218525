package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GenreFilled: ImageVector
    get() {
        if (_genreFilled != null) {
            return _genreFilled!!
        }
        _genreFilled = Builder(name = "GenreFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(14.4007f, 25.5f)
                curveTo(14.4007f, 15.8f, 20.3007f, 6.9f, 24.4007f, 4.0f)
                curveTo(29.3007f, 7.3f, 34.4007f, 15.8f, 34.4007f, 25.5f)
                curveTo(34.4007f, 26.8f, 34.3007f, 28.0f, 34.2007f, 29.0f)
                curveTo(34.3007f, 28.7f, 34.3007f, 28.7f, 34.3007f, 28.7f)
                curveTo(34.3007f, 28.7f, 34.4007f, 28.7f, 34.4007f, 28.8f)
                curveTo(34.5007f, 28.9f, 34.7007f, 29.0f, 34.9007f, 29.1f)
                curveTo(35.3007f, 29.3f, 35.9007f, 29.7f, 36.4007f, 30.1f)
                curveTo(37.0007f, 30.5f, 37.6007f, 31.0f, 38.2007f, 31.5f)
                curveTo(38.7007f, 32.0f, 39.3007f, 32.6f, 39.6007f, 33.3f)
                curveTo(40.0007f, 34.2f, 40.2008f, 35.5f, 40.2008f, 36.8f)
                curveTo(40.3008f, 38.1f, 40.2007f, 39.5f, 40.1007f, 40.6f)
                curveTo(40.0007f, 41.2f, 39.9007f, 41.7f, 39.8007f, 42.2f)
                curveTo(39.7007f, 42.4f, 39.6007f, 42.7f, 39.5007f, 42.9f)
                curveTo(39.4007f, 43.1f, 39.2007f, 43.4f, 38.9007f, 43.7f)
                curveTo(38.5007f, 44.0f, 37.9007f, 44.2f, 37.3007f, 43.9f)
                curveTo(36.8007f, 43.7f, 36.5007f, 43.4f, 36.4007f, 43.2f)
                curveTo(36.1007f, 42.7f, 35.8007f, 42.1f, 35.6007f, 41.4f)
                curveTo(34.9007f, 38.9f, 34.2007f, 38.1f, 33.5007f, 37.6f)
                curveTo(33.4007f, 37.5f, 33.2007f, 37.3f, 32.9007f, 37.2f)
                curveTo(32.3007f, 36.8f, 31.4007f, 36.2f, 30.6007f, 35.2f)
                curveTo(30.2007f, 35.5f, 29.7007f, 35.7f, 29.2007f, 35.9f)
                verticalLineTo(39.2f)
                verticalLineTo(40.6f)
                horizontalLineTo(27.7007f)
                horizontalLineTo(20.4007f)
                horizontalLineTo(18.9007f)
                verticalLineTo(39.2f)
                verticalLineTo(35.9f)
                curveTo(18.4007f, 35.7f, 18.0007f, 35.5f, 17.5007f, 35.2f)
                curveTo(16.7007f, 36.1f, 15.8007f, 36.7f, 15.2007f, 37.2f)
                curveTo(15.0007f, 37.4f, 14.8007f, 37.5f, 14.6007f, 37.6f)
                curveTo(14.0007f, 38.1f, 13.3007f, 38.9f, 12.5007f, 41.4f)
                curveTo(12.3007f, 42.1f, 12.1007f, 42.7f, 11.7007f, 43.2f)
                curveTo(11.5007f, 43.4f, 11.2007f, 43.8f, 10.8007f, 43.9f)
                curveTo(10.2007f, 44.1f, 9.6007f, 44.0f, 9.2007f, 43.7f)
                curveTo(8.9007f, 43.5f, 8.7007f, 43.1f, 8.6007f, 42.9f)
                curveTo(8.5007f, 42.7f, 8.4007f, 42.4f, 8.3007f, 42.2f)
                curveTo(8.2007f, 41.7f, 8.1445f, 41.2441f, 8.1445f, 40.6f)
                curveTo(8.1445f, 40.6f, 8.4007f, 38.1f, 8.4007f, 36.8f)
                curveTo(8.5007f, 35.6f, 8.6007f, 34.2f, 9.0007f, 33.3f)
                curveTo(9.4007f, 32.6f, 10.0007f, 32.0f, 10.5007f, 31.5f)
                curveTo(11.1007f, 31.0f, 11.7007f, 30.5f, 12.3007f, 30.1f)
                curveTo(12.9007f, 29.7f, 13.4007f, 29.4f, 13.8007f, 29.1f)
                curveTo(14.0007f, 29.0f, 14.2007f, 28.9f, 14.3007f, 28.8f)
                curveTo(14.4007f, 28.8f, 14.4007f, 28.8f, 14.4007f, 28.7f)
                curveTo(14.4007f, 28.7f, 14.4007f, 28.7f, 14.5007f, 29.0f)
                curveTo(14.5007f, 27.9f, 14.4007f, 26.8f, 14.4007f, 25.5f)
                close()
                moveTo(24.4007f, 27.1f)
                curveTo(26.5007f, 27.1f, 28.3007f, 25.4f, 28.3007f, 23.2f)
                curveTo(28.3007f, 21.0f, 26.6007f, 19.3f, 24.4007f, 19.3f)
                curveTo(22.2007f, 19.3f, 20.5007f, 21.0f, 20.5007f, 23.2f)
                curveTo(20.5007f, 25.4f, 22.3007f, 27.1f, 24.4007f, 27.1f)
                close()
            }
        }
        .build()
        return _genreFilled!!
    }

private var _genreFilled: ImageVector? = null
