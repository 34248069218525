package com.brdgwtr.designsystem.foundation

import androidx.compose.ui.unit.Dp
import dev.drewhamilton.poko.Poko

@Poko
class BwSpacing(
    val xxxs: Dp,
    val xxs: Dp,
    val xs: Dp,
    val s: Dp,
    val m: Dp,
    val l: Dp,
    val xl: Dp,
    val xxl: Dp,
    val xxxl: Dp,
)
