package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.MoviesFilled: ImageVector
    get() {
        if (_moviesFilled != null) {
            return _moviesFilled!!
        }
        _moviesFilled = Builder(name = "MoviesFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.4285f, 4.0f)
                horizontalLineTo(11.8898f)
                lineTo(8.4613f, 12.5716f)
                horizontalLineTo(4.0f)
                verticalLineTo(9.4287f)
                curveTo(4.0f, 7.989f, 4.5721f, 6.6082f, 5.5899f, 5.59f)
                curveTo(6.608f, 4.5721f, 7.9887f, 4.0f, 9.4285f, 4.0f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(17.0328f, 12.5716f)
                lineTo(20.4613f, 4.0f)
                horizontalLineTo(14.9671f)
                lineTo(11.5386f, 12.5716f)
                horizontalLineTo(17.0328f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(29.0328f, 4.0f)
                lineTo(25.6043f, 12.5716f)
                horizontalLineTo(20.1101f)
                lineTo(23.5386f, 4.0f)
                horizontalLineTo(29.0328f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(37.253f, 12.5711f)
                lineTo(40.5301f, 4.3783f)
                curveTo(41.5505f, 4.7715f, 42.428f, 5.4641f, 43.0472f, 6.3656f)
                curveTo(43.6668f, 7.2668f, 43.9987f, 8.3345f, 44.0f, 9.4282f)
                verticalLineTo(12.5354f)
                lineTo(37.253f, 12.5711f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(34.1757f, 12.5716f)
                lineTo(37.6042f, 4.0f)
                horizontalLineTo(32.11f)
                lineTo(28.6815f, 12.5716f)
                horizontalLineTo(34.1757f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(4.0f, 15.4284f)
                lineTo(40.4993f, 15.367f)
                lineTo(43.9993f, 15.3611f)
                verticalLineTo(18.8523f)
                verticalLineTo(38.5713f)
                curveTo(43.9993f, 40.011f, 43.4273f, 41.3918f, 42.4094f, 42.41f)
                curveTo(41.3913f, 43.4279f, 40.0106f, 44.0f, 38.5709f, 44.0f)
                horizontalLineTo(9.4285f)
                curveTo(7.9888f, 44.0f, 6.6081f, 43.4279f, 5.5899f, 42.41f)
                curveTo(4.5721f, 41.3919f, 4.0f, 40.0111f, 4.0f, 38.5713f)
                verticalLineTo(15.4284f)
                close()
            }
        }
        .build()
        return _moviesFilled!!
    }

private var _moviesFilled: ImageVector? = null
