package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.SettingsOutline: ImageVector
    get() {
        if (_settingsOutline != null) {
            return _settingsOutline!!
        }
        _settingsOutline = Builder(name = "SettingsOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(4.0563f, 28.9298f)
                curveTo(4.1928f, 28.279f, 4.5702f, 27.7013f, 5.1154f, 27.3085f)
                lineTo(5.1871f, 27.2604f)
                lineTo(8.4571f, 25.0768f)
                curveTo(8.4034f, 24.7421f, 8.4034f, 24.3944f, 8.4034f, 24.0069f)
                curveTo(8.4034f, 23.6193f, 8.4256f, 23.2538f, 8.4615f, 22.9104f)
                lineTo(5.3262f, 20.709f)
                lineTo(5.2903f, 20.6826f)
                curveTo(4.7488f, 20.2867f, 4.3752f, 19.7081f, 4.2411f, 19.0579f)
                curveTo(4.1074f, 18.4077f, 4.2224f, 17.732f, 4.5645f, 17.1602f)
                lineTo(8.3537f, 10.7582f)
                curveTo(8.6871f, 10.2023f, 9.212f, 9.7825f, 9.8334f, 9.5749f)
                curveTo(10.4549f, 9.3672f, 11.1316f, 9.3857f, 11.7402f, 9.6268f)
                lineTo(11.8252f, 9.6621f)
                lineTo(15.3054f, 11.2339f)
                curveTo(15.9088f, 10.8383f, 16.5386f, 10.4839f, 17.191f, 10.1728f)
                lineTo(17.5359f, 6.4392f)
                curveTo(17.5881f, 5.7742f, 17.894f, 5.1533f, 18.3923f, 4.7006f)
                curveTo(18.891f, 4.2482f, 19.5449f, 3.9979f, 20.2235f, 4.0f)
                horizontalLineTo(27.8603f)
                curveTo(28.492f, 4.0108f, 29.1004f, 4.2359f, 29.5828f, 4.637f)
                curveTo(30.0652f, 5.038f, 30.3917f, 5.5906f, 30.5073f, 6.2014f)
                curveTo(30.5198f, 6.2638f, 30.5289f, 6.327f, 30.5342f, 6.3906f)
                lineTo(30.8835f, 10.1682f)
                curveTo(31.5312f, 10.4846f, 32.1579f, 10.8405f, 32.7603f, 11.234f)
                lineTo(36.1958f, 9.6839f)
                curveTo(36.7976f, 9.3624f, 37.5049f, 9.288f, 38.1623f, 9.4781f)
                curveTo(38.8197f, 9.6679f, 39.3736f, 10.1059f, 39.703f, 10.6968f)
                lineTo(43.6221f, 17.1558f)
                curveTo(43.9649f, 17.7277f, 44.0799f, 18.4043f, 43.9449f, 19.0544f)
                curveTo(43.8102f, 19.7049f, 43.4353f, 20.2832f, 42.8923f, 20.6779f)
                lineTo(42.8564f, 20.7043f)
                lineTo(39.7211f, 22.9057f)
                curveTo(39.7567f, 23.2494f, 39.7792f, 23.6145f, 39.7792f, 24.0021f)
                curveTo(39.7792f, 24.3814f, 39.7598f, 24.7607f, 39.7208f, 25.1381f)
                lineTo(42.6367f, 27.3217f)
                curveTo(43.1785f, 27.7176f, 43.5516f, 28.2972f, 43.6837f, 28.9477f)
                curveTo(43.8162f, 29.5981f, 43.6984f, 30.2738f, 43.3535f, 30.8441f)
                lineTo(39.5643f, 37.2547f)
                curveTo(39.2309f, 37.8106f, 38.706f, 38.2304f, 38.0846f, 38.4384f)
                curveTo(37.4631f, 38.646f, 36.7864f, 38.6275f, 36.1778f, 38.3865f)
                lineTo(36.0928f, 38.3511f)
                lineTo(32.6035f, 36.7704f)
                curveTo(32.0005f, 37.1687f, 31.3707f, 37.5262f, 30.718f, 37.8404f)
                lineTo(30.373f, 41.5609f)
                curveTo(30.3212f, 42.2258f, 30.015f, 42.8467f, 29.5167f, 43.2991f)
                curveTo(29.0183f, 43.7515f, 28.3641f, 44.0021f, 27.6858f, 44.0f)
                horizontalLineTo(20.0442f)
                curveTo(19.4134f, 43.9883f, 18.8057f, 43.7626f, 18.3242f, 43.3615f)
                curveTo(17.8431f, 42.9607f, 17.5169f, 42.4085f, 17.4016f, 41.7985f)
                curveTo(17.3891f, 41.7374f, 17.3804f, 41.6757f, 17.3748f, 41.6136f)
                lineTo(17.0211f, 37.836f)
                curveTo(16.3747f, 37.5197f, 15.7495f, 37.1637f, 15.1487f, 36.7703f)
                lineTo(11.6953f, 38.3204f)
                curveTo(11.0761f, 38.6613f, 10.3397f, 38.73f, 9.6664f, 38.5095f)
                curveTo(9.0527f, 38.2912f, 8.5372f, 37.8671f, 8.2107f, 37.3121f)
                lineTo(4.3765f, 30.8309f)
                curveTo(4.0338f, 30.2581f, 3.9197f, 29.5806f, 4.0563f, 28.9298f)
                close()
                moveTo(12.0196f, 26.631f)
                curveTo(11.8843f, 26.9338f, 11.6643f, 27.1927f, 11.3859f, 27.3788f)
                lineTo(7.8743f, 29.7258f)
                lineTo(10.8843f, 34.82f)
                lineTo(14.5929f, 33.1425f)
                curveTo(14.8857f, 33.0105f, 15.2087f, 32.9586f, 15.5287f, 32.9917f)
                curveTo(15.8486f, 33.0252f, 16.1535f, 33.1425f, 16.4116f, 33.3317f)
                curveTo(17.3383f, 34.0212f, 18.3478f, 34.5964f, 19.4169f, 35.0445f)
                curveTo(19.7137f, 35.1692f, 19.9708f, 35.3704f, 20.1608f, 35.6271f)
                curveTo(20.3508f, 35.8836f, 20.4667f, 36.1858f, 20.4964f, 36.5018f)
                lineTo(20.8638f, 40.4865f)
                horizontalLineTo(26.8657f)
                lineTo(27.2331f, 36.5018f)
                curveTo(27.2628f, 36.1858f, 27.3787f, 35.8836f, 27.5687f, 35.6271f)
                curveTo(27.7586f, 35.3704f, 28.0158f, 35.1692f, 28.3126f, 35.0445f)
                curveTo(29.3689f, 34.6271f, 30.3609f, 34.067f, 31.2598f, 33.3802f)
                curveTo(31.5198f, 33.1705f, 31.8344f, 33.0372f, 32.1678f, 32.9951f)
                curveTo(32.5015f, 32.953f, 32.8402f, 33.004f, 33.1454f, 33.1425f)
                lineTo(36.854f, 34.82f)
                lineTo(39.8775f, 29.6726f)
                lineTo(36.7197f, 27.3084f)
                curveTo(36.4657f, 27.1187f, 36.2676f, 26.8656f, 36.1464f, 26.5753f)
                curveTo(36.0252f, 26.2851f, 35.9849f, 25.9682f, 36.0299f, 25.6573f)
                curveTo(36.1248f, 25.1042f, 36.1789f, 24.5453f, 36.1914f, 23.9845f)
                curveTo(36.1789f, 23.4237f, 36.1248f, 22.8644f, 36.0299f, 22.3113f)
                curveTo(35.983f, 21.9909f, 36.0267f, 21.6642f, 36.1564f, 21.3666f)
                curveTo(36.2857f, 21.0693f, 36.4963f, 20.8125f, 36.7647f, 20.6249f)
                lineTo(40.124f, 18.2781f)
                lineTo(37.0243f, 13.1796f)
                lineTo(33.3067f, 14.8614f)
                curveTo(33.0136f, 14.9944f, 32.6896f, 15.0466f, 32.3687f, 15.0134f)
                curveTo(32.0479f, 14.9799f, 31.742f, 14.8623f, 31.4836f, 14.6722f)
                curveTo(30.5569f, 13.9824f, 29.5478f, 13.4072f, 28.4783f, 12.9594f)
                curveTo(28.1812f, 12.8322f, 27.925f, 12.6286f, 27.7363f, 12.3697f)
                curveTo(27.5479f, 12.1105f, 27.4345f, 11.8062f, 27.4079f, 11.4889f)
                lineTo(27.0405f, 7.5043f)
                horizontalLineTo(21.0433f)
                lineTo(20.6759f, 11.4889f)
                curveTo(20.6462f, 11.8049f, 20.5303f, 12.1071f, 20.34f, 12.3636f)
                curveTo(20.1501f, 12.6203f, 19.8933f, 12.8215f, 19.5964f, 12.9462f)
                curveTo(18.5398f, 13.3633f, 17.5477f, 13.9235f, 16.6492f, 14.6105f)
                curveTo(16.3893f, 14.8203f, 16.0744f, 14.9536f, 15.741f, 14.9956f)
                curveTo(15.4076f, 15.0377f, 15.0683f, 14.9867f, 14.763f, 14.8482f)
                lineTo(11.0544f, 13.1707f)
                lineTo(8.0357f, 18.2781f)
                lineTo(11.4041f, 20.6427f)
                curveTo(11.6724f, 20.8303f, 11.8827f, 21.0868f, 12.0124f, 21.3844f)
                curveTo(12.142f, 21.6817f, 12.1858f, 22.0085f, 12.1386f, 22.3288f)
                curveTo(12.0436f, 22.8819f, 11.9899f, 23.4412f, 11.9774f, 24.002f)
                curveTo(11.9946f, 24.56f, 12.053f, 25.1165f, 12.152f, 25.6663f)
                curveTo(12.2011f, 25.9937f, 12.1552f, 26.3278f, 12.0196f, 26.631f)
                close()
                moveTo(28.783f, 28.7829f)
                curveTo(27.4702f, 30.0954f, 25.6897f, 30.8329f, 23.8332f, 30.8329f)
                curveTo(21.9708f, 30.8528f, 20.1789f, 30.1218f, 18.8618f, 28.8045f)
                curveTo(17.5445f, 27.4875f, 16.8135f, 25.6956f, 16.8334f, 23.8331f)
                curveTo(16.8334f, 21.9767f, 17.5709f, 20.1961f, 18.8834f, 18.8834f)
                curveTo(20.1962f, 17.5709f, 21.9768f, 16.8333f, 23.8332f, 16.8333f)
                curveTo(25.6896f, 16.8333f, 27.4702f, 17.5709f, 28.783f, 18.8834f)
                curveTo(30.0955f, 20.1961f, 30.833f, 21.9767f, 30.833f, 23.8331f)
                curveTo(30.833f, 25.6895f, 30.0955f, 27.4701f, 28.783f, 28.7829f)
                close()
                moveTo(26.4781f, 21.1881f)
                curveTo(25.7766f, 20.4866f, 24.8251f, 20.0927f, 23.8332f, 20.0927f)
                curveTo(22.8379f, 20.0817f, 21.8801f, 20.4722f, 21.1761f, 21.1759f)
                curveTo(20.4724f, 21.8799f, 20.0819f, 22.8377f, 20.0929f, 23.833f)
                curveTo(20.0929f, 24.8249f, 20.4869f, 25.7764f, 21.1883f, 26.4779f)
                curveTo(21.8898f, 27.1793f, 22.8413f, 27.5732f, 23.8332f, 27.5732f)
                curveTo(24.8251f, 27.5732f, 25.7767f, 27.1793f, 26.4781f, 26.4779f)
                curveTo(27.1795f, 25.7764f, 27.5735f, 24.8249f, 27.5735f, 23.833f)
                curveTo(27.5735f, 22.8411f, 27.1795f, 21.8895f, 26.4781f, 21.1881f)
                close()
            }
        }
        .build()
        return _settingsOutline!!
    }

private var _settingsOutline: ImageVector? = null
