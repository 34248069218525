package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Hide: ImageVector
    get() {
        if (_hide != null) {
            return _hide!!
        }
        _hide = Builder(name = "Hide", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(8.2421f, 9.9901f)
                lineTo(39.3532f, 41.0f)
                lineTo(42.3531f, 38.0099f)
                lineTo(36.874f, 32.5487f)
                curveTo(39.7227f, 30.4464f, 42.1497f, 27.6129f, 44.0f, 24.1701f)
                curveTo(39.4583f, 16.0089f, 32.4583f, 11.3997f, 24.0f, 11.3997f)
                curveTo(21.548f, 11.3997f, 19.1536f, 11.816f, 16.8829f, 12.6226f)
                lineTo(11.242f, 7.0f)
                lineTo(8.2421f, 9.9901f)
                close()
                moveTo(19.8002f, 15.5304f)
                curveTo(21.1719f, 15.1938f, 22.5788f, 15.0242f, 24.0f, 15.0242f)
                curveTo(30.3341f, 15.0242f, 35.8092f, 18.1181f, 39.7718f, 24.1889f)
                curveTo(38.233f, 26.5608f, 36.3704f, 28.4956f, 34.269f, 29.9521f)
                lineTo(30.8453f, 26.5395f)
                curveTo(31.1219f, 25.7731f, 31.2726f, 24.9468f, 31.2726f, 24.0854f)
                curveTo(31.2726f, 20.0819f, 28.0165f, 16.8364f, 23.9999f, 16.8364f)
                curveTo(23.1357f, 16.8364f, 22.3067f, 16.9866f, 21.5378f, 17.2623f)
                lineTo(19.8002f, 15.5304f)
                close()
                moveTo(26.8421f, 32.9292f)
                lineTo(29.8946f, 35.9718f)
                curveTo(28.0153f, 36.4979f, 26.0416f, 36.7714f, 24.0f, 36.7714f)
                curveTo(16.25f, 36.7714f, 8.7917f, 32.3736f, 4.0f, 24.0432f)
                curveTo(5.6945f, 20.9574f, 7.8225f, 18.3907f, 10.2533f, 16.3944f)
                lineTo(12.8381f, 18.9708f)
                curveTo(11.1395f, 20.3147f, 9.5928f, 21.9967f, 8.2607f, 24.0088f)
                curveTo(12.4553f, 30.1796f, 18.2614f, 33.1469f, 24.0f, 33.1469f)
                curveTo(24.9648f, 33.1469f, 25.9135f, 33.0736f, 26.8421f, 32.9292f)
                close()
                moveTo(16.7271f, 24.0854f)
                curveTo(16.7271f, 23.6948f, 16.7581f, 23.3114f, 16.8178f, 22.9376f)
                lineTo(25.1515f, 31.2441f)
                curveTo(24.7764f, 31.3036f, 24.3918f, 31.3345f, 23.9999f, 31.3345f)
                curveTo(19.9833f, 31.3345f, 16.7271f, 28.089f, 16.7271f, 24.0854f)
                close()
            }
        }
        .build()
        return _hide!!
    }

private var _hide: ImageVector? = null
