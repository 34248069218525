package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ThumbsUpOutline: ImageVector
    get() {
        if (_thumbsUpOutline != null) {
            return _thumbsUpOutline!!
        }
        _thumbsUpOutline = Builder(name = "ThumbsUpOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(27.4451f, 9.0955f)
                curveTo(25.4412f, 6.7954f, 23.0366f, 6.8953f, 22.1349f, 7.0954f)
                curveTo(19.2293f, 7.5954f, 18.6281f, 10.7956f, 19.2293f, 12.4956f)
                curveTo(19.3295f, 12.6956f, 19.5f, 13.0f, 19.5299f, 13.6957f)
                curveTo(19.4297f, 17.3959f, 18.2274f, 19.096f, 15.9229f, 20.496f)
                curveTo(15.3218f, 19.996f, 14.5202f, 19.696f, 13.6185f, 19.696f)
                horizontalLineTo(7.8073f)
                curveTo(5.7033f, 19.696f, 4.0f, 21.4961f, 4.0f, 23.5962f)
                verticalLineTo(37.2969f)
                curveTo(4.0f, 39.197f, 5.7033f, 40.9971f, 7.8073f, 40.9971f)
                horizontalLineTo(13.6185f)
                curveTo(14.921f, 40.9971f, 15.9229f, 40.3971f, 16.6243f, 39.397f)
                curveTo(18.027f, 40.2971f, 19.2293f, 40.7971f, 20.9326f, 40.9971f)
                curveTo(21.133f, 40.9971f, 35.8613f, 40.9971f, 35.8613f, 40.9971f)
                curveTo(38.2659f, 41.0971f, 40.7707f, 38.597f, 39.869f, 35.7968f)
                curveTo(41.4721f, 34.8968f, 42.2736f, 32.6967f, 41.5723f, 30.7966f)
                curveTo(43.4759f, 29.7965f, 44.2775f, 26.7964f, 42.8748f, 24.7963f)
                curveTo(45.3796f, 21.9961f, 43.3757f, 17.3959f, 40.0694f, 17.1959f)
                curveTo(39.9692f, 17.1959f, 29.6493f, 17.1959f, 29.1484f, 17.0959f)
                curveTo(29.5492f, 13.5957f, 29.0482f, 10.8956f, 27.4451f, 9.0955f)
                close()
                moveTo(17.4258f, 33.6967f)
                verticalLineTo(23.3962f)
                curveTo(20.5318f, 21.6961f, 22.9364f, 18.3959f, 22.8362f, 13.4957f)
                curveTo(22.8362f, 12.9957f, 22.736f, 12.7957f, 22.6359f, 12.3956f)
                curveTo(22.5357f, 11.6956f, 22.1349f, 11.0956f, 22.5357f, 10.4955f)
                curveTo(22.5357f, 10.4955f, 22.6359f, 10.4955f, 22.6359f, 10.3955f)
                horizontalLineTo(22.736f)
                curveTo(22.9364f, 10.3955f, 23.9384f, 10.1955f, 24.9403f, 11.2956f)
                curveTo(25.7418f, 12.2956f, 26.343f, 14.1957f, 25.6416f, 18.4959f)
                curveTo(25.4412f, 19.496f, 26.2428f, 20.496f, 27.2447f, 20.496f)
                horizontalLineTo(38.8671f)
                curveTo(38.9673f, 20.496f, 39.5684f, 20.496f, 39.6686f, 20.496f)
                curveTo(40.9711f, 20.5961f, 40.9711f, 22.7962f, 39.6686f, 22.8962f)
                curveTo(39.5684f, 22.8962f, 38.6667f, 22.8962f, 38.5665f, 22.8962f)
                curveTo(36.3623f, 22.8962f, 36.3623f, 26.1963f, 38.5665f, 26.2963f)
                horizontalLineTo(39.4682f)
                curveTo(39.5684f, 26.2963f, 39.5684f, 26.2963f, 39.6686f, 26.2963f)
                curveTo(40.0694f, 26.2963f, 40.2698f, 26.6964f, 40.37f, 27.0964f)
                curveTo(40.37f, 27.6964f, 40.0694f, 27.8964f, 39.5684f, 27.9964f)
                horizontalLineTo(37.765f)
                curveTo(35.4605f, 28.0964f, 35.6609f, 31.4966f, 37.9653f, 31.3966f)
                curveTo(38.7669f, 31.5966f, 38.8671f, 32.7967f, 38.0655f, 32.9967f)
                curveTo(37.9653f, 33.0967f, 36.8632f, 33.0967f, 36.5626f, 33.0967f)
                curveTo(34.3584f, 33.0967f, 34.3584f, 36.3969f, 36.5626f, 36.4969f)
                curveTo(36.8632f, 36.4969f, 36.9634f, 37.0969f, 36.8632f, 37.2969f)
                curveTo(36.763f, 37.4969f, 36.3623f, 37.5969f, 36.0617f, 37.6969f)
                curveTo(36.0617f, 37.6969f, 20.5318f, 37.5969f, 20.4316f, 37.4969f)
                curveTo(18.4278f, 36.8969f, 17.3256f, 36.0968f, 17.4258f, 33.6967f)
                close()
                moveTo(7.3064f, 23.4962f)
                curveTo(7.3064f, 23.1962f, 7.5068f, 22.9962f, 7.8073f, 22.9962f)
                horizontalLineTo(13.6185f)
                curveTo(13.8189f, 22.9962f, 14.1195f, 23.1962f, 14.1195f, 23.4962f)
                verticalLineTo(37.1969f)
                curveTo(14.1195f, 37.4969f, 13.9191f, 37.6969f, 13.6185f, 37.6969f)
                horizontalLineTo(7.8073f)
                curveTo(7.6069f, 37.6969f, 7.3064f, 37.4969f, 7.3064f, 37.1969f)
                verticalLineTo(23.4962f)
                close()
            }
        }
        .build()
        return _thumbsUpOutline!!
    }

private var _thumbsUpOutline: ImageVector? = null
