package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.KeyboardLanguage: ImageVector
    get() {
        if (_keyboardLanguage != null) {
            return _keyboardLanguage!!
        }
        _keyboardLanguage = Builder(name = "KeyboardLanguage", defaultWidth = 48.0.dp, defaultHeight
                = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(24.0f, 4.0f)
                curveTo(12.966f, 4.0f, 4.0f, 12.966f, 4.0f, 24.0f)
                reflectiveCurveToRelative(8.966f, 20.0f, 20.0f, 20.0f)
                reflectiveCurveToRelative(20.0f, -8.966f, 20.0f, -20.0f)
                reflectiveCurveTo(35.034f, 4.0f, 24.0f, 4.0f)
                close()
                moveTo(25.199f, 12.37f)
                lineTo(25.199f, 6.549f)
                curveToRelative(2.207f, 0.558f, 4.241f, 2.65f, 5.724f, 5.823f)
                lineTo(25.2f, 12.372f)
                close()
                moveTo(22.801f, 12.37f)
                horizontalLineToRelative(-5.724f)
                curveTo(18.56f, 9.2f, 20.594f, 7.107f, 22.8f, 6.549f)
                verticalLineToRelative(5.823f)
                close()
                moveTo(33.528f, 12.37f)
                curveToRelative(-0.712f, -1.743f, -1.586f, -3.28f, -2.593f, -4.546f)
                arcToRelative(17.713f, 17.713f, 0.0f, false, true, 6.262f, 4.547f)
                horizontalLineToRelative(-3.669f)
                close()
                moveTo(14.472f, 12.37f)
                horizontalLineToRelative(-3.67f)
                arcToRelative(17.713f, 17.713f, 0.0f, false, true, 6.263f, -4.546f)
                curveToRelative(-1.007f, 1.266f, -1.881f, 2.803f, -2.593f, 4.547f)
                close()
                moveTo(6.438f, 22.802f)
                arcToRelative(17.473f, 17.473f, 0.0f, false, true, 2.584f, -8.033f)
                horizontalLineToRelative(4.613f)
                curveToRelative(-0.713f, 2.42f, -1.15f, 5.137f, -1.245f, 8.033f)
                lineTo(6.438f, 22.802f)
                close()
                moveTo(14.757f, 22.802f)
                curveToRelative(0.106f, -2.931f, 0.597f, -5.667f, 1.375f, -8.033f)
                horizontalLineToRelative(6.67f)
                verticalLineToRelative(8.033f)
                horizontalLineToRelative(-8.045f)
                close()
                moveTo(25.199f, 22.802f)
                verticalLineToRelative(-8.033f)
                horizontalLineToRelative(6.67f)
                curveToRelative(0.777f, 2.366f, 1.268f, 5.102f, 1.374f, 8.033f)
                lineTo(25.2f, 22.802f)
                close()
                moveTo(35.609f, 22.802f)
                curveToRelative(-0.094f, -2.896f, -0.53f, -5.613f, -1.244f, -8.033f)
                horizontalLineToRelative(4.613f)
                arcToRelative(17.474f, 17.474f, 0.0f, false, true, 2.584f, 8.033f)
                lineTo(35.61f, 22.802f)
                close()
                moveTo(9.044f, 33.265f)
                arcToRelative(17.474f, 17.474f, 0.0f, false, true, -2.605f, -8.066f)
                horizontalLineToRelative(5.952f)
                curveToRelative(0.095f, 2.91f, 0.536f, 5.638f, 1.255f, 8.066f)
                lineTo(9.043f, 33.265f)
                close()
                moveTo(16.136f, 33.265f)
                curveToRelative(-0.781f, -2.374f, -1.273f, -5.121f, -1.378f, -8.066f)
                lineTo(22.8f, 25.199f)
                verticalLineToRelative(8.066f)
                horizontalLineToRelative(-6.666f)
                close()
                moveTo(25.2f, 33.265f)
                verticalLineToRelative(-8.066f)
                horizontalLineToRelative(8.044f)
                curveToRelative(-0.105f, 2.945f, -0.597f, 5.692f, -1.378f, 8.066f)
                lineTo(25.2f, 33.265f)
                close()
                moveTo(34.356f, 33.265f)
                curveToRelative(0.72f, -2.428f, 1.16f, -5.157f, 1.255f, -8.066f)
                horizontalLineToRelative(5.952f)
                arcToRelative(17.475f, 17.475f, 0.0f, false, true, -2.604f, 8.066f)
                horizontalLineToRelative(-4.603f)
                close()
                moveTo(17.066f, 40.175f)
                arcToRelative(17.71f, 17.71f, 0.0f, false, true, -6.233f, -4.513f)
                horizontalLineToRelative(3.653f)
                curveToRelative(0.71f, 1.73f, 1.579f, 3.256f, 2.58f, 4.514f)
                close()
                moveTo(22.802f, 41.454f)
                curveToRelative(-2.212f, -0.554f, -4.241f, -2.635f, -5.72f, -5.792f)
                horizontalLineToRelative(5.72f)
                verticalLineToRelative(5.792f)
                close()
                moveTo(25.2f, 41.454f)
                verticalLineToRelative(-5.792f)
                horizontalLineToRelative(5.72f)
                curveToRelative(-1.479f, 3.157f, -3.508f, 5.238f, -5.72f, 5.792f)
                close()
                moveTo(30.936f, 40.176f)
                curveToRelative(1.001f, -1.258f, 1.87f, -2.784f, 2.58f, -4.514f)
                horizontalLineToRelative(3.653f)
                arcToRelative(17.713f, 17.713f, 0.0f, false, true, -6.233f, 4.514f)
                close()
            }
        }
        .build()
        return _keyboardLanguage!!
    }

private var _keyboardLanguage: ImageVector? = null
