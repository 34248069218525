package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ThumbsUpFilled: ImageVector
    get() {
        if (_thumbsUpFilled != null) {
            return _thumbsUpFilled!!
        }
        _thumbsUpFilled = Builder(name = "ThumbsUpFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(27.4451f, 9.0955f)
                curveTo(25.4412f, 6.7954f, 23.0366f, 6.8953f, 22.1349f, 7.0954f)
                curveTo(19.2293f, 7.5954f, 18.6281f, 10.7956f, 19.2293f, 12.4956f)
                curveTo(19.3295f, 12.6956f, 19.5f, 13.0f, 19.5299f, 13.6957f)
                curveTo(19.4297f, 17.3959f, 18.2274f, 19.096f, 15.9229f, 20.496f)
                curveTo(15.3218f, 19.996f, 14.5202f, 19.696f, 13.6185f, 19.696f)
                horizontalLineTo(7.8073f)
                curveTo(5.7033f, 19.696f, 4.0f, 21.4961f, 4.0f, 23.5962f)
                verticalLineTo(37.2969f)
                curveTo(4.0f, 39.197f, 5.7033f, 40.9971f, 7.8073f, 40.9971f)
                horizontalLineTo(13.6185f)
                curveTo(14.921f, 40.9971f, 15.9229f, 40.3971f, 16.6243f, 39.397f)
                curveTo(18.027f, 40.2971f, 19.2293f, 40.7971f, 20.9326f, 40.9971f)
                curveTo(21.133f, 40.9971f, 35.8613f, 40.9971f, 35.8613f, 40.9971f)
                curveTo(38.2659f, 41.0971f, 40.7707f, 38.597f, 39.869f, 35.7968f)
                curveTo(41.4721f, 34.8968f, 42.2736f, 32.6967f, 41.5723f, 30.7966f)
                curveTo(43.4759f, 29.7965f, 44.2775f, 26.7964f, 42.8748f, 24.7963f)
                curveTo(45.3796f, 21.9961f, 43.3757f, 17.3959f, 40.0694f, 17.1959f)
                curveTo(39.9692f, 17.1959f, 29.6493f, 17.1959f, 29.1484f, 17.0959f)
                curveTo(29.5492f, 13.5957f, 29.0482f, 10.8956f, 27.4451f, 9.0955f)
                close()
                moveTo(7.3064f, 23.4962f)
                curveTo(7.3064f, 23.1962f, 7.5068f, 22.9962f, 7.8073f, 22.9962f)
                horizontalLineTo(13.6185f)
                curveTo(13.8189f, 22.9962f, 14.1195f, 23.1962f, 14.1195f, 23.4962f)
                verticalLineTo(37.1969f)
                curveTo(14.1195f, 37.4969f, 13.9191f, 37.6969f, 13.6185f, 37.6969f)
                horizontalLineTo(7.8073f)
                curveTo(7.6069f, 37.6969f, 7.3064f, 37.4969f, 7.3064f, 37.1969f)
                verticalLineTo(23.4962f)
                close()
            }
        }
        .build()
        return _thumbsUpFilled!!
    }

private var _thumbsUpFilled: ImageVector? = null
