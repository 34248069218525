package com.brdgwtr.designsystem.components

import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.Stable
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.focusProperties
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.foundation.BwTheme
import com.brdgwtr.designsystem.foundation.contentColorFor

/**
 * Selector building block component for any selectable, checkable and radio item.
 *
 * @param selected Whether the selectable is selected or not.
 * @param selectedIcon Optional icon to display as the selected symbol.
 * @param modifier Modifier for the selector.
 * @param colors Colors to use for the selector.
 * @param shape [RoundedCornerShape] to use for the selector.
 * @param enabled Whether the selector can be interacted with.
 * @param borderInset The inset of the selector border.
 * @param onSelectedChange Optional callback for when the toggle is selected. Setting to null will make the checkmark
 * un-focusable.
 * @param interactionSource Interaction source for the state of the selector.
 */
@Composable
fun Selector(
    selected: Boolean,
    selectedIcon: ImageVector?,
    modifier: Modifier = Modifier,
    colors: SelectorColors = SelectorDefaults.colors(),
    shape: RoundedCornerShape = BwTheme.shapes.circle,
    enabled: Boolean = true,
    borderInset: Dp = 0.dp,
    onSelectedChange: ((Boolean) -> Unit)? = null,
    interactionSource: MutableInteractionSource? = null,
) {
    Surface(
        selected = selected,
        enabled = enabled,
        shape = InteractiveSurfaceDefaults.shape(shape),
        modifier = modifier
            .focusProperties {
                canFocus = onSelectedChange != null
            },
        colors = InteractiveSurfaceDefaults.colors(
            contentColor = colors.checkColor,
            backgroundColor = colors.backgroundColor,
            selectedContentColor = colors.checkColor,
            selectedBackgroundColor = colors.selectedBackgroundColor,
            focusedBackgroundColor = colors.focusBackgroundColor,
            focusedSelectedContentColor = colors.focusSelectedColor,
            focusedSelectedBackgroundColor = colors.focusSelectedBackgroundColor,
        ),
        border = InteractiveSurfaceDefaults.border(
            color = if (selected) colors.selectedBorderColor else colors.borderColor,
            focusedColor = colors.focusBorderColor,
            shape = shape,
            inset = borderInset,
        ),
        interactionSource = interactionSource,
        onClick = {
            onSelectedChange?.invoke(!selected)
        },
    ) {
        if (selected && selectedIcon != null) {
            Icon(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(4.dp),
                imageVector = selectedIcon,
                contentDescription = "",
            )
        }
    }
}

@Immutable
data class SelectorColors(
    val checkColor: Color,
    val focusBorderColor: Color,
    val selectedBorderColor: Color,
    val borderColor: Color,
    val backgroundColor: Color,
    val selectedBackgroundColor: Color,
    val focusSelectedColor: Color,
    val focusBackgroundColor: Color,
    val focusSelectedBackgroundColor: Color,
)

object SelectorDefaults {
    @Stable
    @Composable
    @ReadOnlyComposable
    fun colors(
        selectedBackgroundColor: Color = BwTheme.colors.secondary,
        checkColor: Color = contentColorFor(selectedBackgroundColor),
        backgroundColor: Color = Color.Transparent,
        borderColor: Color = selectedBackgroundColor,
        selectedBorderColor: Color = selectedBackgroundColor,
        focusBackgroundColor: Color = BwTheme.colors.onSurfaceInverse,
        focusBorderColor: Color = BwTheme.colors.surfaceInverse,
        focusSelectedColor: Color = BwTheme.colors.surfaceInverse,
        focusSelectedBackgroundColor: Color = BwTheme.colors.onSurfaceInverse,
    ): SelectorColors = SelectorColors(
        checkColor = checkColor,
        backgroundColor = backgroundColor,
        borderColor = borderColor,
        selectedBackgroundColor = selectedBackgroundColor,
        selectedBorderColor = selectedBorderColor,
        focusBackgroundColor = focusBackgroundColor,
        focusBorderColor = focusBorderColor,
        focusSelectedColor = focusSelectedColor,
        focusSelectedBackgroundColor = focusSelectedBackgroundColor,
    )
}
