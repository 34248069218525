package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoBackOutline: ImageVector
    get() {
        if (_ruwidoBackOutline != null) {
            return _ruwidoBackOutline!!
        }
        _ruwidoBackOutline = Builder(name = "RuwidoBackOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(2.0f, 24.0f)
                curveTo(2.0f, 11.848f, 11.848f, 2.0f, 24.0f, 2.0f)
                reflectiveCurveToRelative(22.0f, 9.848f, 22.0f, 22.0f)
                reflectiveCurveToRelative(-9.848f, 22.0f, -22.0f, 22.0f)
                reflectiveCurveTo(2.0f, 36.152f, 2.0f, 24.0f)
                close()
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(25.048f, 15.595f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, -0.327f, 1.376f)
                lineToRelative(-10.47f, 6.452f)
                horizontalLineToRelative(23.093f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.0f, 2.0f)
                lineTo(14.526f, 25.423f)
                lineToRelative(10.932f, 6.175f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, true, -0.984f, 1.742f)
                lineToRelative(-14.219f, -8.033f)
                arcToRelative(1.165f, 1.165f, 0.0f, false, true, -0.047f, -0.027f)
                lineToRelative(-1.452f, -0.82f)
                lineToRelative(1.435f, -0.884f)
                lineToRelative(0.016f, -0.01f)
                lineToRelative(13.465f, -8.297f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.376f, 0.326f)
                close()
            }
        }
        .build()
        return _ruwidoBackOutline!!
    }

private var _ruwidoBackOutline: ImageVector? = null
