package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ArrowRight: ImageVector
    get() {
        if (_arrowRight != null) {
            return _arrowRight!!
        }
        _arrowRight = Builder(name = "ArrowRight", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(39.9f, 22.258f)
                arcToRelative(1.95f, 1.95f, 0.0f, false, true, 0.0f, 2.817f)
                lineTo(26.844f, 37.75f)
                curveToRelative(-0.801f, 0.778f, -2.1f, 0.778f, -2.901f, 0.0f)
                arcToRelative(1.95f, 1.95f, 0.0f, false, true, 0.0f, -2.817f)
                lineToRelative(9.552f, -9.275f)
                reflectiveCurveTo(12.0f, 25.833f, 9.5f, 25.833f)
                reflectiveCurveToRelative(-2.5f, -2.0f, -2.5f, -2.0f)
                reflectiveCurveToRelative(0.0f, -2.0f, 2.5f, -2.0f)
                reflectiveCurveToRelative(23.996f, -0.158f, 23.996f, -0.158f)
                lineTo(23.944f, 12.4f)
                arcToRelative(1.951f, 1.951f, 0.0f, false, true, 0.0f, -2.817f)
                arcToRelative(2.095f, 2.095f, 0.0f, false, true, 2.9f, 0.0f)
                lineTo(39.9f, 22.258f)
                close()
            }
        }
        .build()
        return _arrowRight!!
    }

private var _arrowRight: ImageVector? = null
