package com.brdgwtr.designsystem.foundation

import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.staticCompositionLocalOf
import com.brdgwtr.designsystem.foundation.mobile.BwMobileSpacing
import com.brdgwtr.designsystem.foundation.mobile.BwMobileTypography
import com.brdgwtr.designsystem.foundation.tv.BwTvSpacing
import com.brdgwtr.designsystem.foundation.tv.BwTvTypography

/**
 * Design System Locals.
 */
internal val LocalBwShapes = staticCompositionLocalOf { BwShapes() }
internal val LocalBwTypography = staticCompositionLocalOf { BwTvTypography }
internal val LocalBwColors = staticCompositionLocalOf { BwDefaultTheme }
internal val LocalBwSpacing = staticCompositionLocalOf { BwTvSpacing }
internal val LocalBwContainerTemplates = staticCompositionLocalOf { BwDefaultContainerTemplates }
internal val LocalBwZoomScales = staticCompositionLocalOf { BwDefaultZoomScales }

/**
 * Contains all current BridgeWater theme values.
 */
object BwTheme {
    val colors: BwColors
        @Composable
        @ReadOnlyComposable
        get() = LocalBwColors.current

    val typography: BwTypography
        @Composable
        @ReadOnlyComposable
        get() = LocalBwTypography.current

    val spacing: BwSpacing
        @Composable
        @ReadOnlyComposable
        get() = LocalBwSpacing.current

    val shapes: BwShapes
        @Composable
        @ReadOnlyComposable
        get() = LocalBwShapes.current

    val containerTemplates: BwContainerTemplates
        @Composable
        @ReadOnlyComposable
        get() = LocalBwContainerTemplates.current

    val zoomScales: BwZoomScales
        @Composable
        @ReadOnlyComposable
        get() = LocalBwZoomScales.current
}

/**
 * BridgeWater design system theming.
 *
 * @param spacing - Semantic spacing Dp values. It is expected you use [BwTvSpacing]
 * or [BwMobileSpacing] rather than creating your own.
 * @param typography - Set of TextStyle's matching BridgeWater design system typography. It is expected you use
 * [BwTvTypography] or [BwMobileTypography] rather than creating your own.
 * @param colors - [BwColors] defined color theme to use matching BridgeWater design system colors.
 * @param shapes - Semantic set of [RoundedCornerShape] values.
 * @param containerTemplates - Semantic set of [BwContainerTemplate] presets used to set the size of specific content
 * like cards and artwork.
 * @param zoomScales - Semantic set of zoom values to support scaling components for states like focused and pressed.
 * @param content - Main Composable content.
 */
@Composable
fun BwTheme(
    spacing: BwSpacing,
    typography: BwTypography,
    colors: BwColors = BwTheme.colors,
    shapes: BwShapes = BwTheme.shapes,
    containerTemplates: BwContainerTemplates = BwTheme.containerTemplates,
    zoomScales: BwZoomScales = BwTheme.zoomScales,
    content: @Composable () -> Unit,
) {
    CompositionLocalProvider(
        LocalBwSpacing provides spacing,
        LocalBwColors provides colors,
        LocalBwShapes provides shapes,
        LocalBwTypography provides typography,
        LocalBwContainerTemplates provides containerTemplates,
        LocalBwZoomScales provides zoomScales,
        content = content,
    )
}

enum class BwPlatform {
    Tv,
    Mobile,
}

/**
 * BridgeWater design system theming with defaults set for the preferred platform.
 *
 * @param platform - [BwPlatform] to setup the design system token defaults for [BwPlatform.Tv] or [BwPlatform.Mobile].
 * @param colors - [BwColors] defined color theme to use matching BridgeWater design system colors.
 * @param content - Main Composable content.
 */
@Composable
fun BwTheme(platform: BwPlatform, colors: BwColors = BwTheme.colors, content: @Composable () -> Unit) {
    BwTheme(
        spacing = if (platform == BwPlatform.Tv) BwTvSpacing else BwMobileSpacing,
        typography = if (platform == BwPlatform.Tv) BwTvTypography else BwMobileTypography,
        // TODO mobile container sizes have not been defined in the DS yet so we are just using the same set.
        containerTemplates = BwDefaultContainerTemplates,
        colors = colors,
        content = content,
    )
}
