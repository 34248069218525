package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoHomeOutline: ImageVector
    get() {
        if (_ruwidoHomeOutline != null) {
            return _ruwidoHomeOutline!!
        }
        _ruwidoHomeOutline = Builder(name = "RuwidoHomeOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(2.0f, 24.0f)
                curveTo(2.0f, 11.848f, 11.848f, 2.0f, 24.0f, 2.0f)
                reflectiveCurveToRelative(22.0f, 9.848f, 22.0f, 22.0f)
                reflectiveCurveToRelative(-9.848f, 22.0f, -22.0f, 22.0f)
                reflectiveCurveTo(2.0f, 36.152f, 2.0f, 24.0f)
                close()
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(24.696f, 13.113f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -0.89f, -0.017f)
                lineTo(10.573f, 19.36f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -0.572f, 0.904f)
                lineTo(10.001f, 32.0f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 1.0f, 1.0f)
                horizontalLineToRelative(26.0f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 1.0f, -1.0f)
                lineTo(38.001f, 20.645f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -0.538f, -0.887f)
                lineToRelative(-12.766f, -6.645f)
                close()
                moveTo(12.0f, 31.0f)
                lineTo(12.0f, 20.896f)
                lineToRelative(12.213f, -5.78f)
                lineTo(36.0f, 21.252f)
                lineTo(36.0f, 31.0f)
                lineTo(12.0f, 31.0f)
                close()
            }
        }
        .build()
        return _ruwidoHomeOutline!!
    }

private var _ruwidoHomeOutline: ImageVector? = null
