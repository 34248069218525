package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Pin: ImageVector
    get() {
        if (_pin != null) {
            return _pin!!
        }
        _pin = Builder(name = "Pin", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp, viewportWidth
                = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(28.082f, 34.165f)
                arcToRelative(2.843f, 2.843f, 0.0f, false, true, -1.806f, -0.62f)
                lineToRelative(-3.399f, -2.779f)
                lineToRelative(-8.36f, 8.123f)
                arcToRelative(3.46f, 3.46f, 0.0f, false, true, -1.092f, 0.792f)
                curveToRelative(-0.414f, 0.19f, -0.863f, 0.299f, -1.32f, 0.319f)
                arcToRelative(2.11f, 2.11f, 0.0f, false, true, -1.377f, -0.478f)
                arcToRelative(2.095f, 2.095f, 0.0f, false, true, -0.553f, -0.743f)
                arcToRelative(2.044f, 2.044f, 0.0f, false, true, -0.174f, -0.903f)
                arcToRelative(3.504f, 3.504f, 0.0f, false, true, 0.782f, -2.097f)
                lineToRelative(6.038f, -9.964f)
                lineToRelative(-2.957f, -2.419f)
                arcToRelative(2.87f, 2.87f, 0.0f, false, true, -0.98f, -2.87f)
                arcToRelative(2.873f, 2.873f, 0.0f, false, true, 2.139f, -2.148f)
                lineToRelative(6.752f, -1.573f)
                curveToRelative(0.26f, -0.06f, 0.49f, -0.21f, 0.652f, -0.422f)
                lineToRelative(5.942f, -7.33f)
                arcToRelative(2.862f, 2.862f, 0.0f, false, true, 4.029f, -0.406f)
                lineToRelative(4.546f, 3.716f)
                arcToRelative(2.864f, 2.864f, 0.0f, false, true, 0.935f, 3.048f)
                arcToRelative(2.866f, 2.866f, 0.0f, false, true, -0.525f, 0.989f)
                lineToRelative(-5.964f, 7.324f)
                arcToRelative(1.19f, 1.19f, 0.0f, false, false, -0.264f, 0.715f)
                lineToRelative(-0.18f, 6.924f)
                arcToRelative(2.857f, 2.857f, 0.0f, false, true, -1.688f, 2.537f)
                arcToRelative(2.9f, 2.9f, 0.0f, false, true, -1.176f, 0.265f)
                close()
            }
        }
        .build()
        return _pin!!
    }

private var _pin: ImageVector? = null
