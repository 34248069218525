package com.brdgwtr.storybook

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowColumn
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.brdgwtr.designsystem.All
import com.brdgwtr.designsystem.Icons
import com.brdgwtr.designsystem.components.ArtworkTile
import com.brdgwtr.designsystem.components.Badge
import com.brdgwtr.designsystem.components.CheckBox
import com.brdgwtr.designsystem.components.CircularProgressIndicator
import com.brdgwtr.designsystem.components.Dialog
import com.brdgwtr.designsystem.components.IconButton
import com.brdgwtr.designsystem.components.InteractiveSurfaceDefaults
import com.brdgwtr.designsystem.components.LinearProgressIndicator
import com.brdgwtr.designsystem.components.PrimaryButton
import com.brdgwtr.designsystem.components.PrimaryButtonDefaults
import com.brdgwtr.designsystem.components.RadioButton
import com.brdgwtr.designsystem.components.Surface
import com.brdgwtr.designsystem.components.Toggle
import com.brdgwtr.designsystem.foundation.BwContainerTemplate
import com.brdgwtr.designsystem.foundation.BwPlatform
import com.brdgwtr.designsystem.foundation.BwTheme
import com.brdgwtr.designsystem.icons.Alert
import com.brdgwtr.designsystem.icons.AppsFilled
import com.brdgwtr.designsystem.icons.Close
import com.brdgwtr.designsystem.icons.PlayFilled
import com.brdgwtr.designsystem.icons.RemoteFilled
import com.brdgwtr.designsystem.icons.RtPopcornFresh
import com.brdgwtr.designsystem.icons.TrailerFilled
import io.daio.wild.foundation.ExperimentalWildApi
import io.daio.wild.foundation.LocalPlatformInteractions
import io.daio.wild.foundation.requestInitialFocus

enum class Screen {
    Home,
    Components,
    Foundations,
    Icons,
}

@OptIn(ExperimentalLayoutApi::class, ExperimentalWildApi::class)
@Composable
fun StorybookApp(modifier: Modifier = Modifier) {
    var currentScreen by remember { mutableStateOf(Screen.Home) }

    BwTheme(if (LocalPlatformInteractions.current.requiresHardwareInput) BwPlatform.Tv else BwPlatform.Mobile) {
        Surface(modifier = modifier.fillMaxSize()) {
            Box(modifier = Modifier.padding(BwTheme.spacing.m)) {
                when (currentScreen) {
                    Screen.Home -> HomeScreen(onScreenSelected = {
                        currentScreen = it
                    })

                    Screen.Components -> Components()
                    Screen.Foundations -> {
                        Column(verticalArrangement = Arrangement.spacedBy(BwTheme.spacing.l)) {
                            Text("Foundations", style = BwTheme.typography.display)
                            Shapes()
                            Colors()
                        }
                    }

                    Screen.Icons -> IconsGrid()
                }
                if (currentScreen != Screen.Home) {
                    IconButton(
                        modifier = Modifier.align(Alignment.TopEnd),
                        icon = Icons.Close,
                        onClick = {
                            currentScreen = Screen.Home
                        },
                    )
                }
            }
        }
    }
}

@Composable
private fun HomeScreen(onScreenSelected: (Screen) -> Unit, modifier: Modifier = Modifier) {
    Column(
        modifier = modifier.fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(
            BwTheme.spacing.xxxl,
            alignment = Alignment.CenterVertically,
        ),
    ) {
        Text("Surf Design System", style = BwTheme.typography.display)
        Row(
            horizontalArrangement = Arrangement.spacedBy(space = 8.dp, Alignment.CenterHorizontally),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            GroupOption(
                title = "Foundations",
                imageVector = Icons.AppsFilled,
                onClick = {
                    onScreenSelected(Screen.Foundations)
                },
            )

            GroupOption(
                title = "Components",
                imageVector = Icons.RemoteFilled,
                onClick = {
                    onScreenSelected(Screen.Components)
                },
            )

            GroupOption(
                title = "Icons",
                imageVector = Icons.TrailerFilled,
                onClick = {
                    onScreenSelected(Screen.Icons)
                },
            )
        }
    }
}

@Composable
private fun GroupOption(title: String, onClick: () -> Unit, imageVector: ImageVector) {
    Surface(
        modifier = Modifier.size(300.dp),
        colors = InteractiveSurfaceDefaults.colors(),
        onClick = onClick,
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(
                BwTheme.spacing.m,
                alignment = Alignment.CenterVertically,
            ),
        ) {
            Icon(imageVector, null)
            Text(title)
        }
    }
}

enum class ComponentGroup {
    Progress,
    Button,
    Switch,
    ArtworkTile,
    Badge,
    Dialog,
}

val artworkTypes = arrayOf(
    "BannerXL", "LandscapeXL", "PortraitL", "SquareL", "LandscapeL", "PortraitM",
    "SquareM", "LandscapeM", "LandscapeS", "LandscapeXS",
    "CircleM", "CircleS", "CircleXS",
)

@OptIn(ExperimentalLayoutApi::class, ExperimentalWildApi::class)
@Composable
fun Components(modifier: Modifier = Modifier) {
    Column(
        modifier = modifier.verticalScroll(rememberScrollState()).fillMaxSize(),
        verticalArrangement = Arrangement.spacedBy(BwTheme.spacing.m),
        horizontalAlignment = Alignment.Start,
    ) {
        Text("Components", style = BwTheme.typography.display)
        var currentComponents by remember { mutableStateOf(ComponentGroup.Progress) }

        ComponentsTabBar {
            currentComponents = it
        }

        FlowColumn(
            modifier = Modifier.fillMaxSize().padding(8.dp),
            horizontalArrangement = Arrangement.spacedBy(BwTheme.spacing.xxxl),
            verticalArrangement = Arrangement.spacedBy(BwTheme.spacing.xl),
        ) {
            when (currentComponents) {
                ComponentGroup.Progress -> {
                    LinearProgressIndicator()
                    CircularProgressIndicator()
                    LinearProgressIndicator(progress = .35f)
                    CircularProgressIndicator(progress = .35f)
                    LinearProgressIndicator(progress = .35f, color = BwTheme.colors.statusSuccess)
                    LinearProgressIndicator(progress = .35f, color = BwTheme.colors.statusWarning)
                }

                ComponentGroup.Button -> {
                    IconButton(onClick = {}, icon = Icons.PlayFilled, text = "Icon Button")
                    PrimaryButton(text = "Primary Button", onClick = {})
                    PrimaryButton(
                        leadingIcon = Icons.PlayFilled,
                        text = "Primary Button Icon",
                        onClick = {},
                    )
                    PrimaryButton(
                        trailingIcon = Icons.PlayFilled,
                        text = "Primary Button Icon",
                        onClick = {},
                    )
                    PrimaryButton(
                        text = "Primary Button Custom Color",
                        onClick = {},
                        colors = PrimaryButtonDefaults.colors(
                            focusedBackgroundColor = BwTheme.colors.primary,
                        ),
                    )
                }

                ComponentGroup.Switch -> {
                    var toggled by remember { mutableStateOf(false) }
                    Toggle(toggled = toggled, onToggleChange = {
                        toggled = it
                    })

                    CheckBox(
                        modifier = Modifier.size(32.dp),
                        checked = toggled,
                        onCheckChange = {
                            toggled = it
                        },
                    )

                    RadioButton(
                        modifier = Modifier.size(32.dp),
                        toggled = toggled,
                        onToggleChange = {
                            toggled = it
                        },
                    )
                }

                ComponentGroup.ArtworkTile -> {
                    artworkTypes.forEach { name ->
                        ArtworkTile(
                            modifier = Modifier.Companion.padding(BwTheme.spacing.l),
                            imageTemplate = BwTheme.containerTemplateFromName(name),
                            onClick = {},
                            title = name,
                            subtitle = "Subtitle",
                            image = {
                                Box(
                                    modifier = Modifier.Companion
                                        .fillMaxSize()
                                        .background(BwTheme.colors.secondary),
                                )
                            },
                        )
                    }
                }

                ComponentGroup.Badge -> {
                    Badge(icon = Icons.RtPopcornFresh, text = "95%")
                }

                ComponentGroup.Dialog -> {
                    var show by remember { mutableStateOf(false) }

                    if (show) {
                        Dialog(
                            backgroundColor = BwTheme.colors.surfaceContainer,
                            icon = Icons.Alert,
                            title = "This is a dialog",
                            primaryAction = {
                                PrimaryButton(text = "Ok", onClick = {
                                    show = false
                                })
                            },
                            secondaryAction = {
                                PrimaryButton(text = "Cancel", onClick = {
                                    show = false
                                })
                            },
                            onDismissRequest = {
                                show = false
                            },
                        )
                    }
                    PrimaryButton(text = "Show Dialog", onClick = {
                        show = true
                    })
                }
            }
        }
    }
}

@OptIn(ExperimentalWildApi::class)
@Composable
private fun ComponentsTabBar(onClick: (ComponentGroup) -> Unit) {
    LazyRow(
        horizontalArrangement = Arrangement.spacedBy(BwTheme.spacing.m),
        contentPadding = PaddingValues(BwTheme.spacing.s),
    ) {
        item {
            PrimaryButton(
                "Progress Indicators",
                modifier = Modifier.requestInitialFocus(),
                onClick = {
                    onClick(ComponentGroup.Progress)
                },
            )
        }
        item {
            PrimaryButton("Buttons", onClick = {
                onClick(ComponentGroup.Button)
            })
        }
        item {
            PrimaryButton("Badges", onClick = {
                onClick(ComponentGroup.Badge)
            })
        }
        item {
            PrimaryButton("Switches", onClick = {
                onClick(ComponentGroup.Switch)
            })
        }
        item {
            PrimaryButton("Dialogs", onClick = {
                onClick(ComponentGroup.Dialog)
            })
        }
        item {
            PrimaryButton("Artwork Tiles", onClick = {
                onClick(ComponentGroup.ArtworkTile)
            })
        }
    }
}

//    Surface(modifier = modifier) {
//        FlowRow(
//            modifier = Modifier
//                .fillMaxSize()
//                .verticalScroll(rememberScrollState())
//                .padding(BwTheme.spacing.xl),
//            horizontalArrangement = Arrangement.Start,
//        ) {
//            Shapes()
//            Colors()
//            IconsGrid()
//            Components()
//        }
//    }
// }

@Composable
private fun Shapes(modifier: Modifier = Modifier) {
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.spacedBy(BwTheme.spacing.l),
    ) {
        Text(
            "Shapes",
            style = BwTheme.typography.headlineMedium,
            color = BwTheme.colors.onSurfaceBackground,
        )
        Row(
            modifier = modifier,
            horizontalArrangement = Arrangement.spacedBy(BwTheme.spacing.m),
        ) {
            FoundationItem("Extra Extra Large", shape = BwTheme.shapes.xxl)
            FoundationItem("Extra Large", shape = BwTheme.shapes.xl)
            FoundationItem("Large", shape = BwTheme.shapes.l)
            FoundationItem("Medium", shape = BwTheme.shapes.m)
            FoundationItem("Small", shape = BwTheme.shapes.s)
            FoundationItem("Extra Small", shape = BwTheme.shapes.xs)
            FoundationItem("Circle", shape = BwTheme.shapes.circle)
            FoundationItem("Zero", shape = BwTheme.shapes.zero)
        }
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
private fun Colors(modifier: Modifier = Modifier) {
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.spacedBy(BwTheme.spacing.l),
    ) {
        Text(
            "Colors",
            style = BwTheme.typography.headlineMedium,
            color = BwTheme.colors.onSurfaceBackground,
        )
        FlowRow(
            horizontalArrangement = Arrangement.spacedBy(BwTheme.spacing.m),
            verticalArrangement = Arrangement.spacedBy(BwTheme.spacing.m),
        ) {
            FoundationItem("surfaceBackground", color = BwTheme.colors.surfaceBackground)
            FoundationItem("onSurfaceBackground", color = BwTheme.colors.onSurfaceBackground)

            FoundationItem("surfaceContainer", color = BwTheme.colors.surfaceContainer)
            FoundationItem("onSurfaceContainer", color = BwTheme.colors.onSurfaceContainer)

            FoundationItem("surfaceEmphasized", color = BwTheme.colors.surfaceEmphasized)
            FoundationItem("onSurfaceEmphasized", color = BwTheme.colors.onSurfaceEmphasized)

            FoundationItem("surfaceInverse", color = BwTheme.colors.surfaceInverse)
            FoundationItem("onSurfaceInverse", color = BwTheme.colors.onSurfaceInverse)

            FoundationItem("primary", color = BwTheme.colors.primary)
            FoundationItem("onPrimary", color = BwTheme.colors.onPrimary)

            FoundationItem("secondary", color = BwTheme.colors.secondary)
            FoundationItem("onSecondary", color = BwTheme.colors.onSecondary)

            FoundationItem("statusSuccess", color = BwTheme.colors.statusSuccess)
            FoundationItem("statusSuccess", color = BwTheme.colors.onStatusSuccess)

            FoundationItem("statusError", color = BwTheme.colors.statusError)
            FoundationItem("statusError", color = BwTheme.colors.onStatusError)

            FoundationItem("statusWarning", color = BwTheme.colors.statusWarning)
            FoundationItem("statusWarning", color = BwTheme.colors.onStatusWarning)

            FoundationItem("dimmerHigh", color = BwTheme.colors.dimmerHigh)
            FoundationItem("dimmerLow", color = BwTheme.colors.dimmerLow)
        }
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
private fun IconsGrid(modifier: Modifier = Modifier) {
    Column(
        modifier = modifier.verticalScroll(rememberScrollState()),
        verticalArrangement = Arrangement.spacedBy(BwTheme.spacing.m),
        horizontalAlignment = Alignment.Start,
    ) {
        Text("Icons", style = BwTheme.typography.display)

        FlowRow(
            horizontalArrangement = Arrangement.spacedBy(BwTheme.spacing.xxxl),
            verticalArrangement = Arrangement.spacedBy(BwTheme.spacing.xl),
        ) {
            val sortedIcons = remember { Icons.All.sortedBy { it.name } }

            sortedIcons.forEach { icon ->
                Column(
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.spacedBy(BwTheme.spacing.xs),
                    modifier = Modifier.width(80.dp),
                ) {
                    Image(icon, null, modifier = Modifier.size(32.dp))
                    Text(icon.name.addSpacesToCamelCase(), fontSize = 8.sp, textAlign = TextAlign.Center)
                }
            }
        }
    }
}

fun String.addSpacesToCamelCase(): String = replace(Regex("(?<=[a-z])(?=[A-Z])"), " ")

@Composable
private fun FoundationItem(
    title: String,
    modifier: Modifier = Modifier,
    color: Color = BwTheme.colors.onSurfaceBackground,
    shape: Shape = BwTheme.shapes.m,
    content: @Composable (() -> Unit)? = null,
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(BwTheme.spacing.l),
    ) {
        Box(
            modifier = Modifier
                .sizeIn(minWidth = 48.dp, minHeight = 48.dp)
                .background(color = color, shape = shape),
        ) {
            content?.invoke()
        }

        Text(title, fontSize = 12.sp)
    }
}

@Composable
@ReadOnlyComposable
private fun BwTheme.containerTemplateFromName(name: String): BwContainerTemplate {
    return with(containerTemplates) {
        when (name) {
            "PortraitL" -> portraitL
            "PortraitM" -> portraitM
            "CircleM" -> circleM
            "CircleS" -> circleS
            "CircleXS" -> circleXS
            "SquareL" -> squareL
            "SquareM" -> squareM
            "LandscapeXL" -> landscapeXL
            "LandscapeL" -> landscapeL
            "LandscapeM" -> landscapeM
            "LandscapeS" -> landscapeS
            "LandscapeXS" -> landscapeXS
            "BannerXL" -> bannerXL
            else -> throw IllegalArgumentException("Unknown template name: $name")
        }
    }
}
