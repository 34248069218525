package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Move: ImageVector
    get() {
        if (_move != null) {
            return _move!!
        }
        _move = Builder(name = "Move", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(14.248f, 39.871f)
                arcToRelative(1.568f, 1.568f, 0.0f, false, true, -2.231f, 0.0f)
                lineTo(3.695f, 31.45f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.0f, -1.406f)
                lineToRelative(8.322f, -8.421f)
                arcToRelative(1.568f, 1.568f, 0.0f, true, true, 2.231f, 2.204f)
                lineToRelative(-5.263f, 5.326f)
                horizontalLineToRelative(14.996f)
                arcToRelative(1.594f, 1.594f, 0.0f, true, true, 0.0f, 3.188f)
                lineTo(8.985f, 32.341f)
                lineToRelative(5.263f, 5.325f)
                arcToRelative(1.568f, 1.568f, 0.0f, false, true, 0.0f, 2.205f)
                close()
                moveTo(35.983f, 26.377f)
                arcToRelative(1.568f, 1.568f, 0.0f, true, true, -2.231f, -2.204f)
                lineToRelative(5.263f, -5.326f)
                lineTo(24.019f, 18.847f)
                arcToRelative(1.594f, 1.594f, 0.0f, false, true, 0.0f, -3.188f)
                horizontalLineToRelative(14.996f)
                lineToRelative(-5.263f, -5.325f)
                arcToRelative(1.568f, 1.568f, 0.0f, true, true, 2.231f, -2.205f)
                lineToRelative(8.322f, 8.421f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.0f, 1.406f)
                lineToRelative(-8.322f, 8.421f)
                close()
            }
        }
        .build()
        return _move!!
    }

private var _move: ImageVector? = null
