package com.brdgwtr.designsystem.tokens.internal

import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.DpSize

internal object BwContainerSizeTokens {
    val sizeTvPortraitL = DpSize(196.00.dp, 294.00.dp)
    val sizeTvPortraitM = DpSize(124.00.dp, 186.00.dp)
    val sizeTvCircleM = DpSize(124.00.dp, 124.00.dp)
    val sizeTvCircleS = DpSize(90.00.dp, 90.00.dp)
    val sizeTvCircleXs = DpSize(52.00.dp, 52.00.dp)
    val sizeTvSquareL = DpSize(196.00.dp, 196.00.dp)
    val sizeTvSquareM = DpSize(124.00.dp, 124.00.dp)
    val sizeTvLandscapeXl = DpSize(412.00.dp, 231.75.dp)
    val sizeTvLandscapeL = DpSize(340.00.dp, 191.25.dp)
    val sizeTvLandscapeM = DpSize(268.00.dp, 160.75.dp)
    val sizeTvLandscapeS = DpSize(196.00.dp, 110.25.dp)
    val sizeTvLandscapeXs = DpSize(124.00.dp, 69.75.dp)
    val sizeTvBannerXl = DpSize(844.00.dp, 250.00.dp)
}
