package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RtTomatoFresh: ImageVector
    get() {
        if (_rtTomatoFresh != null) {
            return _rtTomatoFresh!!
        }
        _rtTomatoFresh = Builder(name = "RtTomatoFresh", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFA320A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(41.9673f, 25.5159f)
                curveTo(41.6077f, 19.6577f, 38.6698f, 15.2743f, 34.2483f, 12.8247f)
                curveTo(34.2731f, 12.9678f, 34.1475f, 13.1454f, 34.0049f, 13.0826f)
                curveTo(31.1135f, 11.7996f, 26.2083f, 15.9503f, 22.7821f, 13.7775f)
                curveTo(22.8085f, 14.5573f, 22.6581f, 18.3637f, 17.3715f, 18.5839f)
                curveTo(17.2459f, 18.5886f, 17.1777f, 18.4596f, 17.2568f, 18.3685f)
                curveTo(17.9637f, 17.5509f, 18.6769f, 15.4802f, 17.6506f, 14.3765f)
                curveTo(15.4522f, 16.3733f, 14.1763f, 17.1248f, 9.9625f, 16.1343f)
                curveTo(7.2665f, 18.9958f, 5.7379f, 22.9107f, 6.0371f, 27.7894f)
                curveTo(6.6495f, 37.748f, 15.8475f, 43.4395f, 25.1092f, 42.8546f)
                curveTo(34.3708f, 42.2682f, 42.5797f, 35.4761f, 41.9673f, 25.5175f)
                verticalLineTo(25.5159f)
                close()
            }
            path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(25.0889f, 10.4332f)
                curveTo(26.9927f, 9.9725f, 32.4669f, 10.3876f, 34.2203f, 12.7475f)
                curveTo(34.3257f, 12.889f, 34.1769f, 13.1563f, 34.0079f, 13.0808f)
                curveTo(31.1166f, 11.7979f, 26.2114f, 15.9486f, 22.7851f, 13.7758f)
                curveTo(22.8115f, 14.5556f, 22.6611f, 18.362f, 17.3745f, 18.5821f)
                curveTo(17.2489f, 18.5868f, 17.1807f, 18.4579f, 17.2598f, 18.3667f)
                curveTo(17.9667f, 17.5492f, 18.6799f, 15.4785f, 17.6536f, 14.3748f)
                curveTo(15.2599f, 16.5508f, 13.9607f, 17.2504f, 8.7656f, 15.826f)
                curveTo(8.5951f, 15.7788f, 8.654f, 15.5021f, 8.8338f, 15.4329f)
                curveTo(9.8152f, 15.0524f, 12.0414f, 13.3843f, 14.1468f, 12.65f)
                curveTo(14.5467f, 12.5101f, 14.9467f, 12.4016f, 15.3374f, 12.3387f)
                curveTo(13.0197f, 12.128f, 11.9748f, 11.801f, 10.5004f, 12.0274f)
                curveTo(10.3392f, 12.0526f, 10.2291f, 11.8608f, 10.3299f, 11.7303f)
                curveTo(12.3158f, 9.1361f, 15.9746f, 8.3515f, 18.2334f, 9.7304f)
                curveTo(16.8412f, 7.982f, 15.7513f, 6.5874f, 15.7513f, 6.5874f)
                lineTo(18.3311f, 5.1001f)
                curveTo(18.3311f, 5.1001f, 19.3977f, 7.5182f, 20.1744f, 9.2776f)
                curveTo(22.0953f, 6.3988f, 25.6703f, 6.1331f, 27.1803f, 8.1754f)
                curveTo(27.2702f, 8.2965f, 27.1757f, 8.4694f, 27.0268f, 8.4663f)
                curveTo(25.7974f, 8.4364f, 25.1215f, 9.57f, 25.0688f, 10.4332f)
                horizontalLineTo(25.0858f)
                horizontalLineTo(25.0889f)
                close()
            }
        }
        .build()
        return _rtTomatoFresh!!
    }

private var _rtTomatoFresh: ImageVector? = null
