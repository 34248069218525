package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ThumbsDownOutline: ImageVector
    get() {
        if (_thumbsDownOutline != null) {
            return _thumbsDownOutline!!
        }
        _thumbsDownOutline = Builder(name = "ThumbsDownOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(20.5549f, 38.9045f)
                curveTo(22.5588f, 41.2047f, 24.9634f, 41.1047f, 25.8651f, 40.9046f)
                curveTo(28.7707f, 40.4046f, 29.3719f, 37.2045f, 28.7707f, 35.5044f)
                curveTo(28.6705f, 35.3044f, 28.5f, 35.0f, 28.4701f, 34.3043f)
                curveTo(28.5703f, 30.6041f, 29.7726f, 28.904f, 32.0771f, 27.504f)
                curveTo(32.6782f, 28.004f, 33.4798f, 28.304f, 34.3815f, 28.304f)
                lineTo(40.1927f, 28.304f)
                curveTo(42.2967f, 28.304f, 44.0f, 26.5039f, 44.0f, 24.4038f)
                lineTo(44.0f, 10.7031f)
                curveTo(44.0f, 8.803f, 42.2967f, 7.0029f, 40.1927f, 7.0029f)
                lineTo(34.3815f, 7.0029f)
                curveTo(33.079f, 7.0029f, 32.0771f, 7.6029f, 31.3757f, 8.603f)
                curveTo(29.973f, 7.7029f, 28.7707f, 7.2029f, 27.0674f, 7.0029f)
                curveTo(26.867f, 7.0029f, 12.1387f, 7.0029f, 12.1387f, 7.0029f)
                curveTo(9.7341f, 6.9029f, 7.2293f, 9.403f, 8.131f, 12.2032f)
                curveTo(6.5279f, 13.1032f, 5.7264f, 15.3033f, 6.4277f, 17.2034f)
                curveTo(4.5241f, 18.2035f, 3.7225f, 21.2036f, 5.1252f, 23.2037f)
                curveTo(2.6204f, 26.0039f, 4.6242f, 30.6041f, 7.9306f, 30.8041f)
                curveTo(8.0308f, 30.8041f, 18.3507f, 30.8041f, 18.8516f, 30.9041f)
                curveTo(18.4508f, 34.4043f, 18.9518f, 37.1044f, 20.5549f, 38.9045f)
                close()
                moveTo(30.5742f, 14.3033f)
                lineTo(30.5742f, 24.6038f)
                curveTo(27.4682f, 26.3039f, 25.0636f, 29.6041f, 25.1638f, 34.5043f)
                curveTo(25.1638f, 35.0043f, 25.264f, 35.2043f, 25.3641f, 35.6044f)
                curveTo(25.4643f, 36.3044f, 25.8651f, 36.9044f, 25.4643f, 37.5045f)
                curveTo(25.4643f, 37.5045f, 25.3641f, 37.5045f, 25.3641f, 37.6045f)
                lineTo(25.264f, 37.6045f)
                curveTo(25.0636f, 37.6045f, 24.0616f, 37.8045f, 23.0597f, 36.7044f)
                curveTo(22.2582f, 35.7044f, 21.657f, 33.8043f, 22.3584f, 29.5041f)
                curveTo(22.5587f, 28.504f, 21.7572f, 27.504f, 20.7553f, 27.504f)
                lineTo(9.1329f, 27.504f)
                curveTo(9.0327f, 27.504f, 8.4316f, 27.504f, 8.3314f, 27.504f)
                curveTo(7.0289f, 27.404f, 7.0289f, 25.2038f, 8.3314f, 25.1038f)
                curveTo(8.4316f, 25.1038f, 9.3333f, 25.1038f, 9.4335f, 25.1038f)
                curveTo(11.6377f, 25.1038f, 11.6377f, 21.8037f, 9.4335f, 21.7037f)
                lineTo(8.5318f, 21.7037f)
                curveTo(8.4316f, 21.7037f, 8.4316f, 21.7037f, 8.3314f, 21.7037f)
                curveTo(7.9306f, 21.7037f, 7.7302f, 21.3036f, 7.63f, 20.9036f)
                curveTo(7.63f, 20.3036f, 7.9306f, 20.1036f, 8.4316f, 20.0036f)
                lineTo(10.235f, 20.0036f)
                curveTo(12.5395f, 19.9036f, 12.3391f, 16.5034f, 10.0347f, 16.6034f)
                curveTo(9.2331f, 16.4034f, 9.1329f, 15.2033f, 9.9345f, 15.0033f)
                curveTo(10.0347f, 14.9033f, 11.1368f, 14.9033f, 11.4374f, 14.9033f)
                curveTo(13.6416f, 14.9033f, 13.6416f, 11.6031f, 11.4374f, 11.5031f)
                curveTo(11.1368f, 11.5031f, 11.0366f, 10.9031f, 11.1368f, 10.7031f)
                curveTo(11.237f, 10.5031f, 11.6377f, 10.4031f, 11.9383f, 10.3031f)
                curveTo(11.9383f, 10.3031f, 27.4682f, 10.4031f, 27.5684f, 10.5031f)
                curveTo(29.5722f, 11.1031f, 30.6744f, 11.9032f, 30.5742f, 14.3033f)
                close()
                moveTo(40.6936f, 24.5038f)
                curveTo(40.6936f, 24.8038f, 40.4933f, 25.0038f, 40.1927f, 25.0038f)
                lineTo(34.3815f, 25.0038f)
                curveTo(34.1811f, 25.0038f, 33.8805f, 24.8038f, 33.8805f, 24.5038f)
                lineTo(33.8805f, 10.8031f)
                curveTo(33.8805f, 10.5031f, 34.0809f, 10.3031f, 34.3815f, 10.3031f)
                lineTo(40.1927f, 10.3031f)
                curveTo(40.3931f, 10.3031f, 40.6936f, 10.5031f, 40.6936f, 10.8031f)
                lineTo(40.6936f, 24.5038f)
                close()
            }
        }
        .build()
        return _thumbsDownOutline!!
    }

private var _thumbsDownOutline: ImageVector? = null
