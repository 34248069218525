package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.SeriesOutline: ImageVector
    get() {
        if (_seriesOutline != null) {
            return _seriesOutline!!
        }
        _seriesOutline = Builder(name = "SeriesOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(32.8719f, 13.2766f)
                horizontalLineTo(12.4627f)
                curveTo(12.5535f, 11.9333f, 13.7108f, 10.8731f, 15.1281f, 10.8731f)
                horizontalLineTo(38.3472f)
                curveTo(39.8238f, 10.8731f, 41.0182f, 12.024f, 41.0182f, 13.4468f)
                verticalLineTo(29.2766f)
                curveTo(41.0182f, 30.6419f, 39.9184f, 31.7569f, 38.5247f, 31.8447f)
                verticalLineTo(18.7234f)
                curveTo(38.5247f, 15.7138f, 35.9953f, 13.2766f, 32.8719f, 13.2766f)
                close()
                moveTo(32.8719f, 40.0f)
                curveTo(35.9371f, 40.0f, 38.4303f, 37.6528f, 38.5221f, 34.7208f)
                curveTo(41.5645f, 34.6319f, 44.0f, 32.2298f, 44.0f, 29.2766f)
                verticalLineTo(13.4468f)
                curveTo(44.0f, 10.4372f, 41.4706f, 8.0f, 38.3472f, 8.0f)
                horizontalLineTo(15.1281f)
                curveTo(12.0629f, 8.0f, 9.5697f, 10.3472f, 9.4779f, 13.2791f)
                curveTo(6.3833f, 13.3682f, 4.142f, 15.8148f, 4.0017f, 18.6551f)
                lineTo(4.0f, 18.6892f)
                verticalLineTo(18.7234f)
                verticalLineTo(34.5532f)
                curveTo(4.0f, 37.5628f, 6.5294f, 40.0f, 9.6528f, 40.0f)
                horizontalLineTo(32.8719f)
                close()
                moveTo(6.9818f, 18.7607f)
                verticalLineTo(34.5532f)
                curveTo(6.9818f, 35.976f, 8.1762f, 37.1269f, 9.6528f, 37.1269f)
                horizontalLineTo(32.8719f)
                curveTo(34.3486f, 37.1269f, 35.543f, 35.976f, 35.543f, 34.5532f)
                verticalLineTo(18.7234f)
                curveTo(35.543f, 17.3006f, 34.3486f, 16.1497f, 32.8719f, 16.1497f)
                horizontalLineTo(9.6528f)
                curveTo(8.2471f, 16.1497f, 7.072f, 17.238f, 6.9818f, 18.7607f)
                close()
                moveTo(26.9581f, 27.3771f)
                curveTo(27.5057f, 27.0605f, 27.5057f, 26.3218f, 26.9581f, 26.0052f)
                lineTo(19.0723f, 21.4673f)
                curveTo(18.5247f, 21.2563f, 17.8675f, 21.5729f, 17.8675f, 22.206f)
                verticalLineTo(31.2818f)
                curveTo(17.8675f, 31.8095f, 18.5247f, 32.2316f, 19.0723f, 31.915f)
                lineTo(26.9581f, 27.3771f)
                close()
            }
        }
        .build()
        return _seriesOutline!!
    }

private var _seriesOutline: ImageVector? = null
