package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.TrailerFilled: ImageVector
    get() {
        if (_trailerFilled != null) {
            return _trailerFilled!!
        }
        _trailerFilled = Builder(name = "TrailerFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(10.1f, 4.0f)
                horizontalLineTo(11.0f)
                curveTo(11.3f, 4.0f, 11.6f, 4.1f, 11.8f, 4.3f)
                curveTo(12.0f, 4.6f, 12.1f, 4.8f, 12.1f, 5.1f)
                verticalLineTo(5.8f)
                curveTo(12.1f, 5.9f, 12.2f, 6.1f, 12.4f, 6.1f)
                horizontalLineTo(13.6f)
                curveTo(13.7f, 6.1f, 13.9f, 6.0f, 13.9f, 5.8f)
                verticalLineTo(5.1f)
                curveTo(13.9f, 4.8f, 14.0f, 4.5f, 14.2f, 4.3f)
                curveTo(14.4f, 4.1f, 14.7f, 4.0f, 15.0f, 4.0f)
                horizontalLineTo(33.1f)
                curveTo(33.4f, 4.0f, 33.7f, 4.1f, 33.9f, 4.3f)
                curveTo(34.1f, 4.5f, 34.2f, 4.8f, 34.2f, 5.1f)
                verticalLineTo(5.8f)
                curveTo(34.2f, 5.9f, 34.3f, 6.1f, 34.5f, 6.1f)
                horizontalLineTo(35.7f)
                curveTo(35.8f, 6.1f, 36.0f, 6.0f, 36.0f, 5.8f)
                verticalLineTo(5.1f)
                curveTo(36.0f, 4.8f, 36.1f, 4.5f, 36.3f, 4.3f)
                curveTo(36.5f, 4.1f, 36.8f, 4.0f, 37.1f, 4.0f)
                horizontalLineTo(38.0f)
                curveTo(39.1f, 4.0f, 40.1f, 4.4f, 40.8f, 5.2f)
                curveTo(41.6f, 5.9f, 42.0f, 6.9f, 42.0f, 8.0f)
                verticalLineTo(40.1f)
                curveTo(42.0f, 41.1f, 41.5f, 42.1f, 40.8f, 42.9f)
                curveTo(40.0f, 43.6f, 39.0f, 44.0f, 37.9f, 44.0f)
                horizontalLineTo(37.1f)
                curveTo(36.5f, 44.0f, 35.9f, 43.5f, 35.9f, 42.9f)
                verticalLineTo(42.2f)
                curveTo(35.9f, 42.1f, 35.8f, 41.9f, 35.6f, 41.9f)
                horizontalLineTo(34.4f)
                curveTo(34.3f, 41.9f, 34.1f, 42.0f, 34.1f, 42.2f)
                verticalLineTo(42.9f)
                curveTo(34.1f, 43.5f, 33.6f, 44.0f, 32.9f, 44.0f)
                horizontalLineTo(14.9f)
                curveTo(14.3f, 44.0f, 13.7f, 43.5f, 13.7f, 42.9f)
                verticalLineTo(42.2f)
                curveTo(13.7f, 42.1f, 13.6f, 41.9f, 13.4f, 41.9f)
                horizontalLineTo(12.3f)
                curveTo(12.2f, 41.9f, 12.0f, 42.0f, 12.0f, 42.2f)
                verticalLineTo(42.9f)
                curveTo(12.0f, 43.5f, 11.5f, 44.0f, 10.8f, 44.0f)
                horizontalLineTo(10.0f)
                curveTo(8.9f, 44.0f, 7.9f, 43.6f, 7.2f, 42.8f)
                curveTo(6.4f, 42.1f, 6.0f, 41.1f, 6.0f, 40.0f)
                verticalLineTo(7.9f)
                curveTo(6.0f, 6.9f, 6.5f, 5.9f, 7.2f, 5.1f)
                curveTo(8.0f, 4.4f, 9.0f, 4.0f, 10.1f, 4.0f)
                close()
                moveTo(15.3f, 26.5f)
                curveTo(15.3f, 26.2f, 15.2f, 25.9f, 15.0f, 25.7f)
                curveTo(14.8f, 25.5f, 14.5f, 25.4f, 14.2f, 25.4f)
                horizontalLineTo(11.8f)
                curveTo(11.2f, 25.4f, 10.7f, 25.9f, 10.7f, 26.5f)
                verticalLineTo(29.1f)
                curveTo(10.7f, 29.7f, 11.2f, 30.2f, 11.8f, 30.2f)
                horizontalLineTo(14.1f)
                curveTo(14.7f, 30.2f, 15.3f, 29.7f, 15.3f, 29.1f)
                verticalLineTo(26.5f)
                close()
                moveTo(15.3f, 18.9f)
                curveTo(15.3f, 18.6f, 15.2f, 18.3f, 15.0f, 18.1f)
                curveTo(14.8f, 17.9f, 14.5f, 17.8f, 14.2f, 17.8f)
                horizontalLineTo(11.8f)
                curveTo(11.2f, 17.8f, 10.7f, 18.3f, 10.7f, 18.9f)
                verticalLineTo(21.5f)
                curveTo(10.7f, 22.1f, 11.2f, 22.6f, 11.8f, 22.6f)
                horizontalLineTo(14.1f)
                curveTo(14.7f, 22.6f, 15.3f, 22.1f, 15.3f, 21.5f)
                verticalLineTo(18.9f)
                close()
                moveTo(15.3f, 11.2f)
                curveTo(15.3f, 10.9f, 15.2f, 10.6f, 15.0f, 10.4f)
                curveTo(14.8f, 10.2f, 14.5f, 10.1f, 14.2f, 10.1f)
                horizontalLineTo(11.8f)
                curveTo(11.2f, 10.1f, 10.7f, 10.6f, 10.7f, 11.2f)
                verticalLineTo(13.8f)
                curveTo(10.7f, 14.4f, 11.2f, 14.9f, 11.8f, 14.9f)
                horizontalLineTo(14.1f)
                curveTo(14.7f, 14.9f, 15.3f, 14.4f, 15.3f, 13.8f)
                verticalLineTo(11.2f)
                close()
                moveTo(15.3f, 34.2f)
                curveTo(15.3f, 33.9f, 15.2f, 33.6f, 15.0f, 33.4f)
                curveTo(14.8f, 33.2f, 14.5f, 33.1f, 14.2f, 33.1f)
                horizontalLineTo(11.8f)
                curveTo(11.2f, 33.1f, 10.7f, 33.6f, 10.7f, 34.2f)
                verticalLineTo(36.8f)
                curveTo(10.7f, 37.4f, 11.2f, 37.9f, 11.8f, 37.9f)
                horizontalLineTo(14.1f)
                curveTo(14.7f, 37.9f, 15.3f, 37.4f, 15.3f, 36.8f)
                verticalLineTo(34.2f)
                close()
                moveTo(37.5f, 26.5f)
                curveTo(37.5f, 26.2f, 37.4f, 25.9f, 37.2f, 25.7f)
                curveTo(37.0f, 25.5f, 36.7f, 25.4f, 36.4f, 25.4f)
                horizontalLineTo(34.0f)
                curveTo(33.4f, 25.4f, 32.9f, 25.9f, 32.9f, 26.5f)
                verticalLineTo(29.1f)
                curveTo(32.9f, 29.7f, 33.4f, 30.2f, 34.0f, 30.2f)
                horizontalLineTo(36.3f)
                curveTo(36.9f, 30.2f, 37.5f, 29.7f, 37.5f, 29.1f)
                verticalLineTo(26.5f)
                close()
                moveTo(37.5f, 18.9f)
                curveTo(37.5f, 18.6f, 37.4f, 18.3f, 37.2f, 18.1f)
                curveTo(37.0f, 17.9f, 36.7f, 17.8f, 36.4f, 17.8f)
                horizontalLineTo(34.0f)
                curveTo(33.4f, 17.8f, 32.9f, 18.3f, 32.9f, 18.9f)
                verticalLineTo(21.5f)
                curveTo(32.9f, 22.1f, 33.4f, 22.6f, 34.0f, 22.6f)
                horizontalLineTo(36.3f)
                curveTo(36.9f, 22.6f, 37.5f, 22.1f, 37.5f, 21.5f)
                verticalLineTo(18.9f)
                close()
                moveTo(37.5f, 11.2f)
                curveTo(37.5f, 10.9f, 37.4f, 10.6f, 37.2f, 10.4f)
                curveTo(37.0f, 10.2f, 36.7f, 10.1f, 36.4f, 10.1f)
                horizontalLineTo(34.0f)
                curveTo(33.4f, 10.1f, 32.9f, 10.6f, 32.9f, 11.2f)
                verticalLineTo(13.8f)
                curveTo(32.9f, 14.4f, 33.4f, 14.9f, 34.0f, 14.9f)
                horizontalLineTo(36.3f)
                curveTo(36.9f, 14.9f, 37.5f, 14.4f, 37.5f, 13.8f)
                verticalLineTo(11.2f)
                close()
                moveTo(37.5f, 34.2f)
                curveTo(37.5f, 33.9f, 37.4f, 33.6f, 37.2f, 33.4f)
                curveTo(37.0f, 33.2f, 36.7f, 33.1f, 36.4f, 33.1f)
                horizontalLineTo(34.0f)
                curveTo(33.4f, 33.1f, 32.9f, 33.6f, 32.9f, 34.2f)
                verticalLineTo(36.8f)
                curveTo(32.9f, 37.4f, 33.4f, 37.9f, 34.0f, 37.9f)
                horizontalLineTo(36.3f)
                curveTo(36.9f, 37.9f, 37.5f, 37.4f, 37.5f, 36.8f)
                verticalLineTo(34.2f)
                close()
            }
        }
        .build()
        return _trailerFilled!!
    }

private var _trailerFilled: ImageVector? = null
