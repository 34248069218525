package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.LiveTvFilled: ImageVector
    get() {
        if (_liveTvFilled != null) {
            return _liveTvFilled!!
        }
        _liveTvFilled = Builder(name = "LiveTvFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(13.9269f, 4.4626f)
                curveTo(14.2182f, 4.1545f, 14.6093f, 3.9997f, 14.9994f, 4.0f)
                horizontalLineTo(15.0006f)
                curveTo(15.3907f, 4.0003f, 15.7811f, 4.1549f, 16.0757f, 4.4657f)
                lineTo(24.0006f, 12.5938f)
                lineTo(31.9255f, 4.4657f)
                curveTo(32.2202f, 4.1549f, 32.6106f, 4.0003f, 33.0006f, 4.0f)
                curveTo(33.3907f, 3.9997f, 33.7818f, 4.1545f, 34.0727f, 4.4626f)
                curveTo(34.655f, 5.0793f, 34.6386f, 6.0494f, 34.0456f, 6.6411f)
                curveTo(32.9756f, 7.7389f, 31.9057f, 8.837f, 30.8357f, 9.9351f)
                curveTo(29.7652f, 11.0337f, 28.6948f, 12.1323f, 27.6241f, 13.2308f)
                horizontalLineTo(34.5f)
                curveTo(38.629f, 13.2308f, 42.0f, 16.6882f, 42.0f, 20.9231f)
                verticalLineTo(36.3077f)
                curveTo(42.0f, 40.5426f, 38.629f, 44.0f, 34.5f, 44.0f)
                horizontalLineTo(13.5f)
                curveTo(9.371f, 44.0f, 6.0f, 40.5426f, 6.0f, 36.3077f)
                verticalLineTo(20.9231f)
                curveTo(6.0f, 16.6882f, 9.371f, 13.2308f, 13.5f, 13.2308f)
                horizontalLineTo(20.3759f)
                curveTo(19.4127f, 12.2425f, 18.4496f, 11.2541f, 17.4865f, 10.2657f)
                curveTo(16.3091f, 9.0574f, 15.1317f, 7.8491f, 13.954f, 6.6411f)
                curveTo(13.3614f, 6.0495f, 13.345f, 5.0793f, 13.9269f, 4.4626f)
                close()
                moveTo(22.2447f, 23.4349f)
                curveTo(22.4768f, 23.2982f, 22.7403f, 23.2275f, 23.0078f, 23.2302f)
                lineTo(23.0079f, 23.2302f)
                curveTo(23.3363f, 23.233f, 23.6547f, 23.3463f, 23.9142f, 23.5523f)
                curveTo(24.174f, 23.7584f, 24.3608f, 24.0458f, 24.4462f, 24.371f)
                horizontalLineTo(24.4459f)
                lineTo(25.9489f, 30.1179f)
                lineTo(30.7449f, 27.279f)
                curveTo(31.0904f, 27.0746f, 31.5006f, 27.019f, 31.8856f, 27.1251f)
                curveTo(32.2706f, 27.2309f, 32.5991f, 27.4891f, 32.7983f, 27.8435f)
                curveTo(32.9979f, 28.1976f, 33.0518f, 28.6183f, 32.9483f, 29.0132f)
                curveTo(32.8452f, 29.4084f, 32.5931f, 29.745f, 32.2479f, 29.9493f)
                lineTo(25.7554f, 33.7937f)
                curveTo(25.3505f, 34.0324f, 24.8607f, 34.065f, 24.4298f, 33.8816f)
                curveTo(23.9985f, 33.6983f, 23.6747f, 33.3198f, 23.5535f, 32.8573f)
                horizontalLineTo(23.5539f)
                lineTo(22.0509f, 27.1103f)
                lineTo(17.2549f, 29.9493f)
                curveTo(16.9093f, 30.154f, 16.4992f, 30.2093f, 16.1141f, 30.1035f)
                curveTo(15.7288f, 29.9977f, 15.4007f, 29.7391f, 15.2014f, 29.3851f)
                curveTo(15.0019f, 29.031f, 14.948f, 28.61f, 15.0514f, 28.2151f)
                curveTo(15.1546f, 27.8202f, 15.4067f, 27.4833f, 15.7519f, 27.279f)
                verticalLineTo(27.2793f)
                lineTo(22.2447f, 23.4349f)
                close()
            }
        }
        .build()
        return _liveTvFilled!!
    }

private var _liveTvFilled: ImageVector? = null
