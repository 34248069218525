package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoInputOutline: ImageVector
    get() {
        if (_ruwidoInputOutline != null) {
            return _ruwidoInputOutline!!
        }
        _ruwidoInputOutline = Builder(name = "RuwidoInputOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(2.0f, 24.0f)
                curveTo(2.0f, 11.848f, 11.848f, 2.0f, 24.0f, 2.0f)
                reflectiveCurveToRelative(22.0f, 9.848f, 22.0f, 22.0f)
                reflectiveCurveToRelative(-9.848f, 22.0f, -22.0f, 22.0f)
                reflectiveCurveTo(2.0f, 36.152f, 2.0f, 24.0f)
                close()
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(10.281f, 16.0f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.0f, -1.0f)
                horizontalLineToRelative(25.992f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.0f, 1.0f)
                verticalLineToRelative(15.952f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, -1.0f, 1.0f)
                lineTo(11.281f, 32.952f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, -1.0f, -1.0f)
                verticalLineToRelative(-3.638f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 2.0f, 0.0f)
                verticalLineToRelative(2.638f)
                horizontalLineToRelative(23.992f)
                lineTo(36.273f, 17.0f)
                lineTo(12.281f, 17.0f)
                verticalLineToRelative(2.708f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, true, -2.0f, 0.0f)
                lineTo(10.281f, 16.0f)
                close()
                moveTo(22.596f, 19.781f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, -1.226f, 1.58f)
                lineToRelative(2.12f, 1.645f)
                lineTo(11.0f, 23.006f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, 0.0f, 2.0f)
                horizontalLineToRelative(12.49f)
                lineToRelative(-2.4f, 1.976f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 1.272f, 1.544f)
                lineToRelative(4.616f, -3.801f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -0.023f, -1.562f)
                lineToRelative(-4.359f, -3.382f)
                close()
            }
        }
        .build()
        return _ruwidoInputOutline!!
    }

private var _ruwidoInputOutline: ImageVector? = null
