package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Pause: ImageVector
    get() {
        if (_pause != null) {
            return _pause!!
        }
        _pause = Builder(name = "Pause", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(21.3333f, 8.8f)
                curveTo(21.3333f, 6.7013f, 19.9156f, 5.0f, 18.1667f, 5.0f)
                curveTo(16.4178f, 5.0f, 15.0f, 6.7013f, 15.0f, 8.8f)
                verticalLineTo(39.2f)
                curveTo(15.0f, 41.2987f, 16.4178f, 43.0f, 18.1667f, 43.0f)
                curveTo(19.9156f, 43.0f, 21.3333f, 41.2987f, 21.3333f, 39.2f)
                verticalLineTo(8.8f)
                close()
                moveTo(34.0f, 8.8f)
                curveTo(34.0f, 6.7013f, 32.5822f, 5.0f, 30.8333f, 5.0f)
                curveTo(29.0844f, 5.0f, 27.6667f, 6.7013f, 27.6667f, 8.8f)
                verticalLineTo(39.2f)
                curveTo(27.6667f, 41.2987f, 29.0844f, 43.0f, 30.8333f, 43.0f)
                curveTo(32.5822f, 43.0f, 34.0f, 41.2987f, 34.0f, 39.2f)
                verticalLineTo(8.8f)
                close()
            }
        }
        .build()
        return _pause!!
    }

private var _pause: ImageVector? = null
