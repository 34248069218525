package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.EditFilled: ImageVector
    get() {
        if (_editFilled != null) {
            return _editFilled!!
        }
        _editFilled = Builder(name = "EditFilled", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(41.8f, 10.6f)
                lineTo(36.9f, 5.4f)
                curveTo(36.0f, 4.5f, 35.0f, 4.0f, 33.8f, 4.0f)
                curveTo(32.6f, 4.0f, 31.6f, 4.4f, 30.8f, 5.3f)
                lineTo(24.3f, 12.1f)
                lineTo(5.0f, 32.4f)
                verticalLineTo(44.0f)
                horizontalLineTo(16.0f)
                lineTo(35.4f, 23.6f)
                lineTo(41.7f, 16.9f)
                curveTo(42.6f, 16.0f, 43.0f, 14.9f, 43.0f, 13.7f)
                curveTo(43.0f, 12.5f, 42.6f, 11.4f, 41.8f, 10.6f)
                close()
                moveTo(32.3f, 20.4f)
                lineTo(27.4f, 15.3f)
                lineTo(32.9f, 9.4f)
                lineTo(37.8f, 14.5f)
                lineTo(32.3f, 20.4f)
                close()
            }
        }
        .build()
        return _editFilled!!
    }

private var _editFilled: ImageVector? = null
