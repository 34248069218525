package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.WatchlistOutline: ImageVector
    get() {
        if (_watchlistOutline != null) {
            return _watchlistOutline!!
        }
        _watchlistOutline = Builder(name = "WatchlistOutline", defaultWidth = 48.0.dp, defaultHeight
                = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(11.0f, 9.887f)
                curveTo(11.0f, 7.188f, 13.312f, 5.0f, 16.164f, 5.0f)
                lineTo(31.83f, 5.0f)
                curveToRelative(2.852f, 0.0f, 5.164f, 2.188f, 5.164f, 4.887f)
                verticalLineToRelative(17.584f)
                lineTo(37.0f, 27.471f)
                lineTo(37.0f, 43.0f)
                lineTo(24.599f, 31.881f)
                horizontalLineToRelative(-1.198f)
                lineTo(11.0f, 43.0f)
                lineTo(11.0f, 9.887f)
                close()
                moveTo(33.557f, 35.425f)
                lineTo(33.55f, 9.887f)
                curveToRelative(0.0f, -0.9f, -0.77f, -1.629f, -1.721f, -1.629f)
                lineTo(16.164f, 8.258f)
                curveToRelative(-0.95f, 0.0f, -1.721f, 0.73f, -1.721f, 1.63f)
                verticalLineToRelative(25.537f)
                lineToRelative(7.586f, -6.802f)
                horizontalLineToRelative(3.942f)
                lineToRelative(7.586f, 6.802f)
                close()
            }
        }
        .build()
        return _watchlistOutline!!
    }

private var _watchlistOutline: ImageVector? = null
