package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Voice: ImageVector
    get() {
        if (_voice != null) {
            return _voice!!
        }
        _voice = Builder(name = "Voice", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(18.611f, 11.05f)
                curveToRelative(0.0f, -2.781f, 2.417f, -5.05f, 5.381f, -5.05f)
                curveToRelative(2.965f, 0.0f, 5.381f, 2.269f, 5.381f, 5.05f)
                verticalLineToRelative(14.055f)
                curveToRelative(0.0f, 2.782f, -2.416f, 5.05f, -5.38f, 5.05f)
                curveToRelative(-2.965f, 0.0f, -5.381f, -2.268f, -5.381f, -5.05f)
                lineTo(18.612f, 11.05f)
                close()
                moveTo(15.111f, 21.568f)
                curveToRelative(0.614f, 0.0f, 1.111f, 0.466f, 1.111f, 1.042f)
                verticalLineToRelative(3.072f)
                curveToRelative(0.0f, 4.07f, 3.498f, 7.353f, 7.778f, 7.353f)
                curveToRelative(4.28f, 0.0f, 7.778f, -3.282f, 7.778f, -7.353f)
                lineTo(31.778f, 22.61f)
                curveToRelative(0.0f, -0.575f, 0.497f, -1.042f, 1.11f, -1.042f)
                curveToRelative(0.614f, 0.0f, 1.112f, 0.466f, 1.112f, 1.042f)
                verticalLineToRelative(3.072f)
                curveToRelative(0.0f, 4.857f, -3.892f, 8.858f, -8.89f, 9.38f)
                verticalLineToRelative(5.895f)
                curveToRelative(0.0f, 0.576f, -0.497f, 1.043f, -1.11f, 1.043f)
                curveToRelative(-0.614f, 0.0f, -1.111f, -0.467f, -1.111f, -1.043f)
                verticalLineToRelative(-5.895f)
                curveTo(17.892f, 34.54f, 14.0f, 30.539f, 14.0f, 25.682f)
                lineTo(14.0f, 22.61f)
                curveToRelative(0.0f, -0.575f, 0.498f, -1.042f, 1.111f, -1.042f)
                close()
            }
        }
        .build()
        return _voice!!
    }

private var _voice: ImageVector? = null
