package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Lock: ImageVector
    get() {
        if (_lock != null) {
            return _lock!!
        }
        _lock = Builder(name = "Lock", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(35.4289f, 18.6864f)
                verticalLineTo(14.667f)
                curveTo(35.4289f, 8.7833f, 30.4615f, 4.0f, 24.3501f, 4.0f)
                horizontalLineTo(23.6506f)
                curveTo(17.5391f, 4.0f, 12.5718f, 8.7833f, 12.5718f, 14.667f)
                verticalLineTo(18.6864f)
                curveTo(10.1069f, 18.6864f, 8.0f, 20.631f, 8.0f, 23.0044f)
                verticalLineTo(39.682f)
                curveTo(8.0f, 42.0557f, 10.019f, 44.0f, 12.4839f, 44.0f)
                horizontalLineTo(35.5161f)
                curveTo(37.981f, 44.0f, 40.0f, 42.0557f, 40.0f, 39.682f)
                verticalLineTo(23.0044f)
                curveTo(40.0003f, 20.631f, 37.8938f, 18.6864f, 35.4289f, 18.6864f)
                close()
                moveTo(31.619f, 18.6758f)
                horizontalLineTo(16.381f)
                verticalLineTo(14.667f)
                curveTo(16.381f, 10.4044f, 19.1927f, 7.4615f, 23.619f, 7.4615f)
                horizontalLineTo(24.0f)
                horizontalLineTo(24.381f)
                curveTo(28.809f, 7.4615f, 31.6173f, 10.4044f, 31.6173f, 14.667f)
                lineTo(31.619f, 18.6758f)
                close()
            }
        }
        .build()
        return _lock!!
    }

private var _lock: ImageVector? = null
