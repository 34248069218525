package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RtVerifiedHotBadge: ImageVector
    get() {
        if (_rtVerifiedHotBadge != null) {
            return _rtVerifiedHotBadge!!
        }
        _rtVerifiedHotBadge = Builder(name = "RtVerifiedHotBadge", defaultWidth = 24.0.dp,
                defaultHeight = 24.0.dp, viewportWidth = 24.0f, viewportHeight = 24.0f).apply {
            path(fill = SolidColor(Color(0xFF4CBFB4)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(18.394f, 16.1936f)
                curveTo(18.3976f, 16.1906f, 18.4017f, 16.1877f, 18.4053f, 16.1853f)
                curveTo(18.4389f, 16.1583f, 18.4714f, 16.1301f, 18.5027f, 16.1008f)
                curveTo(18.5063f, 16.0978f, 18.5098f, 16.0949f, 18.5127f, 16.092f)
                curveTo(18.5446f, 16.0626f, 18.5748f, 16.0327f, 18.6049f, 16.0021f)
                curveTo(18.6078f, 15.9992f, 18.6114f, 15.9962f, 18.6143f, 15.9927f)
                curveTo(18.6444f, 15.961f, 18.674f, 15.9281f, 18.7023f, 15.8946f)
                curveTo(18.7047f, 15.8923f, 18.7065f, 15.8899f, 18.7088f, 15.8876f)
                curveTo(18.736f, 15.8559f, 18.7614f, 15.823f, 18.7868f, 15.7895f)
                curveTo(18.7897f, 15.7854f, 18.7933f, 15.7813f, 18.7968f, 15.7772f)
                curveTo(18.8222f, 15.7425f, 18.8464f, 15.7073f, 18.87f, 15.6715f)
                curveTo(18.8724f, 15.668f, 18.8748f, 15.6644f, 18.8771f, 15.6615f)
                curveTo(18.8996f, 15.6268f, 18.9202f, 15.5916f, 18.9409f, 15.5564f)
                curveTo(18.9439f, 15.5511f, 18.9474f, 15.5464f, 18.9504f, 15.5411f)
                curveTo(18.9698f, 15.5059f, 18.9887f, 15.47f, 19.0065f, 15.4342f)
                curveTo(19.0094f, 15.4283f, 19.013f, 15.4225f, 19.0159f, 15.416f)
                curveTo(19.0324f, 15.3825f, 19.0472f, 15.3479f, 19.0614f, 15.3132f)
                curveTo(19.0649f, 15.3044f, 19.0697f, 15.2956f, 19.0732f, 15.2868f)
                curveTo(19.0868f, 15.2527f, 19.0992f, 15.2187f, 19.1116f, 15.184f)
                curveTo(19.1151f, 15.1741f, 19.1193f, 15.1641f, 19.1228f, 15.1541f)
                curveTo(19.1346f, 15.1194f, 19.1447f, 15.0848f, 19.1541f, 15.0495f)
                curveTo(19.1571f, 15.039f, 19.1606f, 15.029f, 19.1636f, 15.0184f)
                curveTo(19.173f, 14.9832f, 19.1807f, 14.9474f, 19.1884f, 14.9115f)
                curveTo(19.1907f, 14.901f, 19.1937f, 14.8904f, 19.196f, 14.8792f)
                curveTo(19.2037f, 14.8416f, 19.209f, 14.8041f, 19.2149f, 14.7659f)
                curveTo(19.2161f, 14.7565f, 19.2185f, 14.7471f, 19.2197f, 14.7377f)
                curveTo(19.225f, 14.6972f, 19.2285f, 14.6561f, 19.2315f, 14.6155f)
                curveTo(19.2315f, 14.6085f, 19.2332f, 14.6015f, 19.2332f, 14.5944f)
                curveTo(19.2362f, 14.5462f, 19.238f, 14.4981f, 19.238f, 14.4493f)
                verticalLineTo(8.0122f)
                curveTo(19.238f, 7.1371f, 18.7206f, 6.3378f, 17.9198f, 5.9755f)
                lineTo(12.59f, 3.5635f)
                curveTo(12.2941f, 3.4296f, 11.9758f, 3.3627f, 11.658f, 3.3627f)
                curveTo(11.3403f, 3.3627f, 11.022f, 3.4296f, 10.7261f, 3.5635f)
                lineTo(5.3963f, 5.9755f)
                curveTo(4.5955f, 6.3378f, 4.0781f, 7.1371f, 4.0781f, 8.0122f)
                verticalLineTo(14.4493f)
                curveTo(4.0781f, 14.5468f, 4.0846f, 14.6437f, 4.097f, 14.7389f)
                curveTo(4.0988f, 14.753f, 4.1017f, 14.7671f, 4.1041f, 14.7817f)
                curveTo(4.1094f, 14.8146f, 4.1142f, 14.8481f, 4.1206f, 14.881f)
                curveTo(4.1236f, 14.8963f, 4.1277f, 14.9115f, 4.1313f, 14.9268f)
                curveTo(4.1384f, 14.9579f, 4.1449f, 14.9896f, 4.1531f, 15.0202f)
                curveTo(4.1567f, 15.0325f, 4.1608f, 15.0448f, 4.1644f, 15.0572f)
                curveTo(4.1738f, 15.0907f, 4.1832f, 15.1241f, 4.1945f, 15.1564f)
                curveTo(4.198f, 15.1676f, 4.2027f, 15.1787f, 4.2069f, 15.1899f)
                curveTo(4.2187f, 15.2234f, 4.2305f, 15.2563f, 4.2441f, 15.2892f)
                curveTo(4.25f, 15.3033f, 4.2565f, 15.3168f, 4.2624f, 15.3303f)
                curveTo(4.2754f, 15.3596f, 4.2878f, 15.3896f, 4.3019f, 15.4184f)
                curveTo(4.3073f, 15.4289f, 4.3126f, 15.4389f, 4.3179f, 15.4489f)
                curveTo(4.3338f, 15.4806f, 4.3498f, 15.5123f, 4.3675f, 15.5434f)
                curveTo(4.3728f, 15.5534f, 4.3793f, 15.5628f, 4.3852f, 15.5728f)
                curveTo(4.4035f, 15.6039f, 4.4212f, 15.6345f, 4.4407f, 15.6644f)
                curveTo(4.4461f, 15.6721f, 4.4514f, 15.6797f, 4.4567f, 15.6873f)
                curveTo(4.4779f, 15.719f, 4.4992f, 15.7502f, 4.5216f, 15.7807f)
                curveTo(4.5258f, 15.786f, 4.5299f, 15.7907f, 4.534f, 15.796f)
                curveTo(4.5588f, 15.8283f, 4.5837f, 15.8606f, 4.6096f, 15.8911f)
                curveTo(4.6138f, 15.8964f, 4.6191f, 15.9011f, 4.6232f, 15.9064f)
                curveTo(4.6498f, 15.9369f, 4.6764f, 15.9669f, 4.7047f, 15.9962f)
                curveTo(4.7094f, 16.0009f, 4.7142f, 16.0051f, 4.7189f, 16.0097f)
                curveTo(4.7472f, 16.0391f, 4.7768f, 16.0679f, 4.8069f, 16.0955f)
                curveTo(4.8128f, 16.1008f, 4.8193f, 16.1061f, 4.8252f, 16.1113f)
                curveTo(4.8547f, 16.1378f, 4.8843f, 16.1636f, 4.915f, 16.1883f)
                curveTo(4.9209f, 16.193f, 4.9274f, 16.1971f, 4.9333f, 16.2018f)
                curveTo(4.9646f, 16.2265f, 4.9965f, 16.2511f, 5.0295f, 16.274f)
                curveTo(5.0372f, 16.2793f, 5.0455f, 16.284f, 5.0532f, 16.2893f)
                curveTo(5.085f, 16.311f, 5.1175f, 16.3328f, 5.1506f, 16.3533f)
                curveTo(5.3331f, 16.3222f, 5.5161f, 16.2934f, 5.6998f, 16.2646f)
                lineTo(17.6186f, 16.2635f)
                curveTo(17.8029f, 16.2922f, 17.9854f, 16.3192f, 18.172f, 16.3504f)
                curveTo(18.2086f, 16.3281f, 18.244f, 16.304f, 18.2789f, 16.2799f)
                curveTo(18.283f, 16.277f, 18.2877f, 16.274f, 18.2925f, 16.2711f)
                curveTo(18.3279f, 16.2464f, 18.3616f, 16.22f, 18.3952f, 16.193f)
                lineTo(18.394f, 16.1936f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(18.0702f, 5.6448f)
                lineTo(12.7409f, 3.2334f)
                curveTo(12.0523f, 2.9222f, 11.2627f, 2.9222f, 10.5747f, 3.2334f)
                lineTo(5.2448f, 5.6448f)
                curveTo(4.3141f, 6.0659f, 3.7129f, 6.995f, 3.7129f, 8.0116f)
                verticalLineTo(14.4487f)
                curveTo(3.7129f, 15.2263f, 4.0655f, 15.9522f, 4.6478f, 16.439f)
                curveTo(4.8137f, 16.4091f, 4.9802f, 16.3803f, 5.1474f, 16.3521f)
                curveTo(5.1149f, 16.3321f, 5.0836f, 16.311f, 5.0517f, 16.2893f)
                curveTo(5.044f, 16.284f, 5.0358f, 16.2793f, 5.0281f, 16.274f)
                curveTo(4.995f, 16.2505f, 4.9631f, 16.2264f, 4.9318f, 16.2018f)
                curveTo(4.9259f, 16.1971f, 4.9194f, 16.193f, 4.9135f, 16.1883f)
                curveTo(4.8828f, 16.1636f, 4.8533f, 16.1372f, 4.8238f, 16.1113f)
                curveTo(4.8178f, 16.106f, 4.8113f, 16.1008f, 4.8054f, 16.0955f)
                curveTo(4.7753f, 16.0679f, 4.7464f, 16.0391f, 4.7174f, 16.0097f)
                curveTo(4.7127f, 16.005f, 4.708f, 16.0009f, 4.7033f, 15.9962f)
                curveTo(4.6755f, 15.9669f, 4.6483f, 15.9369f, 4.6218f, 15.9064f)
                curveTo(4.6171f, 15.9011f, 4.6123f, 15.8964f, 4.6082f, 15.8911f)
                curveTo(4.5816f, 15.86f, 4.5568f, 15.8283f, 4.5326f, 15.796f)
                curveTo(4.5285f, 15.7907f, 4.5243f, 15.786f, 4.5202f, 15.7807f)
                curveTo(4.4977f, 15.7501f, 4.4765f, 15.719f, 4.4552f, 15.6873f)
                curveTo(4.4499f, 15.6797f, 4.4446f, 15.672f, 4.4393f, 15.6644f)
                curveTo(4.4198f, 15.6345f, 4.4015f, 15.6039f, 4.3838f, 15.5728f)
                curveTo(4.3779f, 15.5628f, 4.372f, 15.5534f, 4.3661f, 15.5434f)
                curveTo(4.3483f, 15.5123f, 4.3324f, 15.4806f, 4.3165f, 15.4489f)
                curveTo(4.3111f, 15.4383f, 4.3052f, 15.4283f, 4.3005f, 15.4183f)
                curveTo(4.2863f, 15.3896f, 4.2739f, 15.3596f, 4.2609f, 15.3302f)
                curveTo(4.255f, 15.3161f, 4.2485f, 15.3032f, 4.2426f, 15.2891f)
                curveTo(4.2291f, 15.2562f, 4.2172f, 15.2234f, 4.2054f, 15.1899f)
                curveTo(4.2013f, 15.1787f, 4.1966f, 15.1676f, 4.193f, 15.1564f)
                curveTo(4.1818f, 15.1235f, 4.1723f, 15.09f, 4.1629f, 15.0572f)
                curveTo(4.1594f, 15.0448f, 4.1552f, 15.0331f, 4.1517f, 15.0202f)
                curveTo(4.1434f, 14.9896f, 4.1369f, 14.9579f, 4.1298f, 14.9268f)
                curveTo(4.1263f, 14.9115f, 4.1221f, 14.8962f, 4.1192f, 14.881f)
                curveTo(4.1127f, 14.8481f, 4.108f, 14.8152f, 4.1027f, 14.7817f)
                curveTo(4.1003f, 14.7676f, 4.0974f, 14.7535f, 4.0956f, 14.7388f)
                curveTo(4.0832f, 14.6437f, 4.0767f, 14.5468f, 4.0767f, 14.4493f)
                verticalLineTo(8.0121f)
                curveTo(4.0767f, 7.1371f, 4.594f, 6.3378f, 5.3948f, 5.9755f)
                lineTo(10.7253f, 3.5635f)
                curveTo(11.0217f, 3.4296f, 11.3395f, 3.3626f, 11.6572f, 3.3626f)
                curveTo(11.9749f, 3.3626f, 12.2932f, 3.4296f, 12.5891f, 3.5635f)
                lineTo(17.9184f, 5.9755f)
                curveTo(18.7192f, 6.3378f, 19.2365f, 7.1371f, 19.2365f, 8.0121f)
                verticalLineTo(14.4493f)
                curveTo(19.2365f, 14.4981f, 19.2347f, 14.5462f, 19.2318f, 14.5944f)
                curveTo(19.2318f, 14.6014f, 19.23f, 14.6085f, 19.23f, 14.6155f)
                curveTo(19.2271f, 14.6566f, 19.2235f, 14.6972f, 19.2182f, 14.7377f)
                curveTo(19.217f, 14.7471f, 19.2147f, 14.7565f, 19.2135f, 14.7659f)
                curveTo(19.2082f, 14.804f, 19.2023f, 14.8416f, 19.1946f, 14.8792f)
                curveTo(19.1922f, 14.8898f, 19.1893f, 14.9004f, 19.1869f, 14.9115f)
                curveTo(19.1792f, 14.9473f, 19.1716f, 14.9832f, 19.1621f, 15.0184f)
                curveTo(19.1592f, 15.029f, 19.1556f, 15.0395f, 19.1527f, 15.0495f)
                curveTo(19.1426f, 15.0848f, 19.1326f, 15.1194f, 19.1214f, 15.1541f)
                curveTo(19.1178f, 15.164f, 19.1137f, 15.174f, 19.1101f, 15.184f)
                curveTo(19.0977f, 15.2187f, 19.0859f, 15.2533f, 19.0718f, 15.2868f)
                curveTo(19.0682f, 15.2956f, 19.0635f, 15.3044f, 19.0599f, 15.3132f)
                curveTo(19.0452f, 15.3479f, 19.0304f, 15.3825f, 19.0145f, 15.416f)
                curveTo(19.0115f, 15.4219f, 19.008f, 15.4277f, 19.005f, 15.4342f)
                curveTo(18.9873f, 15.47f, 18.969f, 15.5064f, 18.9489f, 15.5411f)
                curveTo(18.946f, 15.5464f, 18.9424f, 15.5511f, 18.9395f, 15.5563f)
                curveTo(18.9188f, 15.5922f, 18.8981f, 15.6274f, 18.8757f, 15.6615f)
                curveTo(18.8733f, 15.665f, 18.871f, 15.6685f, 18.8686f, 15.6715f)
                curveTo(18.845f, 15.7073f, 18.8208f, 15.7425f, 18.7954f, 15.7772f)
                curveTo(18.7924f, 15.7813f, 18.7889f, 15.7854f, 18.7853f, 15.7895f)
                curveTo(18.7599f, 15.823f, 18.7345f, 15.8559f, 18.7074f, 15.8876f)
                curveTo(18.705f, 15.8899f, 18.7032f, 15.8923f, 18.7009f, 15.8946f)
                curveTo(18.6725f, 15.9281f, 18.643f, 15.961f, 18.6129f, 15.9927f)
                curveTo(18.6099f, 15.9956f, 18.6064f, 15.9986f, 18.6034f, 16.0021f)
                curveTo(18.5733f, 16.0326f, 18.5432f, 16.0632f, 18.5113f, 16.0919f)
                curveTo(18.5078f, 16.0949f, 18.5042f, 16.0978f, 18.5013f, 16.1008f)
                curveTo(18.4694f, 16.1295f, 18.4369f, 16.1577f, 18.4038f, 16.1853f)
                curveTo(18.4003f, 16.1883f, 18.3961f, 16.1912f, 18.3926f, 16.1935f)
                curveTo(18.3589f, 16.2206f, 18.3253f, 16.2464f, 18.2898f, 16.2717f)
                curveTo(18.2857f, 16.2746f, 18.281f, 16.2775f, 18.2763f, 16.2805f)
                curveTo(18.2426f, 16.304f, 18.2083f, 16.3274f, 18.1729f, 16.3492f)
                curveTo(18.3377f, 16.3768f, 18.5042f, 16.4061f, 18.6713f, 16.4361f)
                curveTo(19.2501f, 15.9492f, 19.6009f, 15.2251f, 19.6009f, 14.4499f)
                verticalLineTo(8.0127f)
                curveTo(19.6009f, 6.9962f, 18.9997f, 6.0671f, 18.069f, 5.646f)
                lineTo(18.0702f, 5.6448f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(4.1047f, 14.7812f)
                curveTo(4.1024f, 14.7671f, 4.0994f, 14.753f, 4.0977f, 14.7383f)
                curveTo(4.0994f, 14.7524f, 4.1024f, 14.7665f, 4.1047f, 14.7812f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(4.1655f, 15.056f)
                curveTo(4.162f, 15.0436f, 4.1578f, 15.0319f, 4.1543f, 15.019f)
                curveTo(4.1578f, 15.0313f, 4.162f, 15.0436f, 4.1655f, 15.056f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(4.3849f, 15.5722f)
                curveTo(4.379f, 15.5622f, 4.3731f, 15.5528f, 4.3672f, 15.5428f)
                curveTo(4.3725f, 15.5528f, 4.379f, 15.5622f, 4.3849f, 15.5722f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(4.4554f, 15.6861f)
                curveTo(4.4501f, 15.6785f, 4.4448f, 15.6709f, 4.4394f, 15.6632f)
                curveTo(4.4442f, 15.6709f, 4.4501f, 15.6785f, 4.4554f, 15.6861f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(4.2625f, 15.3297f)
                curveTo(4.2565f, 15.3156f, 4.2501f, 15.3021f, 4.2441f, 15.2885f)
                curveTo(4.2501f, 15.3026f, 4.2565f, 15.3161f, 4.2625f, 15.3297f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(4.3187f, 15.4483f)
                curveTo(4.3134f, 15.4377f, 4.3075f, 15.4277f, 4.3027f, 15.4178f)
                curveTo(4.3081f, 15.4283f, 4.3134f, 15.4383f, 4.3187f, 15.4483f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(4.623f, 15.9058f)
                curveTo(4.6182f, 15.9005f, 4.6135f, 15.8958f, 4.6094f, 15.8905f)
                curveTo(4.6141f, 15.8958f, 4.6188f, 15.9005f, 4.623f, 15.9058f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(18.4058f, 16.1853f)
                curveTo(18.4022f, 16.1883f, 18.3981f, 16.1912f, 18.3945f, 16.1936f)
                curveTo(18.3981f, 16.1906f, 18.4022f, 16.1877f, 18.4058f, 16.1853f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(18.7094f, 15.8876f)
                curveTo(18.7094f, 15.8876f, 18.7054f, 15.8922f, 18.7031f, 15.8944f)
                curveTo(18.7054f, 15.8922f, 18.7071f, 15.8899f, 18.7094f, 15.8876f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(18.7971f, 15.7772f)
                curveTo(18.7942f, 15.7813f, 18.7907f, 15.7854f, 18.7871f, 15.7895f)
                curveTo(18.7901f, 15.7854f, 18.7936f, 15.7813f, 18.7971f, 15.7772f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(18.8782f, 15.6615f)
                curveTo(18.8758f, 15.665f, 18.8735f, 15.6685f, 18.8711f, 15.6715f)
                curveTo(18.8735f, 15.6679f, 18.8758f, 15.6644f, 18.8782f, 15.6615f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(18.6149f, 15.9927f)
                curveTo(18.612f, 15.9956f, 18.6084f, 15.9986f, 18.6055f, 16.0021f)
                curveTo(18.6084f, 15.9992f, 18.612f, 15.9962f, 18.6149f, 15.9927f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(18.2773f, 16.2804f)
                curveTo(18.2815f, 16.2775f, 18.2862f, 16.2746f, 18.2909f, 16.2716f)
                curveTo(18.2868f, 16.2746f, 18.2821f, 16.2775f, 18.2773f, 16.2804f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(18.512f, 16.0919f)
                curveTo(18.5084f, 16.0949f, 18.5049f, 16.0978f, 18.502f, 16.1008f)
                curveTo(18.5055f, 16.0978f, 18.509f, 16.0949f, 18.512f, 16.0919f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(19.1952f, 14.8792f)
                curveTo(19.1928f, 14.8898f, 19.1899f, 14.9004f, 19.1875f, 14.9115f)
                curveTo(19.1899f, 14.9009f, 19.1928f, 14.8904f, 19.1952f, 14.8792f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(19.1637f, 15.0184f)
                curveTo(19.1608f, 15.029f, 19.1572f, 15.0395f, 19.1543f, 15.0495f)
                curveTo(19.1572f, 15.039f, 19.1608f, 15.029f, 19.1637f, 15.0184f)
                close()
            }
            path(fill = SolidColor(Color(0xFF007870)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(19.0153f, 15.4154f)
                curveTo(19.0124f, 15.4213f, 19.0088f, 15.4272f, 19.0059f, 15.4336f)
                curveTo(19.0088f, 15.4277f, 19.0124f, 15.4219f, 19.0153f, 15.4154f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFD700)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.1098f, 18.3336f)
                curveTo(13.0519f, 18.3424f, 12.994f, 18.3512f, 12.9355f, 18.3594f)
                curveTo(12.9881f, 18.3447f, 13.0466f, 18.3359f, 13.1098f, 18.3336f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFD215)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(6.8879f, 19.8458f)
                curveTo(5.9447f, 19.9052f, 5.1374f, 20.3603f, 4.6626f, 20.9881f)
                curveTo(4.6478f, 21.0045f, 4.6248f, 21.004f, 4.6177f, 20.9863f)
                curveTo(4.4553f, 20.3345f, 4.4104f, 19.6391f, 4.4925f, 18.9385f)
                curveTo(4.4925f, 18.9309f, 4.486f, 18.9256f, 4.4766f, 18.9238f)
                curveTo(4.0331f, 18.7852f, 3.5423f, 18.7095f, 3.0208f, 18.7171f)
                curveTo(2.9925f, 18.72f, 2.9931f, 18.693f, 3.0226f, 18.6648f)
                curveTo(3.5771f, 18.1316f, 4.2457f, 17.7146f, 4.9892f, 17.4503f)
                curveTo(5.2461f, 18.1709f, 5.4977f, 18.8927f, 5.7439f, 19.6156f)
                curveTo(5.7487f, 19.6297f, 5.7681f, 19.6374f, 5.7882f, 19.6338f)
                curveTo(5.7918f, 19.6338f, 6.3959f, 19.5281f, 6.8506f, 19.4659f)
                curveTo(6.869f, 19.6561f, 6.8873f, 19.8464f, 6.8873f, 19.8464f)
                lineTo(6.8879f, 19.8458f)
                close()
                moveTo(20.2937f, 18.6642f)
                curveTo(19.7392f, 18.1304f, 19.0706f, 17.7134f, 18.3265f, 17.4497f)
                curveTo(18.0696f, 18.1703f, 17.8175f, 18.8921f, 17.5712f, 19.615f)
                curveTo(17.5665f, 19.6291f, 17.547f, 19.6368f, 17.5269f, 19.6332f)
                curveTo(17.524f, 19.6332f, 16.9127f, 19.5264f, 16.4651f, 19.4659f)
                curveTo(16.4491f, 19.6432f, 16.4285f, 19.8458f, 16.4285f, 19.8458f)
                curveTo(17.3716f, 19.9052f, 18.1789f, 20.3603f, 18.6537f, 20.9881f)
                curveTo(18.6685f, 21.0045f, 18.6915f, 21.004f, 18.6986f, 20.9863f)
                curveTo(18.861f, 20.3345f, 18.9059f, 19.6391f, 18.8238f, 18.9385f)
                curveTo(18.8238f, 18.9309f, 18.8303f, 18.9256f, 18.8397f, 18.9238f)
                curveTo(19.2833f, 18.7852f, 19.774f, 18.7095f, 20.2955f, 18.7171f)
                curveTo(20.3238f, 18.72f, 20.3232f, 18.693f, 20.2937f, 18.6648f)
                verticalLineTo(18.6642f)
                close()
            }
            path(fill = SolidColor(Color(0xFFE09B00)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(17.5331f, 19.6338f)
                lineTo(16.4288f, 19.8458f)
                lineTo(16.4654f, 19.4659f)
                curveTo(16.8173f, 19.5158f, 17.1835f, 19.5733f, 17.5331f, 19.6344f)
                verticalLineTo(19.6338f)
                close()
                moveTo(5.7832f, 19.6338f)
                lineTo(6.8882f, 19.8458f)
                lineTo(6.8515f, 19.4653f)
                curveTo(6.4996f, 19.5152f, 6.1328f, 19.5727f, 5.7832f, 19.6338f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFEC00)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(5.7514f, 19.6338f)
                lineTo(4.6269f, 16.4431f)
                curveTo(8.7963f, 15.6896f, 13.1612f, 15.4442f, 18.6907f, 16.4384f)
                lineTo(17.5645f, 19.6338f)
                curveTo(13.711f, 19.0207f, 10.1883f, 18.8968f, 5.7508f, 19.6338f)
                horizontalLineTo(5.7514f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFA320A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(16.3289f, 9.4662f)
                curveTo(16.3401f, 9.5209f, 16.3466f, 9.5778f, 16.3466f, 9.636f)
                curveTo(16.3466f, 9.6947f, 16.3413f, 9.7522f, 16.3301f, 9.808f)
                curveTo(16.7441f, 9.8738f, 17.0612f, 10.2285f, 17.0636f, 10.659f)
                curveTo(17.0636f, 10.686f, 17.0624f, 10.7124f, 17.06f, 10.7389f)
                curveTo(17.2709f, 10.7665f, 17.4581f, 10.8692f, 17.5939f, 11.019f)
                curveTo(16.9992f, 11.9175f, 14.5708f, 12.6011f, 11.6593f, 12.6176f)
                curveTo(8.3261f, 12.6364f, 5.6148f, 11.7737f, 5.5635f, 10.6854f)
                curveTo(5.5705f, 10.6778f, 5.5776f, 10.669f, 5.5853f, 10.6613f)
                curveTo(5.5339f, 10.5738f, 5.4985f, 10.4769f, 5.4802f, 10.3736f)
                curveTo(5.1613f, 10.6749f, 4.9682f, 10.918f, 5.0018f, 11.2715f)
                curveTo(5.0036f, 11.295f, 5.3503f, 13.7722f, 5.7005f, 16.2646f)
                curveTo(5.8717f, 16.2376f, 6.0442f, 16.2123f, 6.2166f, 16.1871f)
                lineTo(5.7843f, 12.8959f)
                curveTo(6.1918f, 13.2401f, 6.7446f, 13.4797f, 7.3115f, 13.6612f)
                lineTo(7.5448f, 16.0162f)
                curveTo(7.8685f, 15.9803f, 8.195f, 15.948f, 8.524f, 15.9199f)
                lineTo(8.3645f, 13.9143f)
                curveTo(9.2392f, 14.0999f, 10.2656f, 14.2068f, 11.0587f, 14.2297f)
                lineTo(11.0776f, 15.7901f)
                curveTo(11.4609f, 15.7842f, 11.8489f, 15.7848f, 12.2422f, 15.7912f)
                lineTo(12.2611f, 14.2297f)
                curveTo(13.0542f, 14.2068f, 14.0806f, 14.0999f, 14.9552f, 13.9143f)
                lineTo(14.7958f, 15.9222f)
                curveTo(15.1182f, 15.9498f, 15.4448f, 15.9821f, 15.7749f, 16.0185f)
                lineTo(16.0088f, 13.6612f)
                curveTo(16.5758f, 13.4803f, 17.1285f, 13.2401f, 17.536f, 12.8959f)
                lineTo(17.1037f, 16.1853f)
                curveTo(17.2744f, 16.2094f, 17.4474f, 16.2364f, 17.6199f, 16.2634f)
                curveTo(17.9695f, 13.7716f, 18.3167f, 11.2944f, 18.3179f, 11.2709f)
                curveTo(18.3853f, 10.5633f, 17.5531f, 9.9214f, 16.3289f, 9.4651f)
                verticalLineTo(9.4662f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(7.3124f, 13.6618f)
                curveTo(6.7454f, 13.4809f, 6.1926f, 13.2407f, 5.7852f, 12.8965f)
                lineTo(6.2175f, 16.1877f)
                curveTo(6.6562f, 16.1242f, 7.0986f, 16.0667f, 7.5456f, 16.0168f)
                lineTo(7.3124f, 13.6618f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(11.0594f, 14.2303f)
                curveTo(10.2663f, 14.2074f, 9.2399f, 14.1005f, 8.3652f, 13.9149f)
                lineTo(8.5247f, 15.9204f)
                curveTo(9.3562f, 15.8494f, 10.2048f, 15.8036f, 11.0783f, 15.7901f)
                lineTo(11.0594f, 14.2297f)
                verticalLineTo(14.2303f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(14.9552f, 13.9149f)
                curveTo(14.0806f, 14.1005f, 13.0542f, 14.2074f, 12.2611f, 14.2303f)
                lineTo(12.2422f, 15.7918f)
                curveTo(13.0684f, 15.8053f, 13.9182f, 15.8482f, 14.7958f, 15.9228f)
                lineTo(14.9552f, 13.9149f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(17.5345f, 12.8965f)
                curveTo(17.127f, 13.2407f, 16.5742f, 13.4803f, 16.0073f, 13.6618f)
                lineTo(15.7734f, 16.0191f)
                curveTo(16.2093f, 16.0673f, 16.651f, 16.1219f, 17.1022f, 16.1865f)
                lineTo(17.5345f, 12.8971f)
                verticalLineTo(12.8965f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFD700)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(5.565f, 10.686f)
                curveTo(5.6164f, 11.7742f, 8.3277f, 12.637f, 11.6609f, 12.6182f)
                curveTo(14.5718f, 12.6017f, 17.0002f, 11.9181f, 17.5955f, 11.0196f)
                curveTo(17.4596f, 10.8698f, 17.2724f, 10.7671f, 17.0616f, 10.7395f)
                curveTo(17.0639f, 10.713f, 17.0651f, 10.6866f, 17.0651f, 10.6596f)
                curveTo(17.0628f, 10.2291f, 16.745f, 9.8744f, 16.3316f, 9.8086f)
                curveTo(16.3429f, 9.7528f, 16.3482f, 9.6953f, 16.3482f, 9.6365f)
                curveTo(16.3452f, 9.1573f, 15.9525f, 8.7709f, 15.4706f, 8.7738f)
                curveTo(15.4594f, 8.7738f, 15.4493f, 8.775f, 15.4381f, 8.7756f)
                curveTo(15.4688f, 8.6857f, 15.4865f, 8.59f, 15.486f, 8.4902f)
                curveTo(15.483f, 8.011f, 15.0903f, 7.6245f, 14.6084f, 7.6275f)
                curveTo(14.4979f, 7.628f, 14.3922f, 7.6498f, 14.2954f, 7.688f)
                curveTo(14.1578f, 7.4296f, 13.8926f, 7.2498f, 13.5843f, 7.2293f)
                curveTo(13.5306f, 6.7988f, 13.1609f, 6.467f, 12.7138f, 6.4693f)
                curveTo(12.4327f, 6.4711f, 12.1841f, 6.605f, 12.0252f, 6.8117f)
                curveTo(11.8646f, 6.6379f, 11.6343f, 6.5287f, 11.378f, 6.5304f)
                curveTo(10.8961f, 6.5334f, 10.5075f, 6.9239f, 10.5104f, 7.4031f)
                curveTo(10.5104f, 7.4947f, 10.5258f, 7.5828f, 10.553f, 7.6656f)
                curveTo(10.3563f, 7.702f, 10.1833f, 7.8036f, 10.0569f, 7.9475f)
                curveTo(9.9565f, 7.5758f, 9.6151f, 7.3027f, 9.21f, 7.305f)
                curveTo(8.8315f, 7.3074f, 8.5114f, 7.5493f, 8.3927f, 7.8853f)
                curveTo(8.0708f, 8.0145f, 7.8446f, 8.3287f, 7.847f, 8.6945f)
                curveTo(7.847f, 8.7756f, 7.86f, 8.8543f, 7.8812f, 8.9289f)
                curveTo(7.7726f, 8.8819f, 7.6533f, 8.8555f, 7.5269f, 8.856f)
                curveTo(7.1466f, 8.8584f, 6.8259f, 9.1021f, 6.7078f, 9.4398f)
                curveTo(6.595f, 9.3875f, 6.4692f, 9.3582f, 6.3363f, 9.3588f)
                curveTo(5.8544f, 9.3617f, 5.4658f, 9.7522f, 5.4688f, 10.2315f)
                curveTo(5.47f, 10.3894f, 5.5136f, 10.5368f, 5.5887f, 10.6643f)
                curveTo(5.581f, 10.6719f, 5.5745f, 10.6801f, 5.5668f, 10.6884f)
                lineTo(5.565f, 10.686f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFD700)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.1098f, 18.3418f)
                curveTo(13.0519f, 18.3506f, 12.994f, 18.3594f, 12.9355f, 18.3676f)
                curveTo(12.9881f, 18.353f, 13.0466f, 18.3441f, 13.1098f, 18.3418f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFE600)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(5.7514f, 19.6421f)
                lineTo(4.6269f, 16.4514f)
                curveTo(8.7963f, 15.6979f, 13.1612f, 15.4524f, 18.6907f, 16.4467f)
                lineTo(17.5645f, 19.6421f)
                curveTo(13.711f, 19.0289f, 10.1883f, 18.905f, 5.7508f, 19.6421f)
                horizontalLineTo(5.7514f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFA320A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(12.0001f, 13.6383f)
                curveTo(11.4084f, 13.6383f, 10.9271f, 13.3675f, 10.6448f, 12.9212f)
                verticalLineTo(13.3529f)
                curveTo(10.6448f, 13.4862f, 10.5361f, 13.5948f, 10.4015f, 13.5948f)
                horizontalLineTo(9.5085f)
                curveTo(9.3745f, 13.5948f, 9.2652f, 13.4868f, 9.2652f, 13.3529f)
                verticalLineTo(12.4731f)
                horizontalLineTo(9.0768f)
                verticalLineTo(13.3529f)
                curveTo(9.0768f, 13.4862f, 8.9682f, 13.5948f, 8.8335f, 13.5948f)
                horizontalLineTo(7.9406f)
                curveTo(7.8065f, 13.5948f, 7.6973f, 13.4868f, 7.6973f, 13.3529f)
                verticalLineTo(10.4652f)
                curveTo(7.6973f, 10.3319f, 7.8059f, 10.2232f, 7.9406f, 10.2232f)
                horizontalLineTo(8.8335f)
                curveTo(8.9676f, 10.2232f, 9.0768f, 10.3313f, 9.0768f, 10.4652f)
                verticalLineTo(11.2886f)
                horizontalLineTo(9.2652f)
                verticalLineTo(10.4652f)
                curveTo(9.2652f, 10.3319f, 9.3739f, 10.2232f, 9.5085f, 10.2232f)
                horizontalLineTo(10.4015f)
                curveTo(10.5355f, 10.2232f, 10.6448f, 10.3313f, 10.6448f, 10.4652f)
                verticalLineTo(10.8863f)
                curveTo(10.9247f, 10.4429f, 11.4043f, 10.1798f, 12.0043f, 10.1798f)
                curveTo(12.4425f, 10.1798f, 12.7974f, 10.3066f, 13.085f, 10.5674f)
                verticalLineTo(10.4646f)
                curveTo(13.085f, 10.3313f, 13.1937f, 10.2227f, 13.3283f, 10.2227f)
                horizontalLineTo(15.3746f)
                curveTo(15.5087f, 10.2227f, 15.6179f, 10.3307f, 15.6179f, 10.4646f)
                verticalLineTo(11.1576f)
                curveTo(15.6179f, 11.2909f, 15.5093f, 11.3996f, 15.3746f, 11.3996f)
                horizontalLineTo(15.0173f)
                verticalLineTo(13.3529f)
                curveTo(15.0173f, 13.4862f, 14.9087f, 13.5948f, 14.774f, 13.5948f)
                horizontalLineTo(13.9295f)
                curveTo(13.7955f, 13.5948f, 13.6862f, 13.4868f, 13.6862f, 13.3529f)
                verticalLineTo(12.4931f)
                lineTo(13.5321f, 12.5113f)
                curveTo(13.3218f, 13.1966f, 12.7502f, 13.6383f, 12.0001f, 13.6383f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(7.9414f, 10.4646f)
                horizontalLineTo(8.8343f)
                verticalLineTo(11.53f)
                horizontalLineTo(9.5094f)
                verticalLineTo(10.4646f)
                horizontalLineTo(10.4023f)
                verticalLineTo(13.3529f)
                horizontalLineTo(9.5094f)
                verticalLineTo(12.2312f)
                horizontalLineTo(8.8343f)
                verticalLineTo(13.3529f)
                horizontalLineTo(7.9414f)
                verticalLineTo(10.4646f)
                close()
                moveTo(12.0039f, 10.4218f)
                curveTo(12.4132f, 10.4218f, 12.7268f, 10.5427f, 12.9837f, 10.807f)
                curveTo(13.2494f, 11.0842f, 13.3758f, 11.4307f, 13.3758f, 11.9023f)
                curveTo(13.3758f, 12.7856f, 12.8142f, 13.3963f, 11.9998f, 13.3963f)
                curveTo(11.1854f, 13.3963f, 10.6149f, 12.7991f, 10.6149f, 11.9111f)
                curveTo(10.6149f, 11.0231f, 11.1677f, 10.4218f, 12.0039f, 10.4218f)
                close()
                moveTo(12.3825f, 12.314f)
                curveTo(12.3996f, 12.1971f, 12.4173f, 11.9892f, 12.4173f, 11.8336f)
                curveTo(12.4173f, 11.3226f, 12.2868f, 11.076f, 12.008f, 11.076f)
                curveTo(11.7293f, 11.076f, 11.5728f, 11.3749f, 11.5728f, 11.9375f)
                curveTo(11.5728f, 12.5001f, 11.7122f, 12.7427f, 11.9951f, 12.7427f)
                curveTo(12.2171f, 12.7427f, 12.3346f, 12.6082f, 12.3825f, 12.314f)
                close()
                moveTo(13.9286f, 11.1576f)
                horizontalLineTo(13.3274f)
                verticalLineTo(10.4646f)
                horizontalLineTo(15.3737f)
                verticalLineTo(11.1576f)
                horizontalLineTo(14.7731f)
                verticalLineTo(13.3529f)
                horizontalLineTo(13.9286f)
                verticalLineTo(11.1576f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(15.254f, 15.968f)
                curveTo(15.2055f, 15.6209f, 14.9859f, 15.4107f, 14.6551f, 15.4107f)
                curveTo(14.566f, 15.4107f, 14.4762f, 15.4389f, 14.4f, 15.4806f)
                curveTo(14.3782f, 15.46f, 14.3486f, 15.4471f, 14.3162f, 15.4471f)
                horizontalLineTo(13.7982f)
                curveTo(13.7687f, 15.4471f, 13.7415f, 15.4577f, 13.7209f, 15.4747f)
                curveTo(13.6961f, 15.4324f, 13.6671f, 15.3919f, 13.634f, 15.3537f)
                curveTo(13.4982f, 15.1963f, 13.3057f, 15.1165f, 13.0606f, 15.1165f)
                curveTo(12.8155f, 15.1165f, 12.6324f, 15.1946f, 12.4848f, 15.3479f)
                curveTo(12.4435f, 15.3901f, 12.4074f, 15.4354f, 12.3779f, 15.4847f)
                verticalLineTo(15.0906f)
                horizontalLineTo(11.5653f)
                curveTo(11.498f, 15.0906f, 11.4436f, 15.1447f, 11.4436f, 15.2116f)
                verticalLineTo(15.4448f)
                horizontalLineTo(11.4301f)
                curveTo(11.4123f, 15.4448f, 11.247f, 15.4436f, 11.247f, 15.4436f)
                verticalLineTo(15.2104f)
                curveTo(11.247f, 15.1435f, 11.1926f, 15.0895f, 11.1253f, 15.0895f)
                horizontalLineTo(10.6074f)
                curveTo(10.5401f, 15.0895f, 10.4857f, 15.1435f, 10.4857f, 15.2104f)
                verticalLineTo(15.4436f)
                horizontalLineTo(10.4722f)
                curveTo(10.4048f, 15.4436f, 10.3505f, 15.4976f, 10.3505f, 15.5646f)
                verticalLineTo(15.6644f)
                curveTo(10.2489f, 15.5528f, 10.1107f, 15.4729f, 9.9412f, 15.4342f)
                curveTo(9.8538f, 15.4172f, 9.7794f, 15.4095f, 9.7062f, 15.4095f)
                curveTo(9.6253f, 15.4095f, 9.5438f, 15.4195f, 9.4375f, 15.4424f)
                curveTo(9.2893f, 15.4812f, 9.1652f, 15.5511f, 9.0696f, 15.6456f)
                curveTo(9.0199f, 15.2615f, 8.7152f, 15.036f, 8.2339f, 15.036f)
                horizontalLineTo(7.5181f)
                curveTo(7.4508f, 15.036f, 7.3965f, 15.09f, 7.3965f, 15.157f)
                verticalLineTo(16.0367f)
                curveTo(9.8273f, 15.756f, 12.388f, 15.6891f, 15.2534f, 15.9669f)
                lineTo(15.254f, 15.968f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.7118f, 15.9093f)
                curveTo(13.7118f, 15.7225f, 13.6551f, 15.5646f, 13.5423f, 15.4336f)
                curveTo(13.4295f, 15.3026f, 13.2683f, 15.2386f, 13.0616f, 15.2386f)
                curveTo(12.8549f, 15.2386f, 12.6989f, 15.3026f, 12.5726f, 15.4336f)
                curveTo(12.4462f, 15.5616f, 12.3848f, 15.7272f, 12.3848f, 15.9334f)
                curveTo(12.3848f, 16.1395f, 12.4521f, 16.3075f, 12.5861f, 16.4279f)
                curveTo(12.7202f, 16.5483f, 12.8897f, 16.6093f, 13.094f, 16.6093f)
                curveTo(13.2984f, 16.6093f, 13.5175f, 16.5729f, 13.6917f, 16.3885f)
                lineTo(13.3746f, 16.0919f)
                curveTo(13.3078f, 16.1794f, 13.2151f, 16.1953f, 13.0887f, 16.1953f)
                curveTo(12.9812f, 16.1953f, 12.8655f, 16.1366f, 12.8413f, 16.032f)
                horizontalLineTo(13.7094f)
                lineTo(13.7124f, 15.9093f)
                horizontalLineTo(13.7118f)
                close()
                moveTo(12.8413f, 15.7819f)
                curveTo(12.8625f, 15.6832f, 12.9405f, 15.6145f, 13.0515f, 15.6145f)
                curveTo(13.1749f, 15.6145f, 13.244f, 15.6861f, 13.2529f, 15.7819f)
                horizontalLineTo(12.8413f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(16.2665f, 17.7275f)
                curveTo(16.2665f, 17.5408f, 16.2098f, 17.3828f, 16.097f, 17.2518f)
                curveTo(15.9842f, 17.1209f, 15.8229f, 17.0569f, 15.6162f, 17.0569f)
                curveTo(15.4095f, 17.0569f, 15.2536f, 17.1209f, 15.1273f, 17.2518f)
                curveTo(15.0009f, 17.3799f, 14.9395f, 17.5455f, 14.9395f, 17.7516f)
                curveTo(14.9395f, 17.9577f, 15.0068f, 18.1257f, 15.1408f, 18.2461f)
                curveTo(15.2749f, 18.3665f, 15.4444f, 18.4276f, 15.6487f, 18.4276f)
                curveTo(15.8531f, 18.4276f, 16.0722f, 18.3912f, 16.2464f, 18.2067f)
                lineTo(15.9292f, 17.9102f)
                curveTo(15.8625f, 17.9977f, 15.7698f, 18.0135f, 15.6434f, 18.0135f)
                curveTo(15.5359f, 18.0135f, 15.4202f, 17.9548f, 15.396f, 17.8509f)
                horizontalLineTo(16.2641f)
                lineTo(16.267f, 17.7281f)
                lineTo(16.2665f, 17.7275f)
                close()
                moveTo(15.396f, 17.6001f)
                curveTo(15.4172f, 17.5014f, 15.4952f, 17.4327f, 15.6062f, 17.4327f)
                curveTo(15.7296f, 17.4327f, 15.7987f, 17.5044f, 15.8076f, 17.6001f)
                horizontalLineTo(15.396f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.677f, 16.2634f)
                lineTo(8.6936f, 16.2534f)
                curveTo(8.8678f, 16.1489f, 8.9564f, 15.9833f, 8.9564f, 15.7619f)
                curveTo(8.9564f, 15.3784f, 8.6936f, 15.1582f, 8.2353f, 15.1582f)
                horizontalLineTo(7.5195f)
                verticalLineTo(16.8683f)
                horizontalLineTo(8.0404f)
                verticalLineTo(16.3134f)
                horizontalLineTo(8.1272f)
                lineTo(8.4538f, 16.8683f)
                horizontalLineTo(9.0402f)
                lineTo(8.6776f, 16.2634f)
                horizontalLineTo(8.677f)
                close()
                moveTo(8.1886f, 15.9175f)
                horizontalLineTo(8.0392f)
                verticalLineTo(15.5575f)
                horizontalLineTo(8.1886f)
                curveTo(8.3333f, 15.5575f, 8.4266f, 15.6256f, 8.4266f, 15.7314f)
                curveTo(8.4266f, 15.85f, 8.3398f, 15.9175f, 8.1886f, 15.9175f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.0709f, 17.0798f)
                curveTo(7.8979f, 17.0457f, 7.8022f, 17.0516f, 7.6428f, 17.0862f)
                curveTo(7.3558f, 17.162f, 7.1426f, 17.384f, 7.1426f, 17.7422f)
                curveTo(7.1426f, 18.1921f, 7.4892f, 18.4276f, 7.8696f, 18.4276f)
                curveTo(8.2499f, 18.4276f, 8.5777f, 18.1921f, 8.5777f, 17.7422f)
                curveTo(8.5777f, 17.3734f, 8.3698f, 17.1485f, 8.0709f, 17.0798f)
                close()
                moveTo(7.8601f, 17.9495f)
                curveTo(7.7343f, 17.9495f, 7.6221f, 17.855f, 7.6221f, 17.724f)
                curveTo(7.6221f, 17.593f, 7.7343f, 17.4961f, 7.8601f, 17.4961f)
                curveTo(7.9859f, 17.4961f, 8.0981f, 17.5901f, 8.0981f, 17.724f)
                curveTo(8.0981f, 17.8579f, 7.99f, 17.9495f, 7.8601f, 17.9495f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(10.7736f, 17.6406f)
                verticalLineTo(18.3935f)
                horizontalLineTo(10.2468f)
                verticalLineTo(17.6864f)
                curveTo(10.2468f, 17.5754f, 10.1989f, 17.519f, 10.105f, 17.519f)
                curveTo(10.0111f, 17.519f, 9.9923f, 17.5778f, 9.9863f, 17.5965f)
                curveTo(9.9704f, 17.6541f, 9.9674f, 17.687f, 9.9674f, 17.7569f)
                verticalLineTo(18.3929f)
                horizontalLineTo(9.4483f)
                verticalLineTo(17.6858f)
                curveTo(9.4483f, 17.5748f, 9.3999f, 17.5184f, 9.3036f, 17.5184f)
                curveTo(9.2493f, 17.5184f, 9.2139f, 17.5449f, 9.1997f, 17.5672f)
                curveTo(9.1897f, 17.5819f, 9.1826f, 17.6083f, 9.1778f, 17.6271f)
                curveTo(9.1684f, 17.6805f, 9.1684f, 17.6999f, 9.1684f, 17.7563f)
                verticalLineTo(18.3923f)
                horizontalLineTo(8.6641f)
                verticalLineTo(17.0897f)
                horizontalLineTo(9.1637f)
                verticalLineTo(17.2119f)
                curveTo(9.1985f, 17.1402f, 9.3338f, 17.0557f, 9.5233f, 17.0557f)
                curveTo(9.6792f, 17.0557f, 9.8039f, 17.1091f, 9.883f, 17.2101f)
                lineTo(9.8972f, 17.2277f)
                lineTo(9.9125f, 17.2107f)
                curveTo(10.007f, 17.1044f, 10.1151f, 17.0592f, 10.2745f, 17.0592f)
                curveTo(10.4245f, 17.0592f, 10.7742f, 17.1156f, 10.7742f, 17.6394f)
                lineTo(10.7736f, 17.6406f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.151f, 17.9554f)
                horizontalLineTo(13.3151f)
                verticalLineTo(18.4017f)
                lineTo(13.285f, 18.4029f)
                curveTo(13.2325f, 18.4053f, 13.1616f, 18.4082f, 13.0884f, 18.4082f)
                curveTo(12.7405f, 18.4082f, 12.5415f, 18.2349f, 12.5415f, 17.899f)
                verticalLineTo(17.5396f)
                horizontalLineTo(12.4062f)
                verticalLineTo(17.0909f)
                horizontalLineTo(12.5415f)
                verticalLineTo(16.7362f)
                horizontalLineTo(13.06f)
                verticalLineTo(17.0909f)
                horizontalLineTo(13.2643f)
                verticalLineTo(17.5396f)
                horizontalLineTo(13.06f)
                verticalLineTo(17.8655f)
                curveTo(13.06f, 17.929f, 13.0872f, 17.956f, 13.151f, 17.956f)
                verticalLineTo(17.9554f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(7.3141f, 17.1778f)
                horizontalLineTo(6.8688f)
                verticalLineTo(18.3935f)
                horizontalLineTo(6.3633f)
                verticalLineTo(17.1778f)
                horizontalLineTo(5.918f)
                verticalLineTo(16.6833f)
                horizontalLineTo(7.3141f)
                verticalLineTo(17.1778f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(12.1738f, 16.4314f)
                horizontalLineTo(12.338f)
                verticalLineTo(16.8771f)
                lineTo(12.3079f, 16.8783f)
                curveTo(12.2553f, 16.8807f, 12.1844f, 16.8836f, 12.1112f, 16.8836f)
                curveTo(11.7639f, 16.8836f, 11.5649f, 16.7104f, 11.5649f, 16.375f)
                verticalLineTo(16.0156f)
                horizontalLineTo(11.4297f)
                verticalLineTo(15.5675f)
                horizontalLineTo(11.5649f)
                verticalLineTo(15.2134f)
                horizontalLineTo(12.0829f)
                verticalLineTo(15.5675f)
                horizontalLineTo(12.2872f)
                verticalLineTo(16.0156f)
                horizontalLineTo(12.0829f)
                verticalLineTo(16.3415f)
                curveTo(12.0829f, 16.405f, 12.11f, 16.432f, 12.1738f, 16.432f)
                verticalLineTo(16.4314f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(10.6079f, 16.0144f)
                horizontalLineTo(10.4727f)
                verticalLineTo(15.5663f)
                horizontalLineTo(10.6079f)
                verticalLineTo(15.2122f)
                horizontalLineTo(11.1258f)
                verticalLineTo(15.5663f)
                horizontalLineTo(11.3302f)
                verticalLineTo(16.0144f)
                horizontalLineTo(11.1258f)
                verticalLineTo(16.3404f)
                curveTo(11.1258f, 16.4038f, 11.153f, 16.4308f, 11.2168f, 16.4308f)
                horizontalLineTo(11.3809f)
                verticalLineTo(16.8765f)
                lineTo(11.3508f, 16.8777f)
                curveTo(11.2983f, 16.8801f, 11.2274f, 16.883f, 11.1542f, 16.883f)
                curveTo(10.8069f, 16.883f, 10.6079f, 16.7098f, 10.6079f, 16.3744f)
                verticalLineTo(16.015f)
                verticalLineTo(16.0144f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(11.8051f, 17.0939f)
                verticalLineTo(17.243f)
                lineTo(11.7726f, 17.2072f)
                curveTo(11.6816f, 17.1085f, 11.5676f, 17.0569f, 11.4424f, 17.0569f)
                curveTo(11.3545f, 17.0569f, 11.2611f, 17.0833f, 11.1737f, 17.1338f)
                curveTo(10.9783f, 17.246f, 10.8619f, 17.4726f, 10.8613f, 17.7404f)
                curveTo(10.8613f, 18.0094f, 10.9777f, 18.2373f, 11.1737f, 18.35f)
                curveTo(11.2611f, 18.4006f, 11.355f, 18.427f, 11.4442f, 18.427f)
                curveTo(11.5688f, 18.427f, 11.6822f, 18.3759f, 11.7726f, 18.2802f)
                lineTo(11.8051f, 18.2455f)
                verticalLineTo(18.3917f)
                horizontalLineTo(12.307f)
                verticalLineTo(17.0939f)
                horizontalLineTo(11.8051f)
                close()
                moveTo(11.743f, 17.8902f)
                curveTo(11.7035f, 17.9348f, 11.6474f, 17.9577f, 11.5806f, 17.9577f)
                curveTo(11.4732f, 17.9577f, 11.3639f, 17.8837f, 11.3639f, 17.7422f)
                curveTo(11.3639f, 17.6858f, 11.3834f, 17.6336f, 11.4182f, 17.5942f)
                curveTo(11.4578f, 17.5496f, 11.5139f, 17.5267f, 11.5806f, 17.5267f)
                curveTo(11.6887f, 17.5267f, 11.7974f, 17.6007f, 11.7974f, 17.7422f)
                curveTo(11.7974f, 17.7986f, 11.7779f, 17.8509f, 11.743f, 17.8902f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(14.3221f, 16.8683f)
                horizontalLineTo(13.7988f)
                verticalLineTo(15.5687f)
                horizontalLineTo(14.3168f)
                verticalLineTo(15.719f)
                curveTo(14.3516f, 15.6403f, 14.5046f, 15.5317f, 14.6557f, 15.5317f)
                curveTo(14.9658f, 15.5317f, 15.1435f, 15.7449f, 15.1435f, 16.1172f)
                verticalLineTo(16.8683f)
                horizontalLineTo(14.6215f)
                verticalLineTo(16.1601f)
                curveTo(14.6215f, 16.0508f, 14.5742f, 15.9956f, 14.4809f, 15.9956f)
                curveTo(14.3876f, 15.9956f, 14.3227f, 16.0367f, 14.3227f, 16.2411f)
                verticalLineTo(16.8683f)
                horizontalLineTo(14.3221f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(17.4756f, 17.953f)
                curveTo(17.4756f, 18.0946f, 17.4183f, 18.212f, 17.3055f, 18.3025f)
                curveTo(17.1992f, 18.39f, 17.0587f, 18.4346f, 16.8898f, 18.4346f)
                curveTo(16.6701f, 18.4346f, 16.4923f, 18.3888f, 16.3329f, 18.2848f)
                lineTo(16.3027f, 18.2637f)
                lineTo(16.5106f, 17.9225f)
                curveTo(16.7492f, 18.0746f, 16.8726f, 18.0517f, 16.9199f, 18.0482f)
                curveTo(17.0232f, 18.0405f, 17.0344f, 17.9266f, 16.9488f, 17.9284f)
                curveTo(16.8833f, 17.9301f, 16.7533f, 17.9431f, 16.5614f, 17.8544f)
                curveTo(16.4268f, 17.7727f, 16.3393f, 17.677f, 16.3393f, 17.5202f)
                curveTo(16.3393f, 17.3845f, 16.396f, 17.273f, 16.5083f, 17.1896f)
                curveTo(16.6157f, 17.1091f, 16.7185f, 17.0651f, 16.872f, 17.058f)
                curveTo(17.0711f, 17.0486f, 17.2152f, 17.0921f, 17.3935f, 17.1831f)
                lineTo(17.4313f, 17.2078f)
                lineTo(17.2417f, 17.5184f)
                curveTo(17.0339f, 17.4356f, 16.9264f, 17.4133f, 16.8508f, 17.4274f)
                curveTo(16.7622f, 17.4439f, 16.7752f, 17.5296f, 16.8402f, 17.5378f)
                curveTo(16.9051f, 17.5466f, 17.0091f, 17.5184f, 17.2051f, 17.5877f)
                curveTo(17.3752f, 17.6553f, 17.4762f, 17.7651f, 17.4762f, 17.9524f)
                lineTo(17.4756f, 17.953f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.7071f, 16.9018f)
                curveTo(10.1111f, 16.9018f, 10.4253f, 16.6663f, 10.4253f, 16.217f)
                curveTo(10.4253f, 15.8482f, 10.2174f, 15.6233f, 9.9192f, 15.5546f)
                curveTo(9.7461f, 15.5205f, 9.6292f, 15.5264f, 9.4703f, 15.561f)
                curveTo(9.1833f, 15.6368f, 8.9707f, 15.8588f, 8.9707f, 16.217f)
                curveTo(8.9707f, 16.6663f, 9.3038f, 16.9018f, 9.7077f, 16.9018f)
                horizontalLineTo(9.7071f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFE600)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.3182f, 16.1912f)
                curveTo(9.2497f, 16.1636f, 9.209f, 16.1048f, 9.2409f, 16.0414f)
                curveTo(9.2645f, 15.9939f, 9.3348f, 15.9751f, 9.3885f, 15.9956f)
                curveTo(9.4529f, 16.0203f, 9.4629f, 16.0866f, 9.496f, 16.1142f)
                curveTo(9.5243f, 16.1377f, 9.5627f, 16.1407f, 9.5881f, 16.1248f)
                curveTo(9.607f, 16.1131f, 9.6129f, 16.0866f, 9.6058f, 16.0626f)
                curveTo(9.5964f, 16.0308f, 9.5716f, 16.0109f, 9.5474f, 15.9909f)
                curveTo(9.5043f, 15.9563f, 9.4434f, 15.9263f, 9.4801f, 15.8312f)
                curveTo(9.5102f, 15.7531f, 9.5988f, 15.7507f, 9.5988f, 15.7507f)
                curveTo(9.6336f, 15.7466f, 9.6655f, 15.7572f, 9.6909f, 15.7801f)
                curveTo(9.7251f, 15.8106f, 9.7316f, 15.8511f, 9.7263f, 15.8946f)
                curveTo(9.721f, 15.9339f, 9.7068f, 15.9692f, 9.6997f, 16.0079f)
                curveTo(9.6915f, 16.0538f, 9.7157f, 16.0996f, 9.7623f, 16.1013f)
                curveTo(9.8238f, 16.1037f, 9.8421f, 16.0567f, 9.8497f, 16.0273f)
                curveTo(9.861f, 15.9839f, 9.8751f, 15.9433f, 9.9159f, 15.9181f)
                curveTo(9.9744f, 15.8817f, 10.0547f, 15.8899f, 10.0925f, 15.9598f)
                curveTo(10.122f, 16.015f, 10.1125f, 16.0913f, 10.0671f, 16.133f)
                curveTo(10.0464f, 16.1518f, 10.0222f, 16.1583f, 9.9956f, 16.1583f)
                curveTo(9.9578f, 16.1583f, 9.9194f, 16.1577f, 9.884f, 16.1753f)
                curveTo(9.8598f, 16.1877f, 9.8497f, 16.207f, 9.8497f, 16.2335f)
                curveTo(9.8497f, 16.2593f, 9.8633f, 16.2757f, 9.8852f, 16.2869f)
                curveTo(9.9259f, 16.3075f, 9.9714f, 16.3116f, 10.0157f, 16.3192f)
                curveTo(10.0801f, 16.3304f, 10.1362f, 16.3527f, 10.1728f, 16.412f)
                curveTo(10.1728f, 16.412f, 10.1734f, 16.4132f, 10.174f, 16.4138f)
                curveTo(10.2153f, 16.4836f, 10.1722f, 16.5852f, 10.0901f, 16.5894f)
                curveTo(9.9082f, 16.5987f, 9.8704f, 16.3914f, 9.799f, 16.3932f)
                curveTo(9.7683f, 16.3938f, 9.7446f, 16.4255f, 9.7553f, 16.4625f)
                curveTo(9.7612f, 16.4831f, 9.7771f, 16.5124f, 9.7872f, 16.5312f)
                curveTo(9.8232f, 16.5964f, 9.77f, 16.6698f, 9.708f, 16.6763f)
                curveTo(9.6053f, 16.6868f, 9.5621f, 16.6275f, 9.5651f, 16.5664f)
                curveTo(9.5681f, 16.4983f, 9.6259f, 16.4284f, 9.5669f, 16.3991f)
                curveTo(9.5043f, 16.3679f, 9.4535f, 16.4895f, 9.3938f, 16.5165f)
                curveTo(9.3395f, 16.5412f, 9.2645f, 16.5218f, 9.2379f, 16.4625f)
                curveTo(9.219f, 16.4208f, 9.2226f, 16.3398f, 9.3058f, 16.3092f)
                curveTo(9.3578f, 16.2898f, 9.4741f, 16.3345f, 9.4801f, 16.2781f)
                curveTo(9.4871f, 16.2135f, 9.3578f, 16.2076f, 9.3194f, 16.1924f)
                lineTo(9.3182f, 16.1912f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(14.8565f, 17.5901f)
                curveTo(14.9516f, 17.9807f, 14.6669f, 18.3084f, 14.2967f, 18.3976f)
                curveTo(13.9264f, 18.4863f, 13.5142f, 18.326f, 13.4191f, 17.9354f)
                curveTo(13.3523f, 17.66f, 13.4527f, 17.4362f, 13.6435f, 17.29f)
                curveTo(13.6618f, 17.2753f, 13.6842f, 17.2606f, 13.6996f, 17.2554f)
                curveTo(13.6045f, 17.2636f, 13.5466f, 17.2589f, 13.4888f, 17.2783f)
                curveTo(13.4823f, 17.2806f, 13.4764f, 17.2736f, 13.4799f, 17.2677f)
                curveTo(13.5419f, 17.1508f, 13.6842f, 17.0939f, 13.7852f, 17.1326f)
                curveTo(13.7167f, 17.0733f, 13.663f, 17.0257f, 13.663f, 17.0257f)
                lineTo(13.7569f, 16.9482f)
                curveTo(13.7569f, 16.9482f, 13.8171f, 17.0363f, 13.8608f, 17.1009f)
                curveTo(13.9181f, 16.9729f, 14.061f, 16.9371f, 14.1366f, 17.0075f)
                curveTo(14.1413f, 17.0116f, 14.1384f, 17.0193f, 14.1325f, 17.0199f)
                curveTo(14.0823f, 17.0275f, 14.064f, 17.0792f, 14.0681f, 17.1132f)
                lineTo(14.1112f, 17.108f)
                curveTo(14.4662f, 17.0627f, 14.7709f, 17.2383f, 14.8565f, 17.5901f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(17.7219f, 17.1767f)
                curveTo(17.7473f, 17.1649f, 17.7597f, 17.1455f, 17.7597f, 17.1173f)
                curveTo(17.7597f, 17.0739f, 17.7254f, 17.0527f, 17.6817f, 17.0527f)
                horizontalLineTo(17.5996f)
                verticalLineTo(17.2342f)
                horizontalLineTo(17.6492f)
                verticalLineTo(17.1843f)
                horizontalLineTo(17.674f)
                lineTo(17.7089f, 17.2342f)
                horizontalLineTo(17.765f)
                lineTo(17.7219f, 17.1761f)
                verticalLineTo(17.1767f)
                close()
                moveTo(17.6734f, 17.1402f)
                horizontalLineTo(17.6492f)
                verticalLineTo(17.0956f)
                horizontalLineTo(17.6734f)
                curveTo(17.6976f, 17.0956f, 17.71f, 17.1027f, 17.71f, 17.1173f)
                curveTo(17.71f, 17.132f, 17.6976f, 17.1402f, 17.6734f, 17.1402f)
                close()
            }
            path(fill = SolidColor(Color(0xFF005D7A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(17.815f, 17.0063f)
                curveTo(17.7766f, 16.967f, 17.7293f, 16.9476f, 17.6738f, 16.9476f)
                curveTo(17.6183f, 16.9476f, 17.5716f, 16.967f, 17.5333f, 17.0063f)
                curveTo(17.4955f, 17.0457f, 17.4766f, 17.0927f, 17.4766f, 17.1485f)
                curveTo(17.4766f, 17.2042f, 17.4955f, 17.2506f, 17.5333f, 17.2894f)
                curveTo(17.5716f, 17.3276f, 17.6183f, 17.3475f, 17.6738f, 17.3475f)
                curveTo(17.7293f, 17.3475f, 17.7766f, 17.3282f, 17.815f, 17.2894f)
                curveTo(17.8533f, 17.2501f, 17.8734f, 17.2031f, 17.8734f, 17.1485f)
                curveTo(17.8734f, 17.0938f, 17.8539f, 17.0457f, 17.815f, 17.0063f)
                close()
                moveTo(17.7766f, 17.2524f)
                curveTo(17.7488f, 17.2806f, 17.7146f, 17.2947f, 17.6738f, 17.2947f)
                curveTo(17.6331f, 17.2947f, 17.5994f, 17.2812f, 17.5716f, 17.2524f)
                curveTo(17.5439f, 17.2242f, 17.5303f, 17.1896f, 17.5303f, 17.1479f)
                curveTo(17.5303f, 17.1062f, 17.5439f, 17.0709f, 17.5716f, 17.0427f)
                curveTo(17.5994f, 17.014f, 17.6337f, 16.9993f, 17.6738f, 16.9993f)
                curveTo(17.714f, 16.9993f, 17.7488f, 17.014f, 17.7766f, 17.0427f)
                curveTo(17.8049f, 17.0709f, 17.8191f, 17.1068f, 17.8191f, 17.1479f)
                curveTo(17.8191f, 17.189f, 17.8055f, 17.2236f, 17.7766f, 17.2524f)
                close()
            }
            path(fill = SolidColor(Color(0xFFE09B00)), stroke = null, fillAlpha = 0.5f, strokeAlpha
                    = 0.5f, strokeLineWidth = 0.0f, strokeLineCap = Butt, strokeLineJoin = Miter,
                    strokeLineMiter = 4.0f, pathFillType = NonZero) {
                moveTo(18.3256f, 17.4497f)
                curveTo(18.0782f, 18.1445f, 17.8083f, 18.9185f, 17.5703f, 19.615f)
                lineTo(18.7077f, 17.6013f)
                curveTo(18.7077f, 17.6013f, 18.5223f, 17.519f, 18.3256f, 17.4497f)
                close()
            }
            path(fill = SolidColor(Color(0xFFE09B00)), stroke = null, fillAlpha = 0.5f, strokeAlpha
                    = 0.5f, strokeLineWidth = 0.0f, strokeLineCap = Butt, strokeLineJoin = Miter,
                    strokeLineMiter = 4.0f, pathFillType = NonZero) {
                moveTo(4.9883f, 17.4497f)
                curveTo(4.7852f, 17.5226f, 4.6074f, 17.6013f, 4.6074f, 17.6013f)
                lineTo(5.7431f, 19.6115f)
                curveTo(5.5057f, 18.9162f, 5.2358f, 18.1433f, 4.9883f, 17.4497f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.3502f, 6.3032f)
                lineTo(9.2061f, 6.0172f)
                lineTo(8.5476f, 6.3449f)
                lineTo(8.4153f, 6.0824f)
                lineTo(9.0407f, 5.7711f)
                lineTo(8.9019f, 5.4963f)
                lineTo(8.2771f, 5.8075f)
                lineTo(8.1578f, 5.5708f)
                lineTo(8.8163f, 5.2431f)
                lineTo(8.6722f, 4.9565f)
                lineTo(7.6582f, 5.461f)
                lineTo(8.3368f, 6.8082f)
                lineTo(9.3502f, 6.3032f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(10.4463f, 6.0207f)
                lineTo(10.8615f, 5.9291f)
                lineTo(10.428f, 5.4722f)
                lineTo(10.441f, 5.4622f)
                curveTo(10.6004f, 5.3371f, 10.6554f, 5.1762f, 10.6087f, 4.9695f)
                curveTo(10.5727f, 4.8092f, 10.4912f, 4.6952f, 10.3654f, 4.6318f)
                curveTo(10.2402f, 4.569f, 10.0784f, 4.5584f, 9.8847f, 4.6013f)
                lineTo(9.2988f, 4.7305f)
                lineTo(9.6272f, 6.2016f)
                lineTo(9.9892f, 6.1217f)
                lineTo(9.8853f, 5.6572f)
                lineTo(10.0636f, 5.6178f)
                lineTo(10.4451f, 6.0207f)
                horizontalLineTo(10.4463f)
                close()
                moveTo(9.8174f, 5.3465f)
                lineTo(9.7317f, 4.9636f)
                lineTo(9.9319f, 4.9195f)
                curveTo(10.1209f, 4.8779f, 10.2177f, 4.9166f, 10.2467f, 5.0452f)
                curveTo(10.2615f, 5.111f, 10.2485f, 5.1662f, 10.2089f, 5.2097f)
                curveTo(10.1705f, 5.2514f, 10.1061f, 5.2825f, 10.0175f, 5.3025f)
                lineTo(9.8174f, 5.3465f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(11.5738f, 4.4083f)
                lineTo(11.2031f, 4.4188f)
                lineTo(11.2465f, 5.9246f)
                lineTo(11.6172f, 5.914f)
                lineTo(11.5738f, 4.4083f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(12.2564f, 4.4209f)
                lineTo(12.0391f, 5.9126f)
                lineTo(12.4058f, 5.9654f)
                lineTo(12.4873f, 5.4052f)
                lineTo(13.118f, 5.4962f)
                lineTo(13.1623f, 5.192f)
                lineTo(12.5316f, 5.101f)
                lineTo(12.5771f, 4.7915f)
                lineTo(13.258f, 4.8896f)
                lineTo(13.3041f, 4.5718f)
                lineTo(12.2564f, 4.4209f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.8869f, 4.7427f)
                lineTo(13.4121f, 6.1738f)
                lineTo(13.7643f, 6.2893f)
                lineTo(14.2391f, 4.8583f)
                lineTo(13.8869f, 4.7427f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(14.8654f, 5.0957f)
                lineTo(14.1426f, 6.42f)
                lineTo(15.1389f, 6.958f)
                lineTo(15.293f, 6.6761f)
                lineTo(14.6457f, 6.3272f)
                lineTo(14.7863f, 6.0688f)
                lineTo(15.4005f, 6.4006f)
                lineTo(15.5481f, 6.1305f)
                lineTo(14.9339f, 5.7993f)
                lineTo(15.0609f, 5.5661f)
                lineTo(15.7082f, 5.9156f)
                lineTo(15.8617f, 5.6336f)
                lineTo(14.8654f, 5.0957f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(16.9069f, 7.4777f)
                curveTo(17.0864f, 7.2816f, 17.1596f, 7.0666f, 17.113f, 6.8717f)
                curveTo(17.0687f, 6.6884f, 16.9671f, 6.5269f, 16.8024f, 6.3772f)
                lineTo(16.4014f, 6.0136f)
                lineTo(15.3809f, 7.1271f)
                lineTo(15.7819f, 7.4907f)
                curveTo(15.9643f, 7.6563f, 16.4403f, 7.9863f, 16.9069f, 7.4777f)
                close()
                moveTo(16.3134f, 7.3891f)
                curveTo(16.2023f, 7.3879f, 16.0842f, 7.3315f, 15.9632f, 7.2217f)
                lineTo(15.8722f, 7.1389f)
                lineTo(16.4586f, 6.4993f)
                lineTo(16.5478f, 6.5804f)
                curveTo(16.7929f, 6.8029f, 16.8213f, 7.0208f, 16.6311f, 7.2281f)
                curveTo(16.5425f, 7.3245f, 16.448f, 7.3785f, 16.3488f, 7.3879f)
                curveTo(16.337f, 7.3891f, 16.3252f, 7.3896f, 16.3128f, 7.3896f)
                lineTo(16.3134f, 7.3891f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(6.9417f, 5.9496f)
                lineTo(7.3822f, 6.9832f)
                lineTo(6.4462f, 6.3554f)
                lineTo(6.1367f, 6.6091f)
                lineTo(7.472f, 7.4648f)
                lineTo(7.8405f, 7.163f)
                lineTo(7.2511f, 5.6959f)
                lineTo(6.9417f, 5.9496f)
                close()
            }
        }
        .build()
        return _rtVerifiedHotBadge!!
    }

private var _rtVerifiedHotBadge: ImageVector? = null
