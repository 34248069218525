package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GeoShape5: ImageVector
    get() {
        if (_geoShape5 != null) {
            return _geoShape5!!
        }
        _geoShape5 = Builder(name = "GeoShape5", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(7.01f, 21.89f)
                curveToRelative(0.0f, -1.743f, -0.035f, -3.49f, 0.026f, -5.233f)
                curveToRelative(0.016f, -0.421f, 0.244f, -0.949f, 0.56f, -1.226f)
                arcToRelative(577.357f, 577.357f, 0.0f, false, true, 8.21f, -7.074f)
                curveToRelative(0.308f, -0.26f, 0.73f, -0.44f, 1.13f, -0.534f)
                arcToRelative(309.25f, 309.25f, 0.0f, false, true, 7.956f, -1.771f)
                arcToRelative(2.855f, 2.855f, 0.0f, false, true, 1.581f, 0.144f)
                arcToRelative(460.314f, 460.314f, 0.0f, false, true, 10.052f, 4.39f)
                curveToRelative(0.418f, 0.188f, 0.844f, 0.618f, 1.016f, 1.035f)
                curveToRelative(1.118f, 2.731f, 2.175f, 5.486f, 3.23f, 8.242f)
                curveToRelative(0.136f, 0.355f, 0.207f, 0.757f, 0.21f, 1.14f)
                curveToRelative(0.015f, 3.308f, 0.0f, 6.616f, 0.019f, 9.925f)
                curveToRelative(0.004f, 0.619f, -0.174f, 1.063f, -0.669f, 1.476f)
                curveToRelative(-2.749f, 2.297f, -5.464f, 4.636f, -8.21f, 6.937f)
                curveToRelative(-0.311f, 0.26f, -0.721f, 0.456f, -1.118f, 0.56f)
                arcToRelative(361.148f, 361.148f, 0.0f, false, true, -8.078f, 2.043f)
                curveToRelative(-0.436f, 0.105f, -0.994f, 0.06f, -1.402f, -0.12f)
                arcToRelative(570.918f, 570.918f, 0.0f, false, true, -10.306f, -4.712f)
                curveToRelative(-0.367f, -0.172f, -0.726f, -0.568f, -0.876f, -0.943f)
                arcToRelative(442.547f, 442.547f, 0.0f, false, true, -3.136f, -8.085f)
                arcToRelative(3.381f, 3.381f, 0.0f, false, true, -0.19f, -1.14f)
                curveToRelative(-0.02f, -1.684f, -0.009f, -3.37f, -0.009f, -5.053f)
                horizontalLineToRelative(0.004f)
                close()
                moveTo(25.324f, 32.055f)
                curveToRelative(0.114f, -0.112f, 0.396f, -0.275f, 0.51f, -0.516f)
                arcToRelative(683.716f, 683.716f, 0.0f, false, false, 4.522f, -9.555f)
                curveToRelative(0.109f, -0.233f, 0.016f, -0.716f, -0.171f, -0.899f)
                arcToRelative(561.058f, 561.058f, 0.0f, false, false, -7.561f, -7.225f)
                curveToRelative(-0.168f, -0.157f, -0.63f, -0.216f, -0.835f, -0.105f)
                curveToRelative(-3.108f, 1.67f, -6.192f, 3.385f, -9.294f, 5.069f)
                curveToRelative(-0.418f, 0.227f, -0.517f, 0.457f, -0.426f, 0.927f)
                curveToRelative(0.662f, 3.422f, 1.274f, 6.855f, 1.944f, 10.275f)
                curveToRelative(0.052f, 0.264f, 0.389f, 0.642f, 0.633f, 0.675f)
                curveToRelative(3.48f, 0.472f, 6.966f, 0.89f, 10.677f, 1.352f)
                verticalLineToRelative(0.002f)
                close()
                moveTo(39.034f, 20.139f)
                curveToRelative(-1.042f, -2.667f, -2.008f, -5.19f, -3.024f, -7.69f)
                curveToRelative(-0.102f, -0.252f, -0.47f, -0.442f, -0.759f, -0.568f)
                curveToRelative(-2.084f, -0.927f, -4.179f, -1.833f, -6.27f, -2.745f)
                lineToRelative(-3.21f, -1.394f)
                curveToRelative(-0.866f, 1.575f, -1.71f, 3.066f, -2.49f, 4.59f)
                curveToRelative(-0.093f, 0.18f, 0.078f, 0.614f, 0.266f, 0.794f)
                curveToRelative(2.5f, 2.423f, 5.02f, 4.827f, 7.55f, 7.219f)
                curveToRelative(0.202f, 0.19f, 0.518f, 0.403f, 0.764f, 0.386f)
                curveToRelative(2.339f, -0.165f, 4.674f, -0.379f, 7.173f, -0.594f)
                verticalLineToRelative(0.002f)
                close()
                moveTo(24.39f, 7.89f)
                curveToRelative(-2.525f, 0.57f, -4.81f, 1.075f, -7.088f, 1.614f)
                curveToRelative(-0.276f, 0.066f, -0.53f, 0.283f, -0.756f, 0.475f)
                curveToRelative(-2.26f, 1.932f, -4.51f, 3.876f, -6.772f, 5.806f)
                curveToRelative(-0.223f, 0.19f, -0.507f, 0.31f, -0.848f, 0.514f)
                curveToRelative(0.767f, 0.586f, 1.304f, 0.935f, 1.767f, 1.362f)
                curveToRelative(0.485f, 0.446f, 0.882f, 0.413f, 1.444f, 0.097f)
                curveToRelative(3.078f, -1.725f, 6.184f, -3.403f, 9.273f, -5.11f)
                curveToRelative(0.254f, -0.141f, 0.526f, -0.34f, 0.666f, -0.58f)
                curveToRelative(0.752f, -1.303f, 1.462f, -2.628f, 2.316f, -4.18f)
                lineToRelative(-0.002f, 0.002f)
                close()
                moveTo(8.7f, 17.671f)
                lineToRelative(0.001f, 8.675f)
                curveToRelative(0.0f, 0.301f, -0.043f, 0.627f, 0.058f, 0.895f)
                curveToRelative(0.867f, 2.337f, 1.765f, 4.663f, 2.73f, 7.2f)
                curveToRelative(0.453f, -0.962f, 0.873f, -1.7f, 1.139f, -2.485f)
                curveToRelative(0.191f, -0.566f, 0.323f, -1.226f, 0.225f, -1.802f)
                curveToRelative(-0.529f, -3.082f, -1.253f, -6.136f, -1.669f, -9.23f)
                curveToRelative(-0.213f, -1.582f, -1.023f, -2.47f, -2.485f, -3.255f)
                verticalLineToRelative(0.003f)
                close()
                moveTo(12.312f, 35.504f)
                curveToRelative(1.464f, 0.805f, 2.755f, 1.783f, 4.538f, 1.734f)
                curveToRelative(3.66f, -0.097f, 7.325f, -0.029f, 10.987f, -0.027f)
                horizontalLineToRelative(1.248f)
                curveToRelative(-0.861f, -0.772f, -1.07f, -1.785f, -2.281f, -1.777f)
                curveToRelative(-4.548f, 0.031f, -9.096f, 0.012f, -13.644f, 0.016f)
                curveToRelative(-0.282f, 0.0f, -0.566f, 0.035f, -0.848f, 0.054f)
                close()
                moveTo(27.048f, 35.025f)
                curveToRelative(-0.7f, -1.116f, -1.623f, -1.674f, -2.77f, -1.674f)
                curveToRelative(-3.206f, 0.0f, -6.412f, 0.0f, -9.617f, -0.002f)
                curveToRelative(-1.611f, 0.0f, -1.621f, 0.008f, -2.045f, 1.676f)
                horizontalLineToRelative(14.432f)
                close()
                moveTo(27.602f, 30.805f)
                horizontalLineToRelative(11.56f)
                curveToRelative(0.305f, -1.679f, 0.279f, -1.714f, -1.309f, -1.714f)
                lineTo(29.6f, 29.091f)
                curveToRelative(-1.522f, 0.0f, -1.657f, 0.115f, -1.996f, 1.715f)
                close()
                moveTo(38.932f, 31.217f)
                curveToRelative(-3.877f, 0.0f, -7.54f, -0.006f, -11.203f, 0.004f)
                curveToRelative(-0.85f, 0.002f, -0.75f, 0.81f, -1.056f, 1.274f)
                curveToRelative(0.332f, 0.17f, 0.663f, 0.478f, 0.996f, 0.483f)
                curveToRelative(2.536f, 0.041f, 5.08f, -0.089f, 7.607f, 0.064f)
                curveToRelative(1.59f, 0.096f, 2.554f, -0.644f, 3.656f, -1.823f)
                verticalLineToRelative(-0.002f)
                close()
                moveTo(29.975f, 38.554f)
                curveToRelative(-0.284f, -0.854f, -0.752f, -1.015f, -1.408f, -1.011f)
                curveToRelative(-3.266f, 0.023f, -6.532f, 0.011f, -9.796f, 0.011f)
                horizontalLineToRelative(-2.065f)
                curveToRelative(0.124f, 0.173f, 0.158f, 0.268f, 0.225f, 0.301f)
                curveToRelative(1.797f, 0.893f, 3.574f, 1.802f, 5.713f, 1.507f)
                curveToRelative(0.809f, -0.11f, 1.657f, 0.064f, 2.467f, -0.036f)
                curveToRelative(1.616f, -0.202f, 3.217f, -0.505f, 4.866f, -0.774f)
                lineToRelative(-0.002f, 0.002f)
                close()
                moveTo(29.58f, 26.542f)
                horizontalLineToRelative(9.632f)
                verticalLineToRelative(-1.664f)
                horizontalLineToRelative(-7.47f)
                curveToRelative(-1.111f, 0.0f, -1.832f, 0.555f, -2.162f, 1.664f)
                close()
                moveTo(39.218f, 28.705f)
                verticalLineToRelative(-1.701f)
                horizontalLineToRelative(-8.64f)
                curveToRelative(-1.5f, 0.0f, -1.706f, 0.178f, -1.956f, 1.701f)
                horizontalLineToRelative(10.596f)
                close()
                moveTo(30.527f, 24.403f)
                curveToRelative(0.177f, 0.046f, 0.335f, 0.122f, 0.493f, 0.124f)
                curveToRelative(2.534f, 0.01f, 5.07f, -0.01f, 7.605f, 0.02f)
                curveToRelative(0.822f, 0.009f, 0.62f, -0.534f, 0.658f, -0.982f)
                curveToRelative(0.044f, -0.526f, -0.061f, -0.84f, -0.727f, -0.829f)
                curveToRelative(-1.985f, 0.035f, -3.976f, 0.093f, -5.954f, -0.021f)
                curveToRelative(-1.097f, -0.064f, -1.645f, 0.328f, -1.925f, 1.284f)
                curveToRelative(-0.034f, 0.115f, -0.083f, 0.225f, -0.152f, 0.404f)
                horizontalLineToRelative(0.002f)
                close()
                moveTo(36.367f, 33.429f)
                horizontalLineToRelative(-8.934f)
                curveToRelative(0.658f, 1.237f, 1.514f, 1.887f, 2.956f, 1.68f)
                arcToRelative(9.332f, 9.332f, 0.0f, false, true, 2.65f, 0.0f)
                curveToRelative(1.513f, 0.22f, 2.326f, -0.714f, 3.327f, -1.68f)
                close()
                moveTo(13.565f, 32.949f)
                horizontalLineToRelative(8.819f)
                curveToRelative(-2.493f, -0.448f, -5.001f, -0.71f, -7.494f, -1.08f)
                curveToRelative(-0.907f, -0.136f, -1.213f, 0.204f, -1.323f, 1.08f)
                horizontalLineToRelative(-0.002f)
                close()
                moveTo(39.234f, 21.243f)
                curveToRelative(-2.475f, 0.205f, -4.84f, 0.394f, -7.202f, 0.609f)
                curveToRelative(-0.18f, 0.015f, -0.34f, 0.215f, -0.73f, 0.475f)
                horizontalLineToRelative(7.932f)
                verticalLineToRelative(-1.086f)
                verticalLineToRelative(0.002f)
                close()
                moveTo(33.829f, 35.52f)
                horizontalLineToRelative(-4.577f)
                curveToRelative(1.313f, 2.362f, 2.591f, 2.263f, 4.577f, 0.0f)
                close()
                moveTo(21.291f, 39.73f)
                curveToRelative(1.114f, 0.912f, 1.976f, 0.359f, 2.847f, 0.0f)
                lineTo(21.29f, 39.73f)
                close()
            }
        }
        .build()
        return _geoShape5!!
    }

private var _geoShape5: ImageVector? = null
