package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GeoShape8: ImageVector
    get() {
        if (_geoShape8 != null) {
            return _geoShape8!!
        }
        _geoShape8 = Builder(name = "GeoShape8", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(17.334f, 6.0f)
                curveToRelative(2.385f, 0.428f, 4.542f, 0.81f, 6.698f, 1.203f)
                curveToRelative(3.546f, 0.643f, 7.09f, 1.305f, 10.64f, 1.927f)
                curveToRelative(0.588f, 0.102f, 0.911f, 0.38f, 1.129f, 0.939f)
                curveToRelative(2.01f, 5.14f, 4.032f, 10.276f, 6.067f, 15.406f)
                curveToRelative(0.225f, 0.566f, 0.162f, 1.006f, -0.186f, 1.496f)
                arcToRelative(3495.959f, 3495.959f, 0.0f, false, false, -10.157f, 14.356f)
                curveToRelative(-0.385f, 0.547f, -0.785f, 0.765f, -1.46f, 0.638f)
                arcTo(2021.957f, 2021.957f, 0.0f, false, false, 13.56f, 38.93f)
                curveToRelative(-0.585f, -0.105f, -0.903f, -0.39f, -1.126f, -0.945f)
                arcToRelative(3309.66f, 3309.66f, 0.0f, false, false, -6.285f, -15.606f)
                curveToRelative(-0.242f, -0.593f, -0.195f, -1.052f, 0.182f, -1.575f)
                curveToRelative(3.405f, -4.696f, 6.783f, -9.412f, 10.19f, -14.107f)
                curveToRelative(0.232f, -0.321f, 0.61f, -0.527f, 0.811f, -0.695f)
                lineTo(17.334f, 6.0f)
                close()
                moveTo(14.43f, 21.279f)
                lineTo(27.893f, 31.97f)
                curveToRelative(0.692f, -5.765f, 1.359f, -11.34f, 2.048f, -17.107f)
                curveToRelative(-5.244f, 2.17f, -10.3f, 4.261f, -15.511f, 6.415f)
                close()
                moveTo(27.107f, 32.759f)
                curveToRelative(-4.515f, -3.586f, -8.871f, -7.045f, -13.194f, -10.475f)
                curveToRelative(-0.123f, 0.075f, -0.167f, 0.089f, -0.167f, 0.102f)
                arcToRelative(5541.8f, 5541.8f, 0.0f, false, false, -0.037f, 14.123f)
                curveToRelative(0.0f, 0.51f, 0.223f, 0.682f, 0.724f, 0.505f)
                curveToRelative(1.363f, -0.478f, 2.736f, -0.918f, 4.104f, -1.377f)
                curveToRelative(2.785f, -0.935f, 5.571f, -1.87f, 8.57f, -2.88f)
                verticalLineToRelative(0.002f)
                close()
                moveTo(28.958f, 32.027f)
                lineTo(40.355f, 25.971f)
                lineTo(30.969f, 15.267f)
                lineTo(28.959f, 32.027f)
                close()
                moveTo(14.17f, 20.18f)
                lineToRelative(15.123f, -6.255f)
                lineToRelative(-11.565f, -6.246f)
                lineTo(14.17f, 20.18f)
                close()
                moveTo(12.56f, 21.743f)
                lineTo(7.834f, 22.127f)
                curveToRelative(1.548f, 3.848f, 3.056f, 7.586f, 4.56f, 11.326f)
                lineToRelative(0.166f, -0.058f)
                lineTo(12.56f, 21.74f)
                verticalLineToRelative(0.002f)
                close()
                moveTo(8.28f, 20.951f)
                curveToRelative(1.44f, -0.104f, 2.664f, -0.228f, 3.888f, -0.267f)
                curveToRelative(0.6f, -0.02f, 0.842f, -0.28f, 0.998f, -0.854f)
                curveToRelative(0.758f, -2.764f, 1.554f, -5.516f, 2.332f, -8.272f)
                curveToRelative(0.049f, -0.168f, 0.064f, -0.346f, 0.095f, -0.52f)
                lineToRelative(-0.13f, -0.055f)
                lineToRelative(-7.183f, 9.97f)
                verticalLineToRelative(-0.002f)
                close()
                moveTo(22.218f, 8.597f)
                lineToRelative(-0.063f, 0.205f)
                curveToRelative(2.67f, 1.443f, 5.34f, 2.89f, 8.019f, 4.32f)
                curveToRelative(0.143f, 0.077f, 0.425f, 0.08f, 0.535f, -0.016f)
                curveToRelative(0.87f, -0.761f, 1.713f, -1.555f, 2.715f, -2.477f)
                lineTo(22.22f, 8.597f)
                horizontalLineToRelative(-0.002f)
                close()
                moveTo(16.452f, 37.4f)
                curveToRelative(0.01f, 0.085f, 0.024f, 0.168f, 0.035f, 0.253f)
                curveToRelative(4.16f, 1.182f, 8.392f, 1.352f, 12.685f, 1.07f)
                curveToRelative(-0.21f, -1.11f, -0.815f, -1.665f, -1.812f, -1.666f)
                curveToRelative(-2.91f, 0.0f, -5.822f, -0.021f, -8.732f, 0.017f)
                curveToRelative(-0.727f, 0.01f, -1.452f, 0.213f, -2.176f, 0.328f)
                lineTo(16.452f, 37.4f)
                close()
                moveTo(19.451f, 36.406f)
                lineTo(19.512f, 36.641f)
                horizontalLineToRelative(9.049f)
                curveToRelative(-0.192f, -1.144f, -0.793f, -1.716f, -1.801f, -1.716f)
                curveToRelative(-0.662f, 0.0f, -1.35f, -0.124f, -1.98f, 0.025f)
                curveToRelative(-1.789f, 0.424f, -3.554f, 0.962f, -5.329f, 1.454f)
                verticalLineToRelative(0.002f)
                close()
                moveTo(35.366f, 32.872f)
                curveToRelative(-1.916f, 0.0f, -3.743f, -0.004f, -5.57f, 0.008f)
                curveToRelative(-0.132f, 0.0f, -0.312f, 0.07f, -0.386f, 0.172f)
                curveToRelative(-0.179f, 0.246f, -0.424f, 0.53f, -0.426f, 0.8f)
                curveToRelative(-0.004f, 0.426f, 0.19f, 0.767f, 0.747f, 0.743f)
                curveToRelative(1.063f, -0.044f, 2.145f, -0.133f, 3.19f, 0.018f)
                curveToRelative(1.356f, 0.197f, 1.863f, -0.668f, 2.445f, -1.743f)
                verticalLineToRelative(0.002f)
                close()
                moveTo(31.424f, 13.828f)
                curveToRelative(0.872f, 1.817f, 2.277f, 2.268f, 4.763f, 1.57f)
                curveToRelative(-0.306f, -0.547f, -0.54f, -1.45f, -0.888f, -1.5f)
                curveToRelative(-1.247f, -0.18f, -2.533f, -0.07f, -3.875f, -0.07f)
                close()
                moveTo(34.004f, 35.02f)
                horizontalLineToRelative(-4.83f)
                curveToRelative(0.327f, 0.66f, 0.492f, 1.51f, 0.845f, 1.605f)
                curveToRelative(0.809f, 0.217f, 1.76f, 0.25f, 2.55f, 0.0f)
                curveToRelative(0.526f, -0.165f, 0.873f, -0.943f, 1.435f, -1.607f)
                verticalLineToRelative(0.002f)
                close()
                moveTo(30.789f, 32.455f)
                horizontalLineToRelative(3.925f)
                curveToRelative(1.139f, 0.0f, 1.655f, -0.431f, 1.995f, -1.636f)
                curveToRelative(-2.126f, -0.467f, -4.003f, 0.163f, -5.92f, 1.636f)
                close()
                moveTo(37.107f, 17.56f)
                curveToRelative(-0.396f, -0.621f, -0.637f, -1.443f, -1.04f, -1.54f)
                curveToRelative(-0.889f, -0.214f, -1.859f, -0.067f, -2.851f, -0.067f)
                curveToRelative(0.979f, 1.698f, 1.505f, 1.927f, 3.891f, 1.607f)
                close()
                moveTo(32.0f, 13.43f)
                horizontalLineToRelative(3.319f)
                curveToRelative(-0.17f, -0.46f, -0.322f, -0.838f, -0.448f, -1.225f)
                curveToRelative(-0.209f, -0.633f, -0.656f, -0.678f, -1.084f, -0.366f)
                curveToRelative(-0.574f, 0.418f, -1.074f, 0.945f, -1.788f, 1.59f)
                close()
                moveTo(37.723f, 19.702f)
                curveToRelative(-0.409f, -1.782f, -1.033f, -2.13f, -2.674f, -1.474f)
                curveToRelative(0.741f, 0.785f, 1.22f, 1.983f, 2.674f, 1.474f)
                close()
                moveTo(34.839f, 30.151f)
                curveToRelative(2.306f, 0.399f, 2.905f, 0.1f, 3.484f, -1.807f)
                curveToRelative(-1.291f, 0.67f, -2.388f, 1.237f, -3.484f, 1.807f)
                close()
                moveTo(29.913f, 37.113f)
                curveToRelative(0.113f, 0.428f, 0.195f, 0.8f, 0.312f, 1.16f)
                curveToRelative(0.195f, 0.599f, 0.771f, 0.76f, 1.174f, 0.295f)
                curveToRelative(0.357f, -0.411f, 0.643f, -0.89f, 1.043f, -1.455f)
                horizontalLineToRelative(-2.529f)
                close()
                moveTo(29.444f, 40.105f)
                curveToRelative(0.065f, -0.09f, 0.128f, -0.184f, 0.194f, -0.275f)
                curveToRelative(-0.207f, -0.215f, -0.406f, -0.606f, -0.623f, -0.616f)
                curveToRelative(-1.184f, -0.062f, -2.373f, -0.029f, -3.56f, -0.029f)
                lineToRelative(-0.012f, 0.194f)
                curveToRelative(1.334f, 0.242f, 2.667f, 0.484f, 4.001f, 0.728f)
                verticalLineToRelative(-0.002f)
                close()
                moveTo(38.504f, 22.177f)
                curveToRelative(-0.078f, -1.81f, -0.496f, -2.235f, -1.626f, -1.845f)
                lineToRelative(1.626f, 1.845f)
                close()
                moveTo(27.948f, 34.537f)
                curveToRelative(-0.174f, -0.908f, -0.776f, -0.902f, -2.152f, 0.0f)
                horizontalLineToRelative(2.152f)
                close()
            }
        }
        .build()
        return _geoShape8!!
    }

private var _geoShape8: ImageVector? = null
