package com.brdgwtr.designsystem.foundation

import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import dev.drewhamilton.poko.Poko

internal expect val Lato: FontFamily

@Poko
class BwTypography(
    val display: TextStyle,
    val headlineLarge: TextStyle,
    val headlineMedium: TextStyle,
    val titleLarge: TextStyle,
    val titleMedium: TextStyle,
    val titleSmall: TextStyle,
    val bodyLarge: TextStyle,
    val bodyMedium: TextStyle,
    val bodySmall: TextStyle,
    val label: TextStyle,
    val labelInteraction: TextStyle,
)
