package com.brdgwtr.designsystem.foundation.mobile

import com.brdgwtr.designsystem.foundation.BwTypography
import com.brdgwtr.designsystem.tokens.internal.BwTypographyTokens

internal val BwMobileTypography = BwTypography(
    display = BwTypographyTokens.typographyMobileDisplay,
    headlineLarge = BwTypographyTokens.typographyMobileHeadlineLarge,
    headlineMedium = BwTypographyTokens.typographyMobileHeadlineMedium,
    titleLarge = BwTypographyTokens.typographyMobileTitleLarge,
    titleMedium = BwTypographyTokens.typographyMobileTitleMedium,
    titleSmall = BwTypographyTokens.typographyMobileTitleSmall,
    bodyLarge = BwTypographyTokens.typographyMobileBodyLarge,
    bodyMedium = BwTypographyTokens.typographyMobileBodyMedium,
    bodySmall = BwTypographyTokens.typographyMobileBodySmall,
    label = BwTypographyTokens.typographyMobileLabel,
    labelInteraction = BwTypographyTokens.typographyMobileLabelInteraction,
)
