package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.AppsFilled: ImageVector
    get() {
        if (_appsFilled != null) {
            return _appsFilled!!
        }
        _appsFilled = Builder(name = "AppsFilled", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(6.0f, 9.91f)
                curveTo(6.0f, 7.7506f, 7.7506f, 6.0f, 9.91f, 6.0f)
                horizontalLineTo(17.6384f)
                curveTo(19.7978f, 6.0f, 21.5484f, 7.7506f, 21.5484f, 9.91f)
                verticalLineTo(17.8279f)
                curveTo(21.5484f, 19.9873f, 19.7978f, 21.7379f, 17.6384f, 21.7379f)
                horizontalLineTo(9.91f)
                curveTo(7.7506f, 21.7379f, 6.0f, 19.9873f, 6.0f, 17.8279f)
                verticalLineTo(9.91f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(26.4513f, 9.91f)
                curveTo(26.4513f, 7.7506f, 28.2018f, 6.0f, 30.3613f, 6.0f)
                horizontalLineTo(38.0896f)
                curveTo(40.2491f, 6.0f, 41.9996f, 7.7506f, 41.9996f, 9.91f)
                verticalLineTo(17.8279f)
                curveTo(41.9996f, 19.9873f, 40.2491f, 21.7379f, 38.0896f, 21.7379f)
                horizontalLineTo(30.3613f)
                curveTo(28.2018f, 21.7379f, 26.4513f, 19.9873f, 26.4513f, 17.8279f)
                verticalLineTo(9.91f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(9.91f, 26.2621f)
                curveTo(7.7506f, 26.2621f, 6.0f, 28.0127f, 6.0f, 30.1721f)
                verticalLineTo(38.09f)
                curveTo(6.0f, 40.2494f, 7.7506f, 42.0f, 9.91f, 42.0f)
                horizontalLineTo(17.6384f)
                curveTo(19.7978f, 42.0f, 21.5484f, 40.2494f, 21.5484f, 38.09f)
                verticalLineTo(30.1721f)
                curveTo(21.5484f, 28.0127f, 19.7978f, 26.2621f, 17.6384f, 26.2621f)
                horizontalLineTo(9.91f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(26.45f, 30.1721f)
                curveTo(26.45f, 28.0127f, 28.2006f, 26.2621f, 30.36f, 26.2621f)
                horizontalLineTo(38.09f)
                curveTo(40.2494f, 26.2621f, 42.0f, 28.0127f, 42.0f, 30.1721f)
                verticalLineTo(38.09f)
                curveTo(42.0f, 40.2494f, 40.2494f, 42.0f, 38.09f, 42.0f)
                horizontalLineTo(30.36f)
                curveTo(28.2006f, 42.0f, 26.45f, 40.2494f, 26.45f, 38.09f)
                verticalLineTo(30.1721f)
                close()
            }
        }
        .build()
        return _appsFilled!!
    }

private var _appsFilled: ImageVector? = null
