package com.brdgwtr.designsystem

import androidx.compose.ui.graphics.vector.ImageVector
import com.brdgwtr.designsystem.icons.Accessibility
import com.brdgwtr.designsystem.icons.Add
import com.brdgwtr.designsystem.icons.Alert
import com.brdgwtr.designsystem.icons.AppsFilled
import com.brdgwtr.designsystem.icons.AppsOutline
import com.brdgwtr.designsystem.icons.ArrowDown
import com.brdgwtr.designsystem.icons.ArrowDownKeyboard
import com.brdgwtr.designsystem.icons.ArrowLeft
import com.brdgwtr.designsystem.icons.ArrowLeftKeyboard
import com.brdgwtr.designsystem.icons.ArrowRight
import com.brdgwtr.designsystem.icons.ArrowRightKeyboard
import com.brdgwtr.designsystem.icons.ArrowUp
import com.brdgwtr.designsystem.icons.ArrowUpKeyboard
import com.brdgwtr.designsystem.icons.AudioDescription
import com.brdgwtr.designsystem.icons.Bluetooth
import com.brdgwtr.designsystem.icons.Checkmark
import com.brdgwtr.designsystem.icons.Chevron
import com.brdgwtr.designsystem.icons.Close
import com.brdgwtr.designsystem.icons.DeleteFilled
import com.brdgwtr.designsystem.icons.DeleteOutline
import com.brdgwtr.designsystem.icons.DevicesFilled
import com.brdgwtr.designsystem.icons.DevicesOutline
import com.brdgwtr.designsystem.icons.DiscoverFilled
import com.brdgwtr.designsystem.icons.DiscoverOutline
import com.brdgwtr.designsystem.icons.EditFilled
import com.brdgwtr.designsystem.icons.EditOutline
import com.brdgwtr.designsystem.icons.FavoriteFilled
import com.brdgwtr.designsystem.icons.FavoriteOutline
import com.brdgwtr.designsystem.icons.Filter
import com.brdgwtr.designsystem.icons.GenreFilled
import com.brdgwtr.designsystem.icons.GenreOutline
import com.brdgwtr.designsystem.icons.GeoShape1
import com.brdgwtr.designsystem.icons.GeoShape10
import com.brdgwtr.designsystem.icons.GeoShape2
import com.brdgwtr.designsystem.icons.GeoShape3
import com.brdgwtr.designsystem.icons.GeoShape4
import com.brdgwtr.designsystem.icons.GeoShape5
import com.brdgwtr.designsystem.icons.GeoShape6
import com.brdgwtr.designsystem.icons.GeoShape7
import com.brdgwtr.designsystem.icons.GeoShape8
import com.brdgwtr.designsystem.icons.GeoShape9
import com.brdgwtr.designsystem.icons.Hide
import com.brdgwtr.designsystem.icons.HomeFilled
import com.brdgwtr.designsystem.icons.HomeOutline
import com.brdgwtr.designsystem.icons.KeyboardBackspace
import com.brdgwtr.designsystem.icons.KeyboardLanguage
import com.brdgwtr.designsystem.icons.KeyboardShift
import com.brdgwtr.designsystem.icons.KeyboardShiftSelected
import com.brdgwtr.designsystem.icons.KeyboardSpace
import com.brdgwtr.designsystem.icons.Launch
import com.brdgwtr.designsystem.icons.LiveTvFilled
import com.brdgwtr.designsystem.icons.LiveTvOutline
import com.brdgwtr.designsystem.icons.Lock
import com.brdgwtr.designsystem.icons.Move
import com.brdgwtr.designsystem.icons.MoviesFilled
import com.brdgwtr.designsystem.icons.MoviesOutline
import com.brdgwtr.designsystem.icons.NotificationFilled
import com.brdgwtr.designsystem.icons.NotificationOutline
import com.brdgwtr.designsystem.icons.Pause
import com.brdgwtr.designsystem.icons.Pay
import com.brdgwtr.designsystem.icons.Pin
import com.brdgwtr.designsystem.icons.PlayFilled
import com.brdgwtr.designsystem.icons.PlayOutline
import com.brdgwtr.designsystem.icons.ProfileFilled
import com.brdgwtr.designsystem.icons.ProfileOutline
import com.brdgwtr.designsystem.icons.Refresh
import com.brdgwtr.designsystem.icons.RemoteBack
import com.brdgwtr.designsystem.icons.RemoteFilled
import com.brdgwtr.designsystem.icons.RemoteOutline
import com.brdgwtr.designsystem.icons.RtCertifiedFresh
import com.brdgwtr.designsystem.icons.RtCertifiedFreshBadge
import com.brdgwtr.designsystem.icons.RtPopcornFresh
import com.brdgwtr.designsystem.icons.RtPopcornRotten
import com.brdgwtr.designsystem.icons.RtRottenSplat
import com.brdgwtr.designsystem.icons.RtTomatoFresh
import com.brdgwtr.designsystem.icons.RtVerifiedHot
import com.brdgwtr.designsystem.icons.RtVerifiedHotBadge
import com.brdgwtr.designsystem.icons.RuwidoBackFilled
import com.brdgwtr.designsystem.icons.RuwidoBackOutline
import com.brdgwtr.designsystem.icons.RuwidoHomeFilled
import com.brdgwtr.designsystem.icons.RuwidoHomeOutline
import com.brdgwtr.designsystem.icons.RuwidoInputFilled
import com.brdgwtr.designsystem.icons.RuwidoInputOutline
import com.brdgwtr.designsystem.icons.RuwidoLiveFilled
import com.brdgwtr.designsystem.icons.RuwidoLiveOutline
import com.brdgwtr.designsystem.icons.RuwidoMuteFilled
import com.brdgwtr.designsystem.icons.RuwidoMuteOutline
import com.brdgwtr.designsystem.icons.RuwidoPowerFilled
import com.brdgwtr.designsystem.icons.RuwidoPowerOutline
import com.brdgwtr.designsystem.icons.RuwidoSettingsFilled
import com.brdgwtr.designsystem.icons.RuwidoSettingsOutline
import com.brdgwtr.designsystem.icons.RuwidoVoiceFilled
import com.brdgwtr.designsystem.icons.RuwidoVoiceOutline
import com.brdgwtr.designsystem.icons.RuwidoVolumedownFilled
import com.brdgwtr.designsystem.icons.RuwidoVolumedownOutline
import com.brdgwtr.designsystem.icons.RuwidoVolumeupFilled
import com.brdgwtr.designsystem.icons.RuwidoVolumeupOutline
import com.brdgwtr.designsystem.icons.SearchFilled
import com.brdgwtr.designsystem.icons.SearchOutline
import com.brdgwtr.designsystem.icons.SeeMore
import com.brdgwtr.designsystem.icons.SeriesFilled
import com.brdgwtr.designsystem.icons.SeriesOutline
import com.brdgwtr.designsystem.icons.SettingsFilled
import com.brdgwtr.designsystem.icons.SettingsOutline
import com.brdgwtr.designsystem.icons.Share
import com.brdgwtr.designsystem.icons.ShopFilled
import com.brdgwtr.designsystem.icons.ShopOutline
import com.brdgwtr.designsystem.icons.ShowFilled
import com.brdgwtr.designsystem.icons.ShowOutline
import com.brdgwtr.designsystem.icons.Swap
import com.brdgwtr.designsystem.icons.Talent
import com.brdgwtr.designsystem.icons.ThumbsDownFilled
import com.brdgwtr.designsystem.icons.ThumbsDownOutline
import com.brdgwtr.designsystem.icons.ThumbsUpFilled
import com.brdgwtr.designsystem.icons.ThumbsUpOutline
import com.brdgwtr.designsystem.icons.TopPicksFilled
import com.brdgwtr.designsystem.icons.TopPicksOutline
import com.brdgwtr.designsystem.icons.TrailerFilled
import com.brdgwtr.designsystem.icons.TrailerOutline
import com.brdgwtr.designsystem.icons.Tv
import com.brdgwtr.designsystem.icons.Voice
import com.brdgwtr.designsystem.icons.Volume
import com.brdgwtr.designsystem.icons.WatchlistFilled
import com.brdgwtr.designsystem.icons.WatchlistOutline
import com.brdgwtr.designsystem.icons.WifiBad
import com.brdgwtr.designsystem.icons.WifiFull
import com.brdgwtr.designsystem.icons.WifiGood
import com.brdgwtr.designsystem.icons.WifiLocked
import com.brdgwtr.designsystem.icons.WifiLockedBad
import com.brdgwtr.designsystem.icons.WifiLockedFull
import com.brdgwtr.designsystem.icons.WifiLockedGood
import com.brdgwtr.designsystem.icons.WifiLockedLow
import com.brdgwtr.designsystem.icons.WifiLow
import com.brdgwtr.designsystem.icons.WifiNone
import kotlin.collections.List as ____KtList

public object Icons

private var __All: ____KtList<ImageVector>? = null

public val Icons.All: ____KtList<ImageVector>
  get() {
    if (__All != null) {
      return __All!!
    }
    __All= listOf(EditOutline, Checkmark, RuwidoSettingsFilled, GeoShape2, GeoShape3, Chevron,
        FavoriteFilled, WatchlistOutline, GenreOutline, ShopFilled, ArrowUpKeyboard, GeoShape1,
        WifiBad, WifiLow, RuwidoVolumedownFilled, RuwidoLiveFilled, SeeMore, RtTomatoFresh, Alert,
        GeoShape4, MoviesOutline, AudioDescription, RtCertifiedFresh, GeoShape5, ArrowRightKeyboard,
        LiveTvOutline, TopPicksOutline, WifiLocked, RuwidoSettingsOutline, KeyboardSpace, GeoShape7,
        RtPopcornRotten, RemoteBack, RuwidoHomeOutline, Talent, GeoShape6, Move, SearchFilled,
        RuwidoPowerOutline, WifiFull, Lock, ArrowDownKeyboard, RuwidoInputOutline, ArrowLeft,
        KeyboardShiftSelected, GenreFilled, ProfileOutline, RtRottenSplat, SeriesFilled, Pin,
        ProfileFilled, RemoteOutline, RtPopcornFresh, Tv, Volume, RuwidoVoiceOutline,
        RuwidoVolumedownOutline, ThumbsDownFilled, WifiLockedFull, ArrowUp, RuwidoPowerFilled,
        AppsOutline, RuwidoBackOutline, GeoShape10, RuwidoLiveOutline, RuwidoBackFilled,
        RtCertifiedFreshBadge, DevicesOutline, ShowFilled, ArrowRight, PlayOutline, SettingsOutline,
        WifiNone, Bluetooth, KeyboardShift, Add, HomeFilled, ShowOutline, Close, DeleteOutline,
        KeyboardBackspace, DeleteFilled, Refresh, Launch, Hide, PlayFilled, RuwidoVolumeupFilled,
        ShopOutline, DiscoverOutline, WifiLockedGood, ArrowDown, ThumbsUpFilled, RuwidoInputFilled,
        WifiLockedLow, WifiLockedBad, LiveTvFilled, Voice, TrailerFilled, WatchlistFilled,
        KeyboardLanguage, RuwidoVolumeupOutline, MoviesFilled, RuwidoVoiceFilled, WifiGood,
        EditFilled, RemoteFilled, TopPicksFilled, RtVerifiedHotBadge, RuwidoHomeFilled, AppsFilled,
        SettingsFilled, NotificationFilled, SeriesOutline, NotificationOutline, Share, GeoShape8,
        Filter, Pay, DevicesFilled, HomeOutline, GeoShape9, ThumbsUpOutline, Pause,
        RuwidoMuteOutline, DiscoverFilled, RtVerifiedHot, RuwidoMuteFilled, SearchOutline,
        FavoriteOutline, ThumbsDownOutline, TrailerOutline, ArrowLeftKeyboard, Swap, Accessibility)
    return __All!!
  }
