package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.AudioDescription: ImageVector
    get() {
        if (_audioDescription != null) {
            return _audioDescription!!
        }
        _audioDescription = Builder(name = "AudioDescription", defaultWidth = 48.0.dp, defaultHeight
                = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(25.014f, 19.686f)
                curveToRelative(2.796f, -0.102f, 4.734f, 1.337f, 5.029f, 3.646f)
                curveToRelative(0.39f, 3.095f, -1.887f, 5.106f, -5.166f, 4.883f)
                verticalLineToRelative(-8.323f)
                curveToRelative(0.0f, -0.116f, 0.052f, -0.178f, 0.137f, -0.206f)
                close()
                moveTo(20.675f, 15.283f)
                verticalLineToRelative(17.545f)
                lineToRelative(0.704f, 0.013f)
                horizontalLineToRelative(0.003f)
                curveToRelative(4.14f, 0.081f, 7.358f, 0.144f, 9.762f, -1.39f)
                curveToRelative(2.435f, -1.556f, 4.335f, -4.579f, 3.996f, -8.461f)
                curveToRelative(-0.364f, -4.109f, -3.677f, -7.563f, -7.989f, -7.912f)
                curveToRelative(-2.143f, -0.175f, -6.404f, 0.0f, -6.404f, 0.0f)
                reflectiveCurveToRelative(-0.075f, 0.11f, -0.068f, 0.205f)
                horizontalLineToRelative(-0.004f)
                close()
                moveTo(15.586f, 21.019f)
                verticalLineToRelative(5.671f)
                lineToRelative(-3.598f, -0.02f)
                lineToRelative(3.598f, -5.65f)
                close()
                moveTo(2.0f, 32.86f)
                horizontalLineToRelative(0.006f)
                lineToRelative(-0.003f, 0.003f)
                lineTo(2.0f, 32.858f)
                close()
                moveTo(2.006f, 32.86f)
                lineTo(8.06f, 32.86f)
                lineToRelative(1.653f, -2.273f)
                lineToRelative(5.711f, -0.01f)
                reflectiveCurveToRelative(0.007f, 1.549f, 0.007f, 2.282f)
                horizontalLineToRelative(4.339f)
                lineTo(19.77f, 15.112f)
                horizontalLineToRelative(-5.234f)
                curveToRelative(-0.5f, 0.754f, -12.337f, 17.471f, -12.531f, 17.746f)
                close()
                moveTo(36.401f, 16.86f)
                curveToRelative(-0.406f, -0.742f, -0.823f, -1.503f, -1.8f, -1.257f)
                lineToRelative(-0.004f, 0.003f)
                curveToRelative(1.496f, 2.3f, 2.772f, 5.202f, 2.755f, 8.67f)
                curveToRelative(-0.018f, 3.022f, -1.101f, 5.657f, -2.274f, 7.566f)
                arcToRelative(3.46f, 3.46f, 0.0f, false, true, -0.098f, 0.15f)
                curveToRelative(-0.136f, 0.203f, -0.286f, 0.427f, -0.245f, 0.676f)
                horizontalLineToRelative(0.758f)
                curveToRelative(1.536f, -1.902f, 3.19f, -4.708f, 3.169f, -8.533f)
                curveToRelative(-0.014f, -2.666f, -0.864f, -4.999f, -1.996f, -6.812f)
                curveToRelative(-0.092f, -0.147f, -0.178f, -0.305f, -0.265f, -0.463f)
                close()
                moveTo(38.277f, 15.603f)
                curveToRelative(0.977f, -0.245f, 1.394f, 0.515f, 1.8f, 1.257f)
                curveToRelative(0.087f, 0.158f, 0.174f, 0.316f, 0.265f, 0.463f)
                curveToRelative(1.132f, 1.813f, 1.983f, 4.146f, 1.996f, 6.813f)
                curveToRelative(0.02f, 3.824f, -1.633f, 6.63f, -3.17f, 8.532f)
                horizontalLineToRelative(-0.757f)
                curveToRelative(-0.042f, -0.251f, 0.113f, -0.48f, 0.25f, -0.683f)
                curveToRelative(0.033f, -0.05f, 0.065f, -0.097f, 0.093f, -0.143f)
                curveToRelative(1.173f, -1.909f, 2.257f, -4.544f, 2.274f, -7.566f)
                curveToRelative(0.017f, -3.468f, -1.259f, -6.37f, -2.754f, -8.67f)
                lineToRelative(0.003f, -0.003f)
                close()
                moveTo(43.739f, 16.86f)
                curveToRelative(-0.406f, -0.742f, -0.823f, -1.503f, -1.8f, -1.257f)
                lineToRelative(-0.004f, 0.003f)
                curveToRelative(1.495f, 2.3f, 2.771f, 5.202f, 2.754f, 8.67f)
                curveToRelative(-0.017f, 3.022f, -1.1f, 5.657f, -2.274f, 7.566f)
                curveToRelative(-0.028f, 0.046f, -0.06f, 0.094f, -0.093f, 0.143f)
                curveToRelative(-0.137f, 0.203f, -0.291f, 0.432f, -0.25f, 0.683f)
                horizontalLineToRelative(0.758f)
                curveToRelative(1.537f, -1.902f, 3.19f, -4.708f, 3.17f, -8.533f)
                curveToRelative(-0.014f, -2.666f, -0.865f, -4.999f, -1.997f, -6.812f)
                arcToRelative(9.732f, 9.732f, 0.0f, false, true, -0.264f, -0.463f)
                close()
            }
        }
        .build()
        return _audioDescription!!
    }

private var _audioDescription: ImageVector? = null
