package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GeoShape9: ImageVector
    get() {
        if (_geoShape9 != null) {
            return _geoShape9!!
        }
        _geoShape9 = Builder(name = "GeoShape9", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(40.333f, 23.978f)
                curveToRelative(-0.234f, 0.325f, -0.402f, 0.62f, -0.626f, 0.864f)
                curveToRelative(-4.355f, 4.745f, -8.718f, 9.484f, -13.078f, 14.226f)
                curveToRelative(-0.754f, 0.819f, -1.5f, 1.645f, -2.262f, 2.457f)
                curveToRelative(-0.594f, 0.629f, -0.936f, 0.636f, -1.515f, 0.007f)
                arcTo(23709.947f, 23709.947f, 0.0f, false, true, 7.456f, 24.79f)
                curveToRelative(-0.617f, -0.672f, -0.607f, -0.933f, 0.024f, -1.617f)
                arcToRelative(39279.39f, 39279.39f, 0.0f, false, true, 15.23f, -16.57f)
                curveToRelative(0.74f, -0.806f, 1.05f, -0.804f, 1.792f, 0.005f)
                arcToRelative(23392.63f, 23392.63f, 0.0f, false, true, 15.176f, 16.51f)
                curveToRelative(0.226f, 0.246f, 0.406f, 0.534f, 0.653f, 0.862f)
                lineToRelative(0.002f, -0.002f)
                close()
                moveTo(24.052f, 8.338f)
                verticalLineToRelative(23.028f)
                lineToRelative(14.371f, -7.398f)
                lineToRelative(-14.371f, -15.63f)
                close()
                moveTo(22.942f, 8.579f)
                lineTo(8.79f, 23.97f)
                curveToRelative(4.753f, 2.446f, 9.416f, 4.845f, 14.152f, 7.28f)
                lineTo(22.942f, 8.578f)
                close()
                moveTo(22.79f, 39.237f)
                lineTo(22.99f, 39.189f)
                verticalLineToRelative(-0.843f)
                curveToRelative(0.0f, -1.723f, -0.025f, -3.447f, 0.012f, -5.169f)
                curveToRelative(0.014f, -0.583f, -0.17f, -0.892f, -0.696f, -1.16f)
                curveToRelative(-3.544f, -1.793f, -7.072f, -3.624f, -10.606f, -5.44f)
                curveToRelative(-0.195f, -0.1f, -0.407f, -0.17f, -0.61f, -0.251f)
                lineToRelative(-0.07f, 0.107f)
                lineToRelative(11.77f, 12.804f)
                close()
                moveTo(31.49f, 31.462f)
                curveToRelative(-1.654f, 0.0f, -3.151f, 0.053f, -4.644f, -0.017f)
                curveToRelative(-1.076f, -0.051f, -1.831f, 0.602f, -2.702f, 1.009f)
                curveToRelative(-0.094f, 0.044f, -0.107f, 0.258f, -0.212f, 0.535f)
                horizontalLineToRelative(0.859f)
                curveToRelative(1.403f, 0.0f, 2.816f, -0.102f, 4.208f, 0.03f)
                curveToRelative(1.243f, 0.12f, 1.771f, -0.625f, 2.492f, -1.557f)
                horizontalLineToRelative(-0.001f)
                close()
                moveTo(24.018f, 34.868f)
                curveToRelative(1.2f, 0.0f, 2.317f, -0.089f, 3.413f, 0.024f)
                curveToRelative(1.224f, 0.127f, 1.648f, -0.71f, 2.29f, -1.517f)
                horizontalLineToRelative(-5.703f)
                verticalLineToRelative(1.493f)
                close()
                moveTo(26.938f, 31.153f)
                horizontalLineToRelative(3.871f)
                curveToRelative(1.134f, 0.0f, 1.773f, -0.433f, 2.225f, -1.496f)
                curveToRelative(-2.165f, -0.32f, -4.208f, -0.086f, -6.096f, 1.496f)
                close()
                moveTo(24.03f, 35.203f)
                verticalLineToRelative(1.457f)
                curveToRelative(2.277f, 0.358f, 3.245f, -0.005f, 3.886f, -1.457f)
                lineTo(24.03f, 35.203f)
                close()
                moveTo(34.798f, 27.779f)
                curveToRelative(-1.68f, -0.42f, -2.794f, 0.75f, -4.095f, 1.372f)
                curveToRelative(1.537f, 0.104f, 3.215f, 0.646f, 4.095f, -1.372f)
                close()
                moveTo(24.013f, 38.527f)
                curveToRelative(1.3f, 0.325f, 1.612f, -0.733f, 2.383f, -1.427f)
                horizontalLineToRelative(-2.383f)
                verticalLineToRelative(1.427f)
                close()
                moveTo(34.186f, 27.277f)
                curveToRelative(1.146f, 0.39f, 1.54f, -0.286f, 1.922f, -0.992f)
                curveToRelative(-0.591f, 0.306f, -1.183f, 0.612f, -1.922f, 0.991f)
                close()
            }
        }
        .build()
        return _geoShape9!!
    }

private var _geoShape9: ImageVector? = null
