package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Alert: ImageVector
    get() {
        if (_alert != null) {
            return _alert!!
        }
        _alert = Builder(name = "Alert", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(43.551f, 36.9745f)
                lineTo(26.861f, 7.6747f)
                curveTo(25.5895f, 5.4418f, 22.4079f, 5.4418f, 21.1354f, 7.6747f)
                lineTo(4.4474f, 36.9745f)
                curveTo(3.176f, 39.2075f, 4.7658f, 42.0f, 7.3097f, 42.0f)
                horizontalLineTo(40.6898f)
                curveTo(43.2347f, 42.0f, 44.8245f, 39.2075f, 43.552f, 36.9745f)
                horizontalLineTo(43.551f)
                close()
                moveTo(23.663f, 15.921f)
                horizontalLineTo(24.3365f)
                curveTo(25.4222f, 15.921f, 26.2916f, 16.8332f, 26.2559f, 17.9326f)
                lineTo(25.9191f, 28.1594f)
                curveTo(25.8844f, 29.2092f, 25.0354f, 30.0408f, 23.9997f, 30.0408f)
                curveTo(22.964f, 30.0408f, 22.115f, 29.2082f, 22.0803f, 28.1594f)
                lineTo(21.7436f, 17.9326f)
                curveTo(21.7068f, 16.8321f, 22.5772f, 15.921f, 23.663f, 15.921f)
                close()
                moveTo(23.9997f, 37.1027f)
                curveTo(22.717f, 37.1027f, 21.6783f, 36.0488f, 21.6783f, 34.7498f)
                curveTo(21.6783f, 33.4507f, 22.7181f, 32.3968f, 23.9997f, 32.3968f)
                curveTo(25.2814f, 32.3968f, 26.3212f, 33.4507f, 26.3212f, 34.7498f)
                curveTo(26.3212f, 36.0488f, 25.2814f, 37.1027f, 23.9997f, 37.1027f)
                close()
            }
        }
        .build()
        return _alert!!
    }

private var _alert: ImageVector? = null
