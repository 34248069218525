package com.brdgwtr.designsystem.components

import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.AnimationSpec
import androidx.compose.animation.core.TweenSpec
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.indication
import androidx.compose.foundation.interaction.InteractionSource
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsFocusedAsState
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.requiredSize
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.selection.toggleable
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.focusProperties
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.foundation.BwTheme
import kotlin.math.roundToInt

/**
 * Toggle/Switch component.
 *
 * @param toggled Whether the toggle should be toggled or not.
 * @param onToggleChange Optional callback for when the toggle is selected. Setting to null will make the Toggle
 * un-focusable.
 * @param modifier Modifier for the toggle.
 * @param enabled Whether the toggle can be interacted with.
 * @param colors Colors to use for the toggle.
 * @param interactionSource Interaction source for the interaction state of the toggle.
 */
@Composable
fun Toggle(
    toggled: Boolean,
    onToggleChange: ((Boolean) -> Unit)?,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    colors: ToggleColors = ToggleDefaults.colors(),
    interactionSource: MutableInteractionSource? = null,
) {
    @Suppress("NAME_SHADOWING")
    val interactionSource = interactionSource ?: remember { MutableInteractionSource() }
    val thumbPaddingStart = (ToggleDefaults.toggleHeight - ToggleDefaults.thumbDiameter) / 2
    val minBound = with(LocalDensity.current) { thumbPaddingStart.toPx() }
    val maxBound = with(LocalDensity.current) { ToggleDefaults.thumbPathLength.toPx() }
    val valueToOffset =
        remember<(Boolean) -> Float>(minBound, maxBound) {
            { toggled -> if (toggled) maxBound else minBound }
        }

    val targetValue = valueToOffset(toggled)
    val offset = remember { Animatable(targetValue) }

    LaunchedEffect(toggled) {
        if (offset.targetValue != targetValue) {
            offset.animateTo(targetValue, ToggleDefaults.animationSpec)
        }
    }

    val toggleableModifier =
        if (onToggleChange != null) {
            Modifier.toggleable(
                value = toggled,
                onValueChange = onToggleChange,
                enabled = enabled,
                role = Role.Switch,
                interactionSource = interactionSource,
                indication = null,
            )
        } else {
            Modifier.focusProperties {
                canFocus = false
            }
        }

    Box(
        modifier
            .then(toggleableModifier)
            .wrapContentSize(Alignment.Center)
            .requiredSize(ToggleDefaults.toggleWidth, ToggleDefaults.toggleHeight),
    ) {
        ToggleContent(
            checked = toggled,
            enabled = enabled,
            colors = colors,
            thumbValue = offset.asState(),
            interactionSource = interactionSource,
            minBound = thumbPaddingStart,
            maxBound = ToggleDefaults.thumbPathLength,
        )
    }
}

@Composable
private fun BoxScope.ToggleContent(
    checked: Boolean,
    enabled: Boolean,
    maxBound: Dp,
    minBound: Dp,
    thumbValue: State<Float>,
    interactionSource: InteractionSource,
    modifier: Modifier = Modifier,
    colors: ToggleColors = ToggleDefaults.colors(),
    thumbShape: Shape = ToggleDefaults.thumbShape,
    trackShape: Shape = ToggleDefaults.trackShape,
) {
    val focused by interactionSource.collectIsFocusedAsState()
    val trackColor = colors.trackColor(enabled, checked, focused)

    val thumbValueDp = with(LocalDensity.current) { thumbValue.value.toDp() }

    val thumbSizeDp =
        ToggleDefaults.uncheckedThumbDiameter +
            (ToggleDefaults.thumbDiameter - ToggleDefaults.uncheckedThumbDiameter) *
            ((thumbValueDp - minBound) / (maxBound - minBound))

    val thumbOffset = thumbValue.value

    val modifier =
        modifier
            .align(Alignment.Center)
            .width(ToggleDefaults.toggleWidth)
            .height(ToggleDefaults.toggleHeight)
            .border(
                width = ToggleDefaults.toggleBorderWidth,
                color = colors.borderColor(enabled = enabled, toggled = checked, focused = focused),
                shape = trackShape,
            )
            .background(color = trackColor, shape = trackShape)

    Box(modifier) {
        val thumbColor = colors.thumbColor(enabled = enabled, toggled = checked, focused = focused)
        val resolvedThumbColor = thumbColor
        Box(
            modifier = Modifier
                .align(Alignment.CenterStart)
                .offset { IntOffset(thumbOffset.roundToInt(), 0) }
                .indication(
                    interactionSource = interactionSource,
                    indication = null,
                )
                .requiredSize(thumbSizeDp)
                .background(
                    color = resolvedThumbColor,
                    shape = thumbShape,
                ),
        )
    }
}

@Immutable
data class ToggleColors(
    val checkedThumbColor: Color,
    val checkedTrackColor: Color,
    val checkedBorderColor: Color,
    val focusedCheckedThumbColor: Color,
    val focusedCheckedTrackColor: Color,
    val focusedCheckedBorderColor: Color,
    val uncheckedThumbColor: Color,
    val uncheckedTrackColor: Color,
    val uncheckedBorderColor: Color,
    val focusedUncheckedThumbColor: Color,
    val focusedUncheckedTrackColor: Color,
    val focusedUncheckedBorderColor: Color,
    val disabledCheckedThumbColor: Color,
    val disabledCheckedTrackColor: Color,
    val disabledCheckedBorderColor: Color,
    val disabledUncheckedThumbColor: Color,
    val disabledUncheckedTrackColor: Color,
    val disabledUncheckedBorderColor: Color,
)

object ToggleDefaults {
    val toggleBorderWidth: Dp = 1.dp
    val toggleWidth: Dp = 47.dp
    val toggleHeight: Dp = 16.dp + toggleBorderWidth

    internal val thumbDiameter: Dp = 12.dp
    internal val uncheckedThumbDiameter: Dp = thumbDiameter
    internal val thumbPadding: Dp = (toggleHeight - thumbDiameter) / 2
    internal val thumbPathLength: Dp = (toggleWidth - thumbDiameter) - thumbPadding
    internal val animationSpec: AnimationSpec<Float> = TweenSpec<Float>(durationMillis = 100)

    val trackShape: RoundedCornerShape
        @Composable
        @ReadOnlyComposable
        get() = BwTheme.shapes.circle

    val thumbShape: RoundedCornerShape
        @Composable
        @ReadOnlyComposable
        get() = BwTheme.shapes.circle

    @Composable
    @ReadOnlyComposable
    fun colors(
        checkedThumbColor: Color = BwTheme.colors.onSurfaceInverse,
        checkedTrackColor: Color = BwTheme.colors.statusSuccess,
        checkedBorderColor: Color = checkedTrackColor,
        focusedCheckedThumbColor: Color = BwTheme.colors.onSurfaceInverse,
        focusedCheckedTrackColor: Color = BwTheme.colors.surfaceInverse,
        focusedCheckedBorderColor: Color = focusedCheckedTrackColor,
        uncheckedThumbColor: Color = BwTheme.colors.primary,
        uncheckedTrackColor: Color = Color.Transparent,
        uncheckedBorderColor: Color = BwTheme.colors.primary,
        focusedUncheckedThumbColor: Color = BwTheme.colors.surfaceInverse,
        focusUncheckedTrackColor: Color = Color.Transparent,
        focusedUncheckedBorderColor: Color = BwTheme.colors.surfaceInverse,
        disabledCheckedThumbColor: Color = uncheckedThumbColor.copy(alpha = .4f),
        disabledCheckedTrackColor: Color = uncheckedTrackColor.copy(alpha = .4f),
        disabledCheckedBorderColor: Color = uncheckedBorderColor.copy(alpha = .4f),
        disabledUncheckedThumbColor: Color = uncheckedThumbColor.copy(alpha = .4f),
        disabledUncheckedTrackColor: Color = uncheckedTrackColor.copy(alpha = .4f),
        disabledUncheckedBorderColor: Color = uncheckedBorderColor.copy(alpha = .4f),
    ): ToggleColors = ToggleColors(
        checkedThumbColor = checkedThumbColor,
        checkedTrackColor = checkedTrackColor,
        checkedBorderColor = checkedBorderColor,
        focusedCheckedThumbColor = focusedCheckedThumbColor,
        focusedCheckedTrackColor = focusedCheckedTrackColor,
        focusedCheckedBorderColor = focusedCheckedBorderColor,
        uncheckedThumbColor = uncheckedThumbColor,
        uncheckedTrackColor = uncheckedTrackColor,
        uncheckedBorderColor = uncheckedBorderColor,
        focusedUncheckedThumbColor = focusedUncheckedThumbColor,
        focusedUncheckedTrackColor = focusUncheckedTrackColor,
        focusedUncheckedBorderColor = focusedUncheckedBorderColor,
        disabledCheckedThumbColor = disabledCheckedThumbColor,
        disabledCheckedTrackColor = disabledCheckedTrackColor,
        disabledCheckedBorderColor = disabledCheckedBorderColor,
        disabledUncheckedThumbColor = disabledUncheckedThumbColor,
        disabledUncheckedTrackColor = disabledUncheckedTrackColor,
        disabledUncheckedBorderColor = disabledUncheckedBorderColor,
    )
}

@Stable
@Composable
private fun ToggleColors.borderColor(enabled: Boolean, toggled: Boolean, focused: Boolean): Color {
    val color = when {
        enabled && toggled && focused -> focusedCheckedBorderColor
        enabled && toggled -> checkedBorderColor
        enabled && focused -> focusedUncheckedBorderColor
        enabled -> uncheckedBorderColor
        !enabled && toggled -> disabledCheckedBorderColor
        else -> disabledUncheckedBorderColor
    }
    return color
}

@Stable
@Composable
private fun ToggleColors.trackColor(enabled: Boolean, toggled: Boolean, focused: Boolean): Color {
    val color = when {
        enabled && toggled && focused -> focusedCheckedTrackColor
        enabled && toggled -> checkedTrackColor
        enabled && focused -> focusedUncheckedTrackColor
        enabled -> uncheckedTrackColor
        !enabled && toggled -> disabledCheckedTrackColor
        else -> disabledUncheckedTrackColor
    }
    return color
}

@Stable
@Composable
private fun ToggleColors.thumbColor(enabled: Boolean, toggled: Boolean, focused: Boolean): Color {
    val color = when {
        enabled && toggled && focused -> focusedCheckedThumbColor
        enabled && toggled -> checkedThumbColor
        enabled && focused -> focusedUncheckedThumbColor
        enabled -> uncheckedThumbColor
        !enabled && toggled -> disabledCheckedThumbColor
        else -> disabledUncheckedThumbColor
    }
    return color
}
