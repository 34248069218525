package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.DevicesOutline: ImageVector
    get() {
        if (_devicesOutline != null) {
            return _devicesOutline!!
        }
        _devicesOutline = Builder(name = "DevicesOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(4.0f, 40.0f)
                verticalLineTo(35.5f)
                horizontalLineTo(8.0f)
                verticalLineTo(11.0f)
                curveTo(8.0f, 10.175f, 8.2937f, 9.4688f, 8.8813f, 8.8813f)
                curveTo(9.4688f, 8.2937f, 10.175f, 8.0f, 11.0f, 8.0f)
                horizontalLineTo(42.0f)
                verticalLineTo(11.0f)
                horizontalLineTo(11.0f)
                verticalLineTo(35.5f)
                horizontalLineTo(23.0f)
                verticalLineTo(40.0f)
                horizontalLineTo(4.0f)
                close()
                moveTo(27.5f, 40.0f)
                curveTo(27.075f, 40.0f, 26.7188f, 39.8563f, 26.4312f, 39.5688f)
                curveTo(26.1438f, 39.2812f, 26.0f, 38.925f, 26.0f, 38.5f)
                verticalLineTo(15.5f)
                curveTo(26.0f, 15.075f, 26.1438f, 14.7188f, 26.4312f, 14.4313f)
                curveTo(26.7188f, 14.1438f, 27.075f, 14.0f, 27.5f, 14.0f)
                horizontalLineTo(42.5f)
                curveTo(42.925f, 14.0f, 43.2812f, 14.1438f, 43.5688f, 14.4313f)
                curveTo(43.8563f, 14.7188f, 44.0f, 15.075f, 44.0f, 15.5f)
                verticalLineTo(38.5f)
                curveTo(44.0f, 38.925f, 43.8563f, 39.2812f, 43.5688f, 39.5688f)
                curveTo(43.2812f, 39.8563f, 42.925f, 40.0f, 42.5f, 40.0f)
                horizontalLineTo(27.5f)
                close()
                moveTo(29.0f, 35.5f)
                horizontalLineTo(41.0f)
                verticalLineTo(17.0f)
                horizontalLineTo(29.0f)
                verticalLineTo(35.5f)
                close()
            }
        }
        .build()
        return _devicesOutline!!
    }

private var _devicesOutline: ImageVector? = null
