package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Refresh: ImageVector
    get() {
        if (_refresh != null) {
            return _refresh!!
        }
        _refresh = Builder(name = "Refresh", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(24.0f, 42.0f)
                curveToRelative(-4.988f, 0.0f, -9.234f, -1.753f, -12.74f, -5.26f)
                curveTo(7.752f, 33.235f, 6.0f, 28.988f, 6.0f, 24.0f)
                reflectiveCurveToRelative(1.753f, -9.234f, 5.26f, -12.74f)
                curveTo(14.765f, 7.752f, 19.011f, 6.0f, 24.0f, 6.0f)
                curveToRelative(3.188f, 0.0f, 5.981f, 0.647f, 8.381f, 1.94f)
                curveToRelative(2.4f, 1.294f, 4.481f, 3.066f, 6.244f, 5.316f)
                verticalLineTo(7.687f)
                arcToRelative(1.687f, 1.687f, 0.0f, true, true, 3.375f, 0.0f)
                verticalLineToRelative(11.6f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, -1.0f, 1.0f)
                horizontalLineTo(29.4f)
                arcToRelative(1.687f, 1.687f, 0.0f, true, true, 0.0f, -3.374f)
                horizontalLineToRelative(7.763f)
                curveToRelative(-1.425f, -2.25f, -3.244f, -4.07f, -5.457f, -5.457f)
                curveTo(29.494f, 10.07f, 26.925f, 9.375f, 24.0f, 9.375f)
                curveToRelative(-4.087f, 0.0f, -7.547f, 1.416f, -10.378f, 4.247f)
                curveTo(10.79f, 16.453f, 9.375f, 19.912f, 9.375f, 24.0f)
                curveToRelative(0.0f, 4.087f, 1.416f, 7.547f, 4.247f, 10.378f)
                curveToRelative(2.831f, 2.831f, 6.29f, 4.247f, 10.378f, 4.247f)
                curveToRelative(3.113f, 0.0f, 5.962f, -0.89f, 8.55f, -2.672f)
                curveToRelative(2.242f, -1.543f, 3.893f, -3.516f, 4.952f, -5.918f)
                curveToRelative(0.296f, -0.672f, 0.94f, -1.141f, 1.674f, -1.141f)
                curveToRelative(1.137f, 0.0f, 1.964f, 1.087f, 1.534f, 2.14f)
                curveToRelative(-1.209f, 2.962f, -3.123f, 5.417f, -5.741f, 7.366f)
                curveTo(31.744f, 40.8f, 28.087f, 42.0f, 24.0f, 42.0f)
                close()
            }
        }
        .build()
        return _refresh!!
    }

private var _refresh: ImageVector? = null
