package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoPowerOutline: ImageVector
    get() {
        if (_ruwidoPowerOutline != null) {
            return _ruwidoPowerOutline!!
        }
        _ruwidoPowerOutline = Builder(name = "RuwidoPowerOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(2.0f, 24.0f)
                curveTo(2.0f, 11.848f, 11.848f, 2.0f, 24.0f, 2.0f)
                reflectiveCurveToRelative(22.0f, 9.848f, 22.0f, 22.0f)
                reflectiveCurveToRelative(-9.848f, 22.0f, -22.0f, 22.0f)
                reflectiveCurveTo(2.0f, 36.152f, 2.0f, 24.0f)
                close()
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(24.0f, 9.0f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.0f, 1.0f)
                verticalLineToRelative(11.295f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, true, -2.0f, 0.0f)
                lineTo(23.0f, 10.0f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.0f, -1.0f)
                close()
                moveTo(19.51f, 13.74f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, -0.35f, 1.37f)
                arcToRelative(9.764f, 9.764f, 0.0f, false, false, -4.76f, 8.407f)
                curveToRelative(0.0f, 5.389f, 4.303f, 9.733f, 9.601f, 9.733f)
                curveToRelative(5.298f, 0.0f, 9.601f, -4.344f, 9.601f, -9.733f)
                arcToRelative(9.782f, 9.782f, 0.0f, false, false, -4.503f, -8.264f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, true, 1.079f, -1.684f)
                arcToRelative(11.782f, 11.782f, 0.0f, false, true, 5.424f, 9.947f)
                curveToRelative(0.0f, 6.47f, -5.174f, 11.734f, -11.6f, 11.734f)
                curveToRelative(-6.427f, 0.0f, -11.602f, -5.264f, -11.602f, -11.733f)
                curveToRelative(0.0f, -4.303f, 2.299f, -8.082f, 5.739f, -10.127f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.37f, 0.35f)
                close()
            }
        }
        .build()
        return _ruwidoPowerOutline!!
    }

private var _ruwidoPowerOutline: ImageVector? = null
