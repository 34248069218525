package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.KeyboardShift: ImageVector
    get() {
        if (_keyboardShift != null) {
            return _keyboardShift!!
        }
        _keyboardShift = Builder(name = "KeyboardShift", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(23.0f, 9.29f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, true, 1.418f, 0.0f)
                lineToRelative(13.697f, 13.483f)
                curveToRelative(0.644f, 0.634f, 0.195f, 1.73f, -0.709f, 1.73f)
                horizontalLineToRelative(-5.76f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, false, -1.01f, 1.01f)
                verticalLineToRelative(13.279f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, true, -1.01f, 1.01f)
                horizontalLineTo(17.792f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, true, -1.01f, -1.01f)
                verticalLineTo(25.513f)
                arcToRelative(1.01f, 1.01f, 0.0f, false, false, -1.01f, -1.01f)
                horizontalLineToRelative(-5.76f)
                curveToRelative(-0.904f, 0.0f, -1.353f, -1.096f, -0.708f, -1.73f)
                lineTo(23.0f, 9.29f)
                close()
            }
        }
        .build()
        return _keyboardShift!!
    }

private var _keyboardShift: ImageVector? = null
