package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Add: ImageVector
    get() {
        if (_add != null) {
            return _add!!
        }
        _add = Builder(name = "Add", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp, viewportWidth
                = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(25.958f, 9.267f)
                arcToRelative(2.267f, 2.267f, 0.0f, false, false, -4.533f, 0.0f)
                verticalLineToRelative(11.745f)
                horizontalLineTo(9.267f)
                arcToRelative(2.267f, 2.267f, 0.0f, true, false, 0.0f, 4.534f)
                horizontalLineToRelative(12.158f)
                verticalLineToRelative(13.187f)
                arcToRelative(2.267f, 2.267f, 0.0f, true, false, 4.533f, 0.0f)
                verticalLineTo(25.546f)
                horizontalLineToRelative(12.775f)
                arcToRelative(2.267f, 2.267f, 0.0f, true, false, 0.0f, -4.534f)
                horizontalLineTo(25.958f)
                verticalLineTo(9.267f)
                close()
            }
        }
        .build()
        return _add!!
    }

private var _add: ImageVector? = null
