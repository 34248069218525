package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ShowOutline: ImageVector
    get() {
        if (_showOutline != null) {
            return _showOutline!!
        }
        _showOutline = Builder(name = "ShowOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(31.273f, 24.5001f)
                curveTo(31.273f, 28.445f, 28.0169f, 31.643f, 24.0003f, 31.643f)
                curveTo(19.9837f, 31.643f, 16.7275f, 28.445f, 16.7275f, 24.5001f)
                curveTo(16.7275f, 20.5552f, 19.9837f, 17.3572f, 24.0003f, 17.3572f)
                curveTo(28.0169f, 17.3572f, 31.273f, 20.5552f, 31.273f, 24.5001f)
                close()
                moveTo(27.6366f, 24.5001f)
                curveTo(27.6366f, 26.4725f, 26.0086f, 28.0715f, 24.0003f, 28.0715f)
                curveTo(21.992f, 28.0715f, 20.3639f, 26.4725f, 20.3639f, 24.5001f)
                curveTo(20.3639f, 22.5277f, 21.992f, 20.9287f, 24.0003f, 20.9287f)
                curveTo(26.0086f, 20.9287f, 27.6366f, 22.5277f, 27.6366f, 24.5001f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(24.0f, 12.0001f)
                curveTo(32.4583f, 12.0001f, 39.4583f, 16.5417f, 44.0f, 24.5834f)
                curveTo(39.75f, 32.3751f, 32.4583f, 37.0001f, 24.0f, 37.0001f)
                curveTo(16.25f, 37.0001f, 8.7917f, 32.6667f, 4.0f, 24.4584f)
                curveTo(8.4583f, 16.4584f, 15.9167f, 12.0001f, 24.0f, 12.0001f)
                close()
                moveTo(24.0f, 33.4286f)
                curveTo(18.2614f, 33.4286f, 12.4553f, 30.5049f, 8.2607f, 24.4245f)
                curveTo(12.1814f, 18.589f, 17.9614f, 15.5715f, 24.0f, 15.5715f)
                curveTo(30.3341f, 15.5715f, 35.8092f, 18.6201f, 39.7718f, 24.6019f)
                curveTo(36.0254f, 30.2919f, 30.3603f, 33.4286f, 24.0f, 33.4286f)
                close()
            }
        }
        .build()
        return _showOutline!!
    }

private var _showOutline: ImageVector? = null
