package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RtPopcornFresh: ImageVector
    get() {
        if (_rtPopcornFresh != null) {
            return _rtPopcornFresh!!
        }
        _rtPopcornFresh = Builder(name = "RtPopcornFresh", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFD700)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(10.4557f, 13.5019f)
                curveTo(10.5708f, 15.9533f, 16.5863f, 17.8968f, 23.9843f, 17.8551f)
                curveTo(30.4454f, 17.8179f, 35.8354f, 16.2779f, 37.1559f, 14.254f)
                curveTo(36.8549f, 13.9159f, 36.4388f, 13.685f, 35.9711f, 13.6225f)
                curveTo(35.9755f, 13.5629f, 35.9799f, 13.5033f, 35.9785f, 13.4423f)
                curveTo(35.9726f, 12.4727f, 35.2688f, 11.6745f, 34.3495f, 11.5255f)
                curveTo(34.3732f, 11.4004f, 34.3864f, 11.2709f, 34.3864f, 11.1383f)
                curveTo(34.3805f, 10.0586f, 33.5085f, 9.1873f, 32.4388f, 9.1948f)
                curveTo(32.4152f, 9.1948f, 32.3916f, 9.1977f, 32.3665f, 9.1992f)
                curveTo(32.4344f, 8.9967f, 32.4727f, 8.7822f, 32.4727f, 8.5559f)
                curveTo(32.4668f, 7.4761f, 31.5948f, 6.6063f, 30.5251f, 6.6123f)
                curveTo(30.2802f, 6.6123f, 30.0456f, 6.6629f, 29.8302f, 6.7478f)
                curveTo(29.5247f, 6.167f, 28.936f, 5.7604f, 28.2514f, 5.7142f)
                curveTo(28.1319f, 4.7432f, 27.3115f, 3.9941f, 26.32f, 4.0f)
                curveTo(25.6959f, 4.003f, 25.1441f, 4.3068f, 24.7914f, 4.7715f)
                curveTo(24.4344f, 4.3798f, 23.9238f, 4.1341f, 23.3558f, 4.1371f)
                curveTo(22.2861f, 4.143f, 21.4229f, 5.0232f, 21.4303f, 6.103f)
                curveTo(21.4303f, 6.3085f, 21.4642f, 6.508f, 21.5247f, 6.6942f)
                curveTo(21.088f, 6.7761f, 20.7044f, 7.0055f, 20.424f, 7.3286f)
                curveTo(20.2012f, 6.4917f, 19.4443f, 5.8751f, 18.5443f, 5.881f)
                curveTo(17.7032f, 5.8855f, 16.9935f, 6.4306f, 16.7294f, 7.1887f)
                curveTo(16.0153f, 7.4805f, 15.5136f, 8.1865f, 15.5181f, 9.0116f)
                curveTo(15.5181f, 9.1948f, 15.5476f, 9.3705f, 15.5948f, 9.5388f)
                curveTo(15.3543f, 9.4331f, 15.0887f, 9.3735f, 14.8084f, 9.375f)
                curveTo(13.9644f, 9.3794f, 13.2517f, 9.929f, 12.9906f, 10.69f)
                curveTo(12.7397f, 10.5724f, 12.4609f, 10.5054f, 12.1658f, 10.5068f)
                curveTo(11.0961f, 10.5128f, 10.2329f, 11.393f, 10.2403f, 12.4727f)
                curveTo(10.2418f, 12.8287f, 10.3406f, 13.1608f, 10.5059f, 13.4482f)
                curveTo(10.4896f, 13.4661f, 10.4734f, 13.484f, 10.4572f, 13.5019f)
                horizontalLineTo(10.4557f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFA320A)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(34.3479f, 10.7539f)
                curveTo(34.373f, 10.8775f, 34.3862f, 11.0056f, 34.3877f, 11.1367f)
                curveTo(34.3877f, 11.2692f, 34.3759f, 11.3988f, 34.3508f, 11.5239f)
                curveTo(35.2686f, 11.6728f, 35.9739f, 12.4711f, 35.9798f, 13.4406f)
                curveTo(35.9798f, 13.5017f, 35.9768f, 13.5613f, 35.9724f, 13.6208f)
                curveTo(36.4401f, 13.6834f, 36.8562f, 13.9142f, 37.1572f, 14.2523f)
                curveTo(35.8366f, 16.2763f, 30.4467f, 17.8162f, 23.9856f, 17.8535f)
                curveTo(16.5876f, 17.8967f, 10.5706f, 15.9531f, 10.4555f, 13.5002f)
                curveTo(10.4717f, 13.4823f, 10.4865f, 13.463f, 10.5042f, 13.4466f)
                curveTo(10.3906f, 13.25f, 10.3109f, 13.0311f, 10.2711f, 12.7987f)
                curveTo(9.5614f, 13.4779f, 9.1335f, 14.0259f, 9.2087f, 14.8227f)
                curveTo(9.2161f, 14.9285f, 12.3426f, 37.5258f, 12.3426f, 37.5258f)
                curveTo(12.7027f, 41.1046f, 17.8521f, 43.9656f, 23.9856f, 43.9999f)
                curveTo(30.1192f, 43.9641f, 35.2671f, 41.1046f, 35.6286f, 37.5258f)
                curveTo(35.6286f, 37.5258f, 38.7551f, 14.9299f, 38.7625f, 14.8227f)
                curveTo(38.913f, 13.2291f, 37.0642f, 11.7815f, 34.3479f, 10.7539f)
                close()
                moveTo(13.4802f, 38.0694f)
                lineTo(10.9439f, 18.482f)
                curveTo(11.8484f, 19.2564f, 13.076f, 19.797f, 14.3331f, 20.2051f)
                lineTo(16.3102f, 40.4449f)
                curveTo(15.1534f, 39.8432f, 14.0483f, 38.9407f, 13.4802f, 38.0679f)
                verticalLineTo(38.0694f)
                close()
                moveTo(18.2785f, 41.2893f)
                lineTo(16.6717f, 20.7755f)
                curveTo(18.6134f, 21.194f, 20.8915f, 21.4338f, 22.6518f, 21.4859f)
                lineTo(22.8997f, 42.2857f)
                curveTo(21.3062f, 42.1278f, 20.1936f, 41.9297f, 18.2785f, 41.2893f)
                close()
                moveTo(29.6913f, 41.2893f)
                curveTo(27.7761f, 41.9282f, 26.6636f, 42.1263f, 25.0701f, 42.2857f)
                lineTo(25.318f, 21.4859f)
                curveTo(27.0782f, 21.4353f, 29.3563f, 21.194f, 31.2981f, 20.7755f)
                lineTo(29.6913f, 41.2893f)
                close()
                moveTo(34.4895f, 38.0694f)
                curveTo(33.9215f, 38.9407f, 32.8163f, 39.8432f, 31.6596f, 40.4464f)
                lineTo(33.6367f, 20.2066f)
                curveTo(34.8938f, 19.7985f, 36.0955f, 19.2745f, 37.0f, 18.5f)
                lineTo(34.4895f, 38.0709f)
                verticalLineTo(38.0694f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(16.6719f, 20.7773f)
                lineTo(18.2787f, 41.2912f)
                curveTo(20.1938f, 41.9301f, 21.3063f, 42.1282f, 22.8999f, 42.2875f)
                lineTo(22.652f, 21.4877f)
                curveTo(20.8917f, 21.4371f, 18.6136f, 21.1958f, 16.6719f, 20.7773f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(25.0703f, 42.286f)
                curveTo(26.6638f, 42.1282f, 27.7763f, 41.9301f, 29.6915f, 41.2897f)
                lineTo(31.2983f, 20.7759f)
                curveTo(29.3566f, 21.1944f, 27.0784f, 21.4342f, 25.3182f, 21.4863f)
                lineTo(25.0703f, 42.286f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(31.6455f, 40.5499f)
                curveTo(32.8022f, 39.9483f, 33.9271f, 38.9226f, 34.4951f, 38.0499f)
                lineTo(37.0118f, 18.45f)
                curveTo(36.1073f, 19.2245f, 34.8797f, 19.7651f, 33.6226f, 20.1732f)
                lineTo(31.6455f, 40.4129f)
                verticalLineTo(40.5499f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(10.9443f, 18.4819f)
                lineTo(13.4807f, 38.0694f)
                curveTo(14.0487f, 38.9406f, 15.1539f, 39.8432f, 16.3106f, 40.4463f)
                lineTo(14.3335f, 20.2066f)
                curveTo(13.0764f, 19.7985f, 11.8488f, 19.2579f, 10.9443f, 18.4834f)
                verticalLineTo(18.4819f)
                close()
            }
        }
        .build()
        return _rtPopcornFresh!!
    }

private var _rtPopcornFresh: ImageVector? = null
