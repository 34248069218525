package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoSettingsFilled: ImageVector
    get() {
        if (_ruwidoSettingsFilled != null) {
            return _ruwidoSettingsFilled!!
        }
        _ruwidoSettingsFilled = Builder(name = "RuwidoSettingsFilled", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(37.506f, 26.842f)
                curveToRelative(0.222f, 0.117f, 0.38f, 0.328f, 0.454f, 0.592f)
                lineToRelative(-0.01f, 0.01f)
                arcToRelative(0.955f, 0.955f, 0.0f, false, true, -0.106f, 0.761f)
                lineToRelative(-3.273f, 5.632f)
                curveToRelative(-0.275f, 0.444f, -0.877f, 0.602f, -1.352f, 0.349f)
                lineToRelative(-2.44f, -1.385f)
                curveToRelative(-0.707f, 0.55f, -1.541f, 1.025f, -2.523f, 1.438f)
                verticalLineToRelative(2.789f)
                curveToRelative(0.0f, 0.507f, -0.465f, 0.972f, -0.982f, 0.972f)
                horizontalLineToRelative(-6.558f)
                curveToRelative(-0.518f, 0.0f, -0.982f, -0.465f, -0.982f, -0.972f)
                verticalLineToRelative(-2.79f)
                arcToRelative(10.771f, 10.771f, 0.0f, false, true, -2.524f, -1.437f)
                lineToRelative(-2.44f, 1.385f)
                curveToRelative(-0.443f, 0.264f, -1.066f, 0.105f, -1.351f, -0.349f)
                lineToRelative(-3.284f, -5.642f)
                arcToRelative(0.942f, 0.942f, 0.0f, false, true, -0.106f, -0.719f)
                curveToRelative(0.063f, -0.264f, 0.232f, -0.496f, 0.454f, -0.634f)
                lineToRelative(2.44f, -1.384f)
                curveToRelative(-0.064f, -0.454f, -0.127f, -0.92f, -0.127f, -1.437f)
                curveToRelative(0.0f, -0.518f, 0.063f, -0.993f, 0.127f, -1.458f)
                lineToRelative(-2.43f, -1.384f)
                arcToRelative(1.015f, 1.015f, 0.0f, false, true, -0.358f, -1.342f)
                lineToRelative(3.305f, -5.653f)
                curveToRelative(0.285f, -0.454f, 0.898f, -0.623f, 1.352f, -0.38f)
                lineToRelative(2.439f, 1.416f)
                arcToRelative(11.794f, 11.794f, 0.0f, false, true, 2.524f, -1.459f)
                verticalLineToRelative(-2.789f)
                curveToRelative(0.0f, -0.444f, 0.433f, -0.972f, 0.982f, -0.972f)
                horizontalLineToRelative(6.558f)
                curveToRelative(0.517f, 0.0f, 0.982f, 0.465f, 0.982f, 0.972f)
                verticalLineToRelative(2.79f)
                curveToRelative(0.918f, 0.38f, 1.763f, 0.866f, 2.524f, 1.458f)
                lineToRelative(2.428f, -1.406f)
                curveToRelative(0.476f, -0.253f, 1.12f, -0.074f, 1.363f, 0.38f)
                lineToRelative(3.273f, 5.632f)
                arcToRelative(0.941f, 0.941f, 0.0f, false, true, 0.106f, 0.719f)
                arcToRelative(1.038f, 1.038f, 0.0f, false, true, -0.454f, 0.634f)
                lineToRelative(-2.44f, 1.384f)
                lineToRelative(0.004f, 0.03f)
                curveToRelative(0.062f, 0.476f, 0.123f, 0.942f, 0.123f, 1.428f)
                curveToRelative(0.0f, 0.497f, -0.063f, 0.972f, -0.127f, 1.437f)
                lineToRelative(2.43f, 1.384f)
                close()
                moveTo(17.928f, 30.857f)
                curveToRelative(0.887f, 0.793f, 1.964f, 1.406f, 3.115f, 1.807f)
                horizontalLineToRelative(-0.01f)
                curveToRelative(0.39f, 0.137f, 0.665f, 0.518f, 0.665f, 0.93f)
                verticalLineToRelative(2.43f)
                horizontalLineToRelative(4.572f)
                verticalLineToRelative(-2.43f)
                curveToRelative(0.0f, -0.401f, 0.275f, -0.793f, 0.666f, -0.93f)
                arcToRelative(9.458f, 9.458f, 0.0f, false, false, 3.115f, -1.807f)
                curveToRelative(0.317f, -0.274f, 0.802f, -0.338f, 1.15f, -0.126f)
                lineToRelative(2.155f, 1.215f)
                lineToRelative(2.292f, -3.91f)
                lineToRelative(-2.144f, -1.215f)
                arcToRelative(1.011f, 1.011f, 0.0f, false, true, -0.486f, -1.035f)
                curveToRelative(0.127f, -0.687f, 0.18f, -1.258f, 0.18f, -1.786f)
                arcToRelative(8.44f, 8.44f, 0.0f, false, false, -0.17f, -1.796f)
                curveToRelative(-0.073f, -0.402f, 0.138f, -0.835f, 0.487f, -1.046f)
                lineToRelative(2.154f, -1.215f)
                lineToRelative(-2.292f, -3.91f)
                lineToRelative(-2.154f, 1.215f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, -1.151f, -0.105f)
                curveToRelative(-0.887f, -0.793f, -1.964f, -1.406f, -3.115f, -1.807f)
                arcToRelative(1.002f, 1.002f, 0.0f, false, true, -0.666f, -0.93f)
                verticalLineToRelative(-2.43f)
                lineTo(21.72f, 11.976f)
                verticalLineToRelative(2.42f)
                curveToRelative(0.0f, 0.401f, -0.274f, 0.792f, -0.665f, 0.93f)
                arcToRelative(9.452f, 9.452f, 0.0f, false, false, -3.115f, 1.806f)
                arcToRelative(1.003f, 1.003f, 0.0f, false, true, -1.151f, 0.106f)
                lineToRelative(-2.155f, -1.215f)
                lineToRelative(-2.291f, 3.91f)
                lineToRelative(2.154f, 1.214f)
                curveToRelative(0.359f, 0.212f, 0.55f, 0.634f, 0.486f, 1.036f)
                curveToRelative(-0.085f, 0.486f, -0.18f, 1.14f, -0.18f, 1.806f)
                curveToRelative(0.0f, 0.666f, 0.053f, 1.247f, 0.17f, 1.776f)
                curveToRelative(0.073f, 0.401f, -0.138f, 0.834f, -0.487f, 1.046f)
                lineToRelative(-2.154f, 1.215f)
                lineToRelative(2.292f, 3.91f)
                lineToRelative(2.154f, -1.216f)
                curveToRelative(0.38f, -0.18f, 0.845f, -0.127f, 1.151f, 0.137f)
                close()
                moveTo(24.002f, 19.214f)
                curveToRelative(2.66f, 0.0f, 4.826f, 2.145f, 4.826f, 4.786f)
                curveToRelative(0.0f, 2.642f, -2.165f, 4.787f, -4.826f, 4.787f)
                curveToRelative(-2.661f, 0.0f, -4.826f, -2.145f, -4.826f, -4.787f)
                reflectiveCurveToRelative(2.165f, -4.786f, 4.826f, -4.786f)
                close()
                moveTo(26.885f, 24.032f)
                arcToRelative(2.87f, 2.87f, 0.0f, false, false, -2.883f, -2.864f)
                curveToRelative(-1.584f, 0.0f, -2.883f, 1.279f, -2.883f, 2.864f)
                reflectiveCurveToRelative(1.299f, 2.863f, 2.883f, 2.863f)
                reflectiveCurveToRelative(2.883f, -1.278f, 2.883f, -2.863f)
                close()
            }
        }
        .build()
        return _ruwidoSettingsFilled!!
    }

private var _ruwidoSettingsFilled: ImageVector? = null
