package com.brdgwtr.designsystem.tokens.internal

import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import com.brdgwtr.designsystem.foundation.Lato

internal object BwTypographyTokens {
    val typographyMobileDisplay = TextStyle(
        fontSize = 48.sp,
        lineHeight = 52.sp,
        fontWeight = FontWeight.ExtraBold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyMobileHeadlineLarge = TextStyle(
        fontSize = 42.sp,
        lineHeight = 46.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyMobileHeadlineMedium = TextStyle(
        fontSize = 32.sp,
        lineHeight = 38.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyMobileTitleLarge = TextStyle(
        fontSize = 24.sp,
        lineHeight = 28.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyMobileTitleMedium = TextStyle(
        fontSize = 18.sp,
        lineHeight = 24.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyMobileTitleSmall = TextStyle(
        fontSize = 16.sp,
        lineHeight = 20.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyMobileBodyLarge = TextStyle(
        fontSize = 17.sp,
        lineHeight = 24.sp,
        fontWeight = FontWeight.Normal,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyMobileBodyMedium = TextStyle(
        fontSize = 14.sp,
        lineHeight = 20.sp,
        fontWeight = FontWeight.Normal,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyMobileBodySmall = TextStyle(
        fontSize = 12.sp,
        lineHeight = 16.sp,
        fontWeight = FontWeight.Normal,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyMobileLabel = TextStyle(
        fontSize = 15.sp,
        lineHeight = 20.sp,
        fontWeight = FontWeight.Medium,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyMobileLabelInteraction = TextStyle(
        fontSize = 15.sp,
        lineHeight = 20.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvDisplay = TextStyle(
        fontSize = 32.sp,
        lineHeight = 39.sp,
        fontWeight = FontWeight.ExtraBold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvHeadlineLarge = TextStyle(
        fontSize = 28.sp,
        lineHeight = 32.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvHeadlineMedium = TextStyle(
        fontSize = 24.sp,
        lineHeight = 29.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvTitleLarge = TextStyle(
        fontSize = 16.sp,
        lineHeight = 19.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvTitleMedium = TextStyle(
        fontSize = 14.sp,
        lineHeight = 17.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvTitleSmall = TextStyle(
        fontSize = 12.sp,
        lineHeight = 18.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvBodyLarge = TextStyle(
        fontSize = 21.sp,
        lineHeight = 25.sp,
        fontWeight = FontWeight.Normal,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvBodyMedium = TextStyle(
        fontSize = 14.sp,
        lineHeight = 21.sp,
        fontWeight = FontWeight.Normal,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvBodySmall = TextStyle(
        fontSize = 12.sp,
        lineHeight = 16.sp,
        fontWeight = FontWeight.Normal,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvLabel = TextStyle(
        fontSize = 11.sp,
        lineHeight = 13.sp,
        fontWeight = FontWeight.Medium,
        fontFamily = Lato, // TODO - should be autogenerated
    )
    val typographyTvLabelInteraction = TextStyle(
        fontSize = 14.sp,
        lineHeight = 14.sp,
        fontWeight = FontWeight.Bold,
        fontFamily = Lato, // TODO - should be autogenerated
    )
}
