package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RtCertifiedFresh: ImageVector
    get() {
        if (_rtCertifiedFresh != null) {
            return _rtCertifiedFresh!!
        }
        _rtCertifiedFresh = Builder(name = "RtCertifiedFresh", defaultWidth = 48.0.dp, defaultHeight
                = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(26.5448f, 13.4435f)
                curveTo(27.5094f, 13.3463f, 29.6096f, 13.4003f, 30.6346f, 14.48f)
                curveTo(30.6949f, 14.5448f, 30.6346f, 14.696f, 30.5542f, 14.6744f)
                curveTo(29.0569f, 14.2425f, 26.7055f, 16.3804f, 25.1882f, 14.9983f)
                curveTo(25.148f, 15.4086f, 24.8063f, 17.3629f, 22.2138f, 17.0714f)
                curveTo(22.1535f, 17.0714f, 22.1233f, 16.9958f, 22.1736f, 16.9526f)
                curveTo(22.5755f, 16.5855f, 23.0679f, 15.5706f, 22.6459f, 14.9227f)
                curveTo(21.3295f, 15.8621f, 20.6462f, 15.9053f, 18.2043f, 14.7716f)
                curveTo(18.1239f, 14.7392f, 18.1742f, 14.5988f, 18.2646f, 14.5772f)
                curveTo(18.7671f, 14.4584f, 19.9729f, 13.9834f, 21.0481f, 13.7674f)
                curveTo(21.2491f, 13.7242f, 21.4601f, 13.7026f, 21.651f, 13.7026f)
                curveTo(20.5356f, 13.4219f, 20.0533f, 13.1736f, 19.3097f, 13.1736f)
                curveTo(19.2293f, 13.1736f, 19.1891f, 13.0656f, 19.2494f, 13.0116f)
                curveTo(20.405f, 11.8239f, 22.4851f, 11.7591f, 23.49f, 12.6445f)
                lineTo(22.5856f, 10.6254f)
                lineTo(23.6809f, 10.4527f)
                curveTo(23.6809f, 10.4527f, 23.9622f, 11.5324f, 24.2235f, 12.5041f)
                curveTo(25.359f, 11.1653f, 27.1276f, 11.2948f, 27.7205f, 12.4717f)
                curveTo(27.7607f, 12.5365f, 27.7004f, 12.6229f, 27.63f, 12.6121f)
                curveTo(27.0372f, 12.5041f, 26.6252f, 13.0332f, 26.5347f, 13.4759f)
                lineTo(26.5448f, 13.4435f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFD700)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.7325f, 32.5224f)
                curveTo(12.3458f, 30.7193f, 11.4514f, 28.4519f, 11.3007f, 25.8065f)
                curveTo(11.0294f, 21.2069f, 12.4965f, 16.888f, 18.1841f, 14.7178f)
                curveTo(18.1841f, 14.7394f, 18.1841f, 14.7609f, 18.2042f, 14.7717f)
                curveTo(18.1238f, 14.7394f, 18.174f, 14.599f, 18.2645f, 14.5774f)
                curveTo(18.7669f, 14.4586f, 19.9727f, 13.9835f, 21.0479f, 13.7676f)
                curveTo(21.2489f, 13.7244f, 21.4599f, 13.7028f, 21.6509f, 13.7028f)
                curveTo(20.5355f, 13.4221f, 20.0531f, 13.1738f, 19.3095f, 13.1738f)
                curveTo(19.2291f, 13.1738f, 19.1889f, 13.0658f, 19.2492f, 13.0118f)
                curveTo(20.4048f, 11.8241f, 22.4849f, 11.7593f, 23.4898f, 12.6447f)
                lineTo(22.5854f, 10.6256f)
                lineTo(23.6807f, 10.4528f)
                curveTo(23.6807f, 10.4528f, 23.9621f, 11.5326f, 24.2233f, 12.5043f)
                curveTo(25.3588f, 11.1655f, 27.1274f, 11.295f, 27.7203f, 12.4719f)
                curveTo(27.7605f, 12.5367f, 27.7002f, 12.6231f, 27.6299f, 12.6123f)
                curveTo(27.037f, 12.5043f, 26.625f, 13.0334f, 26.5345f, 13.4761f)
                curveTo(27.4992f, 13.3897f, 29.5994f, 13.4437f, 30.6244f, 14.5234f)
                curveTo(30.554f, 14.4478f, 30.4636f, 14.3938f, 30.3832f, 14.3291f)
                curveTo(34.4831f, 15.7111f, 37.3269f, 18.8423f, 37.6283f, 24.079f)
                curveTo(37.8193f, 27.4153f, 36.8043f, 30.2982f, 35.0458f, 32.5332f)
                curveTo(35.5784f, 32.6196f, 36.111f, 32.7168f, 36.6436f, 32.8032f)
                curveTo(38.8844f, 29.8555f, 40.1807f, 26.1197f, 40.1807f, 22.1139f)
                curveTo(40.1807f, 12.9794f, 33.2672f, 5.8208f, 24.4545f, 5.8208f)
                curveTo(15.6417f, 5.8208f, 8.7383f, 12.9362f, 8.7383f, 22.0707f)
                curveTo(8.7383f, 26.0873f, 10.0345f, 29.8231f, 12.2754f, 32.7708f)
                curveTo(12.7578f, 32.6844f, 13.2401f, 32.598f, 13.7325f, 32.5224f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFA6E0F)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(12.2763f, 32.7706f)
                curveTo(10.0354f, 29.8229f, 8.7391f, 26.0871f, 8.7391f, 22.0705f)
                curveTo(8.7391f, 12.936f, 15.6526f, 5.7774f, 24.4654f, 5.7774f)
                curveTo(33.2781f, 5.7774f, 40.1916f, 12.936f, 40.1916f, 22.0705f)
                curveTo(40.1916f, 26.0763f, 38.8953f, 29.8229f, 36.6545f, 32.7598f)
                curveTo(36.9258f, 32.803f, 37.187f, 32.8461f, 37.4584f, 32.9001f)
                curveTo(39.6188f, 29.9093f, 40.9151f, 26.1518f, 40.9151f, 22.0705f)
                curveTo(40.9252f, 12.3098f, 33.5595f, 5.0f, 24.4654f, 5.0f)
                curveTo(15.3713f, 5.0f, 8.0156f, 12.3098f, 8.0156f, 22.0705f)
                curveTo(8.0156f, 26.1626f, 9.3119f, 29.9093f, 11.4824f, 32.9109f)
                curveTo(11.7437f, 32.8569f, 12.015f, 32.8245f, 12.2763f, 32.7706f)
                close()
            }
            path(fill = SolidColor(Color(0xFF0AC855)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(33.6191f, 39.9615f)
                curveTo(35.4178f, 40.0803f, 36.9151f, 41.0412f, 37.8195f, 42.3261f)
                curveTo(37.8496f, 42.3585f, 37.8898f, 42.3585f, 37.9099f, 42.3261f)
                curveTo(38.2214f, 40.9872f, 38.3018f, 39.5512f, 38.1511f, 38.1044f)
                curveTo(38.1511f, 38.0936f, 38.1611f, 38.0828f, 38.1812f, 38.072f)
                curveTo(39.0253f, 37.7913f, 39.9599f, 37.6293f, 40.9547f, 37.6509f)
                curveTo(41.0049f, 37.6509f, 41.0049f, 37.5969f, 40.9547f, 37.5429f)
                curveTo(39.8996f, 36.4524f, 38.6133f, 35.5886f, 37.1965f, 35.0488f)
                curveTo(36.7041f, 36.528f, 36.2318f, 38.018f, 35.7595f, 39.4972f)
                curveTo(35.7595f, 39.5296f, 35.7093f, 39.5404f, 35.6791f, 39.5404f)
                lineTo(33.6191f, 39.9615f)
                close()
            }
            path(fill = SolidColor(Color(0xFF0B4902)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(35.6791f, 39.5403f)
                lineTo(33.6191f, 39.9614f)
                curveTo(33.6191f, 39.9614f, 33.6493f, 39.7563f, 33.7297f, 39.2056f)
                curveTo(34.7948f, 39.3675f, 35.6791f, 39.5403f, 35.6791f, 39.5403f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFA3200)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.7427f, 32.5222f)
                curveTo(20.7969f, 31.4101f, 28.0119f, 31.3993f, 35.0661f, 32.4898f)
                curveTo(36.8246f, 30.2548f, 37.8395f, 27.3827f, 37.6486f, 24.0356f)
                curveTo(37.3472f, 18.8097f, 34.5034f, 15.6677f, 30.4035f, 14.2857f)
                curveTo(30.4839f, 14.3505f, 30.5743f, 14.4044f, 30.6447f, 14.48f)
                curveTo(30.705f, 14.5448f, 30.6447f, 14.696f, 30.5643f, 14.6744f)
                curveTo(29.067f, 14.2425f, 26.7156f, 16.3803f, 25.1983f, 14.9983f)
                curveTo(25.1581f, 15.4086f, 24.8164f, 17.3629f, 22.2238f, 17.0714f)
                curveTo(22.1635f, 17.0714f, 22.1334f, 16.9958f, 22.1836f, 16.9526f)
                curveTo(22.5856f, 16.5855f, 23.078f, 15.5705f, 22.6559f, 14.9227f)
                curveTo(21.3395f, 15.8621f, 20.6562f, 15.9053f, 18.2144f, 14.7715f)
                curveTo(18.1943f, 14.7715f, 18.2043f, 14.7392f, 18.1943f, 14.7176f)
                curveTo(12.5067f, 16.8878f, 11.0396f, 21.2175f, 11.3109f, 25.8063f)
                curveTo(11.4617f, 28.4625f, 12.3761f, 30.7083f, 13.7528f, 32.5222f)
                horizontalLineTo(13.7427f)
                close()
            }
            path(fill = SolidColor(Color(0xFF0AC855)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.3107f, 39.5406f)
                curveTo(13.3107f, 39.5406f, 13.2303f, 39.5406f, 13.2303f, 39.4974f)
                curveTo(12.7581f, 38.0074f, 12.2858f, 36.5281f, 11.7934f, 35.0381f)
                curveTo(10.3765f, 35.578f, 9.1003f, 36.4418f, 8.0452f, 37.5323f)
                curveTo(7.9849f, 37.5863f, 7.9849f, 37.651f, 8.0452f, 37.6402f)
                curveTo(9.04f, 37.6295f, 9.9746f, 37.7806f, 10.8186f, 38.0613f)
                curveTo(10.8387f, 38.0613f, 10.8488f, 38.0721f, 10.8488f, 38.0937f)
                curveTo(10.688f, 39.5406f, 10.7785f, 40.9658f, 11.09f, 42.3155f)
                curveTo(11.1f, 42.3479f, 11.1503f, 42.3586f, 11.1804f, 42.3155f)
                curveTo(12.0848f, 41.0198f, 13.582f, 40.0696f, 15.3808f, 39.9509f)
                lineTo(13.3208f, 39.5298f)
                lineTo(13.3107f, 39.5406f)
                close()
            }
            path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(35.6791f, 39.5404f)
                curveTo(28.3034f, 38.1476f, 20.6865f, 38.1476f, 13.3107f, 39.5404f)
                curveTo(13.2705f, 39.5404f, 13.2303f, 39.5404f, 13.2303f, 39.4972f)
                curveTo(12.547f, 37.3378f, 11.8436f, 35.1783f, 11.1201f, 33.0297f)
                curveTo(11.11f, 32.9973f, 11.1402f, 32.9649f, 11.1904f, 32.9541f)
                curveTo(19.9529f, 31.2373f, 29.047f, 31.2373f, 37.8095f, 32.9541f)
                curveTo(37.8597f, 32.9541f, 37.8899f, 32.9973f, 37.8798f, 33.0297f)
                curveTo(37.1563f, 35.1783f, 36.4529f, 37.3378f, 35.7696f, 39.4972f)
                curveTo(35.7696f, 39.5296f, 35.7193f, 39.5404f, 35.6892f, 39.5404f)
                horizontalLineTo(35.6791f)
                close()
            }
            path(fill = SolidColor(Color(0xFF0B4902)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(13.3105f, 39.5403f)
                lineTo(15.3705f, 39.9614f)
                curveTo(15.3705f, 39.9614f, 15.3404f, 39.7563f, 15.26f, 39.2056f)
                curveTo(14.1948f, 39.3675f, 13.3105f, 39.5403f, 13.3105f, 39.5403f)
                close()
            }
        }
        .build()
        return _rtCertifiedFresh!!
    }

private var _rtCertifiedFresh: ImageVector? = null
