package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Share: ImageVector
    get() {
        if (_share != null) {
            return _share!!
        }
        _share = Builder(name = "Share", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(6.0f, 39.0f)
                lineTo(6.0f, 9.0f)
                lineToRelative(36.0f, 15.0f)
                lineTo(6.0f, 39.0f)
                close()
                moveTo(8.842f, 34.64f)
                lineTo(34.611f, 24.0f)
                lineTo(8.842f, 13.219f)
                verticalLineToRelative(7.875f)
                lineTo(20.305f, 24.0f)
                lineTo(8.842f, 26.813f)
                verticalLineToRelative(7.828f)
                close()
            }
        }
        .build()
        return _share!!
    }

private var _share: ImageVector? = null
