package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ShopFilled: ImageVector
    get() {
        if (_shopFilled != null) {
            return _shopFilled!!
        }
        _shopFilled = Builder(name = "ShopFilled", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(16.03f, 44.0f)
                curveToRelative(-1.013f, 0.0f, -1.877f, -0.354f, -2.591f, -1.062f)
                curveToRelative(-0.715f, -0.708f, -1.072f, -1.559f, -1.072f, -2.553f)
                reflectiveCurveToRelative(0.361f, -1.842f, 1.082f, -2.543f)
                arcToRelative(3.607f, 3.607f, 0.0f, false, true, 2.603f, -1.051f)
                curveToRelative(1.013f, 0.0f, 1.877f, 0.354f, 2.59f, 1.062f)
                curveToRelative(0.715f, 0.708f, 1.072f, 1.559f, 1.072f, 2.553f)
                reflectiveCurveToRelative(-0.36f, 1.842f, -1.082f, 2.543f)
                arcTo(3.605f, 3.605f, 0.0f, false, true, 16.03f, 44.0f)
                close()
                moveTo(36.438f, 44.0f)
                curveToRelative(-1.013f, 0.0f, -1.877f, -0.354f, -2.591f, -1.062f)
                curveToRelative(-0.715f, -0.708f, -1.072f, -1.559f, -1.072f, -2.553f)
                reflectiveCurveToRelative(0.361f, -1.842f, 1.083f, -2.543f)
                curveToRelative(0.721f, -0.7f, 1.588f, -1.051f, 2.602f, -1.051f)
                curveToRelative(1.013f, 0.0f, 1.877f, 0.354f, 2.59f, 1.062f)
                curveToRelative(0.715f, 0.708f, 1.072f, 1.559f, 1.072f, 2.553f)
                reflectiveCurveToRelative(-0.36f, 1.842f, -1.082f, 2.543f)
                curveToRelative(-0.721f, 0.7f, -1.589f, 1.051f, -2.602f, 1.051f)
                close()
                moveTo(11.858f, 7.955f)
                horizontalLineToRelative(30.054f)
                curveToRelative(0.78f, 0.0f, 1.375f, 0.35f, 1.783f, 1.051f)
                curveToRelative(0.407f, 0.701f, 0.407f, 1.402f, -0.001f, 2.103f)
                lineToRelative(-6.888f, 12.165f)
                arcToRelative(4.504f, 4.504f, 0.0f, false, true, -1.457f, 1.527f)
                arcToRelative(3.556f, 3.556f, 0.0f, false, true, -1.961f, 0.576f)
                horizontalLineToRelative(-15.46f)
                lineToRelative(-2.857f, 5.206f)
                horizontalLineToRelative(25.051f)
                verticalLineToRelative(3.004f)
                lineTo(15.531f, 33.587f)
                curveToRelative(-1.429f, 0.0f, -2.458f, -0.467f, -3.087f, -1.402f)
                curveToRelative(-0.63f, -0.934f, -0.62f, -1.986f, 0.025f, -3.154f)
                lineToRelative(3.266f, -5.907f)
                lineTo(7.98f, 7.004f)
                lineTo(4.0f, 7.004f)
                lineTo(4.0f, 4.0f)
                horizontalLineToRelative(5.97f)
                lineToRelative(1.887f, 3.955f)
                close()
            }
        }
        .build()
        return _shopFilled!!
    }

private var _shopFilled: ImageVector? = null
