package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Close: ImageVector
    get() {
        if (_close != null) {
            return _close!!
        }
        _close = Builder(name = "Close", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(12.0005f, 7.9319f)
                curveTo(10.8083f, 6.7473f, 8.9396f, 6.6836f, 7.8266f, 7.7895f)
                curveTo(6.7135f, 8.8955f, 6.7777f, 10.7523f, 7.9698f, 11.9369f)
                lineTo(20.094f, 23.9841f)
                lineTo(7.9378f, 36.0631f)
                curveTo(6.7457f, 37.2477f, 6.6815f, 39.1045f, 7.7946f, 40.2105f)
                curveTo(8.9076f, 41.3165f, 10.7763f, 41.2527f, 11.9685f, 40.0682f)
                lineTo(24.1247f, 27.9892f)
                lineTo(36.0313f, 39.8202f)
                curveTo(37.2235f, 41.0048f, 39.0922f, 41.0685f, 40.2052f, 39.9626f)
                curveTo(41.3183f, 38.8566f, 41.2541f, 36.9997f, 40.062f, 35.8152f)
                lineTo(28.1553f, 23.9841f)
                lineTo(40.03f, 12.1849f)
                curveTo(41.2221f, 11.0003f, 41.2863f, 9.1434f, 40.1732f, 8.0375f)
                curveTo(39.0602f, 6.9315f, 37.1915f, 6.9952f, 35.9993f, 8.1798f)
                lineTo(24.1247f, 19.9791f)
                lineTo(12.0005f, 7.9319f)
                close()
            }
        }
        .build()
        return _close!!
    }

private var _close: ImageVector? = null
