package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.HomeFilled: ImageVector
    get() {
        if (_homeFilled != null) {
            return _homeFilled!!
        }
        _homeFilled = Builder(name = "HomeFilled", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(21.066f, 8.8161f)
                curveTo(21.9955f, 8.2602f, 23.027f, 8.0f, 24.035f, 8.0f)
                curveTo(25.0485f, 8.0f, 25.9787f, 8.2536f, 26.8169f, 8.7355f)
                lineTo(26.9079f, 8.7879f)
                lineTo(37.2008f, 16.2404f)
                curveTo(38.9567f, 17.3279f, 40.0f, 19.2719f, 40.0f, 21.3191f)
                verticalLineTo(34.0645f)
                curveTo(40.0f, 37.2883f, 37.4448f, 40.0f, 34.185f, 40.0f)
                horizontalLineTo(13.815f)
                curveTo(10.5406f, 40.0f, 8.0f, 37.2733f, 8.0f, 34.0645f)
                verticalLineTo(21.3282f)
                curveTo(8.0f, 19.2393f, 9.0867f, 17.3159f, 10.8907f, 16.1896f)
                lineTo(20.9898f, 8.8617f)
                lineTo(21.066f, 8.8161f)
                close()
            }
        }
        .build()
        return _homeFilled!!
    }

private var _homeFilled: ImageVector? = null
