package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RtPopcornRotten: ImageVector
    get() {
        if (_rtPopcornRotten != null) {
            return _rtPopcornRotten!!
        }
        _rtPopcornRotten = Builder(name = "RtPopcornRotten", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFF00641E)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(27.0097f, 30.5626f)
                curveTo(27.2662f, 29.9244f, 28.1769f, 29.5352f, 28.8311f, 29.581f)
                curveTo(29.5294f, 29.6297f, 30.2719f, 30.3665f, 30.4016f, 31.092f)
                curveTo(30.4259f, 31.0662f, 30.4515f, 31.0404f, 30.4772f, 31.0161f)
                curveTo(30.7009f, 30.8015f, 30.9831f, 30.6584f, 31.2895f, 30.6184f)
                curveTo(31.2425f, 30.4095f, 31.2311f, 30.1848f, 31.2639f, 29.9559f)
                curveTo(31.3779f, 29.1475f, 32.0235f, 28.5408f, 32.7632f, 28.5465f)
                curveTo(33.2406f, 28.5508f, 33.6596f, 28.7955f, 33.9318f, 29.1718f)
                curveTo(33.9561f, 29.1418f, 33.9831f, 29.1131f, 34.0088f, 29.0845f)
                curveTo(34.3209f, 27.452f, 34.519f, 25.6076f, 34.5632f, 23.6503f)
                curveTo(34.7185f, 16.9211f, 33.0169f, 11.4225f, 30.7651f, 11.371f)
                curveTo(28.5133f, 11.3195f, 26.5608f, 16.7323f, 26.4068f, 23.4614f)
                curveTo(26.4068f, 23.4614f, 26.2871f, 25.9153f, 27.0097f, 30.5611f)
                verticalLineTo(30.5626f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFD700)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(42.2603f, 37.9227f)
                curveTo(42.3928f, 37.6994f, 42.4683f, 37.4319f, 42.4626f, 37.1486f)
                curveTo(42.5054f, 36.23f, 41.8783f, 35.3987f, 41.0047f, 35.4903f)
                curveTo(41.0303f, 35.3858f, 41.046f, 35.2756f, 41.0531f, 35.164f)
                curveTo(41.1059f, 34.244f, 40.4731f, 33.4513f, 39.6422f, 33.3941f)
                curveTo(39.6237f, 33.3941f, 39.6066f, 33.3927f, 39.588f, 33.3913f)
                curveTo(39.675f, 33.1623f, 39.7177f, 32.9076f, 39.702f, 32.6386f)
                curveTo(39.6593f, 31.8732f, 39.1334f, 31.2293f, 38.4493f, 31.1019f)
                curveTo(38.2027f, 31.0562f, 37.9633f, 31.079f, 37.7438f, 31.1549f)
                curveTo(37.5372f, 30.6426f, 37.1011f, 30.262f, 36.5723f, 30.1819f)
                curveTo(36.531f, 29.3492f, 35.931f, 28.6653f, 35.1614f, 28.6109f)
                curveTo(34.6768f, 28.5765f, 34.2307f, 28.8012f, 33.9329f, 29.1746f)
                curveTo(33.6592f, 28.7983f, 33.2417f, 28.5522f, 32.7642f, 28.5494f)
                curveTo(32.0246f, 28.5436f, 31.3775f, 29.1489f, 31.2649f, 29.9587f)
                curveTo(31.2322f, 30.1891f, 31.2436f, 30.4123f, 31.2906f, 30.6212f)
                curveTo(30.9827f, 30.6598f, 30.702f, 30.8029f, 30.4782f, 31.019f)
                curveTo(30.4526f, 31.0433f, 30.4269f, 31.069f, 30.4027f, 31.0948f)
                curveTo(30.273f, 30.3694f, 29.5305f, 29.6325f, 28.8321f, 29.5838f)
                curveTo(28.178f, 29.5381f, 27.2559f, 29.9373f, 27.0107f, 30.5654f)
                curveTo(27.1176f, 31.6628f, 27.8017f, 34.6518f, 30.2716f, 37.3346f)
                horizontalLineTo(30.293f)
                curveTo(30.531f, 37.5521f, 30.8416f, 37.6723f, 31.1737f, 37.6422f)
                curveTo(31.3789f, 37.6236f, 31.5685f, 37.5506f, 31.731f, 37.4362f)
                lineTo(31.7709f, 37.439f)
                curveTo(31.9889f, 37.5907f, 32.2497f, 37.6694f, 32.5262f, 37.6451f)
                curveTo(32.6317f, 37.6351f, 32.7329f, 37.6107f, 32.8284f, 37.5735f)
                curveTo(33.0649f, 38.0643f, 33.5923f, 38.382f, 34.1809f, 38.3304f)
                curveTo(34.6369f, 38.2904f, 35.0246f, 38.0371f, 35.2526f, 37.6808f)
                lineTo(35.3267f, 37.6866f)
                curveTo(35.5547f, 37.9126f, 35.8569f, 38.0457f, 36.1833f, 38.0357f)
                curveTo(36.4526f, 38.4449f, 36.9486f, 38.6982f, 37.4959f, 38.6495f)
                curveTo(37.7025f, 38.6309f, 37.8935f, 38.5722f, 38.0659f, 38.4807f)
                curveTo(38.3524f, 38.8341f, 38.8213f, 39.0444f, 39.3372f, 38.9986f)
                curveTo(39.846f, 38.9543f, 40.2764f, 38.671f, 40.5101f, 38.2789f)
                curveTo(40.7396f, 38.4635f, 41.0275f, 38.5637f, 41.3339f, 38.5365f)
                curveTo(41.6303f, 38.5093f, 41.894f, 38.3691f, 42.0921f, 38.1559f)
                lineTo(42.1249f, 38.1587f)
                curveTo(42.1705f, 38.0929f, 42.2089f, 38.0257f, 42.2446f, 37.9584f)
                curveTo(42.2446f, 37.9584f, 42.246f, 37.9556f, 42.2474f, 37.9541f)
                curveTo(42.2517f, 37.9455f, 42.2588f, 37.9355f, 42.2631f, 37.9269f)
                lineTo(42.2603f, 37.9227f)
                close()
            }
            path(fill = SolidColor(Color(0xFF04A53C)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(33.1181f, 11.0518f)
                curveTo(31.8767f, 9.601f, 30.9846f, 8.9184f, 30.2207f, 9.0072f)
                curveTo(30.0782f, 9.0258f, 8.4951f, 12.0462f, 8.4951f, 12.0462f)
                curveTo(5.0704f, 12.3968f, 2.334f, 17.3889f, 2.2998f, 23.3368f)
                curveTo(2.334f, 29.2847f, 5.0704f, 34.2769f, 8.4951f, 34.6274f)
                curveTo(8.4951f, 34.6274f, 30.1181f, 37.6593f, 30.2207f, 37.6665f)
                curveTo(30.4017f, 37.6665f, 30.5798f, 37.6407f, 30.7551f, 37.5964f)
                curveTo(30.5841f, 37.5449f, 30.4259f, 37.4562f, 30.2919f, 37.3345f)
                horizontalLineTo(30.2706f)
                curveTo(27.8007f, 34.6503f, 27.118f, 31.6613f, 27.0097f, 30.5639f)
                curveTo(27.0097f, 30.5639f, 27.0097f, 30.5625f, 27.0097f, 30.561f)
                curveTo(27.0097f, 30.561f, 27.0097f, 30.5625f, 27.0097f, 30.5639f)
                curveTo(26.2872f, 25.9195f, 26.4069f, 23.4642f, 26.4069f, 23.4642f)
                curveTo(26.5622f, 16.735f, 28.5133f, 11.3208f, 30.7651f, 11.3737f)
                curveTo(33.0169f, 11.4253f, 34.7186f, 16.9239f, 34.5632f, 23.653f)
                curveTo(34.5176f, 25.6104f, 34.3209f, 27.4547f, 34.0088f, 29.0873f)
                curveTo(34.3295f, 28.7324f, 34.7599f, 28.5822f, 35.159f, 28.6108f)
                curveTo(35.2331f, 28.6166f, 35.3043f, 28.628f, 35.3742f, 28.6452f)
                curveTo(36.7423f, 21.1305f, 35.3086f, 13.7933f, 33.1167f, 11.0547f)
                lineTo(33.1181f, 11.0518f)
                close()
                moveTo(5.0575f, 17.8024f)
                lineTo(24.6881f, 16.2443f)
                curveTo(24.2876f, 18.1272f, 24.0582f, 20.3364f, 24.0083f, 22.0434f)
                lineTo(4.1041f, 22.2837f)
                curveTo(4.2552f, 20.7385f, 4.4447f, 19.6596f, 5.0575f, 17.8024f)
                close()
                moveTo(5.0575f, 28.8712f)
                curveTo(4.4461f, 27.014f, 4.2566f, 25.9352f, 4.1041f, 24.3899f)
                lineTo(24.0083f, 24.6303f)
                curveTo(24.0567f, 26.3373f, 24.2876f, 28.5464f, 24.6881f, 30.4294f)
                lineTo(5.0575f, 28.8712f)
                close()
                moveTo(26.8829f, 35.9824f)
                lineTo(8.1388f, 33.5228f)
                curveTo(7.3051f, 32.972f, 6.4414f, 31.9003f, 5.8642f, 30.7785f)
                lineTo(25.2325f, 32.6958f)
                curveTo(25.623f, 33.9149f, 26.1404f, 35.1053f, 26.8815f, 35.9824f)
                horizontalLineTo(26.8829f)
                close()
                moveTo(25.2339f, 13.9778f)
                lineTo(5.8656f, 15.8951f)
                curveTo(6.4414f, 14.7734f, 7.3051f, 13.7017f, 8.1402f, 13.1508f)
                lineTo(26.8829f, 10.6912f)
                curveTo(26.1418f, 11.5683f, 25.6244f, 12.7588f, 25.2339f, 13.9778f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(24.6885f, 16.244f)
                lineTo(5.0579f, 17.8022f)
                curveTo(4.4465f, 19.6594f, 4.257f, 20.7382f, 4.1045f, 22.2835f)
                lineTo(24.0087f, 22.0432f)
                curveTo(24.0571f, 20.3362f, 24.288f, 18.127f, 24.6885f, 16.244f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(26.8829f, 10.6914f)
                lineTo(8.1389f, 13.151f)
                curveTo(7.3051f, 13.7019f, 6.4415f, 14.7735f, 5.8643f, 15.8953f)
                lineTo(25.2326f, 13.978f)
                curveTo(25.6231f, 12.7589f, 26.1404f, 11.5685f, 26.8815f, 10.6914f)
                horizontalLineTo(26.8829f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(5.8652f, 30.7783f)
                curveTo(6.441f, 31.9001f, 7.3047f, 32.9718f, 8.1398f, 33.5226f)
                lineTo(26.8839f, 35.9822f)
                curveTo(26.1428f, 35.1051f, 25.6255f, 33.9147f, 25.235f, 32.6956f)
                lineTo(5.8667f, 30.7783f)
                horizontalLineTo(5.8652f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(24.0087f, 24.6304f)
                lineTo(4.1045f, 24.39f)
                curveTo(4.2556f, 25.9353f, 4.4451f, 27.0142f, 5.0579f, 28.8714f)
                lineTo(24.6885f, 30.4295f)
                curveTo(24.288f, 28.5466f, 24.0586f, 26.3374f, 24.0087f, 24.6304f)
                close()
            }
        }
        .build()
        return _rtPopcornRotten!!
    }

private var _rtPopcornRotten: ImageVector? = null
