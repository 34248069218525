package com.brdgwtr.designsystem.components

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.shape.GenericShape
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.isUnspecified
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

/**
 * Defines the border for a component.
 * @param borderStroke - configures the width and brush for the border
 * @param inset - defines how far (in dp) should the border be from the component it's applied to
 * @param shape - defines the Shape of the border
 */
@Immutable
data class Border(
    val borderStroke: BorderStroke,
    val inset: Dp = 0.dp,
    val shape: Shape = BorderDefaults.DefaultShape,
) {
    companion object {
        val None = Border(
            borderStroke = BorderStroke(width = 0.dp, color = Color.Transparent),
            inset = 0.dp,
            shape = RectangleShape,
        )
    }
}

object BorderDefaults {
    val DefaultShape: Shape = GenericShape { _, _ -> close() }

    @Stable
    fun border(color: Color, width: Dp, inset: Dp, shape: Shape): Border {
        return if (color.isUnspecified) {
            Border.None
        } else {
            Border(
                borderStroke = BorderStroke(width, color),
                inset = inset,
                shape = shape,
            )
        }
    }
}

internal fun Border.toWildBorder(): io.daio.wild.style.Border = io.daio.wild.style.Border(
    borderStroke = borderStroke,
    shape = if (shape == BorderDefaults.DefaultShape) {
        io.daio.wild.style.BorderDefaults.BorderDefaultShape
    } else {
        shape
    },
    inset = inset,
)
