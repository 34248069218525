package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoMuteOutline: ImageVector
    get() {
        if (_ruwidoMuteOutline != null) {
            return _ruwidoMuteOutline!!
        }
        _ruwidoMuteOutline = Builder(name = "RuwidoMuteOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(2.0f, 24.0f)
                curveTo(2.0f, 11.848f, 11.848f, 2.0f, 24.0f, 2.0f)
                reflectiveCurveToRelative(22.0f, 9.848f, 22.0f, 22.0f)
                reflectiveCurveToRelative(-9.848f, 22.0f, -22.0f, 22.0f)
                reflectiveCurveTo(2.0f, 36.152f, 2.0f, 24.0f)
                close()
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(30.952f, 11.0f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -1.603f, -0.798f)
                lineToRelative(-8.265f, 6.24f)
                lineToRelative(-5.133f, -5.165f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -1.418f, 1.41f)
                lineToRelative(4.178f, 4.203f)
                horizontalLineToRelative(-4.88f)
                curveTo(11.0f, 16.89f, 10.0f, 18.932f, 10.0f, 20.865f)
                verticalLineToRelative(5.48f)
                arcToRelative(4.1f, 4.1f, 0.0f, false, false, 4.1f, 4.103f)
                horizontalLineToRelative(6.586f)
                lineToRelative(8.66f, 6.583f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 1.606f, -0.797f)
                verticalLineToRelative(-7.03f)
                lineToRelative(4.747f, 4.776f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, 1.419f, -1.41f)
                lineToRelative(-6.166f, -6.203f)
                lineTo(30.952f, 11.0f)
                close()
                moveTo(22.024f, 28.952f)
                verticalLineToRelative(-8.729f)
                lineToRelative(6.928f, 6.97f)
                verticalLineToRelative(7.025f)
                lineToRelative(-6.928f, -5.266f)
                close()
                moveTo(22.507f, 17.872f)
                lineTo(28.952f, 24.355f)
                lineTo(28.952f, 13.008f)
                lineToRelative(-6.445f, 4.865f)
                close()
                moveTo(20.023f, 18.76f)
                verticalLineToRelative(9.688f)
                lineTo(14.1f, 28.448f)
                arcToRelative(2.1f, 2.1f, 0.0f, false, true, -2.1f, -2.102f)
                verticalLineToRelative(-5.481f)
                arcToRelative(2.1f, 2.1f, 0.0f, false, true, 2.1f, -2.102f)
                horizontalLineToRelative(1.775f)
                lineToRelative(4.148f, -0.003f)
                close()
            }
        }
        .build()
        return _ruwidoMuteOutline!!
    }

private var _ruwidoMuteOutline: ImageVector? = null
