package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Filter: ImageVector
    get() {
        if (_filter != null) {
            return _filter!!
        }
        _filter = Builder(name = "Filter", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(22.1111f, 41.0f)
                verticalLineTo(29.6667f)
                horizontalLineTo(25.8889f)
                verticalLineTo(33.4444f)
                horizontalLineTo(41.0f)
                verticalLineTo(37.2222f)
                horizontalLineTo(25.8889f)
                verticalLineTo(41.0f)
                horizontalLineTo(22.1111f)
                close()
                moveTo(7.0f, 37.2222f)
                verticalLineTo(33.4444f)
                horizontalLineTo(18.3333f)
                verticalLineTo(37.2222f)
                horizontalLineTo(7.0f)
                close()
                moveTo(14.5556f, 29.6667f)
                verticalLineTo(25.8889f)
                horizontalLineTo(7.0f)
                verticalLineTo(22.1111f)
                horizontalLineTo(14.5556f)
                verticalLineTo(18.3333f)
                horizontalLineTo(18.3333f)
                verticalLineTo(29.6667f)
                horizontalLineTo(14.5556f)
                close()
                moveTo(22.1111f, 25.8889f)
                verticalLineTo(22.1111f)
                horizontalLineTo(41.0f)
                verticalLineTo(25.8889f)
                horizontalLineTo(22.1111f)
                close()
                moveTo(29.6667f, 18.3333f)
                verticalLineTo(7.0f)
                horizontalLineTo(33.4444f)
                verticalLineTo(10.7778f)
                horizontalLineTo(41.0f)
                verticalLineTo(14.5556f)
                horizontalLineTo(33.4444f)
                verticalLineTo(18.3333f)
                horizontalLineTo(29.6667f)
                close()
                moveTo(7.0f, 14.5556f)
                verticalLineTo(10.7778f)
                horizontalLineTo(25.8889f)
                verticalLineTo(14.5556f)
                horizontalLineTo(7.0f)
                close()
            }
        }
        .build()
        return _filter!!
    }

private var _filter: ImageVector? = null
