package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.SearchFilled: ImageVector
    get() {
        if (_searchFilled != null) {
            return _searchFilled!!
        }
        _searchFilled = Builder(name = "SearchFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(33.3797f, 30.4154f)
                lineTo(43.3859f, 40.4277f)
                verticalLineTo(40.4441f)
                curveTo(44.2047f, 41.2634f, 44.2047f, 42.5744f, 43.3859f, 43.3937f)
                curveTo(42.9765f, 43.8034f, 42.436f, 44.0f, 41.912f, 44.0f)
                curveTo(41.3879f, 44.0f, 40.8475f, 43.8034f, 40.4381f, 43.3937f)
                lineTo(30.4319f, 33.3814f)
                curveTo(27.6643f, 35.5445f, 24.1924f, 37.0f, 20.4094f, 37.0f)
                curveTo(11.3531f, 37.0f, 4.0f, 29.4814f, 4.0f, 20.4195f)
                curveTo(4.0f, 11.3576f, 11.3695f, 4.0f, 20.4094f, 4.0f)
                curveTo(29.4493f, 4.0f, 37.0f, 11.6686f, 37.0f, 20.7305f)
                curveTo(37.0f, 24.4831f, 35.5251f, 27.646f, 33.3797f, 30.4154f)
                close()
                moveTo(20.4094f, 8.161f)
                curveTo(13.3838f, 8.161f, 8.0f, 13.406f, 8.0f, 20.4195f)
                curveTo(8.0f, 27.4494f, 13.3838f, 33.0f, 20.4094f, 33.0f)
                curveTo(27.435f, 33.0f, 33.0f, 27.6929f, 33.0f, 20.663f)
                curveTo(33.0f, 13.6331f, 27.435f, 8.161f, 20.4094f, 8.161f)
                close()
                moveTo(20.5f, 30.5f)
                curveTo(26.0228f, 30.5f, 30.5f, 26.0228f, 30.5f, 20.5f)
                curveTo(30.5f, 14.9772f, 26.0228f, 10.5f, 20.5f, 10.5f)
                curveTo(14.9772f, 10.5f, 10.5f, 14.9772f, 10.5f, 20.5f)
                curveTo(10.5f, 26.0228f, 14.9772f, 30.5f, 20.5f, 30.5f)
                close()
            }
        }
        .build()
        return _searchFilled!!
    }

private var _searchFilled: ImageVector? = null
