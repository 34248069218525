package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.KeyboardBackspace: ImageVector
    get() {
        if (_keyboardBackspace != null) {
            return _keyboardBackspace!!
        }
        _keyboardBackspace = Builder(name = "KeyboardBackspace", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(6.388f, 22.428f)
                lineTo(16.625f, 12.19f)
                arcToRelative(2.7f, 2.7f, 0.0f, false, true, 1.91f, -0.79f)
                horizontalLineToRelative(20.804f)
                arcToRelative(2.7f, 2.7f, 0.0f, false, true, 2.7f, 2.7f)
                verticalLineToRelative(19.8f)
                arcToRelative(2.7f, 2.7f, 0.0f, false, true, -2.7f, 2.7f)
                lineTo(18.534f, 36.6f)
                arcToRelative(2.7f, 2.7f, 0.0f, false, true, -1.909f, -0.79f)
                lineTo(6.388f, 25.572f)
                curveToRelative(-0.9f, -0.9f, -0.9f, -2.245f, 0.0f, -3.145f)
                close()
                moveTo(21.125f, 30.963f)
                arcToRelative(1.575f, 1.575f, 0.0f, false, true, 0.0f, -2.227f)
                lineTo(25.86f, 24.0f)
                lineToRelative(-4.736f, -4.736f)
                arcToRelative(1.575f, 1.575f, 0.0f, true, true, 2.227f, -2.228f)
                lineToRelative(4.736f, 4.736f)
                lineToRelative(4.737f, -4.736f)
                arcToRelative(1.575f, 1.575f, 0.0f, true, true, 2.228f, 2.227f)
                lineTo(30.316f, 24.0f)
                lineToRelative(4.737f, 4.737f)
                arcToRelative(1.575f, 1.575f, 0.0f, false, true, -2.228f, 2.227f)
                lineToRelative(-4.737f, -4.737f)
                lineToRelative(-4.736f, 4.736f)
                arcToRelative(1.575f, 1.575f, 0.0f, false, true, -2.227f, 0.0f)
                close()
            }
        }
        .build()
        return _keyboardBackspace!!
    }

private var _keyboardBackspace: ImageVector? = null
