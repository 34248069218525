package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.group
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.FavoriteFilled: ImageVector
    get() {
        if (_favoriteFilled != null) {
            return _favoriteFilled!!
        }
        _favoriteFilled = Builder(name = "FavoriteFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            group {
                path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = EvenOdd) {
                    moveTo(44.0f, 15.7f)
                    curveTo(44.0f, 20.7f, 41.1f, 26.5f, 35.5f, 32.7f)
                    curveTo(31.2f, 37.5f, 26.5f, 41.2f, 25.1f, 42.3f)
                    lineTo(24.0f, 43.1f)
                    lineTo(22.9f, 42.3f)
                    curveTo(21.6f, 41.3f, 16.9f, 37.5f, 12.5f, 32.7f)
                    curveTo(6.9f, 26.5f, 4.0f, 20.7f, 4.0f, 15.7f)
                    curveTo(4.0f, 9.3f, 9.2f, 4.0f, 15.5f, 4.0f)
                    curveTo(18.8f, 4.0f, 21.8f, 5.4f, 24.0f, 7.8f)
                    curveTo(26.2f, 5.4f, 29.2f, 4.0f, 32.5f, 4.0f)
                    curveTo(38.8f, 4.0f, 44.0f, 9.3f, 44.0f, 15.7f)
                    close()
                }
            }
        }
        .build()
        return _favoriteFilled!!
    }

private var _favoriteFilled: ImageVector? = null
