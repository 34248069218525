package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoVoiceOutline: ImageVector
    get() {
        if (_ruwidoVoiceOutline != null) {
            return _ruwidoVoiceOutline!!
        }
        _ruwidoVoiceOutline = Builder(name = "RuwidoVoiceOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(2.0f, 24.0f)
                curveTo(2.0f, 11.848f, 11.848f, 2.0f, 24.0f, 2.0f)
                reflectiveCurveToRelative(22.0f, 9.848f, 22.0f, 22.0f)
                reflectiveCurveToRelative(-9.848f, 22.0f, -22.0f, 22.0f)
                reflectiveCurveTo(2.0f, 36.152f, 2.0f, 24.0f)
                close()
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(23.993f, 7.0f)
                curveToRelative(-2.668f, 0.0f, -4.843f, 2.143f, -4.843f, 4.77f)
                verticalLineToRelative(13.273f)
                curveToRelative(0.0f, 2.628f, 2.175f, 4.77f, 4.843f, 4.77f)
                reflectiveCurveToRelative(4.843f, -2.142f, 4.843f, -4.77f)
                lineTo(28.836f, 11.77f)
                curveToRelative(0.0f, -2.627f, -2.175f, -4.77f, -4.843f, -4.77f)
                close()
                moveTo(17.0f, 22.687f)
                arcToRelative(0.992f, 0.992f, 0.0f, false, false, -1.0f, -0.984f)
                curveToRelative(-0.552f, 0.0f, -1.0f, 0.44f, -1.0f, 0.984f)
                verticalLineToRelative(2.901f)
                curveToRelative(0.0f, 4.587f, 3.503f, 8.366f, 8.0f, 8.86f)
                verticalLineToRelative(5.567f)
                curveToRelative(0.0f, 0.544f, 0.448f, 0.985f, 1.0f, 0.985f)
                reflectiveCurveToRelative(1.0f, -0.441f, 1.0f, -0.985f)
                verticalLineToRelative(-5.568f)
                curveToRelative(4.497f, -0.493f, 8.0f, -4.272f, 8.0f, -8.859f)
                verticalLineToRelative(-2.9f)
                arcToRelative(0.992f, 0.992f, 0.0f, false, false, -1.0f, -0.985f)
                curveToRelative(-0.552f, 0.0f, -1.0f, 0.44f, -1.0f, 0.984f)
                verticalLineToRelative(2.901f)
                curveToRelative(0.0f, 3.845f, -3.148f, 6.945f, -7.0f, 6.945f)
                reflectiveCurveToRelative(-7.0f, -3.1f, -7.0f, -6.945f)
                verticalLineToRelative(-2.9f)
                close()
            }
        }
        .build()
        return _ruwidoVoiceOutline!!
    }

private var _ruwidoVoiceOutline: ImageVector? = null
