package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Checkmark: ImageVector
    get() {
        if (_checkmark != null) {
            return _checkmark!!
        }
        _checkmark = Builder(name = "Checkmark", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(20.452f, 34.503f)
                arcToRelative(2.0f, 2.0f, 0.0f, false, true, -2.882f, 0.0f)
                lineToRelative(-8.227f, -8.546f)
                arcToRelative(2.0f, 2.0f, 0.0f, false, true, 0.009f, -2.783f)
                lineToRelative(0.317f, -0.325f)
                arcToRelative(2.0f, 2.0f, 0.0f, false, true, 2.877f, 0.012f)
                lineToRelative(5.744f, 5.995f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 1.443f, 0.0f)
                lineToRelative(15.721f, -16.365f)
                arcToRelative(2.0f, 2.0f, 0.0f, false, true, 2.875f, -0.01f)
                lineToRelative(0.32f, 0.327f)
                arcToRelative(2.0f, 2.0f, 0.0f, false, true, 0.008f, 2.783f)
                lineTo(20.452f, 34.503f)
                close()
            }
        }
        .build()
        return _checkmark!!
    }

private var _checkmark: ImageVector? = null
