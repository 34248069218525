package com.brdgwtr.designsystem.components

import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.foundation.BwTheme

/**
 * RadioButton toggle component.
 *
 * @param toggled Whether the radio button is toggled or not.
 * @param modifier Modifier for the radio button.
 * @param onToggleChange Optional callback for when the toggle is selected. Setting to null will make the radio
 * button un-focusable.
 * @param enabled Whether the radio button can be interacted with.
 * @param colors Colors to use for the radio button.
 * @param interactionSource Interaction source for the state of the check mark.
 */
@Composable
@NonRestartableComposable
fun RadioButton(
    toggled: Boolean,
    modifier: Modifier = Modifier,
    onToggleChange: ((Boolean) -> Unit)? = null,
    enabled: Boolean = true,
    colors: SelectorColors = RadioButtonDefaults.colors(),
    interactionSource: MutableInteractionSource? = null,
) {
    Selector(
        modifier = modifier
            .semantics {
                role = Role.RadioButton
            }
            .padding(BwTheme.spacing.xxs)
            .size(RadioButtonDefaults.defaultSize),
        selectedIcon = null,
        borderInset = RadioButtonDefaults.borderInset,
        enabled = enabled,
        colors = colors,
        selected = toggled,
        interactionSource = interactionSource,
        onSelectedChange = onToggleChange,
    )
}

object RadioButtonDefaults {
    val defaultSize: Dp = 28.dp
    val borderInset: Dp = 4.dp

    @Composable
    @ReadOnlyComposable
    fun colors(
        toggledBackgroundColor: Color = BwTheme.colors.secondary,
        backgroundColor: Color = Color.Transparent,
        borderColor: Color = toggledBackgroundColor,
        toggledBorderColor: Color = toggledBackgroundColor,
        focusBackgroundColor: Color = backgroundColor,
        focusBorderColor: Color = BwTheme.colors.surfaceInverse,
        focusToggledBackgroundColor: Color = BwTheme.colors.surfaceInverse,
    ): SelectorColors {
        return SelectorColors(
            checkColor = Color.Transparent,
            backgroundColor = backgroundColor,
            borderColor = borderColor,
            selectedBackgroundColor = toggledBackgroundColor,
            selectedBorderColor = toggledBorderColor,
            focusBackgroundColor = focusBackgroundColor,
            focusBorderColor = focusBorderColor,
            focusSelectedColor = Color.Transparent,
            focusSelectedBackgroundColor = focusToggledBackgroundColor,
        )
    }
}
