package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.group
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoHomeFilled: ImageVector
    get() {
        if (_ruwidoHomeFilled != null) {
            return _ruwidoHomeFilled!!
        }
        _ruwidoHomeFilled = Builder(name = "RuwidoHomeFilled", defaultWidth = 48.0.dp, defaultHeight
                = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            group {
                path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = EvenOdd) {
                    moveTo(0.0f, 24.0f)
                    curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                    reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                    reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                    reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                    close()
                    moveTo(23.807f, 13.096f)
                    arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.89f, 0.017f)
                    lineToRelative(12.765f, 6.645f)
                    arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.538f, 0.887f)
                    lineTo(38.0f, 32.0f)
                    arcToRelative(1.0f, 1.0f, 0.0f, false, true, -1.0f, 1.0f)
                    lineTo(11.0f, 33.0f)
                    arcToRelative(1.0f, 1.0f, 0.0f, false, true, -1.0f, -1.0f)
                    lineTo(10.0f, 20.263f)
                    arcToRelative(1.0f, 1.0f, 0.0f, false, true, 0.572f, -0.904f)
                    lineToRelative(13.235f, -6.263f)
                    close()
                    moveTo(12.0f, 20.896f)
                    lineTo(12.0f, 31.0f)
                    horizontalLineToRelative(24.0f)
                    verticalLineToRelative(-9.748f)
                    lineToRelative(-11.787f, -6.136f)
                    lineTo(12.0f, 20.896f)
                    close()
                }
            }
        }
        .build()
        return _ruwidoHomeFilled!!
    }

private var _ruwidoHomeFilled: ImageVector? = null
