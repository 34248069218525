package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.WatchlistFilled: ImageVector
    get() {
        if (_watchlistFilled != null) {
            return _watchlistFilled!!
        }
        _watchlistFilled = Builder(name = "WatchlistFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(11.0f, 9.887f)
                curveTo(11.0f, 7.188f, 13.312f, 5.0f, 16.164f, 5.0f)
                horizontalLineTo(31.83f)
                curveToRelative(2.852f, 0.0f, 5.164f, 2.188f, 5.164f, 4.887f)
                verticalLineToRelative(17.584f)
                horizontalLineTo(37.0f)
                verticalLineTo(43.0f)
                lineTo(24.599f, 31.881f)
                horizontalLineToRelative(-1.198f)
                lineTo(11.0f, 43.0f)
                verticalLineTo(9.887f)
                close()
            }
        }
        .build()
        return _watchlistFilled!!
    }

private var _watchlistFilled: ImageVector? = null
