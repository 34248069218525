package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ProfileOutline: ImageVector
    get() {
        if (_profileOutline != null) {
            return _profileOutline!!
        }
        _profileOutline = Builder(name = "ProfileOutline", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(40.0f, 41.2729f)
                curveTo(40.0f, 42.2271f, 39.2039f, 43.0f, 38.2224f, 43.0f)
                curveTo(37.2402f, 43.0f, 36.4441f, 42.2271f, 36.4441f, 41.2729f)
                curveTo(36.4441f, 34.5949f, 30.873f, 29.1822f, 24.0f, 29.1822f)
                curveTo(17.127f, 29.1822f, 11.5559f, 34.5951f, 11.5559f, 41.2729f)
                curveTo(11.5559f, 42.2271f, 10.7597f, 43.0f, 9.7776f, 43.0f)
                curveTo(8.7961f, 43.0f, 8.0f, 42.2271f, 8.0f, 41.2729f)
                curveTo(8.0f, 32.6871f, 15.1633f, 25.7274f, 24.0f, 25.7274f)
                curveTo(32.8367f, 25.7274f, 40.0f, 32.6872f, 40.0f, 41.2729f)
                close()
                moveTo(24.0f, 24.0001f)
                curveTo(18.6f, 24.0001f, 14.2219f, 19.747f, 14.2219f, 14.4997f)
                curveTo(14.2219f, 9.2531f, 18.6f, 5.0f, 24.0f, 5.0f)
                curveTo(29.4f, 5.0f, 33.7781f, 9.2531f, 33.7781f, 14.4997f)
                curveTo(33.7781f, 19.7468f, 29.4f, 24.0001f, 24.0f, 24.0001f)
                close()
                moveTo(24.0f, 20.5458f)
                curveTo(27.4362f, 20.5458f, 30.2221f, 17.8391f, 30.2221f, 14.4999f)
                curveTo(30.2221f, 11.1613f, 27.4362f, 8.4546f, 24.0f, 8.4546f)
                curveTo(20.5638f, 8.4546f, 17.7779f, 11.1613f, 17.7779f, 14.4999f)
                curveTo(17.7779f, 17.8391f, 20.5638f, 20.5458f, 24.0f, 20.5458f)
                close()
            }
        }
        .build()
        return _profileOutline!!
    }

private var _profileOutline: ImageVector? = null
