package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.WifiLow: ImageVector
    get() {
        if (_wifiLow != null) {
            return _wifiLow!!
        }
        _wifiLow = Builder(name = "WifiLow", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(9.0195f, 19.8443f)
                curveTo(8.7198f, 20.1366f, 8.327f, 20.2828f, 7.9343f, 20.2828f)
                verticalLineTo(20.2825f)
                curveTo(7.5416f, 20.2825f, 7.1488f, 20.1363f, 6.8491f, 19.844f)
                curveTo(6.2495f, 19.2597f, 6.2495f, 18.312f, 6.8491f, 17.7274f)
                curveTo(11.4301f, 13.2601f, 17.5211f, 10.8f, 23.9996f, 10.8f)
                curveTo(30.478f, 10.8f, 36.5688f, 13.2601f, 41.1498f, 17.7277f)
                curveTo(41.7493f, 18.312f, 41.7493f, 19.2597f, 41.1498f, 19.8443f)
                curveTo(40.5506f, 20.4289f, 39.5785f, 20.4289f, 38.9793f, 19.8443f)
                curveTo(30.7193f, 11.7894f, 17.2795f, 11.7897f, 9.0195f, 19.8443f)
                close()
                moveTo(14.126f, 24.8236f)
                curveTo(13.8262f, 25.1159f, 13.4335f, 25.2621f, 13.0407f, 25.2621f)
                curveTo(12.648f, 25.2621f, 12.2553f, 25.1159f, 11.9555f, 24.8236f)
                curveTo(11.356f, 24.2393f, 11.356f, 23.2917f, 11.9555f, 22.707f)
                curveTo(18.5969f, 16.2305f, 29.403f, 16.2305f, 36.0444f, 22.707f)
                curveTo(36.6439f, 23.2914f, 36.6439f, 24.239f, 36.0444f, 24.8236f)
                curveTo(35.4452f, 25.4082f, 34.4731f, 25.4082f, 33.8739f, 24.8236f)
                curveTo(28.4294f, 19.5143f, 19.5705f, 19.5143f, 14.126f, 24.8236f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, fillAlpha = 0.5f,
                    strokeLineWidth = 0.0f, strokeLineCap = Butt, strokeLineJoin = Miter,
                    strokeLineMiter = 4.0f, pathFillType = EvenOdd) {
                moveTo(9.0195f, 19.8443f)
                curveTo(8.7198f, 20.1366f, 8.327f, 20.2828f, 7.9343f, 20.2828f)
                verticalLineTo(20.2825f)
                curveTo(7.5416f, 20.2825f, 7.1488f, 20.1363f, 6.8491f, 19.844f)
                curveTo(6.2495f, 19.2597f, 6.2495f, 18.312f, 6.8491f, 17.7274f)
                curveTo(11.4301f, 13.2601f, 17.5211f, 10.8f, 23.9996f, 10.8f)
                curveTo(30.478f, 10.8f, 36.5688f, 13.2601f, 41.1498f, 17.7277f)
                curveTo(41.7493f, 18.312f, 41.7493f, 19.2597f, 41.1498f, 19.8443f)
                curveTo(40.5506f, 20.4289f, 39.5785f, 20.4289f, 38.9793f, 19.8443f)
                curveTo(30.7193f, 11.7894f, 17.2795f, 11.7897f, 9.0195f, 19.8443f)
                close()
                moveTo(14.126f, 24.8236f)
                curveTo(13.8262f, 25.1159f, 13.4335f, 25.2621f, 13.0407f, 25.2621f)
                curveTo(12.648f, 25.2621f, 12.2553f, 25.1159f, 11.9555f, 24.8236f)
                curveTo(11.356f, 24.2393f, 11.356f, 23.2917f, 11.9555f, 22.707f)
                curveTo(18.5969f, 16.2305f, 29.403f, 16.2305f, 36.0444f, 22.707f)
                curveTo(36.6439f, 23.2914f, 36.6439f, 24.239f, 36.0444f, 24.8236f)
                curveTo(35.4452f, 25.4082f, 34.4731f, 25.4082f, 33.8739f, 24.8236f)
                curveTo(28.4294f, 19.5143f, 19.5705f, 19.5143f, 14.126f, 24.8236f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(19.2315f, 29.8027f)
                curveTo(18.9317f, 30.095f, 18.5389f, 30.2412f, 18.1462f, 30.2412f)
                verticalLineTo(30.2415f)
                curveTo(17.7535f, 30.2412f, 17.3607f, 30.0953f, 17.061f, 29.803f)
                curveTo(16.4614f, 29.2184f, 16.4614f, 28.2707f, 17.061f, 27.6861f)
                curveTo(18.9143f, 25.8785f, 21.3786f, 24.8833f, 23.9993f, 24.8833f)
                curveTo(26.6201f, 24.8833f, 29.0844f, 25.8788f, 30.9377f, 27.6861f)
                curveTo(31.5373f, 28.2704f, 31.5373f, 29.2181f, 30.9377f, 29.8027f)
                curveTo(30.3385f, 30.3873f, 29.3665f, 30.3873f, 28.7673f, 29.8027f)
                curveTo(27.4936f, 28.5609f, 25.8004f, 27.8768f, 23.9993f, 27.8768f)
                curveTo(22.1983f, 27.8768f, 20.5051f, 28.5606f, 19.2315f, 29.8027f)
                close()
                moveTo(26.8164f, 34.4522f)
                curveTo(26.8164f, 35.9698f, 25.5548f, 37.2001f, 23.9985f, 37.2001f)
                curveTo(22.4423f, 37.2001f, 21.1807f, 35.9698f, 21.1807f, 34.4522f)
                curveTo(21.1807f, 32.9346f, 22.4423f, 31.7043f, 23.9985f, 31.7043f)
                curveTo(25.5548f, 31.7043f, 26.8164f, 32.9346f, 26.8164f, 34.4522f)
                close()
            }
        }
        .build()
        return _wifiLow!!
    }

private var _wifiLow: ImageVector? = null
