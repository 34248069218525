package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoVolumedownOutline: ImageVector
    get() {
        if (_ruwidoVolumedownOutline != null) {
            return _ruwidoVolumedownOutline!!
        }
        _ruwidoVolumedownOutline = Builder(name = "RuwidoVolumedownOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(2.0f, 24.0f)
                curveTo(2.0f, 11.848f, 11.848f, 2.0f, 24.0f, 2.0f)
                reflectiveCurveToRelative(22.0f, 9.848f, 22.0f, 22.0f)
                reflectiveCurveToRelative(-9.848f, 22.0f, -22.0f, 22.0f)
                reflectiveCurveTo(2.0f, 36.152f, 2.0f, 24.0f)
                close()
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(10.0f, 22.75f)
                arcToRelative(1.25f, 1.25f, 0.0f, true, false, 0.0f, 2.5f)
                horizontalLineToRelative(28.0f)
                arcToRelative(1.25f, 1.25f, 0.0f, true, false, 0.0f, -2.5f)
                lineTo(10.0f, 22.75f)
                close()
            }
        }
        .build()
        return _ruwidoVolumedownOutline!!
    }

private var _ruwidoVolumedownOutline: ImageVector? = null
