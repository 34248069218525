package com.brdgwtr.designsystem.foundation

import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.ui.unit.DpSize
import com.brdgwtr.designsystem.tokens.internal.BwContainerSizeTokens
import com.brdgwtr.designsystem.tokens.internal.BwRadiiTokens
import dev.drewhamilton.poko.Poko

/**
 * Style definition used for a container to supply a fix size and shape.
 *
 * These are typically used for things like cards and artwork where fixed size containers are required.
 */
@Poko
class BwContainerTemplate(
    val size: DpSize,
    val shape: RoundedCornerShape,
) {
    val aspectRatio: Float
        get() = size.width / size.height
}

/**
 * Preset style definitions for containers.
 *
 * These are typically used for things like cards and artwork where fixed size containers are required.
 */
@Poko
class BwContainerTemplates(
    val portraitL: BwContainerTemplate,
    val portraitM: BwContainerTemplate,
    val circleM: BwContainerTemplate,
    val circleS: BwContainerTemplate,
    val circleXS: BwContainerTemplate,
    val squareL: BwContainerTemplate,
    val squareM: BwContainerTemplate,
    val landscapeXL: BwContainerTemplate,
    val landscapeL: BwContainerTemplate,
    val landscapeM: BwContainerTemplate,
    val landscapeS: BwContainerTemplate,
    val landscapeXS: BwContainerTemplate,
    val bannerXL: BwContainerTemplate,
)

internal val BwDefaultContainerTemplates = BwContainerTemplates(
    portraitL = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvPortraitL,
        shape = RoundedCornerShape(BwRadiiTokens.radiiM),
    ),
    portraitM = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvPortraitM,
        shape = RoundedCornerShape(BwRadiiTokens.radiiM),
    ),
    circleM = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvCircleM,
        shape = CircleShape,
    ),
    circleS = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvCircleS,
        shape = CircleShape,
    ),
    circleXS = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvCircleXs,
        shape = CircleShape,
    ),
    squareL = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvSquareL,
        shape = RoundedCornerShape(BwRadiiTokens.radiiM),
    ),
    squareM = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvSquareM,
        shape = RoundedCornerShape(BwRadiiTokens.radiiM),
    ),
    landscapeXL = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvLandscapeXl,
        shape = RoundedCornerShape(BwRadiiTokens.radiiL),
    ),
    landscapeL = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvLandscapeL,
        shape = RoundedCornerShape(BwRadiiTokens.radiiM),
    ),
    landscapeM = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvLandscapeM,
        shape = RoundedCornerShape(BwRadiiTokens.radiiM),
    ),
    landscapeS = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvLandscapeS,
        shape = RoundedCornerShape(BwRadiiTokens.radiiM),
    ),
    landscapeXS = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvLandscapeXs,
        shape = RoundedCornerShape(BwRadiiTokens.radiiM),
    ),
    bannerXL = BwContainerTemplate(
        size = BwContainerSizeTokens.sizeTvBannerXl,
        shape = RoundedCornerShape(BwRadiiTokens.radiiL),
    ),
)
