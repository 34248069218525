package com.brdgwtr.designsystem.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.ProvideTextStyle
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.foundation.BwTheme

/**
 * Icon and text badge component typically used to label content.
 *
 * @param icon The icon to display on the left side of the badge or centered if no text is supplied.
 * @param modifier The [Modifier] to apply to the badge.
 * @param text Optional text to display on the right of the icon.
 * @param iconColorFilter Optional [ColorFilter] to apply to the icon.
 * @param backgroundColor The background color for the badge container.
 */
@Composable
fun Badge(
    icon: ImageVector,
    modifier: Modifier = Modifier,
    text: String? = null,
    iconColorFilter: ColorFilter? = null,
    backgroundColor: Color = BwTheme.colors.surfaceContainer,
) {
    Badge(
        modifier = modifier,
        backgroundColor = backgroundColor,
        icon = {
            // We use an image so that full color icons are not tinted.
            Image(
                imageVector = icon,
                contentDescription = text,
                colorFilter = iconColorFilter,
            )
        },
        text = text?.let { { Text(it) } },
    )
}

/**
 * Icon and text badge component typically used to label content.
 *
 * @param icon The icon content to display on the left side of the badge or centered if no text is supplied.
 * @param modifier The [Modifier] to apply to the badge.
 * @param text Optional text content co display on the right of the icon.
 * @param backgroundColor The background color for the badge container.
 */
@Composable
fun Badge(
    icon: @Composable () -> Unit,
    modifier: Modifier = Modifier,
    text: (@Composable () -> Unit)? = null,
    backgroundColor: Color = BwTheme.colors.surfaceContainer,
) {
    Surface(
        shape = BwTheme.shapes.m,
        color = backgroundColor,
        modifier = modifier
            .widthIn(min = BadgeDefaults.minWidth)
            .height(BadgeDefaults.height),
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(BwTheme.spacing.xxs, Alignment.CenterHorizontally),
            modifier = Modifier.padding(horizontal = if (text != null) 6.dp else 0.dp),
        ) {
            Box(modifier = Modifier.size(BadgeDefaults.iconSize)) {
                icon()
            }
            if (text != null) {
                ProvideTextStyle(BwTheme.typography.bodySmall) {
                    text()
                }
            }
        }
    }
}

object BadgeDefaults {
    val minWidth = 26.dp
    val height = 26.dp
    val iconSize = 16.dp
}
