package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GeoShape2: ImageVector
    get() {
        if (_geoShape2 != null) {
            return _geoShape2!!
        }
        _geoShape2 = Builder(name = "GeoShape2", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(42.0f, 24.038f)
                curveToRelative(-0.015f, 9.937f, -8.055f, 17.97f, -17.973f, 17.962f)
                curveTo(14.063f, 41.992f, 5.997f, 33.92f, 6.0f, 23.962f)
                curveTo(6.0f, 14.042f, 14.156f, 5.952f, 24.105f, 6.0f)
                curveTo(34.03f, 6.046f, 42.017f, 14.095f, 42.0f, 24.04f)
                verticalLineToRelative(-0.002f)
                close()
                moveTo(38.664f, 30.434f)
                horizontalLineToRelative(-6.21f)
                curveToRelative(0.0f, -0.096f, -0.003f, -0.19f, -0.003f, -0.287f)
                curveToRelative(0.303f, -0.018f, 0.606f, -0.054f, 0.91f, -0.056f)
                curveToRelative(1.427f, -0.007f, 2.857f, 0.0f, 4.284f, 0.0f)
                curveToRelative(1.53f, 0.0f, 1.791f, -0.268f, 1.806f, -1.952f)
                horizontalLineToRelative(-5.337f)
                lineToRelative(-0.012f, -0.255f)
                curveToRelative(0.293f, -0.027f, 0.588f, -0.073f, 0.88f, -0.075f)
                curveToRelative(1.137f, -0.009f, 2.274f, -0.005f, 3.41f, -0.005f)
                curveToRelative(1.542f, 0.0f, 1.72f, -0.213f, 1.517f, -1.974f)
                horizontalLineToRelative(-4.612f)
                verticalLineToRelative(-0.308f)
                horizontalLineToRelative(4.826f)
                lineTo(40.123f, 23.59f)
                lineTo(36.01f, 23.59f)
                curveToRelative(0.0f, -0.128f, 0.0f, -0.258f, 0.003f, -0.386f)
                horizontalLineToRelative(4.062f)
                curveToRelative(0.043f, -1.249f, -0.492f, -1.873f, -1.606f, -1.871f)
                horizontalLineToRelative(-1.99f)
                lineToRelative(0.013f, -0.41f)
                horizontalLineToRelative(3.327f)
                curveToRelative(-0.255f, -1.891f, -0.255f, -1.887f, -1.923f, -1.895f)
                curveToRelative(-0.437f, 0.0f, -0.875f, -0.047f, -1.312f, -0.072f)
                curveToRelative(0.006f, -0.102f, 0.01f, -0.205f, 0.017f, -0.308f)
                horizontalLineToRelative(2.49f)
                curveToRelative(0.04f, -2.035f, -1.383f, -1.98f, -2.775f, -1.968f)
                lineToRelative(0.098f, -0.247f)
                lineToRelative(1.796f, -0.144f)
                curveToRelative(-0.345f, -0.563f, -0.544f, -1.12f, -0.933f, -1.453f)
                curveToRelative(-0.36f, -0.306f, -0.925f, -0.37f, -1.5f, -0.58f)
                lineToRelative(0.857f, -0.295f)
                curveToRelative(-0.188f, -0.245f, -0.314f, -0.425f, -0.456f, -0.593f)
                curveTo(30.699f, 6.972f, 20.876f, 5.994f, 14.2f, 11.18f)
                curveToRelative(-3.843f, 2.984f, -6.237f, 7.607f, -6.358f, 12.48f)
                arcToRelative(15.815f, 15.815f, 0.0f, false, false, 1.193f, 6.43f)
                arcToRelative(15.273f, 15.273f, 0.0f, false, false, 1.638f, 2.987f)
                curveToRelative(0.582f, 0.816f, 1.304f, 1.484f, 1.984f, 2.215f)
                curveToRelative(0.848f, 0.91f, 1.553f, 1.654f, 2.855f, 1.654f)
                curveToRelative(5.648f, 0.0f, 11.296f, -0.057f, 16.943f, 0.035f)
                curveToRelative(1.555f, 0.025f, 2.254f, -0.925f, 3.083f, -1.966f)
                horizontalLineToRelative(-9.265f)
                arcToRelative(1.607f, 1.607f, 0.0f, false, true, 1.059f, -0.362f)
                curveToRelative(2.467f, -0.012f, 4.94f, -0.123f, 7.398f, 0.036f)
                curveToRelative(1.517f, 0.098f, 2.134f, -0.696f, 2.802f, -1.964f)
                horizontalLineToRelative(-7.408f)
                lineToRelative(-0.013f, -0.285f)
                curveToRelative(0.295f, -0.031f, 0.59f, -0.086f, 0.885f, -0.088f)
                curveToRelative(1.85f, -0.008f, 3.7f, -0.002f, 5.55f, -0.006f)
                curveToRelative(1.524f, -0.002f, 1.733f, -0.18f, 2.122f, -1.91f)
                lineToRelative(-0.004f, -0.002f)
                close()
                moveTo(15.286f, 37.371f)
                curveToRelative(1.113f, 0.87f, 2.229f, 1.769f, 3.66f, 1.794f)
                curveToRelative(3.373f, 0.06f, 6.751f, 0.059f, 10.127f, -0.004f)
                curveToRelative(1.452f, -0.027f, 2.574f, -0.934f, 3.675f, -1.792f)
                lineTo(15.286f, 37.369f)
                verticalLineToRelative(0.002f)
                close()
                moveTo(27.125f, 39.971f)
                lineTo(27.135f, 39.718f)
                horizontalLineToRelative(-6.329f)
                curveToRelative(0.0f, 0.084f, 0.002f, 0.167f, 0.005f, 0.253f)
                horizontalLineToRelative(6.314f)
                close()
            }
        }
        .build()
        return _geoShape2!!
    }

private var _geoShape2: ImageVector? = null
