package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ArrowUpKeyboard: ImageVector
    get() {
        if (_arrowUpKeyboard != null) {
            return _arrowUpKeyboard!!
        }
        _arrowUpKeyboard = Builder(name = "ArrowUpKeyboard", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(22.253f, 11.0f)
                curveToRelative(0.776f, -1.333f, 2.718f, -1.333f, 3.494f, 0.0f)
                lineToRelative(13.98f, 24.0f)
                curveToRelative(0.776f, 1.333f, -0.194f, 3.0f, -1.748f, 3.0f)
                horizontalLineTo(10.021f)
                curveToRelative(-1.554f, 0.0f, -2.524f, -1.667f, -1.748f, -3.0f)
                lineToRelative(13.98f, -24.0f)
                close()
            }
        }
        .build()
        return _arrowUpKeyboard!!
    }

private var _arrowUpKeyboard: ImageVector? = null
