package com.brdgwtr.designsystem.components

import androidx.annotation.FloatRange
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsFocusedAsState
import androidx.compose.foundation.interaction.collectIsHoveredAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.ProvideTextStyle
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.text.style.TextAlign
import com.brdgwtr.designsystem.foundation.BwTheme

/**
 * Primary button component matching the design system primary button style.
 *
 * @param text Text to be used on the button
 * @param onClick The callback to be invoked when the button is clicked.
 * @param modifier [Modifier] to be applied to the button.
 * @param colors Defines the background & content colors to be used in this button for different interaction states.
 * correct content color for the current button
 * @param shape Defines the button shape.
 * @param scale Defines size of the button relative to its original size based on states such as focused.
 * @param border Defines border of the button based on the current state.
 * @param contentPadding The padding around the button content.
 * @param onLongClick callback to be called when the button is long clicked (long-pressed).
 * @param leadingIcon Optional leading icon [ImageVector].
 * @param trailingIcon Optional trailing icon [ImageVector].
 * @param enabled Controls the button's enabled state.
 * @param interactionSource the MutableInteractionSource representing the stream of Interactions for this Surface.
 */
@Composable
fun PrimaryButton(
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    colors: ButtonColors = PrimaryButtonDefaults.colors(),
    shape: RoundedCornerShape = PrimaryButtonDefaults.shape,
    scale: ButtonScale = PrimaryButtonDefaults.scale(),
    border: ButtonBorder = PrimaryButtonDefaults.border(),
    contentPadding: PaddingValues = PrimaryButtonDefaults.contentPadding,
    onLongClick: (() -> Unit)? = null,
    leadingIcon: ImageVector? = null,
    trailingIcon: ImageVector? = null,
    enabled: Boolean = true,
    interactionSource: MutableInteractionSource? = null,
) {
    PrimaryButton(
        onClick = onClick,
        onLongClick = onLongClick,
        colors = colors,
        shape = shape,
        scale = scale,
        border = border,
        contentPadding = contentPadding,
        modifier = modifier,
        enabled = enabled,
        interactionSource = interactionSource,
        leading = leadingIcon?.let {
            {
                Icon(
                    imageVector = it,
                    contentDescription = null,
                    modifier = Modifier.size(PrimaryButtonDefaults.iconSize),
                )
            }
        },
        text = {
            Text(
                text = text,
                textAlign = TextAlign.Center,
                maxLines = 1,
            )
        },
        trailing = trailingIcon?.let {
            {
                Icon(
                    imageVector = it,
                    contentDescription = null,
                    modifier = Modifier.size(PrimaryButtonDefaults.iconSize),
                )
            }
        },
    )
}

/**
 * Primary button component matching the design system primary button style.
 *
 * @param text Text to be used on the button
 * @param onClick The callback to be invoked when the button is clicked.
 * @param modifier [Modifier] to be applied to the button.
 * @param colors Defines the background & content colors to be used in this button for different interaction states.
 * correct content color for the current button
 * @param shape Defines the button shape.
 * @param scale Defines size of the button relative to its original size based on states such as focused.
 * @param border Defines border of the button based on the current state.
 * @param contentPadding The padding around the button content.
 * @param onLongClick callback to be called when the button is long clicked (long-pressed).
 * @param leading Optional leading icon content.
 * @param trailing Optional trailing icon content.
 * @param enabled Controls the button's enabled state.
 * @param interactionSource the MutableInteractionSource representing the stream of Interactions for this Surface.
 */
@Composable
@NonRestartableComposable
fun PrimaryButton(
    text: @Composable () -> Unit,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    colors: ButtonColors = PrimaryButtonDefaults.colors(),
    shape: RoundedCornerShape = PrimaryButtonDefaults.shape,
    scale: ButtonScale = PrimaryButtonDefaults.scale(),
    border: ButtonBorder = PrimaryButtonDefaults.border(),
    contentPadding: PaddingValues = PrimaryButtonDefaults.contentPadding,
    onLongClick: (() -> Unit)? = null,
    leading: @Composable (() -> Unit)? = null,
    trailing: @Composable (() -> Unit)? = null,
    enabled: Boolean = true,
    interactionSource: MutableInteractionSource? = null,
) {
    PrimaryButton(
        onClick = onClick,
        onLongClick = onLongClick,
        colors = colors,
        shape = shape,
        scale = scale,
        border = border,
        contentPadding = contentPadding,
        modifier = modifier,
        enabled = enabled,
        interactionSource = interactionSource,
    ) {
        leading?.invoke()
        text()
        trailing?.invoke()
    }
}

/**
 * Primary button component matching the design system primary button style.
 *
 * @param onClick The callback to be invoked when the button is clicked.
 * @param modifier [Modifier] to be applied to the button.
 * @param colors Defines the background & content colors to be used in this button for different interaction states.
 * correct content color for the current button
 * @param shape Defines the button shape.
 * @param scale Defines size of the button relative to its original size based on states such as focused.
 * @param border Defines border of the button based on the current state.
 * @param contentPadding The padding around the button content.
 * @param onLongClick Optional callback to be called when the button is long clicked (long-pressed).
 * @param enabled Controls the button's enabled state.
 * @param interactionSource The MutableInteractionSource representing the stream of Interactions for this Surface.
 * @param content The main composable content to display within the button. Use the [LocalContentColor].current color
 * to update the content based on the state of the button.
 */
@Composable
@NonRestartableComposable
fun PrimaryButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    colors: ButtonColors = PrimaryButtonDefaults.colors(),
    shape: RoundedCornerShape = PrimaryButtonDefaults.shape,
    scale: ButtonScale = PrimaryButtonDefaults.scale(),
    border: ButtonBorder = PrimaryButtonDefaults.border(),
    contentPadding: PaddingValues = PrimaryButtonDefaults.contentPadding,
    onLongClick: (() -> Unit)? = null,
    enabled: Boolean = true,
    interactionSource: MutableInteractionSource? = null,
    content: @Composable RowScope.() -> Unit,
) {
    Button(
        onClick = onClick,
        onLongClick = onLongClick,
        colors = colors,
        shape = shape,
        scale = scale,
        border = border,
        modifier = modifier
            .defaultMinSize(
                minWidth = ButtonDefaults.minWidth,
                minHeight = ButtonDefaults.minHeight,
            )
            .width(IntrinsicSize.Max),
        enabled = enabled,
        interactionSource = interactionSource,
        content = {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(contentPadding),
                horizontalArrangement = Arrangement.spacedBy(BwTheme.spacing.xs, Alignment.CenterHorizontally),
                verticalAlignment = Alignment.CenterVertically,
                content = content,
            )
        },
    )
}

/**
 * Icon button component matching the design system icon button style.
 *
 * A circle shaped button with optional text. The button will expand to reveal the text only when the button has
 * been focused.
 *
 * @param icon [ImageVector] to display on the button.
 * @param onClick The callback to be invoked when the button is clicked.
 * @param modifier [Modifier] to be applied to the button.
 * @param text Optional text to reveal on focus.
 * @param focusedIcon Icon to display on focus. Defaults to the [icon] if no value is passed.
 * @param enabled Controls the button's enabled state.
 * @param colors Defines the background & content colors to be used in this button for different interaction states.
 * correct content color for the current button
 * @param onLongClick Optional callback to be called when the button is long clicked (long-pressed).
 * @param interactionSource MutableInteractionSource representing the stream of Interactions for this Surface.
 */
@Composable
fun IconButton(
    icon: ImageVector,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    text: String? = null,
    focusedIcon: ImageVector = icon,
    enabled: Boolean = true,
    colors: ButtonColors = IconButtonDefaults.colors(),
    onLongClick: (() -> Unit)? = null,
    interactionSource: MutableInteractionSource? = null,
) {
    @Suppress("NAME_SHADOWING")
    val interactionSource = interactionSource ?: remember { MutableInteractionSource() }
    val focused by interactionSource.collectIsFocusedAsState()
    val hovered by interactionSource.collectIsHoveredAsState()

    IconButton(
        onClick = onClick,
        modifier = modifier,
        enabled = enabled,
        colors = colors,
        interactionSource = interactionSource,
        onLongClick = onLongClick,
        icon = {
            Icon(
                imageVector = if (focused) focusedIcon else icon,
                modifier = Modifier.size(IconButtonDefaults.iconSize),
                contentDescription = text,
            )
        },
        text = text?.let {
            {
                AnimatedVisibility(
                    visible = text.isNotBlank() && (focused || hovered),
                    enter = IconButtonDefaults.textEnterAnimation,
                    exit = IconButtonDefaults.textExitAnimation,
                ) {
                    Text(
                        modifier = Modifier
                            .wrapContentHeight()
                            .padding(end = BwTheme.spacing.xs),
                        text = text,
                        textAlign = TextAlign.Center,
                        maxLines = 1,
                    )
                }
            }
        },
    )
}

/**
 * Icon button component matching the design system icon button style.
 *
 * A circle shaped button with optional text. The button will expand to reveal the text only when the button has
 * been focused.
 *
 * @param icon Composable content for icon.
 * @param onClick The callback to be invoked when the button is clicked.
 * @param modifier [Modifier] to be applied to the button.
 * @param text Optional text content.
 * @param enabled Controls the button enabled state.
 * @param colors Defines the background & content colors to be used in this button for different interaction states.
 * correct content color for the current button
 * @param onLongClick Optional callback to be called when the button is long clicked (long-pressed).
 * @param interactionSource - the MutableInteractionSource representing the stream of Interactions for this Surface.
 */
@Composable
@NonRestartableComposable
fun IconButton(
    icon: @Composable RowScope.() -> Unit,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    text: @Composable (RowScope.() -> Unit)? = null,
    enabled: Boolean = true,
    colors: ButtonColors = IconButtonDefaults.colors(),
    onLongClick: (() -> Unit)? = null,
    interactionSource: MutableInteractionSource? = null,
) {
    IconButton(
        onClick = onClick,
        modifier = modifier,
        enabled = enabled,
        colors = colors,
        interactionSource = interactionSource,
        onLongClick = onLongClick,
    ) {
        icon()
        text?.invoke(this)
    }
}

/**
 * Icon button component matching the design system icon button style.
 *
 * A circle shaped button with optional text. The button will expand to reveal the text only when the button has
 * been focused.
 *
 * @param onClick The callback to be invoked when the button is clicked.
 * @param modifier [Modifier] to be applied to the button.
 * @param enabled Controls the button enabled state.
 * @param colors Defines the background & content colors to be used in this button for different interaction states.
 * correct content color for the current button
 * @param onLongClick Optional callback to be called when the button is long clicked (long-pressed).
 * @param interactionSource MutableInteractionSource representing the stream of Interactions for this Surface.
 * @param content Composable content to display in the icon button.
 */
@Composable
fun IconButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    colors: ButtonColors = IconButtonDefaults.colors(),
    onLongClick: (() -> Unit)? = null,
    interactionSource: MutableInteractionSource? = null,
    content: @Composable RowScope.() -> Unit,
) {
    Button(
        onClick = onClick,
        modifier = modifier
            .height(IconButtonDefaults.iconButtonSize)
            .widthIn(min = IconButtonDefaults.iconButtonSize),
        enabled = enabled,
        colors = colors,
        interactionSource = interactionSource,
        border = IconButtonDefaults.border(),
        shape = BwTheme.shapes.circle,
        scale = ButtonScale.None,
        onLongClick = onLongClick,
    ) {
        Row(
            modifier = Modifier
                .align(Alignment.Center)
                .padding(IconButtonDefaults.contentPadding),
            horizontalArrangement = Arrangement.spacedBy(BwTheme.spacing.xs, Alignment.CenterHorizontally),
            verticalAlignment = Alignment.CenterVertically,
            content = content,
        )
    }
}

/**
 * Base button component to be used as a building block for other design system buttons.
 *
 * @param onClick The callback to be invoked when the button is clicked.
 * @param colors Defines the background & content colors to be used in this button for different interaction states.
 * correct content color for the current button
 * @param shape Defines the button shape.
 * @param scale Defines size of the button relative to its original size based on states such as focused.
 * @param border Defines border of the button based on the current state.
 * @param modifier [Modifier] to be applied to the button.
 * @param onLongClick Optional callback to be called when the button is long clicked (long-pressed).
 * @param enabled Controls the button enabled state. When false the button will still be focusable
 * but not clickable.
 * @param interactionSource the MutableInteractionSource representing the stream of Interactions for this Surface.
 * @param content The main composable content to display within the button. Use the [LocalContentColor].current color
 * to update the content based on the state of the button.
 */
@Composable
@NonRestartableComposable
private fun Button(
    onClick: () -> Unit,
    colors: ButtonColors,
    shape: RoundedCornerShape,
    scale: ButtonScale,
    border: ButtonBorder,
    modifier: Modifier = Modifier,
    onLongClick: (() -> Unit)? = null,
    enabled: Boolean = true,
    interactionSource: MutableInteractionSource? = null,
    content: @Composable BoxScope.() -> Unit,
) {
    Surface(
        onClick = onClick,
        onLongClick = onLongClick,
        modifier = modifier.semantics { role = Role.Button },
        enabled = enabled,
        interactionSource = interactionSource,
        border = border.toSurfaceBorder(),
        shape = InteractiveSurfaceDefaults.shape(shape),
        scale = scale.toSurfaceScale(),
        colors = colors.toSurfaceColors(),
    ) {
        ProvideTextStyle(BwTheme.typography.labelInteraction) {
            content()
        }
    }
}

@Immutable
data class ButtonColors(
    val backgroundColor: Color,
    val contentColor: Color,
    val focusedBackgroundColor: Color,
    val focusedContentColor: Color,
    val disabledBackgroundColor: Color,
    val disabledContentColor: Color,
    val pressedContentColor: Color,
    val pressedBackgroundColor: Color,
    val disabledFocusedBackgroundColor: Color,
    val disabledFocusedContentColor: Color,
)

@Immutable
data class ButtonBorder(
    val border: Border,
    val focusedBorder: Border,
    val pressedBorder: Border,
    val disabledBorder: Border,
    val focusedDisabledBorder: Border,
)

@Immutable
class ButtonScale(
    @FloatRange(from = 0.0) val scale: Float,
    @FloatRange(from = 0.0) val focusedScale: Float,
    @FloatRange(from = 0.0) val pressedScale: Float,
    @FloatRange(from = 0.0) val disabledScale: Float,
) {
    companion object {
        val None = ButtonScale(
            scale = 1f,
            focusedScale = 1f,
            pressedScale = 1f,
            disabledScale = 1f,
        )
    }
}

internal fun ButtonColors.toSurfaceColors(): InteractiveSurfaceColors = InteractiveSurfaceColors(
    backgroundColor = backgroundColor,
    contentColor = contentColor,
    focusedBackgroundColor = focusedBackgroundColor,
    focusedContentColor = focusedContentColor,
    disabledBackgroundColor = disabledBackgroundColor,
    disabledContentColor = disabledContentColor,
    pressedBackgroundColor = pressedBackgroundColor,
    pressedContentColor = pressedContentColor,
    disabledFocusedBackgroundColor = disabledFocusedBackgroundColor,
    disabledFocusedContentColor = disabledFocusedContentColor,
)

internal fun ButtonScale.toSurfaceScale(): InteractiveSurfaceScale = InteractiveSurfaceScale(
    scale = scale,
    focusedScale = focusedScale,
    disabledScale = disabledScale,
    pressedScale = pressedScale,
    focusedDisabledScale = disabledScale,
)

internal fun ButtonBorder.toSurfaceBorder(): InteractiveSurfaceBorder = InteractiveSurfaceBorder(
    border = border,
    focusedBorder = focusedBorder,
    disabledBorder = disabledBorder,
    pressedBorder = pressedBorder,
    focusedDisabledBorder = focusedDisabledBorder,
)
