package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoVolumeupOutline: ImageVector
    get() {
        if (_ruwidoVolumeupOutline != null) {
            return _ruwidoVolumeupOutline!!
        }
        _ruwidoVolumeupOutline = Builder(name = "RuwidoVolumeupOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(2.0f, 24.0f)
                curveTo(2.0f, 11.848f, 11.848f, 2.0f, 24.0f, 2.0f)
                reflectiveCurveToRelative(22.0f, 9.848f, 22.0f, 22.0f)
                reflectiveCurveToRelative(-9.848f, 22.0f, -22.0f, 22.0f)
                reflectiveCurveTo(2.0f, 36.152f, 2.0f, 24.0f)
                close()
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(24.094f, 7.5f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.0f, 1.0f)
                verticalLineToRelative(11.918f)
                curveToRelative(0.0f, 1.546f, 1.183f, 2.731f, 2.566f, 2.731f)
                horizontalLineToRelative(11.842f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, true, 0.0f, 2.0f)
                lineTo(27.66f, 25.149f)
                curveToRelative(-2.579f, 0.0f, -4.566f, -2.174f, -4.566f, -4.731f)
                lineTo(23.094f, 8.5f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.0f, -1.0f)
                close()
                moveTo(7.5f, 24.15f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.0f, -1.0f)
                horizontalLineToRelative(11.959f)
                curveToRelative(2.566f, 0.0f, 4.613f, 2.088f, 4.613f, 4.637f)
                verticalLineToRelative(11.615f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, true, -2.0f, 0.0f)
                lineTo(23.072f, 27.787f)
                arcToRelative(2.622f, 2.622f, 0.0f, false, false, -2.613f, -2.638f)
                lineTo(8.5f, 25.149f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, -1.0f, -1.0f)
                close()
            }
        }
        .build()
        return _ruwidoVolumeupOutline!!
    }

private var _ruwidoVolumeupOutline: ImageVector? = null
