package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.WifiNone: ImageVector
    get() {
        if (_wifiNone != null) {
            return _wifiNone!!
        }
        _wifiNone = Builder(name = "WifiNone", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(31.4272f, 12.6722f)
                curveTo(28.6251f, 11.4454f, 25.5632f, 10.8f, 22.3995f, 10.8f)
                curveTo(16.51f, 10.8f, 10.9727f, 13.0365f, 6.8082f, 17.0977f)
                curveTo(6.2632f, 17.6292f, 6.2632f, 18.4907f, 6.8082f, 19.0219f)
                curveTo(7.0807f, 19.2876f, 7.4377f, 19.4205f, 7.7948f, 19.4205f)
                verticalLineTo(19.4208f)
                curveTo(8.1518f, 19.4208f, 8.5088f, 19.2879f, 8.7813f, 19.0221f)
                curveTo(14.6827f, 13.2675f, 23.4925f, 12.0353f, 30.6393f, 15.3259f)
                curveTo(30.7365f, 14.3779f, 31.0103f, 13.4822f, 31.4272f, 12.6722f)
                close()
                moveTo(19.2315f, 29.8028f)
                curveTo(18.9317f, 30.0951f, 18.5389f, 30.2412f, 18.1462f, 30.2412f)
                verticalLineTo(30.2415f)
                curveTo(17.7535f, 30.2412f, 17.3607f, 30.0954f, 17.061f, 29.8031f)
                curveTo(16.4614f, 29.2184f, 16.4614f, 28.2708f, 17.061f, 27.6862f)
                curveTo(18.9143f, 25.8786f, 21.3786f, 24.8834f, 23.9993f, 24.8834f)
                curveTo(26.6201f, 24.8834f, 29.0844f, 25.8789f, 30.9377f, 27.6862f)
                curveTo(31.5373f, 28.2705f, 31.5373f, 29.2181f, 30.9377f, 29.8028f)
                curveTo(30.3385f, 30.3874f, 29.3665f, 30.3874f, 28.7673f, 29.8028f)
                curveTo(27.4936f, 28.561f, 25.8004f, 27.8769f, 23.9993f, 27.8769f)
                curveTo(22.1983f, 27.8769f, 20.5051f, 28.5607f, 19.2315f, 29.8028f)
                close()
                moveTo(26.8164f, 34.4523f)
                curveTo(26.8164f, 35.9699f, 25.5548f, 37.2002f, 23.9985f, 37.2002f)
                curveTo(22.4423f, 37.2002f, 21.1807f, 35.9699f, 21.1807f, 34.4523f)
                curveTo(21.1807f, 32.9347f, 22.4423f, 31.7044f, 23.9985f, 31.7044f)
                curveTo(25.5548f, 31.7044f, 26.8164f, 32.9347f, 26.8164f, 34.4523f)
                close()
                moveTo(11.9158f, 22.2654f)
                curveTo(17.5629f, 16.7586f, 26.522f, 16.4023f, 32.5975f, 21.1967f)
                curveTo(32.9543f, 21.5816f, 33.3509f, 21.9291f, 33.7808f, 22.2324f)
                lineTo(33.8148f, 22.2654f)
                curveTo(34.3598f, 22.7966f, 34.3598f, 23.6581f, 33.8148f, 24.1896f)
                curveTo(33.2701f, 24.7211f, 32.3864f, 24.7211f, 31.8416f, 24.1896f)
                curveTo(26.892f, 19.3629f, 18.8386f, 19.3629f, 13.889f, 24.1896f)
                curveTo(13.6165f, 24.4553f, 13.2594f, 24.5882f, 12.9024f, 24.5882f)
                curveTo(12.5453f, 24.5882f, 12.1883f, 24.4553f, 11.9158f, 24.1896f)
                curveTo(11.3708f, 23.6584f, 11.3708f, 22.7969f, 11.9158f, 22.2654f)
                close()
            }
            path(fill = SolidColor(Color(0xFF000000)), stroke = null, fillAlpha = 0.5f,
                    strokeLineWidth = 0.0f, strokeLineCap = Butt, strokeLineJoin = Miter,
                    strokeLineMiter = 4.0f, pathFillType = EvenOdd) {
                moveTo(31.4272f, 12.6722f)
                curveTo(28.6251f, 11.4454f, 25.5632f, 10.8f, 22.3995f, 10.8f)
                curveTo(16.51f, 10.8f, 10.9727f, 13.0365f, 6.8082f, 17.0977f)
                curveTo(6.2632f, 17.6292f, 6.2632f, 18.4907f, 6.8082f, 19.0219f)
                curveTo(7.0807f, 19.2876f, 7.4377f, 19.4205f, 7.7948f, 19.4205f)
                verticalLineTo(19.4208f)
                curveTo(8.1518f, 19.4208f, 8.5088f, 19.2879f, 8.7813f, 19.0221f)
                curveTo(14.6827f, 13.2675f, 23.4925f, 12.0353f, 30.6393f, 15.3259f)
                curveTo(30.7365f, 14.3779f, 31.0103f, 13.4822f, 31.4272f, 12.6722f)
                close()
                moveTo(19.2315f, 29.8028f)
                curveTo(18.9317f, 30.0951f, 18.5389f, 30.2412f, 18.1462f, 30.2412f)
                verticalLineTo(30.2415f)
                curveTo(17.7535f, 30.2412f, 17.3607f, 30.0954f, 17.061f, 29.8031f)
                curveTo(16.4614f, 29.2184f, 16.4614f, 28.2708f, 17.061f, 27.6862f)
                curveTo(18.9143f, 25.8786f, 21.3786f, 24.8834f, 23.9993f, 24.8834f)
                curveTo(26.6201f, 24.8834f, 29.0844f, 25.8789f, 30.9377f, 27.6862f)
                curveTo(31.5373f, 28.2705f, 31.5373f, 29.2181f, 30.9377f, 29.8028f)
                curveTo(30.3385f, 30.3874f, 29.3665f, 30.3874f, 28.7673f, 29.8028f)
                curveTo(27.4936f, 28.561f, 25.8004f, 27.8769f, 23.9993f, 27.8769f)
                curveTo(22.1983f, 27.8769f, 20.5051f, 28.5607f, 19.2315f, 29.8028f)
                close()
                moveTo(26.8164f, 34.4523f)
                curveTo(26.8164f, 35.9699f, 25.5548f, 37.2002f, 23.9985f, 37.2002f)
                curveTo(22.4423f, 37.2002f, 21.1807f, 35.9699f, 21.1807f, 34.4523f)
                curveTo(21.1807f, 32.9347f, 22.4423f, 31.7044f, 23.9985f, 31.7044f)
                curveTo(25.5548f, 31.7044f, 26.8164f, 32.9347f, 26.8164f, 34.4523f)
                close()
                moveTo(11.9158f, 22.2654f)
                curveTo(17.5629f, 16.7586f, 26.522f, 16.4023f, 32.5975f, 21.1967f)
                curveTo(32.9543f, 21.5816f, 33.3509f, 21.9291f, 33.7808f, 22.2324f)
                lineTo(33.8148f, 22.2654f)
                curveTo(34.3598f, 22.7966f, 34.3598f, 23.6581f, 33.8148f, 24.1896f)
                curveTo(33.2701f, 24.7211f, 32.3864f, 24.7211f, 31.8416f, 24.1896f)
                curveTo(26.892f, 19.3629f, 18.8386f, 19.3629f, 13.889f, 24.1896f)
                curveTo(13.6165f, 24.4553f, 13.2594f, 24.5882f, 12.9024f, 24.5882f)
                curveTo(12.5453f, 24.5882f, 12.1883f, 24.4553f, 11.9158f, 24.1896f)
                curveTo(11.3708f, 23.6584f, 11.3708f, 22.7969f, 11.9158f, 22.2654f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(38.65f, 24.05f)
                curveTo(42.5988f, 24.05f, 45.8f, 20.8488f, 45.8f, 16.9f)
                curveTo(45.8f, 12.9512f, 42.5988f, 9.75f, 38.65f, 9.75f)
                curveTo(34.7012f, 9.75f, 31.5f, 12.9512f, 31.5f, 16.9f)
                curveTo(31.5f, 20.8488f, 34.7012f, 24.05f, 38.65f, 24.05f)
                close()
                moveTo(42.2912f, 13.2513f)
                curveTo(42.5596f, 13.5197f, 42.5596f, 13.9548f, 42.2912f, 14.2232f)
                lineTo(39.6184f, 16.896f)
                lineTo(42.2993f, 19.5769f)
                curveTo(42.5677f, 19.8452f, 42.5677f, 20.2804f, 42.2993f, 20.5487f)
                curveTo(42.0309f, 20.8171f, 41.5958f, 20.8171f, 41.3274f, 20.5487f)
                lineTo(38.6465f, 17.8679f)
                lineTo(35.9739f, 20.5404f)
                curveTo(35.7056f, 20.8088f, 35.2704f, 20.8088f, 35.0021f, 20.5404f)
                curveTo(34.7337f, 20.2721f, 34.7337f, 19.8369f, 35.0021f, 19.5685f)
                lineTo(37.6746f, 16.896f)
                lineTo(35.0101f, 14.2315f)
                curveTo(34.7418f, 13.9631f, 34.7418f, 13.528f, 35.0101f, 13.2596f)
                curveTo(35.2785f, 12.9912f, 35.7137f, 12.9912f, 35.982f, 13.2596f)
                lineTo(38.6465f, 15.9241f)
                lineTo(41.3193f, 13.2513f)
                curveTo(41.5877f, 12.9829f, 42.0228f, 12.9829f, 42.2912f, 13.2513f)
                close()
            }
        }
        .build()
        return _wifiNone!!
    }

private var _wifiNone: ImageVector? = null
