package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GeoShape3: ImageVector
    get() {
        if (_geoShape3 != null) {
            return _geoShape3!!
        }
        _geoShape3 = Builder(name = "GeoShape3", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(36.94f, 23.899f)
                curveToRelative(0.0f, 3.456f, -0.042f, 6.912f, 0.016f, 10.368f)
                curveToRelative(0.033f, 1.962f, -0.72f, 3.445f, -2.167f, 4.6f)
                curveToRelative(-2.156f, 1.72f, -4.67f, 2.471f, -7.301f, 2.85f)
                curveToRelative(-3.814f, 0.549f, -7.595f, 0.363f, -11.206f, -1.115f)
                curveToRelative(-1.31f, -0.535f, -2.545f, -1.377f, -3.672f, -2.272f)
                curveToRelative(-1.15f, -0.914f, -1.617f, -2.266f, -1.61f, -3.82f)
                curveToRelative(0.026f, -6.826f, 0.01f, -13.654f, 0.01f, -20.48f)
                curveToRelative(0.0f, -2.028f, 0.74f, -3.676f, 2.294f, -4.91f)
                curveToRelative(1.905f, -1.515f, 4.1f, -2.29f, 6.421f, -2.697f)
                curveToRelative(4.471f, -0.784f, 8.862f, -0.566f, 13.06f, 1.397f)
                curveToRelative(0.83f, 0.39f, 1.617f, 0.932f, 2.332f, 1.522f)
                curveToRelative(1.383f, 1.142f, 1.956f, 2.612f, 1.876f, 4.532f)
                curveToRelative(-0.14f, 3.335f, -0.039f, 6.684f, -0.039f, 10.027f)
                horizontalLineToRelative(-0.014f)
                verticalLineToRelative(-0.002f)
                close()
                moveTo(33.203f, 38.07f)
                curveToRelative(-0.014f, -0.099f, -0.028f, -0.197f, -0.04f, -0.294f)
                horizontalLineToRelative(-4.349f)
                curveToRelative(0.0f, -0.08f, 0.002f, -0.16f, 0.004f, -0.243f)
                curveToRelative(0.255f, -0.018f, 0.51f, -0.05f, 0.764f, -0.051f)
                curveToRelative(1.192f, -0.006f, 2.386f, 0.0f, 3.578f, -0.006f)
                curveToRelative(1.043f, -0.003f, 1.557f, -0.42f, 1.945f, -1.67f)
                horizontalLineToRelative(-6.26f)
                curveToRelative(0.0f, -0.08f, -0.003f, -0.16f, -0.004f, -0.24f)
                curveToRelative(0.25f, -0.015f, 0.5f, -0.044f, 0.751f, -0.044f)
                curveToRelative(1.654f, -0.004f, 3.309f, -0.035f, 4.96f, 0.013f)
                curveToRelative(0.703f, 0.02f, 0.932f, -0.256f, 0.845f, -0.936f)
                curveToRelative(-0.028f, -0.217f, -0.035f, -0.438f, -0.063f, -0.796f)
                lineTo(28.84f, 33.803f)
                verticalLineToRelative(-0.283f)
                horizontalLineToRelative(6.517f)
                verticalLineToRelative(-1.703f)
                lineTo(28.85f, 31.817f)
                verticalLineToRelative(-0.294f)
                horizontalLineToRelative(6.492f)
                verticalLineToRelative(-1.706f)
                horizontalLineToRelative(-6.495f)
                lineToRelative(0.005f, -0.27f)
                horizontalLineToRelative(6.484f)
                lineTo(35.336f, 27.81f)
                lineTo(28.84f, 27.81f)
                lineToRelative(0.003f, -0.261f)
                horizontalLineToRelative(6.495f)
                lineTo(35.338f, 25.82f)
                horizontalLineToRelative(-6.49f)
                curveToRelative(0.0f, -0.098f, 0.002f, -0.195f, 0.004f, -0.294f)
                horizontalLineToRelative(6.48f)
                verticalLineToRelative(-1.693f)
                horizontalLineToRelative(-6.52f)
                lineToRelative(0.001f, -0.287f)
                horizontalLineToRelative(6.504f)
                verticalLineToRelative(-1.72f)
                horizontalLineToRelative(-6.476f)
                verticalLineToRelative(-0.258f)
                horizontalLineToRelative(6.478f)
                verticalLineToRelative(-1.677f)
                curveToRelative(-1.883f, 0.0f, -3.695f, 0.007f, -5.508f, -0.004f)
                curveToRelative(-0.713f, -0.005f, -1.436f, -0.15f, -2.137f, -0.064f)
                curveToRelative(-3.042f, 0.375f, -6.057f, 0.328f, -9.049f, -0.394f)
                curveToRelative(-2.181f, -0.526f, -4.204f, -1.408f, -6.032f, -3.036f)
                curveToRelative(-0.02f, 0.46f, -0.044f, 0.758f, -0.044f, 1.058f)
                curveToRelative(-0.001f, 5.637f, 0.023f, 11.276f, -0.017f, 16.913f)
                curveToRelative(-0.009f, 1.315f, 0.44f, 2.327f, 1.402f, 3.081f)
                curveToRelative(0.666f, 0.523f, 1.38f, 1.018f, 2.143f, 1.361f)
                curveToRelative(3.94f, 1.778f, 8.043f, 1.88f, 12.205f, 1.151f)
                curveToRelative(0.412f, -0.073f, 0.795f, -0.44f, 1.199f, -0.453f)
                curveToRelative(1.416f, -0.046f, 2.559f, -0.747f, 3.72f, -1.434f)
                horizontalLineToRelative(0.001f)
                close()
                moveTo(24.076f, 18.56f)
                curveToRelative(1.45f, -0.172f, 2.917f, -0.267f, 4.35f, -0.536f)
                curveToRelative(2.126f, -0.398f, 4.143f, -1.125f, 5.826f, -2.628f)
                curveToRelative(1.564f, -1.398f, 1.666f, -3.334f, 0.0f, -4.704f)
                curveToRelative(-0.902f, -0.742f, -1.926f, -1.414f, -3.0f, -1.814f)
                curveToRelative(-4.619f, -1.722f, -9.298f, -1.704f, -13.963f, -0.159f)
                curveToRelative(-1.376f, 0.457f, -2.659f, 1.147f, -3.67f, 2.273f)
                curveToRelative(-1.224f, 1.359f, -1.256f, 2.84f, 0.06f, 4.102f)
                arcToRelative(10.435f, 10.435f, 0.0f, false, false, 2.552f, 1.77f)
                curveToRelative(2.468f, 1.23f, 5.129f, 1.575f, 7.847f, 1.695f)
                horizontalLineToRelative(-0.002f)
                close()
                moveTo(29.802f, 19.502f)
                horizontalLineToRelative(5.553f)
                verticalLineToRelative(-1.509f)
                curveToRelative(-2.124f, -0.573f, -3.653f, 1.199f, -5.553f, 1.509f)
                close()
            }
        }
        .build()
        return _geoShape3!!
    }

private var _geoShape3: ImageVector? = null
