package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.group
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RtCertifiedFreshBadge: ImageVector
    get() {
        if (_rtCertifiedFreshBadge != null) {
            return _rtCertifiedFreshBadge!!
        }
        _rtCertifiedFreshBadge = Builder(name = "RtCertifiedFreshBadge", defaultWidth = 24.0.dp,
                defaultHeight = 25.0.dp, viewportWidth = 24.0f, viewportHeight = 25.0f).apply {
            group {
                path(fill = SolidColor(Color(0xFF0AC855)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(6.8886f, 19.8554f)
                    curveTo(5.9449f, 19.9141f, 5.1381f, 20.3663f, 4.663f, 20.9883f)
                    curveTo(4.6481f, 21.0047f, 4.6248f, 21.0035f, 4.6183f, 20.9865f)
                    curveTo(4.4557f, 20.3399f, 4.4111f, 19.6499f, 4.4932f, 18.9545f)
                    curveTo(4.4932f, 18.9469f, 4.4873f, 18.9416f, 4.4772f, 18.9399f)
                    curveTo(4.0336f, 18.8024f, 3.543f, 18.7273f, 3.0208f, 18.7349f)
                    curveTo(2.9922f, 18.7378f, 2.9934f, 18.7108f, 3.0226f, 18.6826f)
                    curveTo(3.5769f, 18.1535f, 4.2456f, 17.7395f, 4.9898f, 17.4781f)
                    curveTo(5.247f, 18.1928f, 5.4983f, 18.9093f, 5.7448f, 19.6264f)
                    curveTo(5.7496f, 19.6399f, 5.7692f, 19.6481f, 5.7895f, 19.6446f)
                    curveTo(5.793f, 19.6446f, 6.3974f, 19.5395f, 6.8523f, 19.4784f)
                    lineTo(6.8886f, 19.8554f)
                    close()
                    moveTo(20.2981f, 18.6826f)
                    curveTo(19.7431f, 18.1535f, 19.0745f, 17.7395f, 18.3302f, 17.4775f)
                    curveTo(18.073f, 18.1923f, 17.8211f, 18.9087f, 17.5746f, 19.6258f)
                    curveTo(17.5699f, 19.6393f, 17.5502f, 19.6475f, 17.53f, 19.644f)
                    curveTo(17.527f, 19.644f, 16.9155f, 19.5383f, 16.4677f, 19.4778f)
                    curveTo(16.4517f, 19.654f, 16.4314f, 19.8548f, 16.4314f, 19.8548f)
                    curveTo(17.3752f, 19.9136f, 18.1819f, 20.3658f, 18.6571f, 20.9877f)
                    curveTo(18.672f, 21.0041f, 18.6952f, 21.003f, 18.7017f, 20.9859f)
                    curveTo(18.8643f, 20.3393f, 18.909f, 19.6493f, 18.8268f, 18.954f)
                    curveTo(18.8268f, 18.9463f, 18.8333f, 18.941f, 18.8429f, 18.9393f)
                    curveTo(19.2865f, 18.8018f, 19.7771f, 18.7267f, 20.2993f, 18.7343f)
                    curveTo(20.3278f, 18.7372f, 20.3267f, 18.7102f, 20.2975f, 18.682f)
                    lineTo(20.2981f, 18.6826f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(7.12f, 6.4743f)
                    curveTo(7.1498f, 6.6523f, 7.1307f, 6.765f, 7.0086f, 6.8878f)
                    curveTo(6.9283f, 6.9688f, 6.8336f, 7.0082f, 6.7217f, 7.0058f)
                    curveTo(6.6091f, 7.004f, 6.5073f, 6.9571f, 6.418f, 6.8707f)
                    curveTo(6.3299f, 6.7856f, 6.2822f, 6.6875f, 6.2781f, 6.5771f)
                    curveTo(6.2739f, 6.4661f, 6.3084f, 6.3721f, 6.3876f, 6.2929f)
                    curveTo(6.499f, 6.1813f, 6.5907f, 6.166f, 6.7467f, 6.1742f)
                    lineTo(6.7383f, 5.816f)
                    curveTo(6.4978f, 5.8001f, 6.2882f, 5.8806f, 6.1137f, 6.0562f)
                    curveTo(5.9548f, 6.2159f, 5.8785f, 6.4015f, 5.8809f, 6.6135f)
                    curveTo(5.8845f, 6.8243f, 5.9661f, 7.0064f, 6.128f, 7.1632f)
                    curveTo(6.29f, 7.32f, 6.4781f, 7.401f, 6.6901f, 7.4022f)
                    curveTo(6.9021f, 7.4034f, 7.0866f, 7.3229f, 7.2456f, 7.1626f)
                    curveTo(7.4415f, 6.9653f, 7.5201f, 6.7398f, 7.482f, 6.4896f)
                    lineTo(7.12f, 6.4749f)
                    verticalLineTo(6.4743f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(8.0057f, 6.0726f)
                    lineTo(7.8646f, 5.8518f)
                    lineTo(8.4559f, 5.4842f)
                    lineTo(8.2749f, 5.2005f)
                    lineTo(7.6836f, 5.5687f)
                    lineTo(7.5574f, 5.372f)
                    lineTo(8.1808f, 4.9844f)
                    lineTo(7.992f, 4.6902f)
                    lineTo(7.0078f, 5.3027f)
                    lineTo(7.8325f, 6.5924f)
                    lineTo(8.8173f, 5.9793f)
                    lineTo(8.6291f, 5.685f)
                    lineTo(8.0057f, 6.0726f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(9.8955f, 4.5269f)
                    curveTo(9.7842f, 4.2057f, 9.4972f, 4.1052f, 9.1155f, 4.2339f)
                    lineTo(8.5332f, 4.4306f)
                    lineTo(9.0345f, 5.8729f)
                    lineTo(9.4144f, 5.7443f)
                    lineTo(9.259f, 5.298f)
                    lineTo(9.4091f, 5.2469f)
                    lineTo(9.833f, 5.6022f)
                    lineTo(10.2689f, 5.4548f)
                    lineTo(9.7794f, 5.0455f)
                    curveTo(9.9277f, 4.901f, 9.9658f, 4.7295f, 9.8949f, 4.5257f)
                    lineTo(9.8955f, 4.5269f)
                    close()
                    moveTo(9.3251f, 4.9104f)
                    lineTo(9.1459f, 4.9709f)
                    lineTo(9.028f, 4.6314f)
                    lineTo(9.2072f, 4.571f)
                    curveTo(9.3823f, 4.5116f, 9.4746f, 4.5357f, 9.515f, 4.6508f)
                    curveTo(9.5555f, 4.7683f, 9.4924f, 4.854f, 9.3257f, 4.9104f)
                    horizontalLineTo(9.3251f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(11.3204f, 3.8016f)
                    lineTo(10.0879f, 3.9479f)
                    lineTo(10.1302f, 4.2938f)
                    lineTo(10.5458f, 4.2439f)
                    lineTo(10.6887f, 5.4125f)
                    lineTo(11.0894f, 5.3644f)
                    lineTo(10.9465f, 4.1963f)
                    lineTo(11.3627f, 4.1469f)
                    lineTo(11.3204f, 3.8016f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(11.9025f, 3.8221f)
                    lineTo(11.8184f, 5.3444f)
                    lineTo(12.2191f, 5.366f)
                    lineTo(12.3032f, 3.8437f)
                    lineTo(11.9025f, 3.8221f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(13.9621f, 4.5064f)
                    lineTo(14.0449f, 4.1681f)
                    lineTo(12.9863f, 3.9156f)
                    lineTo(12.623f, 5.3979f)
                    lineTo(13.013f, 5.4907f)
                    lineTo(13.147f, 4.9451f)
                    lineTo(13.7663f, 5.0931f)
                    lineTo(13.846f, 4.7671f)
                    lineTo(13.2268f, 4.6197f)
                    lineTo(13.2935f, 4.3467f)
                    lineTo(13.9621f, 4.5064f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(14.5939f, 4.384f)
                    lineTo(13.9746f, 5.7808f)
                    lineTo(14.3423f, 5.9394f)
                    lineTo(14.9616f, 4.5426f)
                    lineTo(14.5939f, 4.384f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(15.2459f, 6.0427f)
                    lineTo(15.3948f, 5.8271f)
                    lineTo(15.9723f, 6.2153f)
                    lineTo(16.1635f, 5.9381f)
                    lineTo(15.5859f, 5.5505f)
                    lineTo(15.7187f, 5.3579f)
                    lineTo(16.3272f, 5.7667f)
                    lineTo(16.5261f, 5.4789f)
                    lineTo(15.5633f, 4.8329f)
                    lineTo(14.6934f, 6.0932f)
                    lineTo(15.6562f, 6.7398f)
                    lineTo(15.8544f, 6.452f)
                    lineTo(15.2459f, 6.0427f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF000000)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(17.6222f, 6.7927f)
                    curveTo(17.5948f, 6.5971f, 17.5049f, 6.428f, 17.3566f, 6.267f)
                    lineTo(16.9821f, 5.8589f)
                    lineTo(15.8359f, 6.8819f)
                    lineTo(16.2105f, 7.2901f)
                    curveTo(16.4903f, 7.5949f, 16.9184f, 7.7728f, 17.3531f, 7.3846f)
                    curveTo(17.5609f, 7.199f, 17.6496f, 6.9882f, 17.6216f, 6.7927f)
                    horizontalLineTo(17.6222f)
                    close()
                    moveTo(17.0827f, 7.0892f)
                    curveTo(16.8702f, 7.2789f, 16.6558f, 7.256f, 16.4397f, 7.0205f)
                    lineTo(16.3671f, 6.9412f)
                    lineTo(16.9899f, 6.3851f)
                    lineTo(17.0607f, 6.4626f)
                    curveTo(17.278f, 6.6999f, 17.2852f, 6.9078f, 17.0822f, 7.0886f)
                    lineTo(17.0827f, 7.0892f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFA3108)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(12.4896f, 8.03f)
                    lineTo(12.4062f, 8.0247f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, fillAlpha = 0.5f,
                        strokeAlpha = 0.5f, strokeLineWidth = 0.0f, strokeLineCap = Butt,
                        strokeLineJoin = Miter, strokeLineMiter = 4.0f, pathFillType = NonZero) {
                    moveTo(18.3318f, 17.4775f)
                    curveTo(18.0841f, 18.1664f, 17.8143f, 18.9345f, 17.5762f, 19.6257f)
                    lineTo(18.714f, 17.6278f)
                    curveTo(18.714f, 17.6278f, 18.5288f, 17.5462f, 18.3318f, 17.4775f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, fillAlpha = 0.5f,
                        strokeAlpha = 0.5f, strokeLineWidth = 0.0f, strokeLineCap = Butt,
                        strokeLineJoin = Miter, strokeLineMiter = 4.0f, pathFillType = NonZero) {
                    moveTo(4.9904f, 17.4781f)
                    curveTo(4.7874f, 17.5503f, 4.6094f, 17.6284f, 4.6094f, 17.6284f)
                    lineTo(5.7448f, 19.6228f)
                    curveTo(5.5073f, 18.9328f, 5.2375f, 18.1658f, 4.9899f, 17.4781f)
                    horizontalLineTo(4.9904f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, fillAlpha = 0.8f,
                        strokeAlpha = 0.8f, strokeLineWidth = 0.0f, strokeLineCap = Butt,
                        strokeLineJoin = Miter, strokeLineMiter = 4.0f, pathFillType = NonZero) {
                    moveTo(17.5362f, 19.6452f)
                    lineTo(16.4317f, 19.8554f)
                    lineTo(16.468f, 19.4784f)
                    curveTo(16.8199f, 19.5277f, 17.1861f, 19.5853f, 17.5362f, 19.6452f)
                    close()
                    moveTo(5.7832f, 19.6452f)
                    lineTo(6.8889f, 19.8554f)
                    lineTo(6.8526f, 19.4784f)
                    curveTo(6.5007f, 19.5277f, 6.1339f, 19.5853f, 5.7838f, 19.6458f)
                    lineTo(5.7832f, 19.6452f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF050505)), stroke = null, fillAlpha = 0.3f,
                        strokeAlpha = 0.3f, strokeLineWidth = 0.0f, strokeLineCap = Butt,
                        strokeLineJoin = Miter, strokeLineMiter = 4.0f, pathFillType = NonZero) {
                    moveTo(17.4673f, 16.2783f)
                    curveTo(18.1931f, 15.3028f, 18.6123f, 14.1036f, 18.573f, 12.7382f)
                    curveTo(18.5331f, 14.1059f, 18.0258f, 15.2946f, 17.2148f, 16.2418f)
                    curveTo(17.2994f, 16.2536f, 17.3833f, 16.2659f, 17.4679f, 16.2783f)
                    horizontalLineTo(17.4673f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF050505)), stroke = null, fillAlpha = 0.3f,
                        strokeAlpha = 0.3f, strokeLineWidth = 0.0f, strokeLineCap = Butt,
                        strokeLineJoin = Miter, strokeLineMiter = 4.0f, pathFillType = NonZero) {
                    moveTo(5.7587f, 16.2924f)
                    curveTo(5.8403f, 16.28f, 5.9213f, 16.2683f, 6.0028f, 16.2565f)
                    curveTo(5.2812f, 15.3856f, 4.8144f, 14.2986f, 4.734f, 13.0242f)
                    curveTo(4.7274f, 12.9202f, 4.7233f, 12.8169f, 4.7209f, 12.7135f)
                    curveTo(4.7155f, 12.9326f, 4.7203f, 13.154f, 4.734f, 13.3765f)
                    curveTo(4.8049f, 14.5006f, 5.177f, 15.479f, 5.7587f, 16.2918f)
                    verticalLineTo(16.2924f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFA6E0F)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(12.877f, 18.3943f)
                    curveTo(12.9294f, 18.409f, 12.9877f, 18.4178f, 13.0514f, 18.4201f)
                    curveTo(12.9937f, 18.4113f, 12.9353f, 18.4025f, 12.877f, 18.3943f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFA6E0F)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(5.2491f, 16.3734f)
                    curveTo(5.114f, 16.2096f, 4.9842f, 16.041f, 4.8609f, 15.8666f)
                    curveTo(3.8981f, 14.5f, 3.3896f, 12.895f, 3.3896f, 11.2248f)
                    curveTo(3.3896f, 9.0713f, 4.2459f, 7.0804f, 5.8011f, 5.6192f)
                    curveTo(7.342f, 4.1716f, 9.4177f, 3.3747f, 11.6463f, 3.3747f)
                    curveTo(13.875f, 3.3747f, 15.95f, 4.1722f, 17.4916f, 5.6192f)
                    curveTo(19.0468f, 7.0804f, 19.903f, 9.0707f, 19.903f, 11.2248f)
                    curveTo(19.903f, 12.8885f, 19.3981f, 14.4883f, 18.4419f, 15.8514f)
                    curveTo(18.3168f, 16.0299f, 18.184f, 16.202f, 18.0465f, 16.3693f)
                    curveTo(18.1888f, 16.3928f, 18.3305f, 16.4169f, 18.4722f, 16.4416f)
                    curveTo(19.6065f, 14.9998f, 20.2829f, 13.1904f, 20.2829f, 11.2248f)
                    curveTo(20.2823f, 6.5201f, 16.4156f, 3.0f, 11.6463f, 3.0f)
                    curveTo(6.877f, 3.0f, 3.0098f, 6.5201f, 3.0098f, 11.2248f)
                    curveTo(3.0098f, 13.1928f, 3.6874f, 15.0039f, 4.8234f, 16.4463f)
                    curveTo(4.9651f, 16.4216f, 5.1068f, 16.3969f, 5.2491f, 16.3734f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFA6E0F)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(17.6762f, 17.1633f)
                    horizontalLineTo(17.6523f)
                    verticalLineTo(17.1187f)
                    horizontalLineTo(17.6762f)
                    curveTo(17.7f, 17.1187f, 17.7125f, 17.1258f, 17.7125f, 17.1404f)
                    curveTo(17.7125f, 17.1551f, 17.7f, 17.1633f, 17.6762f, 17.1633f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(17.6781f, 17.1187f)
                    horizontalLineTo(17.6543f)
                    verticalLineTo(17.1633f)
                    horizontalLineTo(17.6781f)
                    curveTo(17.7019f, 17.1633f, 17.7144f, 17.1557f, 17.7144f, 17.1404f)
                    curveTo(17.7144f, 17.1251f, 17.7019f, 17.1187f, 17.6781f, 17.1187f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(7.8612f, 17.5162f)
                    curveTo(7.735f, 17.5162f, 7.6231f, 17.6114f, 7.6231f, 17.7423f)
                    curveTo(7.6231f, 17.8733f, 7.735f, 17.9661f, 7.8612f, 17.9661f)
                    curveTo(7.9874f, 17.9661f, 8.0994f, 17.8762f, 8.0994f, 17.7423f)
                    curveTo(8.0994f, 17.6084f, 7.9892f, 17.5162f, 7.8612f, 17.5162f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(15.6112f, 17.4534f)
                    curveTo(15.4998f, 17.4534f, 15.4218f, 17.5216f, 15.4004f, 17.6196f)
                    horizontalLineTo(15.8124f)
                    curveTo(15.8035f, 17.5245f, 15.7344f, 17.4534f, 15.6112f, 17.4534f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(17.6787f, 17.0241f)
                    curveTo(17.6388f, 17.0241f, 17.6042f, 17.0388f, 17.5768f, 17.067f)
                    curveTo(17.5494f, 17.0952f, 17.5352f, 17.1304f, 17.5352f, 17.1709f)
                    curveTo(17.5352f, 17.2115f, 17.5489f, 17.2461f, 17.5768f, 17.2743f)
                    curveTo(17.6042f, 17.3025f, 17.6388f, 17.316f, 17.6787f, 17.316f)
                    curveTo(17.7185f, 17.316f, 17.7537f, 17.3025f, 17.7811f, 17.2743f)
                    curveTo(17.8096f, 17.2461f, 17.8233f, 17.212f, 17.8233f, 17.1709f)
                    curveTo(17.8233f, 17.1298f, 17.8096f, 17.0946f, 17.7811f, 17.067f)
                    curveTo(17.7537f, 17.0382f, 17.7191f, 17.0241f, 17.6787f, 17.0241f)
                    close()
                    moveTo(17.7144f, 17.2567f)
                    lineTo(17.6792f, 17.2073f)
                    horizontalLineTo(17.6542f)
                    verticalLineTo(17.2567f)
                    horizontalLineTo(17.6048f)
                    verticalLineTo(17.0764f)
                    horizontalLineTo(17.687f)
                    curveTo(17.7305f, 17.0764f, 17.765f, 17.0969f, 17.765f, 17.1404f)
                    curveTo(17.765f, 17.1686f, 17.7525f, 17.188f, 17.7269f, 17.1991f)
                    lineTo(17.7698f, 17.2567f)
                    horizontalLineTo(17.7138f)
                    horizontalLineTo(17.7144f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(9.8003f, 16.4216f)
                    curveTo(9.8717f, 16.4204f, 9.9092f, 16.6259f, 10.0914f, 16.6165f)
                    curveTo(10.173f, 16.6124f, 10.2171f, 16.512f, 10.1754f, 16.4427f)
                    curveTo(10.1754f, 16.4427f, 10.1748f, 16.4415f, 10.1742f, 16.4409f)
                    curveTo(10.1379f, 16.3822f, 10.0813f, 16.3599f, 10.017f, 16.3487f)
                    curveTo(9.9729f, 16.3411f, 9.9277f, 16.337f, 9.8866f, 16.3164f)
                    curveTo(9.8646f, 16.3059f, 9.8515f, 16.2888f, 9.8515f, 16.2636f)
                    curveTo(9.8515f, 16.2372f, 9.8622f, 16.2178f, 9.886f, 16.206f)
                    curveTo(9.9211f, 16.1884f, 9.9592f, 16.1896f, 9.9973f, 16.189f)
                    curveTo(10.0241f, 16.189f, 10.0485f, 16.1825f, 10.0688f, 16.1638f)
                    curveTo(10.114f, 16.1226f, 10.1236f, 16.0469f, 10.0944f, 15.9917f)
                    curveTo(10.0569f, 15.9224f, 9.9759f, 15.9142f, 9.9181f, 15.9506f)
                    curveTo(9.8777f, 15.9758f, 9.8634f, 16.0158f, 9.8521f, 16.0586f)
                    curveTo(9.8443f, 16.088f, 9.8265f, 16.1344f, 9.7651f, 16.132f)
                    curveTo(9.7187f, 16.1303f, 9.6943f, 16.0845f, 9.7026f, 16.0392f)
                    curveTo(9.7098f, 16.0005f, 9.724f, 15.9658f, 9.7294f, 15.9265f)
                    curveTo(9.7354f, 15.8836f, 9.7282f, 15.8431f, 9.6943f, 15.8126f)
                    curveTo(9.6687f, 15.7902f, 9.6371f, 15.7797f, 9.602f, 15.7832f)
                    curveTo(9.602f, 15.7832f, 9.5139f, 15.7861f, 9.4835f, 15.8631f)
                    curveTo(9.4466f, 15.957f, 9.5073f, 15.987f, 9.5508f, 16.0216f)
                    curveTo(9.5752f, 16.041f, 9.5996f, 16.061f, 9.6091f, 16.0927f)
                    curveTo(9.6163f, 16.1168f, 9.6097f, 16.1426f, 9.5913f, 16.1549f)
                    curveTo(9.5657f, 16.1714f, 9.527f, 16.1685f, 9.499f, 16.145f)
                    curveTo(9.4662f, 16.1179f, 9.4561f, 16.0522f, 9.3918f, 16.0275f)
                    curveTo(9.3382f, 16.0069f, 9.268f, 16.0263f, 9.2441f, 16.0733f)
                    curveTo(9.2126f, 16.1362f, 9.2531f, 16.1943f, 9.3215f, 16.2219f)
                    curveTo(9.3602f, 16.2372f, 9.4895f, 16.2424f, 9.4823f, 16.307f)
                    curveTo(9.4763f, 16.3628f, 9.3602f, 16.3188f, 9.3078f, 16.3382f)
                    curveTo(9.2245f, 16.3687f, 9.2209f, 16.4492f, 9.24f, 16.4903f)
                    curveTo(9.2668f, 16.5496f, 9.3418f, 16.5684f, 9.396f, 16.5443f)
                    curveTo(9.4555f, 16.5173f, 9.5067f, 16.3969f, 9.5686f, 16.428f)
                    curveTo(9.6282f, 16.4574f, 9.5704f, 16.5267f, 9.5668f, 16.5942f)
                    curveTo(9.5645f, 16.6541f, 9.6073f, 16.7134f, 9.7098f, 16.7029f)
                    curveTo(9.7717f, 16.6964f, 9.8247f, 16.6236f, 9.7889f, 16.559f)
                    curveTo(9.7788f, 16.5408f, 9.7627f, 16.5114f, 9.7568f, 16.4909f)
                    curveTo(9.7461f, 16.4545f, 9.7705f, 16.4227f, 9.8008f, 16.4222f)
                    lineTo(9.8003f, 16.4216f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(11.582f, 17.5462f)
                    curveTo(11.5153f, 17.5462f, 11.4587f, 17.5697f, 11.4194f, 17.6137f)
                    curveTo(11.3843f, 17.6525f, 11.3652f, 17.7048f, 11.3652f, 17.7606f)
                    curveTo(11.3652f, 17.9009f, 11.4742f, 17.9743f, 11.582f, 17.9743f)
                    curveTo(11.6487f, 17.9743f, 11.7052f, 17.9508f, 11.7445f, 17.9068f)
                    curveTo(11.7796f, 17.868f, 11.7987f, 17.8158f, 11.7987f, 17.76f)
                    curveTo(11.7987f, 17.6196f, 11.6897f, 17.5462f, 11.582f, 17.5462f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(18.6487f, 16.472f)
                    curveTo(17.4871f, 16.263f, 16.3147f, 16.1073f, 15.137f, 16.0034f)
                    curveTo(15.1435f, 16.0486f, 15.1477f, 16.0968f, 15.1477f, 16.1485f)
                    verticalLineTo(16.8937f)
                    horizontalLineTo(14.6255f)
                    verticalLineTo(16.1913f)
                    curveTo(14.6255f, 16.0833f, 14.5779f, 16.0281f, 14.485f, 16.0281f)
                    curveTo(14.3921f, 16.0281f, 14.3266f, 16.0686f, 14.3266f, 16.2718f)
                    verticalLineTo(16.8937f)
                    horizontalLineTo(13.8032f)
                    verticalLineTo(15.9083f)
                    curveTo(13.7734f, 15.9065f, 13.7443f, 15.9047f, 13.7145f, 15.9036f)
                    curveTo(13.7145f, 15.9165f, 13.7157f, 15.9288f, 13.7157f, 15.9417f)
                    lineTo(13.7133f, 16.0639f)
                    horizontalLineTo(12.8452f)
                    curveTo(12.8696f, 16.1672f, 12.9851f, 16.2254f, 13.0929f, 16.2254f)
                    curveTo(13.2191f, 16.2254f, 13.312f, 16.2095f, 13.3787f, 16.1226f)
                    lineTo(13.696f, 16.4168f)
                    curveTo(13.5216f, 16.6001f, 13.3037f, 16.6359f, 13.0982f, 16.6359f)
                    curveTo(12.8928f, 16.6359f, 12.7249f, 16.5748f, 12.5903f, 16.4556f)
                    curveTo(12.4558f, 16.3364f, 12.3891f, 16.1719f, 12.3891f, 15.9652f)
                    curveTo(12.3891f, 15.9276f, 12.3921f, 15.8924f, 12.3962f, 15.8578f)
                    curveTo(12.3617f, 15.8572f, 12.3278f, 15.8566f, 12.2932f, 15.856f)
                    verticalLineTo(16.0469f)
                    horizontalLineTo(12.089f)
                    verticalLineTo(16.3699f)
                    curveTo(12.089f, 16.4327f, 12.1164f, 16.4591f, 12.1795f, 16.4591f)
                    horizontalLineTo(12.3438f)
                    verticalLineTo(16.9013f)
                    lineTo(12.3135f, 16.9025f)
                    curveTo(12.2605f, 16.9049f, 12.1902f, 16.9078f, 12.117f, 16.9078f)
                    curveTo(11.7699f, 16.9078f, 11.5704f, 16.7357f, 11.5704f, 16.4027f)
                    verticalLineTo(16.0463f)
                    horizontalLineTo(11.4352f)
                    verticalLineTo(15.8507f)
                    curveTo(11.4019f, 15.8507f, 11.3685f, 15.8513f, 11.3346f, 15.8519f)
                    verticalLineTo(16.0457f)
                    horizontalLineTo(11.1304f)
                    verticalLineTo(16.3687f)
                    curveTo(11.1304f, 16.4315f, 11.1578f, 16.458f, 11.2209f, 16.458f)
                    horizontalLineTo(11.3852f)
                    verticalLineTo(16.9002f)
                    lineTo(11.3548f, 16.9013f)
                    curveTo(11.3019f, 16.9037f, 11.2316f, 16.9066f, 11.1584f, 16.9066f)
                    curveTo(10.8112f, 16.9066f, 10.6118f, 16.7346f, 10.6118f, 16.4016f)
                    verticalLineTo(16.0451f)
                    horizontalLineTo(10.4766f)
                    verticalLineTo(15.8672f)
                    curveTo(10.429f, 15.8683f, 10.3807f, 15.8707f, 10.3331f, 15.8718f)
                    curveTo(10.3938f, 15.9723f, 10.429f, 16.0974f, 10.429f, 16.2459f)
                    curveTo(10.429f, 16.6917f, 10.1146f, 16.9254f, 9.7109f, 16.9254f)
                    curveTo(9.3072f, 16.9254f, 8.9738f, 16.6917f, 8.9738f, 16.2459f)
                    curveTo(8.9738f, 16.1279f, 8.9976f, 16.0251f, 9.0393f, 15.9376f)
                    curveTo(9.0077f, 15.94f, 8.9761f, 15.9417f, 8.9446f, 15.9441f)
                    curveTo(8.9124f, 16.0903f, 8.8297f, 16.2042f, 8.6975f, 16.2829f)
                    lineTo(8.6814f, 16.2929f)
                    lineTo(9.044f, 16.8931f)
                    horizontalLineTo(8.4575f)
                    lineTo(8.1312f, 16.3428f)
                    horizontalLineTo(8.0443f)
                    verticalLineTo(16.8931f)
                    horizontalLineTo(7.5233f)
                    verticalLineTo(16.0668f)
                    curveTo(6.5689f, 16.1672f, 5.6186f, 16.3017f, 4.6754f, 16.4715f)
                    curveTo(4.6504f, 16.4762f, 4.6343f, 16.492f, 4.6397f, 16.5067f)
                    curveTo(5.0202f, 17.5438f, 5.3893f, 18.5839f, 5.7478f, 19.6257f)
                    curveTo(5.7525f, 19.6392f, 5.7722f, 19.6474f, 5.7924f, 19.6439f)
                    curveTo(9.6627f, 18.975f, 13.6633f, 18.975f, 17.5335f, 19.6439f)
                    curveTo(17.5538f, 19.6474f, 17.5734f, 19.6392f, 17.5782f, 19.6257f)
                    curveTo(17.9366f, 18.5839f, 18.3058f, 17.5444f, 18.6862f, 16.5067f)
                    curveTo(18.6916f, 16.492f, 18.6755f, 16.4762f, 18.6505f, 16.4715f)
                    lineTo(18.6487f, 16.472f)
                    close()
                    moveTo(6.8707f, 18.4065f)
                    horizontalLineTo(6.3652f)
                    verticalLineTo(17.2003f)
                    horizontalLineTo(5.9198f)
                    verticalLineTo(16.7099f)
                    horizontalLineTo(7.3161f)
                    verticalLineTo(17.2003f)
                    horizontalLineTo(6.8707f)
                    verticalLineTo(18.4065f)
                    close()
                    moveTo(7.871f, 18.44f)
                    curveTo(7.4906f, 18.44f, 7.144f, 18.2063f, 7.144f, 17.7599f)
                    curveTo(7.144f, 17.4046f, 7.3572f, 17.1838f, 7.6442f, 17.1087f)
                    curveTo(7.8038f, 17.0746f, 7.899f, 17.0687f, 8.0729f, 17.1022f)
                    curveTo(8.3718f, 17.1703f, 8.5796f, 17.3935f, 8.5796f, 17.7594f)
                    curveTo(8.5796f, 18.2057f, 8.2521f, 18.4394f, 7.8716f, 18.4394f)
                    lineTo(7.871f, 18.44f)
                    close()
                    moveTo(10.7767f, 18.4065f)
                    horizontalLineTo(10.2497f)
                    verticalLineTo(17.7053f)
                    curveTo(10.2497f, 17.5949f, 10.2021f, 17.5391f, 10.108f, 17.5391f)
                    curveTo(10.014f, 17.5391f, 9.9955f, 17.5973f, 9.9895f, 17.6161f)
                    curveTo(9.9735f, 17.673f, 9.9705f, 17.7059f, 9.9705f, 17.7752f)
                    verticalLineTo(18.4065f)
                    horizontalLineTo(9.4513f)
                    verticalLineTo(17.7053f)
                    curveTo(9.4513f, 17.5949f, 9.4025f, 17.5391f, 9.3066f, 17.5391f)
                    curveTo(9.2524f, 17.5391f, 9.2167f, 17.565f, 9.2024f, 17.5879f)
                    curveTo(9.1923f, 17.6026f, 9.1851f, 17.629f, 9.1804f, 17.6472f)
                    curveTo(9.1708f, 17.7f, 9.1708f, 17.7194f, 9.1708f, 17.7758f)
                    verticalLineTo(18.4071f)
                    horizontalLineTo(8.6659f)
                    verticalLineTo(17.1151f)
                    horizontalLineTo(9.1655f)
                    verticalLineTo(17.2367f)
                    curveTo(9.2006f, 17.1656f, 9.3358f, 17.0816f, 9.5251f, 17.0816f)
                    curveTo(9.6811f, 17.0816f, 9.8056f, 17.1345f, 9.8848f, 17.2343f)
                    lineTo(9.899f, 17.2519f)
                    lineTo(9.9139f, 17.2349f)
                    curveTo(10.0086f, 17.1298f, 10.1164f, 17.0846f, 10.2759f, 17.0846f)
                    curveTo(10.426f, 17.0846f, 10.7761f, 17.141f, 10.7761f, 17.6601f)
                    verticalLineTo(18.4065f)
                    horizontalLineTo(10.7767f)
                    close()
                    moveTo(12.3099f, 18.4048f)
                    horizontalLineTo(11.8074f)
                    verticalLineTo(18.2597f)
                    lineTo(11.7746f, 18.2944f)
                    curveTo(11.6841f, 18.3895f, 11.571f, 18.44f, 11.4459f, 18.44f)
                    curveTo(11.3566f, 18.44f, 11.2632f, 18.4136f, 11.1756f, 18.3637f)
                    curveTo(10.9797f, 18.2521f, 10.863f, 18.026f, 10.863f, 17.7588f)
                    curveTo(10.863f, 17.4927f, 10.9797f, 17.2678f, 11.1756f, 17.1568f)
                    curveTo(11.2632f, 17.1069f, 11.356f, 17.0805f, 11.4448f, 17.0805f)
                    curveTo(11.5698f, 17.0805f, 11.6841f, 17.1321f, 11.7746f, 17.2296f)
                    lineTo(11.8074f, 17.2649f)
                    verticalLineTo(17.1169f)
                    horizontalLineTo(12.3099f)
                    verticalLineTo(18.4048f)
                    close()
                    moveTo(13.3191f, 18.4148f)
                    lineTo(13.2888f, 18.4159f)
                    curveTo(13.2358f, 18.4183f, 13.1655f, 18.4212f, 13.0923f, 18.4212f)
                    curveTo(12.7446f, 18.4212f, 12.5451f, 18.2491f, 12.5451f, 17.9162f)
                    verticalLineTo(17.5591f)
                    horizontalLineTo(12.4099f)
                    verticalLineTo(17.1139f)
                    horizontalLineTo(12.5451f)
                    verticalLineTo(16.7622f)
                    horizontalLineTo(13.0637f)
                    verticalLineTo(17.1139f)
                    horizontalLineTo(13.2685f)
                    verticalLineTo(17.5591f)
                    horizontalLineTo(13.0637f)
                    verticalLineTo(17.8827f)
                    curveTo(13.0637f, 17.9455f, 13.0911f, 17.9725f, 13.1548f, 17.9725f)
                    horizontalLineTo(13.3191f)
                    verticalLineTo(18.4153f)
                    verticalLineTo(18.4148f)
                    close()
                    moveTo(14.2998f, 18.4101f)
                    curveTo(13.9294f, 18.4981f, 13.5168f, 18.339f, 13.4221f, 17.9514f)
                    curveTo(13.3555f, 17.6777f, 13.4561f, 17.4563f, 13.6466f, 17.3113f)
                    curveTo(13.6651f, 17.2966f, 13.6877f, 17.2825f, 13.7032f, 17.2772f)
                    curveTo(13.6079f, 17.2854f, 13.5502f, 17.2807f, 13.4924f, 17.3001f)
                    curveTo(13.4859f, 17.3025f, 13.4805f, 17.2954f, 13.4835f, 17.2895f)
                    curveTo(13.5454f, 17.1733f, 13.6883f, 17.1169f, 13.7889f, 17.155f)
                    curveTo(13.7204f, 17.0957f, 13.6663f, 17.0487f, 13.6663f, 17.0487f)
                    lineTo(13.7603f, 16.9718f)
                    curveTo(13.7603f, 16.9718f, 13.8205f, 17.0593f, 13.8645f, 17.1233f)
                    curveTo(13.9223f, 16.9965f, 14.0646f, 16.9607f, 14.1402f, 17.0305f)
                    curveTo(14.145f, 17.0347f, 14.142f, 17.0423f, 14.1361f, 17.0429f)
                    curveTo(14.086f, 17.0505f, 14.0676f, 17.1016f, 14.0717f, 17.1357f)
                    lineTo(14.1152f, 17.1304f)
                    curveTo(14.4701f, 17.0857f, 14.7755f, 17.2602f, 14.8607f, 17.6084f)
                    curveTo(14.9553f, 17.996f, 14.6713f, 18.3208f, 14.3004f, 18.4095f)
                    lineTo(14.2998f, 18.4101f)
                    close()
                    moveTo(16.2694f, 17.8674f)
                    horizontalLineTo(15.4013f)
                    curveTo(15.4257f, 17.9708f, 15.5412f, 18.0289f, 15.649f, 18.0289f)
                    curveTo(15.7752f, 18.0289f, 15.8681f, 18.0131f, 15.9348f, 17.9267f)
                    lineTo(16.2522f, 18.221f)
                    curveTo(16.0777f, 18.4042f, 15.8598f, 18.44f, 15.6544f, 18.44f)
                    curveTo(15.449f, 18.44f, 15.281f, 18.3789f, 15.1465f, 18.2597f)
                    curveTo(15.0119f, 18.1405f, 14.9452f, 17.9761f, 14.9452f, 17.7693f)
                    curveTo(14.9452f, 17.5626f, 15.0071f, 17.4011f, 15.1334f, 17.2737f)
                    curveTo(15.2596f, 17.1439f, 15.4233f, 17.0805f, 15.6222f, 17.0805f)
                    curveTo(15.8211f, 17.0805f, 15.9902f, 17.1439f, 16.1033f, 17.2737f)
                    curveTo(16.2164f, 17.4035f, 16.2724f, 17.5597f, 16.2724f, 17.7453f)
                    lineTo(16.27f, 17.8674f)
                    horizontalLineTo(16.2694f)
                    close()
                    moveTo(17.3102f, 18.3155f)
                    curveTo(17.2037f, 18.4024f, 17.0637f, 18.4465f, 16.894f, 18.4465f)
                    curveTo(16.6743f, 18.4465f, 16.4963f, 18.4012f, 16.3367f, 18.2979f)
                    lineTo(16.3064f, 18.2767f)
                    lineTo(16.5142f, 17.9385f)
                    curveTo(16.7529f, 18.0894f, 16.8762f, 18.0671f, 16.9232f, 18.063f)
                    curveTo(17.0262f, 18.0553f, 17.0375f, 17.9426f, 16.9524f, 17.9443f)
                    curveTo(16.8869f, 17.9461f, 16.7571f, 17.959f, 16.5648f, 17.8709f)
                    curveTo(16.4302f, 17.7899f, 16.3427f, 17.6953f, 16.3427f, 17.5391f)
                    curveTo(16.3427f, 17.4046f, 16.3992f, 17.2942f, 16.5112f, 17.2114f)
                    curveTo(16.619f, 17.1316f, 16.722f, 17.0875f, 16.875f, 17.0811f)
                    curveTo(17.0739f, 17.0722f, 17.2179f, 17.1145f, 17.3966f, 17.2056f)
                    lineTo(17.4341f, 17.2302f)
                    lineTo(17.2441f, 17.5385f)
                    curveTo(17.0363f, 17.4563f, 16.9286f, 17.434f, 16.8535f, 17.4481f)
                    curveTo(16.7654f, 17.4645f, 16.7779f, 17.5491f, 16.8428f, 17.5579f)
                    curveTo(16.9077f, 17.5667f, 17.0119f, 17.5385f, 17.2078f, 17.6072f)
                    curveTo(17.3781f, 17.6742f, 17.4787f, 17.7834f, 17.4787f, 17.969f)
                    curveTo(17.4787f, 18.1094f, 17.4216f, 18.2262f, 17.309f, 18.3155f)
                    horizontalLineTo(17.3102f)
                    close()
                    moveTo(17.8193f, 17.3113f)
                    curveTo(17.7806f, 17.3494f, 17.7336f, 17.3688f, 17.6782f, 17.3688f)
                    curveTo(17.6228f, 17.3688f, 17.5764f, 17.3494f, 17.5377f, 17.3113f)
                    curveTo(17.4996f, 17.2725f, 17.4811f, 17.2255f, 17.4811f, 17.1715f)
                    curveTo(17.4811f, 17.1175f, 17.5002f, 17.0693f, 17.5377f, 17.0305f)
                    curveTo(17.5764f, 16.9918f, 17.6228f, 16.9724f, 17.6782f, 16.9724f)
                    curveTo(17.7336f, 16.9724f, 17.7806f, 16.9918f, 17.8193f, 17.0305f)
                    curveTo(17.858f, 17.0693f, 17.8777f, 17.1163f, 17.8777f, 17.1715f)
                    curveTo(17.8777f, 17.2267f, 17.858f, 17.2725f, 17.8193f, 17.3113f)
                    close()
                }
                path(fill = SolidColor(Color(0xFF00912D)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(12.7363f, 7.0704f)
                    curveTo(13.2407f, 7.0258f, 14.3434f, 7.0511f, 14.8816f, 7.572f)
                    curveTo(14.9138f, 7.6031f, 14.8864f, 7.6753f, 14.84f, 7.663f)
                    curveTo(14.0516f, 7.4534f, 12.8209f, 8.4858f, 12.023f, 7.818f)
                    curveTo(12.001f, 8.013f, 11.8224f, 8.9556f, 10.4607f, 8.8158f)
                    curveTo(10.4285f, 8.8123f, 10.4154f, 8.7776f, 10.4392f, 8.7577f)
                    curveTo(10.6506f, 8.5797f, 10.9096f, 8.0905f, 10.6869f, 7.7781f)
                    curveTo(9.9938f, 8.2321f, 9.6354f, 8.2514f, 8.357f, 7.7053f)
                    curveTo(8.3153f, 7.6877f, 8.3404f, 7.6207f, 8.3892f, 7.6096f)
                    curveTo(8.6547f, 7.5508f, 9.2859f, 7.3242f, 9.8521f, 7.219f)
                    curveTo(9.9599f, 7.1991f, 10.0659f, 7.1867f, 10.1683f, 7.1856f)
                    curveTo(9.5824f, 7.0481f, 9.327f, 6.9277f, 8.9411f, 6.9301f)
                    curveTo(8.8989f, 6.9301f, 8.878f, 6.879f, 8.9084f, 6.8496f)
                    curveTo(9.5127f, 6.2765f, 10.6077f, 6.2477f, 11.1347f, 6.674f)
                    lineTo(10.6619f, 5.7003f)
                    lineTo(11.2359f, 5.6152f)
                    curveTo(11.2359f, 5.6152f, 11.3859f, 6.1343f, 11.5199f, 6.6012f)
                    curveTo(12.1177f, 5.9552f, 13.043f, 6.0204f, 13.355f, 6.5848f)
                    curveTo(13.3734f, 6.6182f, 13.3431f, 6.6576f, 13.305f, 6.6517f)
                    curveTo(12.9912f, 6.5989f, 12.7762f, 6.8567f, 12.7316f, 7.0693f)
                    lineTo(12.7363f, 7.0704f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFA320A)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(13.0526f, 15.6494f)
                    curveTo(12.9412f, 15.6494f, 12.8632f, 15.7175f, 12.8418f, 15.8156f)
                    horizontalLineTo(13.2538f)
                    curveTo(13.2449f, 15.7204f, 13.1758f, 15.6494f, 13.0526f, 15.6494f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFA320A)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(8.4267f, 15.7651f)
                    curveTo(8.4267f, 15.6605f, 8.3332f, 15.5924f, 8.1885f, 15.5924f)
                    horizontalLineTo(8.0391f)
                    verticalLineTo(15.9495f)
                    horizontalLineTo(8.1885f)
                    curveTo(8.3398f, 15.9495f, 8.4267f, 15.8819f, 8.4267f, 15.7645f)
                    verticalLineTo(15.7651f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFA320A)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(7.5193f, 16.0681f)
                    verticalLineTo(15.1972f)
                    horizontalLineTo(8.235f)
                    curveTo(8.6935f, 15.1972f, 8.956f, 15.4156f, 8.956f, 15.7956f)
                    curveTo(8.956f, 15.8484f, 8.9507f, 15.8978f, 8.9406f, 15.9436f)
                    curveTo(8.9721f, 15.9412f, 9.0037f, 15.9395f, 9.0358f, 15.9377f)
                    curveTo(9.1204f, 15.7615f, 9.2782f, 15.6464f, 9.4699f, 15.5959f)
                    curveTo(9.6295f, 15.5619f, 9.7456f, 15.556f, 9.9188f, 15.5895f)
                    curveTo(10.0957f, 15.63f, 10.2404f, 15.7251f, 10.3291f, 15.8719f)
                    curveTo(10.3773f, 15.8702f, 10.4249f, 15.869f, 10.4732f, 15.8678f)
                    verticalLineTo(15.6012f)
                    horizontalLineTo(10.6083f)
                    verticalLineTo(15.2494f)
                    horizontalLineTo(11.1269f)
                    verticalLineTo(15.6012f)
                    horizontalLineTo(11.3312f)
                    verticalLineTo(15.8514f)
                    curveTo(11.3645f, 15.8514f, 11.3979f, 15.8514f, 11.4318f, 15.8514f)
                    verticalLineTo(15.6024f)
                    horizontalLineTo(11.567f)
                    verticalLineTo(15.2506f)
                    horizontalLineTo(12.0856f)
                    verticalLineTo(15.6024f)
                    horizontalLineTo(12.2898f)
                    verticalLineTo(15.8555f)
                    curveTo(12.3243f, 15.8555f, 12.3583f, 15.8567f, 12.3928f, 15.8573f)
                    curveTo(12.4113f, 15.7022f, 12.4708f, 15.573f, 12.5738f, 15.4691f)
                    curveTo(12.7f, 15.3393f, 12.8638f, 15.2759f, 13.0627f, 15.2759f)
                    curveTo(13.2615f, 15.2759f, 13.4306f, 15.3393f, 13.5438f, 15.4691f)
                    curveTo(13.6491f, 15.59f, 13.7045f, 15.7345f, 13.7117f, 15.9036f)
                    curveTo(13.7414f, 15.9054f, 13.7706f, 15.9066f, 13.8004f, 15.9083f)
                    verticalLineTo(15.6036f)
                    horizontalLineTo(14.3184f)
                    verticalLineTo(15.7527f)
                    curveTo(14.3535f, 15.6746f, 14.5066f, 15.5666f, 14.6578f, 15.5666f)
                    curveTo(14.9245f, 15.5666f, 15.093f, 15.7239f, 15.1353f, 16.0029f)
                    curveTo(15.8308f, 16.064f, 16.5238f, 16.1433f, 17.2145f, 16.2413f)
                    curveTo(18.135f, 15.166f, 18.665f, 13.7801f, 18.5631f, 12.1733f)
                    curveTo(18.4042f, 9.6609f, 16.9198f, 8.1487f, 14.7787f, 7.4821f)
                    curveTo(14.815f, 7.5103f, 14.8495f, 7.5397f, 14.8823f, 7.5714f)
                    curveTo(14.9144f, 7.6025f, 14.887f, 7.6747f, 14.8406f, 7.6624f)
                    curveTo(14.0522f, 7.4528f, 12.8215f, 8.4852f, 12.0237f, 7.8175f)
                    curveTo(12.0016f, 8.0124f, 11.823f, 8.955f, 10.4613f, 8.8152f)
                    curveTo(10.4291f, 8.8117f, 10.416f, 8.7771f, 10.4398f, 8.7571f)
                    curveTo(10.6512f, 8.5792f, 10.9102f, 8.0899f, 10.6875f, 7.7775f)
                    curveTo(9.9945f, 8.2315f, 9.636f, 8.2509f, 8.3576f, 7.7047f)
                    curveTo(8.3446f, 7.6988f, 8.3386f, 7.6883f, 8.3374f, 7.6765f)
                    curveTo(5.3526f, 8.7242f, 4.5946f, 10.8102f, 4.7345f, 13.0236f)
                    curveTo(4.8149f, 14.2975f, 5.2817f, 15.3851f, 6.0034f, 16.256f)
                    curveTo(6.5071f, 16.1832f, 7.0132f, 16.1204f, 7.5199f, 16.0669f)
                    lineTo(7.5193f, 16.0681f)
                    close()
                    moveTo(15.2586f, 10.5242f)
                    horizontalLineTo(16.0975f)
                    verticalLineTo(11.569f)
                    horizontalLineTo(16.9079f)
                    verticalLineTo(10.5242f)
                    horizontalLineTo(17.751f)
                    verticalLineTo(13.4212f)
                    horizontalLineTo(16.9079f)
                    verticalLineTo(12.3524f)
                    horizontalLineTo(16.0975f)
                    verticalLineTo(13.4212f)
                    horizontalLineTo(15.2586f)
                    verticalLineTo(10.5242f)
                    close()
                    moveTo(13.2246f, 12.2925f)
                    lineTo(13.2609f, 12.3389f)
                    curveTo(13.4658f, 12.602f, 13.7117f, 12.7294f, 14.0124f, 12.7294f)
                    curveTo(14.1999f, 12.7294f, 14.3166f, 12.6666f, 14.3166f, 12.565f)
                    curveTo(14.3166f, 12.5339f, 14.3166f, 12.4405f, 14.0767f, 12.4f)
                    lineTo(13.6396f, 12.3201f)
                    curveTo(13.3919f, 12.2784f, 13.1829f, 12.1774f, 13.0186f, 12.0194f)
                    curveTo(12.8549f, 11.8538f, 12.7727f, 11.6512f, 12.7727f, 11.4169f)
                    curveTo(12.7727f, 11.1385f, 12.887f, 10.9106f, 13.1121f, 10.7386f)
                    curveTo(13.3366f, 10.5677f, 13.6069f, 10.4807f, 13.9159f, 10.4807f)
                    curveTo(14.3714f, 10.4807f, 14.7519f, 10.6528f, 15.0466f, 10.9929f)
                    lineTo(15.077f, 11.0275f)
                    lineTo(14.5405f, 11.5936f)
                    lineTo(14.503f, 11.549f)
                    curveTo(14.3148f, 11.3252f, 14.1124f, 11.2166f, 13.8837f, 11.2166f)
                    curveTo(13.6837f, 11.2166f, 13.6122f, 11.2929f, 13.6122f, 11.3652f)
                    curveTo(13.6122f, 11.3916f, 13.6122f, 11.4715f, 13.82f, 11.5061f)
                    lineTo(14.2082f, 11.5743f)
                    curveTo(14.8376f, 11.6823f, 15.1567f, 12.0065f, 15.1567f, 12.5374f)
                    curveTo(15.1567f, 12.8134f, 15.0383f, 13.0401f, 14.8043f, 13.2122f)
                    curveTo(14.5726f, 13.3825f, 14.294f, 13.4653f, 13.9528f, 13.4653f)
                    curveTo(13.4384f, 13.4653f, 12.9751f, 13.2392f, 12.7137f, 12.861f)
                    lineTo(12.6899f, 12.8263f)
                    lineTo(13.2264f, 12.2925f)
                    horizontalLineTo(13.2246f)
                    close()
                    moveTo(10.3934f, 10.5242f)
                    horizontalLineTo(12.6316f)
                    verticalLineTo(11.2642f)
                    horizontalLineTo(11.2812f)
                    verticalLineTo(11.5889f)
                    horizontalLineTo(12.5625f)
                    verticalLineTo(12.3048f)
                    horizontalLineTo(11.2812f)
                    verticalLineTo(12.6813f)
                    horizontalLineTo(12.6316f)
                    verticalLineTo(13.4212f)
                    horizontalLineTo(10.3934f)
                    verticalLineTo(10.5242f)
                    close()
                    moveTo(7.7884f, 10.5242f)
                    horizontalLineTo(8.968f)
                    curveTo(9.7486f, 10.5242f, 10.1963f, 10.8948f, 10.1963f, 11.5402f)
                    curveTo(10.1963f, 11.9325f, 10.0308f, 12.2197f, 9.7039f, 12.3941f)
                    lineTo(10.3243f, 13.4212f)
                    horizontalLineTo(9.3591f)
                    lineTo(8.8376f, 12.5562f)
                    horizontalLineTo(8.628f)
                    verticalLineTo(13.4212f)
                    horizontalLineTo(7.789f)
                    verticalLineTo(10.5242f)
                    horizontalLineTo(7.7884f)
                    close()
                    moveTo(5.5419f, 10.5242f)
                    horizontalLineTo(7.6425f)
                    verticalLineTo(11.2642f)
                    horizontalLineTo(6.3815f)
                    verticalLineTo(11.6764f)
                    horizontalLineTo(7.5497f)
                    verticalLineTo(12.3923f)
                    horizontalLineTo(6.3815f)
                    verticalLineTo(13.4212f)
                    horizontalLineTo(5.5425f)
                    verticalLineTo(10.5242f)
                    horizontalLineTo(5.5419f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFA320A)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(9.3563f, 11.5326f)
                    curveTo(9.3563f, 11.31f, 9.1831f, 11.2648f, 8.9229f, 11.2648f)
                    horizontalLineTo(8.627f)
                    verticalLineTo(11.8203f)
                    horizontalLineTo(8.9229f)
                    curveTo(9.3111f, 11.8203f, 9.3563f, 11.6559f, 9.3563f, 11.5326f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(9.2176f, 15.718f)
                    curveTo(9.2176f, 15.718f, 9.2134f, 15.7216f, 9.2109f, 15.7228f)
                    curveTo(9.2134f, 15.721f, 9.2152f, 15.7192f, 9.2176f, 15.718f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(9.1602f, 15.7664f)
                    curveTo(9.1602f, 15.7664f, 9.1627f, 15.7645f, 9.1633f, 15.7632f)
                    curveTo(9.162f, 15.7645f, 9.1608f, 15.7651f, 9.1602f, 15.7664f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(8.9434f, 15.9441f)
                    curveTo(8.9749f, 15.9418f, 9.0065f, 15.94f, 9.0386f, 15.9382f)
                    curveTo(9.0071f, 15.9406f, 8.9755f, 15.9423f, 8.9434f, 15.9441f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(8.9593f, 15.7955f)
                    curveTo(8.9593f, 15.822f, 8.9581f, 15.8472f, 8.9551f, 15.8719f)
                    curveTo(8.9575f, 15.8472f, 8.9593f, 15.8214f, 8.9593f, 15.7955f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(14.8092f, 15.587f)
                    curveTo(14.8003f, 15.5847f, 14.7908f, 15.5823f, 14.7812f, 15.5806f)
                    curveTo(14.7908f, 15.5823f, 14.7997f, 15.5847f, 14.8092f, 15.587f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(14.7131f, 15.5694f)
                    curveTo(14.6959f, 15.5683f, 14.6786f, 15.5671f, 14.6602f, 15.5671f)
                    curveTo(14.678f, 15.5671f, 14.6959f, 15.5677f, 14.7131f, 15.5694f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(14.7632f, 15.5765f)
                    curveTo(14.7525f, 15.5747f, 14.7412f, 15.573f, 14.7305f, 15.5718f)
                    curveTo(14.7418f, 15.573f, 14.7525f, 15.5741f, 14.7632f, 15.5765f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(14.8964f, 15.6205f)
                    curveTo(14.8893f, 15.617f, 14.8821f, 15.6129f, 14.875f, 15.6099f)
                    curveTo(14.8821f, 15.6134f, 14.8893f, 15.617f, 14.8964f, 15.6205f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(9.9992f, 15.6123f)
                    curveTo(9.9938f, 15.6105f, 9.9884f, 15.6082f, 9.9824f, 15.6064f)
                    curveTo(9.9878f, 15.6082f, 9.9938f, 15.6105f, 9.9992f, 15.6123f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(10.3307f, 15.8725f)
                    curveTo(10.3414f, 15.8725f, 10.3521f, 15.8719f, 10.3622f, 15.8713f)
                    curveTo(10.3515f, 15.8713f, 10.3408f, 15.8713f, 10.3301f, 15.8725f)
                    horizontalLineTo(10.3307f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(10.1616f, 15.6922f)
                    curveTo(10.1616f, 15.6922f, 10.158f, 15.6898f, 10.1562f, 15.6887f)
                    curveTo(10.158f, 15.6898f, 10.1598f, 15.691f, 10.1616f, 15.6922f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(9.4093f, 15.6158f)
                    curveTo(9.4033f, 15.6176f, 9.398f, 15.6199f, 9.3926f, 15.6223f)
                    curveTo(9.398f, 15.6199f, 9.4039f, 15.6181f, 9.4093f, 15.6158f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(12.5756f, 15.4696f)
                    curveTo(12.5614f, 15.4843f, 12.5483f, 15.499f, 12.5352f, 15.5148f)
                    curveTo(12.5477f, 15.4996f, 12.5614f, 15.4843f, 12.5756f, 15.4696f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(12.4552f, 15.6411f)
                    curveTo(12.4528f, 15.6458f, 12.451f, 15.6505f, 12.4492f, 15.6552f)
                    curveTo(12.451f, 15.6505f, 12.4534f, 15.6458f, 12.4552f, 15.6411f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(12.4844f, 15.5864f)
                    curveTo(12.4796f, 15.5941f, 12.4755f, 15.6017f, 12.4707f, 15.6099f)
                    curveTo(12.4749f, 15.6023f, 12.4796f, 15.5941f, 12.4844f, 15.5864f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(13.686f, 15.7268f)
                    curveTo(13.686f, 15.7268f, 13.6842f, 15.7215f, 13.6836f, 15.7186f)
                    curveTo(13.6842f, 15.7215f, 13.6854f, 15.7239f, 13.686f, 15.7268f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(13.7004f, 15.7838f)
                    curveTo(13.7004f, 15.7838f, 13.6998f, 15.7809f, 13.6992f, 15.7791f)
                    curveTo(13.6992f, 15.7809f, 13.6998f, 15.782f, 13.7004f, 15.7838f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(12.5228f, 15.533f)
                    curveTo(12.5156f, 15.5424f, 12.5085f, 15.5524f, 12.502f, 15.5618f)
                    curveTo(12.5085f, 15.5518f, 12.5156f, 15.5424f, 12.5228f, 15.533f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(16.99f, 6.3856f)
                    lineTo(16.3672f, 6.9418f)
                    lineTo(16.4398f, 7.021f)
                    curveTo(16.656f, 7.2565f, 16.8703f, 7.2794f, 17.0829f, 7.0898f)
                    curveTo(17.2853f, 6.9089f, 17.2788f, 6.7004f, 17.0614f, 6.4637f)
                    lineTo(16.9906f, 6.3862f)
                    lineTo(16.99f, 6.3856f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(9.2066f, 4.5709f)
                    lineTo(9.0273f, 4.6314f)
                    lineTo(9.1452f, 4.9708f)
                    lineTo(9.3245f, 4.9104f)
                    curveTo(9.4912f, 4.854f, 9.5549f, 4.7682f, 9.5138f, 4.6508f)
                    curveTo(9.4739f, 4.5357f, 9.3816f, 4.5116f, 9.206f, 4.5709f)
                    horizontalLineTo(9.2066f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD700)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(17.4939f, 5.6192f)
                    curveTo(15.953f, 4.1716f, 13.8773f, 3.3746f, 11.6487f, 3.3746f)
                    curveTo(9.42f, 3.3746f, 7.345f, 4.1722f, 5.8034f, 5.6192f)
                    curveTo(4.2488f, 7.0803f, 3.3926f, 9.0706f, 3.3926f, 11.2247f)
                    curveTo(3.3926f, 12.895f, 3.9017f, 14.5f, 4.8639f, 15.8666f)
                    curveTo(4.9865f, 16.041f, 5.1163f, 16.2095f, 5.2509f, 16.3728f)
                    curveTo(5.5016f, 16.3317f, 5.7534f, 16.2935f, 6.0053f, 16.2571f)
                    curveTo(5.2836f, 15.3862f, 4.8168f, 14.2991f, 4.7364f, 13.0253f)
                    curveTo(4.5965f, 10.8119f, 5.3545f, 8.7253f, 8.3393f, 7.6782f)
                    curveTo(8.3369f, 7.6518f, 8.3584f, 7.6183f, 8.3917f, 7.6112f)
                    curveTo(8.6573f, 7.5525f, 9.2884f, 7.3258f, 9.8547f, 7.2207f)
                    curveTo(9.9624f, 7.2007f, 10.0684f, 7.1884f, 10.1708f, 7.1872f)
                    curveTo(9.5849f, 7.0498f, 9.3295f, 6.9294f, 8.9437f, 6.9318f)
                    curveTo(8.9014f, 6.9318f, 8.8806f, 6.8807f, 8.9109f, 6.8513f)
                    curveTo(9.5153f, 6.2781f, 10.6103f, 6.2494f, 11.1372f, 6.6757f)
                    lineTo(10.6644f, 5.702f)
                    lineTo(11.2384f, 5.6168f)
                    curveTo(11.2384f, 5.6168f, 11.3885f, 6.136f, 11.5224f, 6.6029f)
                    curveTo(12.1202f, 5.9569f, 13.0455f, 6.0221f, 13.3575f, 6.5864f)
                    curveTo(13.376f, 6.6199f, 13.3456f, 6.6593f, 13.3075f, 6.6534f)
                    curveTo(12.9937f, 6.6005f, 12.7788f, 6.8583f, 12.7341f, 7.071f)
                    lineTo(12.7389f, 7.0721f)
                    curveTo(13.2128f, 7.0304f, 14.2149f, 7.0504f, 14.7806f, 7.4844f)
                    curveTo(16.9217f, 8.151f, 18.4061f, 9.6632f, 18.5651f, 12.1755f)
                    curveTo(18.6669f, 13.7823f, 18.137f, 15.1683f, 17.2164f, 16.2436f)
                    curveTo(17.2075f, 16.2424f, 17.1992f, 16.2413f, 17.1902f, 16.2401f)
                    curveTo(17.4772f, 16.2806f, 17.7636f, 16.3235f, 18.0494f, 16.3705f)
                    curveTo(18.187f, 16.2037f, 18.3197f, 16.031f, 18.4448f, 15.8531f)
                    curveTo(19.4004f, 14.49f, 19.9059f, 12.8903f, 19.9059f, 11.2265f)
                    curveTo(19.9059f, 9.073f, 19.0497f, 7.0821f, 17.4945f, 5.621f)
                    lineTo(17.4939f, 5.6192f)
                    close()
                    moveTo(7.2467f, 7.162f)
                    curveTo(7.0877f, 7.3217f, 6.9032f, 7.4022f, 6.6912f, 7.4016f)
                    curveTo(6.4792f, 7.401f, 6.2911f, 7.3194f, 6.1291f, 7.1626f)
                    curveTo(5.9672f, 7.0058f, 5.8856f, 6.8237f, 5.882f, 6.6129f)
                    curveTo(5.8797f, 6.4009f, 5.9559f, 6.2153f, 6.1148f, 6.0556f)
                    curveTo(6.2893f, 5.88f, 6.4989f, 5.7995f, 6.7394f, 5.8154f)
                    lineTo(6.7478f, 6.1736f)
                    curveTo(6.5918f, 6.1659f, 6.5001f, 6.1806f, 6.3887f, 6.2922f)
                    curveTo(6.3101f, 6.3715f, 6.275f, 6.4655f, 6.2792f, 6.5765f)
                    curveTo(6.2833f, 6.6869f, 6.331f, 6.7849f, 6.4191f, 6.8701f)
                    curveTo(6.509f, 6.957f, 6.6108f, 7.0034f, 6.7228f, 7.0052f)
                    curveTo(6.8353f, 7.0069f, 6.93f, 6.9676f, 7.0097f, 6.8871f)
                    curveTo(7.1318f, 6.7644f, 7.1509f, 6.6522f, 7.1211f, 6.4737f)
                    lineTo(7.4831f, 6.4884f)
                    curveTo(7.5218f, 6.7386f, 7.4426f, 6.9641f, 7.2467f, 7.1614f)
                    verticalLineTo(7.162f)
                    close()
                    moveTo(7.8332f, 6.5923f)
                    lineTo(7.0086f, 5.3027f)
                    lineTo(7.9934f, 4.6895f)
                    lineTo(8.1815f, 4.9838f)
                    lineTo(7.5587f, 5.3714f)
                    lineTo(7.685f, 5.5681f)
                    lineTo(8.2762f, 5.2005f)
                    lineTo(8.4572f, 5.4835f)
                    lineTo(7.866f, 5.8518f)
                    lineTo(8.0071f, 6.0726f)
                    lineTo(8.6299f, 5.685f)
                    lineTo(8.818f, 5.9792f)
                    lineTo(7.8332f, 6.5923f)
                    close()
                    moveTo(9.8338f, 5.6033f)
                    lineTo(9.4099f, 5.248f)
                    lineTo(9.2598f, 5.2991f)
                    lineTo(9.4152f, 5.7455f)
                    lineTo(9.0354f, 5.8741f)
                    lineTo(8.534f, 4.4317f)
                    lineTo(9.1164f, 4.235f)
                    curveTo(9.498f, 4.1058f, 9.785f, 4.2068f, 9.8963f, 4.528f)
                    curveTo(9.9672f, 4.7318f, 9.9291f, 4.9033f, 9.7808f, 5.0478f)
                    lineTo(10.2703f, 5.4571f)
                    lineTo(9.8344f, 5.6045f)
                    lineTo(9.8338f, 5.6033f)
                    close()
                    moveTo(10.9473f, 4.1962f)
                    lineTo(11.0902f, 5.3643f)
                    lineTo(10.6894f, 5.4119f)
                    lineTo(10.5465f, 4.2438f)
                    lineTo(10.1303f, 4.2931f)
                    lineTo(10.0881f, 3.9478f)
                    lineTo(11.3206f, 3.801f)
                    lineTo(11.3629f, 4.1463f)
                    lineTo(10.9467f, 4.1957f)
                    lineTo(10.9473f, 4.1962f)
                    close()
                    moveTo(12.2203f, 5.3661f)
                    lineTo(11.8196f, 5.3444f)
                    lineTo(11.9041f, 3.8221f)
                    lineTo(12.3048f, 3.8439f)
                    lineTo(12.2203f, 5.3661f)
                    close()
                    moveTo(13.8476f, 4.7671f)
                    lineTo(13.7678f, 5.093f)
                    lineTo(13.1479f, 4.9456f)
                    lineTo(13.0146f, 5.4912f)
                    lineTo(12.6246f, 5.3984f)
                    lineTo(12.9878f, 3.9161f)
                    lineTo(14.047f, 4.1686f)
                    lineTo(13.9643f, 4.5069f)
                    lineTo(13.2956f, 4.3478f)
                    lineTo(13.2289f, 4.6208f)
                    lineTo(13.8481f, 4.7682f)
                    lineTo(13.8476f, 4.7671f)
                    close()
                    moveTo(13.975f, 5.7807f)
                    lineTo(14.5942f, 4.3836f)
                    lineTo(14.9622f, 4.5421f)
                    lineTo(14.3429f, 5.9393f)
                    lineTo(13.975f, 5.7807f)
                    close()
                    moveTo(14.6948f, 6.0931f)
                    lineTo(15.5647f, 4.8328f)
                    lineTo(16.5275f, 5.4788f)
                    lineTo(16.3287f, 5.7666f)
                    lineTo(15.7196f, 5.3579f)
                    lineTo(15.5868f, 5.5505f)
                    lineTo(16.1649f, 5.9387f)
                    lineTo(15.9738f, 6.2159f)
                    lineTo(15.3956f, 5.8277f)
                    lineTo(15.2468f, 6.0432f)
                    lineTo(15.8559f, 6.452f)
                    lineTo(15.657f, 6.7397f)
                    lineTo(14.6942f, 6.0931f)
                    horizontalLineTo(14.6948f)
                    close()
                    moveTo(17.3546f, 7.3846f)
                    curveTo(16.9199f, 7.7727f, 16.4912f, 7.5948f, 16.212f, 7.29f)
                    lineTo(15.8374f, 6.8818f)
                    lineTo(16.9836f, 5.8588f)
                    lineTo(17.3581f, 6.267f)
                    curveTo(17.5064f, 6.4285f, 17.5957f, 6.5976f, 17.6237f, 6.7926f)
                    curveTo(17.6511f, 6.9881f, 17.5624f, 7.199f, 17.3552f, 7.3846f)
                    horizontalLineTo(17.3546f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(15.0415f, 15.7474f)
                    curveTo(15.0386f, 15.7433f, 15.0362f, 15.7397f, 15.0332f, 15.7362f)
                    curveTo(15.0362f, 15.7397f, 15.0392f, 15.7438f, 15.0415f, 15.7474f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(14.9766f, 15.6751f)
                    curveTo(14.9766f, 15.6751f, 14.9814f, 15.6793f, 14.9838f, 15.6811f)
                    curveTo(14.9814f, 15.6787f, 14.979f, 15.6769f, 14.9766f, 15.6751f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(15.0856f, 15.8237f)
                    curveTo(15.0856f, 15.8237f, 15.0832f, 15.8184f, 15.082f, 15.8155f)
                    curveTo(15.0832f, 15.8184f, 15.0844f, 15.8208f, 15.0856f, 15.8237f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(13.6163f, 15.567f)
                    curveTo(13.6109f, 15.5582f, 13.605f, 15.55f, 13.5996f, 15.5412f)
                    curveTo(13.605f, 15.5494f, 13.6109f, 15.5582f, 13.6163f, 15.567f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(13.6675f, 15.6717f)
                    curveTo(13.6657f, 15.6675f, 13.6639f, 15.6634f, 13.6621f, 15.6587f)
                    curveTo(13.6639f, 15.6628f, 13.6657f, 15.6669f, 13.6675f, 15.6717f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(13.6443f, 15.6188f)
                    curveTo(13.6413f, 15.6129f, 13.6378f, 15.607f, 13.6348f, 15.6005f)
                    curveTo(13.6378f, 15.6064f, 13.6413f, 15.6123f, 13.6443f, 15.6188f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFD800)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(14.9326f, 15.6423f)
                    curveTo(14.9266f, 15.6382f, 14.9206f, 15.634f, 14.9141f, 15.6305f)
                    curveTo(14.9206f, 15.634f, 14.926f, 15.6382f, 14.9326f, 15.6423f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFAF519)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(13.0952f, 16.6359f)
                    curveTo(13.3006f, 16.6359f, 13.5185f, 16.6001f, 13.693f, 16.4169f)
                    lineTo(13.3756f, 16.1227f)
                    curveTo(13.3089f, 16.2096f, 13.216f, 16.2254f, 13.0898f, 16.2254f)
                    curveTo(12.982f, 16.2254f, 12.8665f, 16.1673f, 12.8421f, 16.0639f)
                    horizontalLineTo(13.7102f)
                    lineTo(13.7126f, 15.9418f)
                    curveTo(13.7126f, 15.7562f, 13.656f, 15.6f, 13.5435f, 15.4702f)
                    curveTo(13.4304f, 15.3404f, 13.2696f, 15.277f, 13.0624f, 15.277f)
                    curveTo(12.8552f, 15.277f, 12.6998f, 15.3404f, 12.5736f, 15.4702f)
                    curveTo(12.4473f, 15.5976f, 12.3854f, 15.7615f, 12.3854f, 15.9659f)
                    curveTo(12.3854f, 16.1702f, 12.4527f, 16.337f, 12.5867f, 16.4562f)
                    curveTo(12.7212f, 16.5755f, 12.8903f, 16.6365f, 13.0946f, 16.6365f)
                    lineTo(13.0952f, 16.6359f)
                    close()
                    moveTo(13.0529f, 15.6493f)
                    curveTo(13.1761f, 15.6493f, 13.2452f, 15.7204f, 13.2541f, 15.8155f)
                    horizontalLineTo(12.8421f)
                    curveTo(12.8629f, 15.7174f, 12.9415f, 15.6493f, 13.0529f, 15.6493f)
                    close()
                    moveTo(15.6197f, 17.0805f)
                    curveTo(15.4209f, 17.0805f, 15.2571f, 17.1439f, 15.1309f, 17.2737f)
                    curveTo(15.0047f, 17.4012f, 14.9427f, 17.565f, 14.9427f, 17.7694f)
                    curveTo(14.9427f, 17.9738f, 15.01f, 18.1405f, 15.144f, 18.2598f)
                    curveTo(15.2786f, 18.379f, 15.4477f, 18.4401f, 15.6519f, 18.4401f)
                    curveTo(15.8561f, 18.4401f, 16.0752f, 18.4042f, 16.2497f, 18.221f)
                    lineTo(15.9323f, 17.9268f)
                    curveTo(15.8656f, 18.0137f, 15.7728f, 18.029f, 15.6465f, 18.029f)
                    curveTo(15.5388f, 18.029f, 15.4232f, 17.9708f, 15.3988f, 17.8675f)
                    horizontalLineTo(16.267f)
                    lineTo(16.2693f, 17.7453f)
                    curveTo(16.2693f, 17.5597f, 16.2128f, 17.4035f, 16.1002f, 17.2737f)
                    curveTo(15.9871f, 17.1439f, 15.8263f, 17.0805f, 15.6191f, 17.0805f)
                    horizontalLineTo(15.6197f)
                    close()
                    moveTo(15.3988f, 17.619f)
                    curveTo(15.4197f, 17.521f, 15.4983f, 17.4528f, 15.6096f, 17.4528f)
                    curveTo(15.7329f, 17.4528f, 15.8019f, 17.5239f, 15.8109f, 17.619f)
                    horizontalLineTo(15.3988f)
                    close()
                    moveTo(8.04f, 16.3429f)
                    horizontalLineTo(8.127f)
                    lineTo(8.4533f, 16.8932f)
                    horizontalLineTo(9.0398f)
                    lineTo(8.6771f, 16.293f)
                    lineTo(8.6932f, 16.283f)
                    curveTo(8.8677f, 16.179f, 8.9558f, 16.0146f, 8.9558f, 15.795f)
                    curveTo(8.9558f, 15.4144f, 8.6926f, 15.1965f, 8.2347f, 15.1965f)
                    horizontalLineTo(7.5191f)
                    verticalLineTo(16.8932f)
                    horizontalLineTo(8.04f)
                    verticalLineTo(16.3429f)
                    close()
                    moveTo(8.0388f, 15.5924f)
                    horizontalLineTo(8.1883f)
                    curveTo(8.333f, 15.5924f, 8.4265f, 15.6599f, 8.4265f, 15.765f)
                    curveTo(8.4265f, 15.8825f, 8.3395f, 15.95f, 8.1883f, 15.95f)
                    horizontalLineTo(8.0388f)
                    verticalLineTo(15.5929f)
                    verticalLineTo(15.5924f)
                    close()
                    moveTo(8.071f, 17.1028f)
                    curveTo(7.8977f, 17.0694f, 7.8019f, 17.0746f, 7.6423f, 17.1093f)
                    curveTo(7.3553f, 17.1845f, 7.1421f, 17.4047f, 7.1421f, 17.7606f)
                    curveTo(7.1421f, 18.2069f, 7.4887f, 18.4406f, 7.8692f, 18.4406f)
                    curveTo(8.2496f, 18.4406f, 8.5771f, 18.2069f, 8.5771f, 17.7606f)
                    curveTo(8.5771f, 17.3947f, 8.3687f, 17.1715f, 8.0704f, 17.1034f)
                    lineTo(8.071f, 17.1028f)
                    close()
                    moveTo(7.8596f, 17.9661f)
                    curveTo(7.7334f, 17.9661f, 7.6215f, 17.8722f, 7.6215f, 17.7424f)
                    curveTo(7.6215f, 17.6126f, 7.7334f, 17.5163f, 7.8596f, 17.5163f)
                    curveTo(7.9859f, 17.5163f, 8.0978f, 17.6091f, 8.0978f, 17.7424f)
                    curveTo(8.0978f, 17.8757f, 7.9894f, 17.9661f, 7.8596f, 17.9661f)
                    close()
                    moveTo(10.7748f, 17.6596f)
                    verticalLineTo(18.406f)
                    horizontalLineTo(10.2479f)
                    verticalLineTo(17.7048f)
                    curveTo(10.2479f, 17.5944f, 10.2002f, 17.5386f, 10.1061f, 17.5386f)
                    curveTo(10.0121f, 17.5386f, 9.9936f, 17.5967f, 9.9877f, 17.6155f)
                    curveTo(9.9716f, 17.6725f, 9.9686f, 17.7054f, 9.9686f, 17.7747f)
                    verticalLineTo(18.406f)
                    horizontalLineTo(9.4494f)
                    verticalLineTo(17.7048f)
                    curveTo(9.4494f, 17.5944f, 9.4006f, 17.5386f, 9.3047f, 17.5386f)
                    curveTo(9.2505f, 17.5386f, 9.2148f, 17.5644f, 9.2005f, 17.5873f)
                    curveTo(9.1904f, 17.602f, 9.1833f, 17.6284f, 9.1785f, 17.6466f)
                    curveTo(9.169f, 17.6995f, 9.169f, 17.7189f, 9.169f, 17.7753f)
                    verticalLineTo(18.4066f)
                    horizontalLineTo(8.664f)
                    verticalLineTo(17.1146f)
                    horizontalLineTo(9.1636f)
                    verticalLineTo(17.2361f)
                    curveTo(9.1987f, 17.1651f, 9.3339f, 17.0811f, 9.5232f, 17.0811f)
                    curveTo(9.6792f, 17.0811f, 9.8037f, 17.134f, 9.8829f, 17.2338f)
                    lineTo(9.8972f, 17.2514f)
                    lineTo(9.912f, 17.2344f)
                    curveTo(10.0067f, 17.1293f, 10.1145f, 17.084f, 10.2741f, 17.084f)
                    curveTo(10.4241f, 17.084f, 10.7742f, 17.1404f, 10.7742f, 17.6596f)
                    horizontalLineTo(10.7748f)
                    close()
                    moveTo(13.1529f, 17.972f)
                    horizontalLineTo(13.3172f)
                    verticalLineTo(18.4148f)
                    lineTo(13.2869f, 18.416f)
                    curveTo(13.2339f, 18.4183f, 13.1636f, 18.4213f, 13.0904f, 18.4213f)
                    curveTo(12.7427f, 18.4213f, 12.5432f, 18.2492f, 12.5432f, 17.9162f)
                    verticalLineTo(17.5591f)
                    horizontalLineTo(12.408f)
                    verticalLineTo(17.114f)
                    horizontalLineTo(12.5432f)
                    verticalLineTo(16.7622f)
                    horizontalLineTo(13.0618f)
                    verticalLineTo(17.114f)
                    horizontalLineTo(13.2666f)
                    verticalLineTo(17.5591f)
                    horizontalLineTo(13.0618f)
                    verticalLineTo(17.8827f)
                    curveTo(13.0618f, 17.9456f, 13.0892f, 17.9726f, 13.1529f, 17.9726f)
                    verticalLineTo(17.972f)
                    close()
                    moveTo(7.3142f, 17.2003f)
                    horizontalLineTo(6.8689f)
                    verticalLineTo(18.4066f)
                    horizontalLineTo(6.3633f)
                    verticalLineTo(17.2003f)
                    horizontalLineTo(5.918f)
                    verticalLineTo(16.7099f)
                    horizontalLineTo(7.3142f)
                    verticalLineTo(17.2003f)
                    close()
                    moveTo(12.1764f, 16.4598f)
                    horizontalLineTo(12.3408f)
                    verticalLineTo(16.902f)
                    lineTo(12.3104f, 16.9032f)
                    curveTo(12.2574f, 16.9055f, 12.1871f, 16.9084f, 12.1139f, 16.9084f)
                    curveTo(11.7668f, 16.9084f, 11.5673f, 16.7364f, 11.5673f, 16.4034f)
                    verticalLineTo(16.0469f)
                    horizontalLineTo(11.4321f)
                    verticalLineTo(15.6023f)
                    horizontalLineTo(11.5673f)
                    verticalLineTo(15.2506f)
                    horizontalLineTo(12.0859f)
                    verticalLineTo(15.6023f)
                    horizontalLineTo(12.2901f)
                    verticalLineTo(16.0469f)
                    horizontalLineTo(12.0859f)
                    verticalLineTo(16.3699f)
                    curveTo(12.0859f, 16.4327f, 12.1133f, 16.4592f, 12.1764f, 16.4592f)
                    verticalLineTo(16.4598f)
                    close()
                    moveTo(10.6087f, 16.0463f)
                    horizontalLineTo(10.4735f)
                    verticalLineTo(15.6018f)
                    horizontalLineTo(10.6087f)
                    verticalLineTo(15.25f)
                    horizontalLineTo(11.1273f)
                    verticalLineTo(15.6018f)
                    horizontalLineTo(11.3315f)
                    verticalLineTo(16.0463f)
                    horizontalLineTo(11.1273f)
                    verticalLineTo(16.3693f)
                    curveTo(11.1273f, 16.4322f, 11.1547f, 16.4586f, 11.2178f, 16.4586f)
                    horizontalLineTo(11.3821f)
                    verticalLineTo(16.9008f)
                    lineTo(11.3518f, 16.902f)
                    curveTo(11.2988f, 16.9043f, 11.2285f, 16.9073f, 11.1553f, 16.9073f)
                    curveTo(10.8081f, 16.9073f, 10.6087f, 16.7352f, 10.6087f, 16.4022f)
                    verticalLineTo(16.0457f)
                    verticalLineTo(16.0463f)
                    close()
                    moveTo(11.8055f, 17.2649f)
                    lineTo(11.7727f, 17.2297f)
                    curveTo(11.6822f, 17.1322f, 11.5679f, 17.0805f, 11.4429f, 17.0805f)
                    curveTo(11.3547f, 17.0805f, 11.2619f, 17.1069f, 11.1737f, 17.1569f)
                    curveTo(10.9784f, 17.2679f, 10.8617f, 17.4934f, 10.8611f, 17.7588f)
                    curveTo(10.8611f, 18.026f, 10.9778f, 18.2521f, 11.1737f, 18.3637f)
                    curveTo(11.2613f, 18.4136f, 11.3547f, 18.4401f, 11.4441f, 18.4401f)
                    curveTo(11.5685f, 18.4401f, 11.6822f, 18.3896f, 11.7727f, 18.2944f)
                    lineTo(11.8055f, 18.2598f)
                    verticalLineTo(18.4048f)
                    horizontalLineTo(12.308f)
                    verticalLineTo(17.1169f)
                    horizontalLineTo(11.8055f)
                    verticalLineTo(17.2649f)
                    close()
                    moveTo(11.7441f, 17.9068f)
                    curveTo(11.7043f, 17.9509f, 11.6483f, 17.9743f, 11.5816f, 17.9743f)
                    curveTo(11.4738f, 17.9743f, 11.3649f, 17.9009f, 11.3649f, 17.7606f)
                    curveTo(11.3649f, 17.7048f, 11.3839f, 17.6525f, 11.419f, 17.6138f)
                    curveTo(11.4589f, 17.5697f, 11.5149f, 17.5462f, 11.5816f, 17.5462f)
                    curveTo(11.6894f, 17.5462f, 11.7983f, 17.6196f, 11.7983f, 17.76f)
                    curveTo(11.7983f, 17.8158f, 11.7793f, 17.8681f, 11.7441f, 17.9068f)
                    close()
                    moveTo(14.3235f, 16.8932f)
                    horizontalLineTo(13.8001f)
                    verticalLineTo(15.6035f)
                    horizontalLineTo(14.3181f)
                    verticalLineTo(15.7527f)
                    curveTo(14.3533f, 15.6746f, 14.5063f, 15.5665f, 14.6575f, 15.5665f)
                    curveTo(14.9678f, 15.5665f, 15.1452f, 15.7779f, 15.1452f, 16.1473f)
                    verticalLineTo(16.8926f)
                    horizontalLineTo(14.623f)
                    verticalLineTo(16.1902f)
                    curveTo(14.623f, 16.0821f, 14.5754f, 16.0269f, 14.4825f, 16.0269f)
                    curveTo(14.3896f, 16.0269f, 14.3241f, 16.0675f, 14.3241f, 16.2707f)
                    verticalLineTo(16.8926f)
                    lineTo(14.3235f, 16.8932f)
                    close()
                    moveTo(17.478f, 17.9696f)
                    curveTo(17.478f, 18.11f, 17.4209f, 18.2269f, 17.3083f, 18.3161f)
                    curveTo(17.2018f, 18.4031f, 17.0618f, 18.4471f, 16.8921f, 18.4471f)
                    curveTo(16.6724f, 18.4471f, 16.4944f, 18.4019f, 16.3348f, 18.2985f)
                    lineTo(16.3045f, 18.2774f)
                    lineTo(16.5123f, 17.9391f)
                    curveTo(16.751f, 18.09f, 16.8743f, 18.0677f, 16.9213f, 18.0636f)
                    curveTo(17.0243f, 18.056f, 17.0356f, 17.9432f, 16.9505f, 17.945f)
                    curveTo(16.885f, 17.9467f, 16.7552f, 17.9597f, 16.5629f, 17.8716f)
                    curveTo(16.4283f, 17.7905f, 16.3408f, 17.696f, 16.3408f, 17.5398f)
                    curveTo(16.3408f, 17.4053f, 16.3974f, 17.2949f, 16.5093f, 17.2121f)
                    curveTo(16.6171f, 17.1322f, 16.7201f, 17.0881f, 16.8731f, 17.0817f)
                    curveTo(17.072f, 17.0729f, 17.2161f, 17.1152f, 17.3947f, 17.2062f)
                    lineTo(17.4322f, 17.2309f)
                    lineTo(17.2423f, 17.5392f)
                    curveTo(17.0345f, 17.457f, 16.9267f, 17.4346f, 16.8517f, 17.4487f)
                    curveTo(16.7635f, 17.4652f, 16.776f, 17.5497f, 16.8409f, 17.5586f)
                    curveTo(16.9058f, 17.5674f, 17.01f, 17.5392f, 17.2059f, 17.6079f)
                    curveTo(17.3762f, 17.6748f, 17.4768f, 17.7841f, 17.4768f, 17.9696f)
                    horizontalLineTo(17.478f)
                    close()
                    moveTo(9.7072f, 16.9266f)
                    curveTo(10.1115f, 16.9266f, 10.4253f, 16.6929f, 10.4253f, 16.2472f)
                    curveTo(10.4253f, 15.8813f, 10.2175f, 15.6581f, 9.9186f, 15.59f)
                    curveTo(9.7453f, 15.5565f, 9.6286f, 15.5618f, 9.4697f, 15.5965f)
                    curveTo(9.1826f, 15.6716f, 8.9695f, 15.8919f, 8.9695f, 16.2472f)
                    curveTo(8.9695f, 16.6929f, 9.3029f, 16.9266f, 9.7066f, 16.9266f)
                    horizontalLineTo(9.7072f)
                    close()
                    moveTo(9.319f, 16.2219f)
                    curveTo(9.2505f, 16.1949f, 9.21f, 16.1362f, 9.2416f, 16.0733f)
                    curveTo(9.2654f, 16.0264f, 9.3357f, 16.007f, 9.3893f, 16.0275f)
                    curveTo(9.4536f, 16.0522f, 9.4637f, 16.118f, 9.4964f, 16.145f)
                    curveTo(9.525f, 16.1685f, 9.5637f, 16.1714f, 9.5887f, 16.155f)
                    curveTo(9.6072f, 16.1432f, 9.6137f, 16.1168f, 9.6066f, 16.0927f)
                    curveTo(9.5971f, 16.061f, 9.5726f, 16.041f, 9.5482f, 16.0217f)
                    curveTo(9.5054f, 15.987f, 9.4446f, 15.9576f, 9.481f, 15.8631f)
                    curveTo(9.5113f, 15.7862f, 9.5995f, 15.7832f, 9.5995f, 15.7832f)
                    curveTo(9.6346f, 15.7791f, 9.6661f, 15.7897f, 9.6917f, 15.8126f)
                    curveTo(9.7263f, 15.8425f, 9.7328f, 15.8831f, 9.7269f, 15.9265f)
                    curveTo(9.7215f, 15.9659f, 9.7078f, 16.0005f, 9.7001f, 16.0393f)
                    curveTo(9.6917f, 16.0845f, 9.7162f, 16.1303f, 9.7626f, 16.1321f)
                    curveTo(9.8239f, 16.1344f, 9.8424f, 16.088f, 9.8495f, 16.0587f)
                    curveTo(9.8602f, 16.0158f, 9.8751f, 15.9753f, 9.9156f, 15.9506f)
                    curveTo(9.974f, 15.9142f, 10.0543f, 15.9224f, 10.0919f, 15.9917f)
                    curveTo(10.1216f, 16.0469f, 10.1121f, 16.1227f, 10.0663f, 16.1638f)
                    curveTo(10.046f, 16.1826f, 10.021f, 16.189f, 9.9948f, 16.189f)
                    curveTo(9.9567f, 16.189f, 9.9186f, 16.1884f, 9.8835f, 16.2061f)
                    curveTo(9.8596f, 16.2178f, 9.8489f, 16.2378f, 9.8489f, 16.2636f)
                    curveTo(9.8489f, 16.2889f, 9.8626f, 16.3059f, 9.8841f, 16.3165f)
                    curveTo(9.9251f, 16.337f, 9.9704f, 16.3411f, 10.0145f, 16.3488f)
                    curveTo(10.0788f, 16.3599f, 10.1353f, 16.3822f, 10.1716f, 16.441f)
                    curveTo(10.1716f, 16.441f, 10.1722f, 16.4421f, 10.1728f, 16.4427f)
                    curveTo(10.2145f, 16.5126f, 10.1711f, 16.6125f, 10.0889f, 16.6166f)
                    curveTo(9.9067f, 16.626f, 9.8692f, 16.4204f, 9.7977f, 16.4216f)
                    curveTo(9.7674f, 16.4222f, 9.7435f, 16.4539f, 9.7537f, 16.4903f)
                    curveTo(9.7596f, 16.5103f, 9.7757f, 16.5402f, 9.7858f, 16.5584f)
                    curveTo(9.8215f, 16.623f, 9.7685f, 16.6964f, 9.7066f, 16.7023f)
                    curveTo(9.6036f, 16.7129f, 9.5608f, 16.6536f, 9.5637f, 16.5937f)
                    curveTo(9.5667f, 16.5261f, 9.625f, 16.4568f, 9.5655f, 16.4275f)
                    curveTo(9.503f, 16.3969f, 9.4524f, 16.5173f, 9.3928f, 16.5437f)
                    curveTo(9.3386f, 16.5678f, 9.2636f, 16.549f, 9.2368f, 16.4897f)
                    curveTo(9.2184f, 16.448f, 9.2214f, 16.3681f, 9.3047f, 16.3376f)
                    curveTo(9.3571f, 16.3182f, 9.4732f, 16.3629f, 9.4792f, 16.3065f)
                    curveTo(9.4863f, 16.2419f, 9.3571f, 16.2366f, 9.3184f, 16.2213f)
                    lineTo(9.319f, 16.2219f)
                    close()
                    moveTo(14.8582f, 17.6097f)
                    curveTo(14.9529f, 17.9973f, 14.6688f, 18.322f, 14.2979f, 18.4107f)
                    curveTo(13.9276f, 18.4988f, 13.5149f, 18.3396f, 13.4203f, 17.952f)
                    curveTo(13.3536f, 17.6784f, 13.4542f, 17.457f, 13.6447f, 17.3119f)
                    curveTo(13.6632f, 17.2972f, 13.6858f, 17.2831f, 13.7013f, 17.2778f)
                    curveTo(13.606f, 17.2861f, 13.5483f, 17.2814f, 13.4905f, 17.3007f)
                    curveTo(13.484f, 17.3031f, 13.4786f, 17.296f, 13.4816f, 17.2902f)
                    curveTo(13.5435f, 17.1739f, 13.6864f, 17.1175f, 13.787f, 17.1557f)
                    curveTo(13.7186f, 17.0964f, 13.6644f, 17.0494f, 13.6644f, 17.0494f)
                    lineTo(13.7585f, 16.9725f)
                    curveTo(13.7585f, 16.9725f, 13.8186f, 17.06f, 13.8627f, 17.124f)
                    curveTo(13.9204f, 16.9971f, 14.0627f, 16.9613f, 14.1383f, 17.0312f)
                    curveTo(14.1431f, 17.0353f, 14.1401f, 17.0429f, 14.1342f, 17.0435f)
                    curveTo(14.0841f, 17.0512f, 14.0657f, 17.1022f, 14.0699f, 17.1363f)
                    lineTo(14.1133f, 17.131f)
                    curveTo(14.4682f, 17.0864f, 14.7736f, 17.2608f, 14.8588f, 17.6091f)
                    lineTo(14.8582f, 17.6097f)
                    close()
                    moveTo(17.7626f, 17.1404f)
                    curveTo(17.7626f, 17.0975f, 17.7287f, 17.0764f, 17.6846f, 17.0764f)
                    horizontalLineTo(17.6025f)
                    verticalLineTo(17.2567f)
                    horizontalLineTo(17.6519f)
                    verticalLineTo(17.2074f)
                    horizontalLineTo(17.6769f)
                    lineTo(17.712f, 17.2567f)
                    horizontalLineTo(17.768f)
                    lineTo(17.7251f, 17.1991f)
                    curveTo(17.7507f, 17.1874f, 17.7632f, 17.168f, 17.7632f, 17.1404f)
                    horizontalLineTo(17.7626f)
                    close()
                    moveTo(17.6763f, 17.1633f)
                    horizontalLineTo(17.6525f)
                    verticalLineTo(17.1187f)
                    horizontalLineTo(17.6763f)
                    curveTo(17.7001f, 17.1187f, 17.7126f, 17.1257f, 17.7126f, 17.1404f)
                    curveTo(17.7126f, 17.1551f, 17.7001f, 17.1633f, 17.6763f, 17.1633f)
                    close()
                    moveTo(17.8174f, 17.0306f)
                    curveTo(17.7787f, 16.9918f, 17.7317f, 16.9725f, 17.6763f, 16.9725f)
                    curveTo(17.6209f, 16.9725f, 17.5745f, 16.9918f, 17.5358f, 17.0306f)
                    curveTo(17.4977f, 17.0694f, 17.4792f, 17.1163f, 17.4792f, 17.1715f)
                    curveTo(17.4792f, 17.2267f, 17.4983f, 17.2726f, 17.5358f, 17.3113f)
                    curveTo(17.5745f, 17.3495f, 17.6209f, 17.3689f, 17.6763f, 17.3689f)
                    curveTo(17.7317f, 17.3689f, 17.7787f, 17.3495f, 17.8174f, 17.3113f)
                    curveTo(17.8561f, 17.2726f, 17.8758f, 17.2256f, 17.8758f, 17.1715f)
                    curveTo(17.8758f, 17.1175f, 17.8561f, 17.0694f, 17.8174f, 17.0306f)
                    close()
                    moveTo(17.7787f, 17.2743f)
                    curveTo(17.7513f, 17.3025f, 17.7168f, 17.316f, 17.6763f, 17.316f)
                    curveTo(17.6358f, 17.316f, 17.6019f, 17.3025f, 17.5745f, 17.2743f)
                    curveTo(17.5471f, 17.2461f, 17.5328f, 17.2121f, 17.5328f, 17.171f)
                    curveTo(17.5328f, 17.1298f, 17.5465f, 17.0946f, 17.5745f, 17.067f)
                    curveTo(17.6019f, 17.0382f, 17.6364f, 17.0241f, 17.6763f, 17.0241f)
                    curveTo(17.7162f, 17.0241f, 17.7513f, 17.0388f, 17.7787f, 17.067f)
                    curveTo(17.8073f, 17.0952f, 17.821f, 17.1304f, 17.821f, 17.171f)
                    curveTo(17.821f, 17.2115f, 17.8073f, 17.2461f, 17.7787f, 17.2743f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(14.0137f, 12.7294f)
                    curveTo(13.713f, 12.7294f, 13.4671f, 12.6014f, 13.2622f, 12.3389f)
                    lineTo(13.2259f, 12.2925f)
                    lineTo(12.6895f, 12.8263f)
                    lineTo(12.7133f, 12.8609f)
                    curveTo(12.9747f, 13.2391f, 13.4379f, 13.4652f, 13.9523f, 13.4652f)
                    curveTo(14.2935f, 13.4652f, 14.5722f, 13.3824f, 14.8038f, 13.2121f)
                    curveTo(15.0378f, 13.0401f, 15.1563f, 12.8134f, 15.1563f, 12.5374f)
                    curveTo(15.1563f, 12.0065f, 14.8371f, 11.6823f, 14.2078f, 11.5742f)
                    lineTo(13.8196f, 11.5061f)
                    curveTo(13.6118f, 11.4709f, 13.6118f, 11.391f, 13.6118f, 11.3652f)
                    curveTo(13.6118f, 11.2935f, 13.6832f, 11.2166f, 13.8833f, 11.2166f)
                    curveTo(14.1113f, 11.2166f, 14.3138f, 11.3252f, 14.5025f, 11.549f)
                    lineTo(14.54f, 11.5936f)
                    lineTo(15.0765f, 11.0275f)
                    lineTo(15.0461f, 10.9928f)
                    curveTo(14.7514f, 10.6528f, 14.3709f, 10.4807f, 13.9154f, 10.4807f)
                    curveTo(13.6064f, 10.4807f, 13.3361f, 10.5676f, 13.1116f, 10.7385f)
                    curveTo(12.8865f, 10.9106f, 12.7722f, 11.1385f, 12.7722f, 11.4168f)
                    curveTo(12.7722f, 11.6517f, 12.8544f, 11.8538f, 13.0181f, 12.0194f)
                    curveTo(13.1825f, 12.1774f, 13.3915f, 12.279f, 13.6391f, 12.3201f)
                    lineTo(14.0762f, 12.3999f)
                    curveTo(14.3161f, 12.4405f, 14.3161f, 12.5338f, 14.3161f, 12.565f)
                    curveTo(14.3161f, 12.6666f, 14.1994f, 12.7294f, 14.0119f, 12.7294f)
                    horizontalLineTo(14.0137f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(17.7522f, 13.4212f)
                    verticalLineTo(10.5242f)
                    horizontalLineTo(16.9091f)
                    verticalLineTo(11.5689f)
                    horizontalLineTo(16.0993f)
                    verticalLineTo(10.5242f)
                    horizontalLineTo(15.2598f)
                    verticalLineTo(13.4212f)
                    horizontalLineTo(16.0993f)
                    verticalLineTo(12.3529f)
                    horizontalLineTo(16.9091f)
                    verticalLineTo(13.4212f)
                    horizontalLineTo(17.7522f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(12.6321f, 11.2647f)
                    verticalLineTo(10.5242f)
                    horizontalLineTo(10.3945f)
                    verticalLineTo(13.4212f)
                    horizontalLineTo(12.6321f)
                    verticalLineTo(12.6812f)
                    horizontalLineTo(11.2823f)
                    verticalLineTo(12.3048f)
                    horizontalLineTo(12.5636f)
                    verticalLineTo(11.5889f)
                    horizontalLineTo(11.2823f)
                    verticalLineTo(11.2647f)
                    horizontalLineTo(12.6321f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(7.6436f, 11.2647f)
                    verticalLineTo(10.5242f)
                    horizontalLineTo(5.543f)
                    verticalLineTo(13.4212f)
                    horizontalLineTo(6.3819f)
                    verticalLineTo(12.3929f)
                    horizontalLineTo(7.5501f)
                    verticalLineTo(11.677f)
                    horizontalLineTo(6.3819f)
                    verticalLineTo(11.2647f)
                    horizontalLineTo(7.6436f)
                    close()
                }
                path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                        strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                        pathFillType = NonZero) {
                    moveTo(10.1969f, 11.5402f)
                    curveTo(10.1969f, 10.8947f, 9.7492f, 10.5242f, 8.9686f, 10.5242f)
                    horizontalLineTo(7.7891f)
                    verticalLineTo(13.4212f)
                    horizontalLineTo(8.628f)
                    verticalLineTo(12.5561f)
                    horizontalLineTo(8.8376f)
                    lineTo(9.3592f, 13.4212f)
                    horizontalLineTo(10.3244f)
                    lineTo(9.7039f, 12.3941f)
                    curveTo(10.0308f, 12.2196f, 10.1963f, 11.9325f, 10.1963f, 11.5402f)
                    horizontalLineTo(10.1969f)
                    close()
                    moveTo(8.9239f, 11.8203f)
                    horizontalLineTo(8.628f)
                    verticalLineTo(11.2647f)
                    horizontalLineTo(8.9239f)
                    curveTo(9.1841f, 11.2647f, 9.3574f, 11.3105f, 9.3574f, 11.5325f)
                    curveTo(9.3574f, 11.6559f, 9.3127f, 11.8203f, 8.9239f, 11.8203f)
                    close()
                }
            }
        }
        .build()
        return _rtCertifiedFreshBadge!!
    }

private var _rtCertifiedFreshBadge: ImageVector? = null
