package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Tv: ImageVector
    get() {
        if (_tv != null) {
            return _tv!!
        }
        _tv = Builder(name = "Tv", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp, viewportWidth =
                48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(27.0357f, 23.4243f)
                curveTo(27.3214f, 23.2544f, 27.3214f, 22.8297f, 27.0357f, 22.6598f)
                lineTo(21.8929f, 19.6019f)
                curveTo(21.6071f, 19.432f, 21.25f, 19.6443f, 21.25f, 19.9841f)
                verticalLineTo(26.1f)
                curveTo(21.25f, 26.4398f, 21.6071f, 26.6522f, 21.8929f, 26.4823f)
                lineTo(27.0357f, 23.4243f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(16.5f, 34.8889f)
                verticalLineTo(38.0f)
                horizontalLineTo(31.5f)
                verticalLineTo(34.8889f)
                horizontalLineTo(41.0f)
                curveTo(41.8f, 34.8889f, 42.5f, 34.6556f, 43.1f, 34.1889f)
                curveTo(43.7f, 33.7222f, 44.0f, 33.1778f, 44.0f, 32.5556f)
                verticalLineTo(12.3333f)
                curveTo(44.0f, 11.7111f, 43.7f, 11.1667f, 43.1f, 10.7f)
                curveTo(42.5f, 10.2333f, 41.8f, 10.0f, 41.0f, 10.0f)
                horizontalLineTo(7.0f)
                curveTo(6.2f, 10.0f, 5.5f, 10.2333f, 4.9f, 10.7f)
                curveTo(4.3f, 11.1667f, 4.0f, 11.7111f, 4.0f, 12.3333f)
                verticalLineTo(32.5556f)
                curveTo(4.0f, 33.1778f, 4.3f, 33.7222f, 4.9f, 34.1889f)
                curveTo(5.5f, 34.6556f, 6.2f, 34.8889f, 7.0f, 34.8889f)
                horizontalLineTo(16.5f)
                close()
                moveTo(40.0f, 31.5756f)
                horizontalLineTo(8.0f)
                verticalLineTo(13.3133f)
                horizontalLineTo(40.0f)
                verticalLineTo(31.5756f)
                close()
            }
        }
        .build()
        return _tv!!
    }

private var _tv: ImageVector? = null
