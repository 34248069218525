package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoLiveOutline: ImageVector
    get() {
        if (_ruwidoLiveOutline != null) {
            return _ruwidoLiveOutline!!
        }
        _ruwidoLiveOutline = Builder(name = "RuwidoLiveOutline", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(2.0f, 24.0f)
                curveTo(2.0f, 11.848f, 11.848f, 2.0f, 24.0f, 2.0f)
                reflectiveCurveToRelative(22.0f, 9.848f, 22.0f, 22.0f)
                reflectiveCurveToRelative(-9.848f, 22.0f, -22.0f, 22.0f)
                reflectiveCurveTo(2.0f, 36.152f, 2.0f, 24.0f)
                close()
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(31.927f, 13.508f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, -0.378f, 1.363f)
                lineToRelative(-4.755f, 2.687f)
                lineTo(38.0f, 17.558f)
                verticalLineToRelative(17.0f)
                lineTo(10.0f, 34.558f)
                verticalLineToRelative(-17.0f)
                horizontalLineToRelative(11.062f)
                lineToRelative(-4.57f, -2.697f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, true, 1.016f, -1.722f)
                lineToRelative(6.38f, 3.764f)
                lineToRelative(6.677f, -3.773f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, true, 1.362f, 0.378f)
                close()
                moveTo(12.014f, 19.46f)
                verticalLineToRelative(13.198f)
                horizontalLineToRelative(23.972f)
                lineTo(35.986f, 19.459f)
                lineTo(12.014f, 19.459f)
                close()
            }
        }
        .build()
        return _ruwidoLiveOutline!!
    }

private var _ruwidoLiveOutline: ImageVector? = null
