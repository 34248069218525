package com.brdgwtr.designsystem.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.material3.Icon
import androidx.compose.material3.ProvideTextStyle
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.Stable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import androidx.compose.ui.window.DialogProperties
import com.brdgwtr.designsystem.foundation.BwTheme

/**
 * Dialog component supporting fullscreen (Overlay) style and alert dialog style.
 * Fullscreen can be configured through [options].
 *
 * @param onDismissRequest Callback called when the dialog is requesting dismissal.
 * @param modifier Modifier to apply to the main Dialog layout.
 * @param backgroundColor Background color for the dialog.
 * @param options Dialog options to configure dialog behaviour such as fullscreen and dismissOnBackPress.
 * @param content Content to be displayed within the dialog.
 */
@Composable
fun Dialog(
    onDismissRequest: () -> Unit,
    modifier: Modifier = Modifier,
    options: DialogOptions = DialogDefaults.options(),
    backgroundColor: Color = BwTheme.colors.surfaceEmphasized,
    content: @Composable () -> Unit,
) {
    Dialog(
        properties = options.toDialogProperties(),
        onDismissRequest = onDismissRequest,
    ) {
        Surface(
            color = backgroundColor,
            modifier = if (options.fullscreen) modifier.fillMaxSize() else modifier.wrapContentSize(),
            shape = if (options.fullscreen) RectangleShape else BwTheme.shapes.xl,
            content = content,
        )
    }
}

/**
 * Dialog component for Tv supporting fullscreen (Overlay) style and alert dialog style.
 * Fullscreen can be configured through [options].
 *
 * @param title Main title to display within the dialog.
 * @param onDismissRequest Callback called when the dialog is requesting dismissal.
 * @param primaryAction Primary action button content, typically uses [com.brdgwtr.designsystem.components.PrimaryButton].
 * @param modifier Modifier to apply to the main Dialog layout.
 * @param subtitle Optional subtitle to display below the title.
 * @param icon Optional icon to display above the title.
 * @param secondaryAction Optional secondary action button displayed next to the [primaryAction].
 * @param backgroundColor Background color for the dialog.
 * @param options Dialog options to configure dialog behaviour such as fullscreen and dismissOnBackPress.
 */
@Composable
fun Dialog(
    title: String,
    onDismissRequest: () -> Unit,
    primaryAction: @Composable () -> Unit,
    modifier: Modifier = Modifier,
    subtitle: String? = null,
    icon: ImageVector? = null,
    secondaryAction: (@Composable () -> Unit)? = null,
    backgroundColor: Color = BwTheme.colors.surfaceEmphasized,
    options: DialogOptions = DialogDefaults.options(),
) {
    Dialog(
        modifier = modifier,
        options = options,
        backgroundColor = backgroundColor,
        onDismissRequest = onDismissRequest,
        title = {
            Text(
                text = title,
                textAlign = TextAlign.Center,
                maxLines = 2,
                overflow = TextOverflow.Ellipsis,
            )
        },
        subtitle = subtitle?.let {
            {
                Text(
                    text = it,
                    textAlign = TextAlign.Center,
                    maxLines = 2,
                    overflow = TextOverflow.Ellipsis,
                )
            }
        },
        icon = icon?.let {
            {
                Icon(
                    imageVector = it,
                    modifier = Modifier.size(TvDialogDefaults.iconSize),
                    contentDescription = "",
                )
            }
        },
        actions = {
            primaryAction()
            secondaryAction?.invoke()
        },
    )
}

/**
 * Dialog component for Tv supporting fullscreen (Overlay) style and alert dialog style.
 * Fullscreen can be configured through [options].
 *
 * @param title Main title to display within the dialog.
 * @param onDismissRequest Callback called when the dialog is requesting dismissal.
 * @param actions Action buttons content row, typically uses [com.brdgwtr.designsystem.components.PrimaryButton]s.
 * @param modifier Modifier to apply to the main Dialog layout.
 * @param subtitle Optional subtitle to display below the title.
 * @param icon Optional icon to display above the title.
 * @param backgroundColor Background color for the dialog.
 * @param options Dialog options to configure dialog behaviour such as fullscreen and dismissOnBackPress.
 */
@Composable
fun Dialog(
    title: @Composable () -> Unit,
    onDismissRequest: () -> Unit,
    actions: @Composable RowScope.() -> Unit,
    modifier: Modifier = Modifier,
    subtitle: (@Composable () -> Unit)? = null,
    icon: (@Composable () -> Unit)? = null,
    backgroundColor: Color = BwTheme.colors.surfaceEmphasized,
    options: DialogOptions = DialogDefaults.options(),
) {
    com.brdgwtr.designsystem.components.Dialog(
        onDismissRequest = onDismissRequest,
        modifier = modifier,
        backgroundColor = backgroundColor,
        options = options,
    ) {
        Box {
            Column(
                modifier = Modifier
                    .padding(TvDialogDefaults.contentPadding)
                    .width(TvDialogDefaults.contentWidth)
                    .wrapContentHeight()
                    .align(Alignment.Center),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                icon?.invoke()
                Spacer(modifier = Modifier.height(BwTheme.spacing.xl))
                ProvideTextStyle(BwTheme.typography.display) {
                    title()
                }
                subtitle?.let {
                    Spacer(modifier = Modifier.height(BwTheme.spacing.m))
                    ProvideTextStyle(BwTheme.typography.bodyLarge) {
                        subtitle()
                    }
                }
                Spacer(modifier = Modifier.height(BwTheme.spacing.xxxl))
                Row(
                    horizontalArrangement = Arrangement.spacedBy(BwTheme.spacing.xl),
                    content = actions,
                )
            }
        }
    }
}

object TvDialogDefaults {
    val contentPadding = PaddingValues(horizontal = 32.dp, vertical = 80.dp)
    val contentWidth = 480.dp
    val iconSize = 38.dp
}

object DialogDefaults {
    /**
     * [DialogOptions].
     *
     * @param dismissOnBackPress Whether the dialog should close on back press.
     * @param dismissOnClickOutside Whether the dialog should close when clicked outside.
     * @param fullscreen Whether the dialog should display as a fullscreen overlay.
     */
    @Stable
    fun options(
        dismissOnBackPress: Boolean = true,
        dismissOnClickOutside: Boolean = dismissOnBackPress,
        fullscreen: Boolean = false,
    ): DialogOptions = DialogOptions(
        dismissOnBackPress = dismissOnBackPress,
        dismissOnClickOutside = dismissOnClickOutside,
        fullscreen = fullscreen,
    )
}

@Immutable
data class DialogOptions(
    val dismissOnBackPress: Boolean,
    val dismissOnClickOutside: Boolean,
    val fullscreen: Boolean,
)

@Stable
private fun DialogOptions.toDialogProperties(): DialogProperties = DialogProperties(
    dismissOnBackPress = dismissOnBackPress,
    dismissOnClickOutside = dismissOnClickOutside,
    usePlatformDefaultWidth = false,
)
