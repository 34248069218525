package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoVolumeupFilled: ImageVector
    get() {
        if (_ruwidoVolumeupFilled != null) {
            return _ruwidoVolumeupFilled!!
        }
        _ruwidoVolumeupFilled = Builder(name = "RuwidoVolumeupFilled", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(25.094f, 8.5f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -2.0f, 0.0f)
                verticalLineToRelative(11.918f)
                curveToRelative(0.0f, 2.557f, 1.987f, 4.731f, 4.566f, 4.731f)
                horizontalLineToRelative(11.842f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, 0.0f, -2.0f)
                horizontalLineTo(27.66f)
                curveToRelative(-1.383f, 0.0f, -2.566f, -1.185f, -2.566f, -2.731f)
                verticalLineTo(8.5f)
                close()
                moveTo(8.5f, 23.15f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, 0.0f, 2.0f)
                horizontalLineToRelative(11.959f)
                arcToRelative(2.622f, 2.622f, 0.0f, false, true, 2.613f, 2.637f)
                verticalLineToRelative(11.615f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, 2.0f, 0.0f)
                verticalLineTo(27.787f)
                curveToRelative(0.0f, -2.549f, -2.047f, -4.638f, -4.613f, -4.638f)
                horizontalLineTo(8.5f)
                close()
            }
        }
        .build()
        return _ruwidoVolumeupFilled!!
    }

private var _ruwidoVolumeupFilled: ImageVector? = null
