package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Launch: ImageVector
    get() {
        if (_launch != null) {
            return _launch!!
        }
        _launch = Builder(name = "Launch", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(8.842f, 43.0f)
                curveToRelative(-0.758f, 0.0f, -1.421f, -0.292f, -1.99f, -0.877f)
                curveTo(6.285f, 41.54f, 6.0f, 40.856f, 6.0f, 40.077f)
                verticalLineToRelative(-29.23f)
                curveToRelative(0.0f, -0.78f, 0.284f, -1.462f, 0.853f, -2.047f)
                curveToRelative(0.568f, -0.585f, 1.231f, -0.877f, 1.99f, -0.877f)
                horizontalLineToRelative(11.753f)
                arcToRelative(1.462f, 1.462f, 0.0f, false, true, 0.0f, 2.923f)
                lineTo(8.842f, 10.846f)
                verticalLineToRelative(29.23f)
                horizontalLineToRelative(28.421f)
                verticalLineToRelative(-12.17f)
                arcToRelative(1.421f, 1.421f, 0.0f, true, true, 2.842f, 0.0f)
                verticalLineToRelative(12.17f)
                curveToRelative(0.0f, 0.78f, -0.284f, 1.462f, -0.852f, 2.047f)
                curveToRelative(-0.569f, 0.585f, -1.232f, 0.877f, -1.99f, 0.877f)
                lineTo(8.843f, 43.0f)
                close()
                moveTo(21.352f, 28.33f)
                arcToRelative(1.443f, 1.443f, 0.0f, false, true, -2.07f, -2.012f)
                lineTo(37.169f, 7.923f)
                horizontalLineToRelative(-8.912f)
                arcToRelative(1.462f, 1.462f, 0.0f, true, true, 0.0f, -2.923f)
                lineTo(42.0f, 5.0f)
                verticalLineToRelative(14.217f)
                arcToRelative(1.421f, 1.421f, 0.0f, false, true, -2.842f, 0.0f)
                verticalLineToRelative(-9.2f)
                lineTo(21.352f, 28.332f)
                close()
            }
        }
        .build()
        return _launch!!
    }

private var _launch: ImageVector? = null
