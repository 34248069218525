package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ProfileFilled: ImageVector
    get() {
        if (_profileFilled != null) {
            return _profileFilled!!
        }
        _profileFilled = Builder(name = "ProfileFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(40.0001f, 42.0f)
                verticalLineTo(41.2729f)
                curveTo(40.0001f, 32.6872f, 32.8368f, 25.7274f, 24.0001f, 25.7274f)
                curveTo(15.1635f, 25.7274f, 8.0001f, 32.6871f, 8.0001f, 41.2729f)
                lineTo(8.0f, 42.0f)
                horizontalLineTo(40.0001f)
                close()
            }
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(14.222f, 14.4997f)
                curveTo(14.222f, 19.747f, 18.6001f, 24.0001f, 24.0001f, 24.0001f)
                curveTo(29.4001f, 24.0001f, 33.7782f, 19.7468f, 33.7782f, 14.4997f)
                curveTo(33.7782f, 9.2531f, 29.4001f, 5.0f, 24.0001f, 5.0f)
                curveTo(18.6001f, 5.0f, 14.222f, 9.2531f, 14.222f, 14.4997f)
                close()
            }
        }
        .build()
        return _profileFilled!!
    }

private var _profileFilled: ImageVector? = null
