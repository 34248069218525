package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.DeleteFilled: ImageVector
    get() {
        if (_deleteFilled != null) {
            return _deleteFilled!!
        }
        _deleteFilled = Builder(name = "DeleteFilled", defaultWidth = 48.0.dp, defaultHeight =
                48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(33.74f, 7.258f)
                horizontalLineToRelative(-4.305f)
                curveTo(29.14f, 5.416f, 27.364f, 4.0f, 25.217f, 4.0f)
                horizontalLineToRelative(-2.434f)
                curveToRelative(-2.147f, 0.0f, -3.923f, 1.416f, -4.218f, 3.258f)
                horizontalLineTo(14.26f)
                curveToRelative(-2.353f, 0.0f, -4.261f, 1.702f, -4.261f, 3.8f)
                horizontalLineToRelative(28.0f)
                curveToRelative(0.0f, -2.098f, -1.908f, -3.8f, -4.26f, -3.8f)
                close()
                moveTo(17.542f, 44.0f)
                curveToRelative(-2.666f, 0.0f, -5.25f, -3.977f, -5.25f, -6.173f)
                lineTo(10.0f, 13.412f)
                horizontalLineToRelative(28.0f)
                lineToRelative(-1.79f, 24.415f)
                curveToRelative(0.0f, 2.196f, -2.0f, 6.173f, -4.666f, 6.173f)
                horizontalLineToRelative(-14.0f)
                close()
            }
        }
        .build()
        return _deleteFilled!!
    }

private var _deleteFilled: ImageVector? = null
