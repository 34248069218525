package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RemoteBack: ImageVector
    get() {
        if (_remoteBack != null) {
            return _remoteBack!!
        }
        _remoteBack = Builder(name = "RemoteBack", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(7.6008f, 25.075f)
                curveTo(6.7997f, 24.2972f, 6.7997f, 23.0361f, 7.6008f, 22.2583f)
                lineTo(20.6551f, 9.5834f)
                curveTo(21.4562f, 8.8055f, 22.755f, 8.8055f, 23.556f, 9.5834f)
                curveTo(24.3571f, 10.3612f, 24.3571f, 11.6222f, 23.556f, 12.4f)
                lineTo(14.0035f, 21.675f)
                curveTo(14.0035f, 21.675f, 35.5f, 21.5f, 38.0f, 21.5f)
                curveTo(40.5f, 21.5f, 40.5f, 23.5f, 40.5f, 23.5f)
                curveTo(40.5f, 23.5f, 40.5f, 25.5f, 38.0f, 25.5f)
                curveTo(35.5f, 25.5f, 14.0035f, 25.6583f, 14.0035f, 25.6583f)
                lineTo(23.556f, 34.9333f)
                curveTo(24.3571f, 35.7111f, 24.3571f, 36.9722f, 23.556f, 37.75f)
                curveTo(22.755f, 38.5278f, 21.4562f, 38.5278f, 20.6551f, 37.75f)
                lineTo(7.6008f, 25.075f)
                close()
            }
        }
        .build()
        return _remoteBack!!
    }

private var _remoteBack: ImageVector? = null
