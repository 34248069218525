package com.brdgwtr.designsystem.tokens.internal

import androidx.compose.ui.unit.dp

internal object BwSpacingTokens {
    val spacingMobileXxxs = 2.00.dp
    val spacingMobileXxs = 4.00.dp
    val spacingMobileXs = 8.00.dp
    val spacingMobileS = 12.00.dp
    val spacingMobileM = 16.00.dp
    val spacingMobileL = 24.00.dp
    val spacingMobileXl = 32.00.dp
    val spacingMobileXxl = 48.00.dp
    val spacingMobileXxxl = 64.00.dp
    val spacingTvXxxs = 1.00.dp
    val spacingTvXxs = 2.00.dp
    val spacingTvXs = 4.00.dp
    val spacingTvS = 6.00.dp
    val spacingTvM = 8.00.dp
    val spacingTvL = 12.00.dp
    val spacingTvXl = 16.00.dp
    val spacingTvXxl = 24.00.dp
    val spacingTvXxxl = 32.00.dp
}
