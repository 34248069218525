package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GeoShape6: ImageVector
    get() {
        if (_geoShape6 != null) {
            return _geoShape6!!
        }
        _geoShape6 = Builder(name = "GeoShape6", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(23.555f, 41.998f)
                curveToRelative(-3.508f, -0.035f, -7.38f, -0.547f, -10.863f, -2.523f)
                curveToRelative(-1.346f, -0.765f, -2.626f, -1.829f, -3.596f, -3.033f)
                curveToRelative(-1.393f, -1.726f, -1.436f, -3.717f, -0.256f, -5.7f)
                curveToRelative(4.704f, -7.9f, 9.38f, -15.815f, 14.07f, -23.723f)
                curveToRelative(0.811f, -1.369f, 1.397f, -1.358f, 2.22f, 0.029f)
                curveToRelative(4.691f, 7.908f, 9.373f, 15.82f, 14.074f, 23.723f)
                curveToRelative(1.157f, 1.944f, 1.018f, 3.824f, -0.194f, 5.655f)
                curveToRelative(-1.071f, 1.617f, -2.64f, 2.648f, -4.358f, 3.447f)
                curveToRelative(-3.377f, 1.572f, -6.962f, 2.119f, -11.095f, 2.127f)
                lineToRelative(-0.002f, -0.002f)
                close()
                moveTo(30.997f, 33.886f)
                lineTo(30.987f, 33.539f)
                horizontalLineToRelative(7.15f)
                curveToRelative(0.0f, -1.609f, -0.251f, -1.874f, -1.65f, -1.874f)
                curveToRelative(-1.81f, 0.0f, -3.62f, 0.004f, -5.429f, -0.004f)
                curveToRelative(-0.231f, 0.0f, -0.46f, -0.064f, -0.692f, -0.097f)
                lineToRelative(0.035f, -0.267f)
                horizontalLineToRelative(6.833f)
                curveToRelative(-0.557f, -1.738f, -0.737f, -1.882f, -2.289f, -1.882f)
                curveToRelative(-1.493f, 0.0f, -2.986f, 0.004f, -4.477f, -0.006f)
                curveToRelative(-0.235f, 0.0f, -0.468f, -0.066f, -0.704f, -0.1f)
                curveToRelative(0.01f, -0.1f, 0.023f, -0.198f, 0.033f, -0.297f)
                horizontalLineToRelative(6.15f)
                curveToRelative(-0.517f, -1.204f, -1.36f, -1.806f, -2.53f, -1.806f)
                curveToRelative(-1.142f, 0.0f, -2.285f, 0.006f, -3.43f, -0.004f)
                curveToRelative(-0.282f, -0.002f, -0.567f, -0.051f, -0.85f, -0.078f)
                curveToRelative(0.005f, -0.099f, 0.007f, -0.195f, 0.01f, -0.294f)
                horizontalLineToRelative(5.46f)
                curveToRelative(-0.67f, -1.83f, -0.74f, -1.882f, -2.361f, -1.878f)
                curveToRelative(-1.049f, 0.0f, -2.095f, 0.006f, -3.143f, -0.008f)
                curveToRelative(-0.185f, -0.002f, -0.367f, -0.088f, -0.55f, -0.133f)
                arcToRelative(6.33f, 6.33f, 0.0f, false, false, 0.023f, -0.233f)
                horizontalLineToRelative(4.702f)
                curveToRelative(-0.47f, -1.253f, -1.287f, -1.88f, -2.45f, -1.882f)
                horizontalLineToRelative(-2.83f)
                lineToRelative(0.005f, -0.343f)
                horizontalLineToRelative(3.928f)
                curveToRelative(-0.598f, -1.78f, -0.744f, -1.892f, -2.329f, -1.892f)
                horizontalLineToRelative(-2.238f)
                verticalLineToRelative(-0.39f)
                horizontalLineToRelative(3.27f)
                curveToRelative(-0.518f, -1.22f, -1.365f, -1.83f, -2.539f, -1.834f)
                curveToRelative(-0.46f, -0.002f, -0.923f, -0.049f, -1.384f, -0.075f)
                lineToRelative(0.016f, -0.305f)
                horizontalLineToRelative(2.515f)
                curveToRelative(-0.27f, -2.017f, -1.757f, -1.96f, -3.234f, -1.915f)
                lineToRelative(0.09f, -0.258f)
                curveToRelative(0.617f, -0.041f, 1.231f, -0.085f, 1.83f, -0.124f)
                curveToRelative(-0.314f, -1.387f, -1.084f, -2.069f, -2.407f, -2.124f)
                curveToRelative(0.356f, -0.05f, 0.713f, -0.097f, 1.163f, -0.159f)
                curveToRelative(-0.51f, -0.805f, -0.602f, -1.859f, -1.855f, -1.83f)
                curveToRelative(0.567f, -0.627f, 0.356f, -1.192f, -0.889f, -2.544f)
                curveToRelative(-0.176f, 0.273f, -0.354f, 0.532f, -0.514f, 0.801f)
                lineToRelative(-12.85f, 21.673f)
                curveToRelative(-1.126f, 1.899f, -1.026f, 3.405f, 0.65f, 4.845f)
                curveToRelative(1.111f, 0.956f, 2.414f, 1.79f, 3.77f, 2.336f)
                curveToRelative(4.763f, 1.922f, 9.704f, 2.057f, 14.692f, 1.044f)
                curveToRelative(1.12f, -0.228f, 2.208f, -0.616f, 3.31f, -0.93f)
                lineToRelative(-0.06f, -0.2f)
                curveToRelative(-0.262f, -0.074f, -0.524f, -0.15f, -0.788f, -0.224f)
                curveToRelative(0.85f, -0.224f, 1.73f, -0.164f, 2.486f, -0.444f)
                curveToRelative(0.768f, -0.283f, 1.415f, -0.902f, 2.113f, -1.372f)
                lineToRelative(-0.08f, -0.313f)
                horizontalLineToRelative(-5.088f)
                lineToRelative(0.01f, -0.35f)
                horizontalLineToRelative(4.783f)
                curveToRelative(1.13f, -0.003f, 1.624f, -0.55f, 1.598f, -1.879f)
                lineTo(31.0f, 33.89f)
                lineToRelative(-0.004f, -0.004f)
                close()
            }
        }
        .build()
        return _geoShape6!!
    }

private var _geoShape6: ImageVector? = null
