package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.PlayFilled: ImageVector
    get() {
        if (_playFilled != null) {
            return _playFilled!!
        }
        _playFilled = Builder(name = "PlayFilled", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(14.9032f, 7.4634f)
                curveTo(13.9517f, 6.728f, 12.8713f, 6.9797f, 12.2126f, 7.4477f)
                curveTo(11.5488f, 7.9193f, 11.0f, 8.7911f, 11.0f, 9.8643f)
                verticalLineTo(38.1364f)
                curveTo(11.0f, 39.0738f, 11.4086f, 39.9793f, 12.1242f, 40.5217f)
                curveTo(12.9081f, 41.1157f, 13.9527f, 41.1824f, 14.8473f, 40.575f)
                curveTo(14.8489f, 40.5739f, 14.8505f, 40.5728f, 14.8522f, 40.5717f)
                lineTo(35.8944f, 26.3866f)
                lineTo(35.969f, 26.3229f)
                curveTo(37.3437f, 25.1509f, 37.3437f, 22.8497f, 35.969f, 21.6778f)
                lineTo(35.8944f, 21.6141f)
                lineTo(14.9032f, 7.4634f)
                close()
            }
        }
        .build()
        return _playFilled!!
    }

private var _playFilled: ImageVector? = null
