package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GeoShape1: ImageVector
    get() {
        if (_geoShape1 != null) {
            return _geoShape1!!
        }
        _geoShape1 = Builder(name = "GeoShape1", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(23.98f, 42.0f)
                curveToRelative(-0.325f, -0.13f, -0.639f, -0.238f, -0.936f, -0.381f)
                curveToRelative(-6.038f, -2.95f, -12.077f, -5.903f, -18.113f, -8.861f)
                curveToRelative(-1.07f, -0.526f, -1.193f, -0.974f, -0.495f, -1.887f)
                curveToRelative(6.163f, -8.047f, 12.328f, -16.093f, 18.5f, -24.135f)
                curveToRelative(0.758f, -0.987f, 1.358f, -0.981f, 2.123f, 0.017f)
                curveTo(31.21f, 14.77f, 37.358f, 22.788f, 43.5f, 30.807f)
                curveToRelative(0.768f, 1.0f, 0.678f, 1.398f, -0.456f, 1.955f)
                arcToRelative(12138.44f, 12138.44f, 0.0f, false, true, -18.2f, 8.907f)
                curveToRelative(-0.267f, 0.13f, -0.554f, 0.213f, -0.864f, 0.331f)
                close()
                moveTo(6.388f, 31.424f)
                curveToRelative(5.637f, 2.756f, 11.144f, 5.45f, 16.74f, 8.185f)
                lineTo(23.128f, 9.585f)
                lineTo(6.387f, 31.422f)
                verticalLineToRelative(0.002f)
                close()
                moveTo(40.4f, 30.056f)
                curveToRelative(-0.595f, -1.408f, -1.487f, -1.945f, -3.064f, -1.882f)
                curveToRelative(-3.507f, 0.134f, -7.024f, 0.043f, -10.537f, 0.043f)
                horizontalLineToRelative(-2.277f)
                verticalLineToRelative(1.84f)
                horizontalLineToRelative(15.876f)
                lineToRelative(0.002f, -0.001f)
                close()
                moveTo(41.458f, 31.42f)
                curveToRelative(-0.236f, -0.776f, -0.744f, -0.944f, -1.48f, -0.942f)
                curveToRelative(-4.866f, 0.027f, -9.73f, 0.012f, -14.595f, 0.017f)
                curveToRelative(-0.287f, 0.0f, -0.578f, 0.039f, -0.874f, 0.062f)
                verticalLineToRelative(1.787f)
                horizontalLineToRelative(1.136f)
                curveToRelative(4.257f, 0.0f, 8.514f, -0.032f, 12.768f, 0.02f)
                curveToRelative(1.15f, 0.015f, 2.1f, -0.312f, 3.043f, -0.944f)
                horizontalLineToRelative(0.002f)
                close()
                moveTo(24.528f, 27.762f)
                horizontalLineToRelative(14.125f)
                curveToRelative(-0.582f, -1.388f, -1.483f, -1.922f, -3.05f, -1.86f)
                curveToRelative(-3.302f, 0.13f, -6.613f, 0.04f, -9.919f, 0.04f)
                lineTo(24.53f, 25.942f)
                verticalLineToRelative(1.82f)
                close()
                moveTo(24.522f, 32.766f)
                verticalLineToRelative(1.849f)
                curveToRelative(3.164f, 0.0f, 6.27f, -0.052f, 9.37f, 0.02f)
                curveToRelative(1.83f, 0.042f, 3.03f, -1.115f, 4.474f, -1.87f)
                lineTo(24.523f, 32.765f)
                close()
                moveTo(36.935f, 25.538f)
                curveToRelative(-0.584f, -1.393f, -1.457f, -1.948f, -2.992f, -1.855f)
                curveToRelative(-2.323f, 0.14f, -4.663f, 0.037f, -6.994f, 0.035f)
                horizontalLineToRelative(-2.414f)
                verticalLineToRelative(1.82f)
                horizontalLineToRelative(12.4f)
                close()
                moveTo(24.498f, 23.213f)
                horizontalLineToRelative(10.668f)
                curveToRelative(-0.573f, -1.344f, -1.45f, -1.986f, -2.97f, -1.785f)
                curveToRelative(-0.927f, 0.122f, -1.886f, 0.023f, -2.83f, 0.023f)
                horizontalLineToRelative(-4.868f)
                verticalLineToRelative(1.764f)
                verticalLineToRelative(-0.002f)
                close()
                moveTo(24.468f, 21.003f)
                horizontalLineToRelative(8.952f)
                curveToRelative(0.056f, -0.08f, 0.11f, -0.16f, 0.168f, -0.24f)
                curveToRelative(-0.609f, -0.527f, -1.182f, -1.461f, -1.83f, -1.51f)
                curveToRelative(-2.373f, -0.185f, -4.77f, -0.073f, -7.175f, -0.073f)
                curveToRelative(-0.048f, 0.223f, -0.105f, 0.377f, -0.109f, 0.53f)
                curveToRelative(-0.013f, 0.41f, -0.004f, 0.822f, -0.004f, 1.296f)
                lineToRelative(-0.003f, -0.002f)
                close()
                moveTo(24.507f, 36.845f)
                curveToRelative(3.282f, 0.045f, 6.55f, 0.598f, 9.286f, -1.8f)
                horizontalLineToRelative(-9.286f)
                verticalLineToRelative(1.8f)
                close()
                moveTo(24.502f, 18.718f)
                horizontalLineToRelative(7.463f)
                curveToRelative(-0.678f, -0.716f, -1.14f, -1.656f, -1.701f, -1.714f)
                curveToRelative(-1.889f, -0.195f, -3.814f, -0.075f, -5.762f, -0.075f)
                verticalLineToRelative(1.789f)
                close()
                moveTo(29.892f, 16.505f)
                curveToRelative(0.064f, -0.077f, 0.13f, -0.151f, 0.195f, -0.228f)
                curveToRelative(-0.536f, -0.526f, -1.003f, -1.387f, -1.627f, -1.507f)
                curveToRelative(-1.253f, -0.239f, -2.595f, -0.07f, -3.907f, -0.07f)
                verticalLineToRelative(1.805f)
                horizontalLineToRelative(5.338f)
                close()
                moveTo(24.54f, 12.503f)
                verticalLineToRelative(1.737f)
                horizontalLineToRelative(3.678f)
                curveToRelative(-0.774f, -1.775f, -1.35f, -2.042f, -3.678f, -1.738f)
                close()
                moveTo(24.513f, 39.066f)
                curveToRelative(1.948f, 0.249f, 3.156f, -0.973f, 4.56f, -1.75f)
                horizontalLineToRelative(-4.56f)
                verticalLineToRelative(1.75f)
                close()
                moveTo(24.509f, 11.956f)
                horizontalLineToRelative(2.316f)
                curveToRelative(-0.942f, -0.65f, -0.968f, -1.87f, -2.316f, -1.787f)
                verticalLineToRelative(1.787f)
                close()
            }
        }
        .build()
        return _geoShape1!!
    }

private var _geoShape1: ImageVector? = null
