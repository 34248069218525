package com.brdgwtr.designsystem.components

import androidx.annotation.FloatRange
import androidx.compose.animation.core.CubicBezierEasing
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.VectorConverter
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.animateValue
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.keyframes
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.progressSemantics
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.drawscope.DrawScope
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.layout.layout
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.offset
import androidx.compose.ui.util.fastCoerceIn
import com.brdgwtr.designsystem.foundation.BwTheme
import kotlin.math.PI
import kotlin.math.abs
import kotlin.math.max

/**
 * Linear determinate progress indicator component.
 *
 * @param progress The progress of this indicator, where 0.0 represents no progress and 1.0
 * represents full progress. Values outside of this range are coerced into the range.
 * @param modifier the [Modifier] to be applied to this progress indicator
 * @param color The color of the progress indicator.
 * @param backgroundColor The color of the background behind the indicator, visible when the
 * progress has not reached that area of the overall indicator yet.
 * @param strokeCap stroke cap to use for the ends of this progress indicator
 */
@Composable
fun LinearProgressIndicator(
    @FloatRange(from = 0.0, to = 1.0) progress: Float,
    modifier: Modifier = Modifier,
    color: Color = BwTheme.colors.primary,
    backgroundColor: Color = color.copy(alpha = ProgressIndicatorDefaults.INDICATOR_BACKGROUND_OPACITY),
    strokeCap: StrokeCap = StrokeCap.Round,
) {
    val coercedProgress = progress.fastCoerceIn(0f, 1f)
    Canvas(
        modifier
            .increaseSemanticsBounds()
            .progressSemantics(coercedProgress)
            .size(ProgressIndicatorDefaults.linearIndicatorWidth, ProgressIndicatorDefaults.linearIndicatorHeight),
    ) {
        val strokeWidth = size.height
        drawLinearIndicatorBackground(backgroundColor, strokeWidth, strokeCap)
        drawLinearIndicator(0f, coercedProgress, color, strokeWidth, strokeCap)
    }
}

/**
 * Linear indeterminate progress indicator component.
 *
 * @param modifier the [Modifier] to be applied to this progress indicator
 * @param color The color of the progress indicator.
 * @param backgroundColor The color of the background behind the indicator, visible when the
 * progress has not reached that area of the overall indicator yet.
 * @param strokeCap stroke cap to use for the ends of this progress indicator
 */
@Composable
fun LinearProgressIndicator(
    modifier: Modifier = Modifier,
    color: Color = BwTheme.colors.primary,
    backgroundColor: Color = color.copy(alpha = ProgressIndicatorDefaults.INDICATOR_BACKGROUND_OPACITY),
    strokeCap: StrokeCap = StrokeCap.Butt,
) {
    val infiniteTransition = rememberInfiniteTransition()
    // Fractional position of the 'head' and 'tail' of the two lines drawn. I.e if the head is 0.8
    // and the tail is 0.2, there is a line drawn from between 20% along to 80% along the total
    // width.
    val firstLineHead by infiniteTransition.animateFloat(
        0f,
        1f,
        infiniteRepeatable(
            animation = keyframes {
                durationMillis = LINEAR_ANIMATION_DURATION
                0f at FIRST_LINE_HEAD_DELAY using FIRST_LINE_HEAD_EASING
                1f at FIRST_LINE_HEAD_DURATION + FIRST_LINE_HEAD_DELAY
            },
        ),
    )
    val firstLineTail by infiniteTransition.animateFloat(
        0f,
        1f,
        infiniteRepeatable(
            animation = keyframes {
                durationMillis = LINEAR_ANIMATION_DURATION
                0f at FIRST_LINE_TAIL_DELAY using FIRST_LINE_TAIL_EASING
                1f at FIRST_LINE_TAIL_DURATION + FIRST_LINE_TAIL_DELAY
            },
        ),
    )
    val secondLineHead by infiniteTransition.animateFloat(
        0f,
        1f,
        infiniteRepeatable(
            animation = keyframes {
                durationMillis = LINEAR_ANIMATION_DURATION
                0f at SECOND_LINE_HEAD_DELAY using SECOND_LINE_HEAD_EASING
                1f at SECOND_LINE_HEAD_DURATION + SECOND_LINE_HEAD_DELAY
            },
        ),
    )
    val secondLineTail by infiniteTransition.animateFloat(
        0f,
        1f,
        infiniteRepeatable(
            animation = keyframes {
                durationMillis = LINEAR_ANIMATION_DURATION
                0f at SECOND_LINE_TAIL_DELAY using SECOND_LINE_TAIL_EASING
                1f at SECOND_LINE_TAIL_DURATION + SECOND_LINE_TAIL_DELAY
            },
        ),
    )
    Canvas(
        modifier
            .increaseSemanticsBounds()
            .progressSemantics()
            .size(ProgressIndicatorDefaults.linearIndicatorWidth, ProgressIndicatorDefaults.linearIndicatorHeight),
    ) {
        val strokeWidth = size.height
        drawLinearIndicatorBackground(backgroundColor, strokeWidth, strokeCap)
        if (firstLineHead - firstLineTail > 0) {
            drawLinearIndicator(
                firstLineHead,
                firstLineTail,
                color,
                strokeWidth,
                strokeCap,
            )
        }
        if ((secondLineHead - secondLineTail) > 0) {
            drawLinearIndicator(
                secondLineHead,
                secondLineTail,
                color,
                strokeWidth,
                strokeCap,
            )
        }
    }
}

/**
 * Linear determinate progress indicator component.
 *
 * @param progress The progress of this progress indicator, where 0.0 represents no progress and 1.0
 * represents full progress. Values outside of this range are coerced into the range.
 * @param modifier the [Modifier] to be applied to this progress indicator
 * @param color The color of the progress indicator.
 * @param strokeWidth The stroke width for the progress indicator.
 * @param backgroundColor The color of the background behind the indicator, visible when the
 * progress has not reached that area of the overall indicator yet.
 * @param strokeCap stroke cap to use for the ends of this progress indicator
 */
@Composable
fun CircularProgressIndicator(
    @FloatRange(from = 0.0, to = 1.0) progress: Float,
    modifier: Modifier = Modifier,
    color: Color = BwTheme.colors.primary,
    strokeWidth: Dp = ProgressIndicatorDefaults.circularIndicatorStrokeWidth,
    backgroundColor: Color = color.copy(alpha = ProgressIndicatorDefaults.INDICATOR_BACKGROUND_OPACITY),
    strokeCap: StrokeCap = StrokeCap.Round,
) {
    val coercedProgress = progress.fastCoerceIn(0f, 1f)
    val stroke = with(LocalDensity.current) {
        Stroke(width = strokeWidth.toPx(), cap = strokeCap)
    }
    Canvas(
        modifier
            .progressSemantics(coercedProgress)
            .size(ProgressIndicatorDefaults.circularIndicatorDiameter),
    ) {
        // Start at 12 O'clock
        val startAngle = 270f
        val sweep = coercedProgress * 360f
        drawCircularIndicatorBackground(backgroundColor, stroke)
        drawDeterminateCircularIndicator(startAngle, sweep, color, stroke)
    }
}

/**
 * Circular indeterminate progress indicator component.
 *
 * @param modifier the [Modifier] to be applied to this progress indicator
 * @param color The color of the progress indicator.
 * @param backgroundColor The color of the background behind the indicator, visible when the
 * progress has not reached that area of the overall indicator yet.
 * @param strokeCap stroke cap to use for the ends of this progress indicator
 */
@Composable
fun CircularProgressIndicator(
    modifier: Modifier = Modifier,
    color: Color = BwTheme.colors.primary,
    strokeWidth: Dp = ProgressIndicatorDefaults.circularIndicatorStrokeWidth,
    backgroundColor: Color = Color.Transparent,
    strokeCap: StrokeCap = StrokeCap.Round,
) {
    val stroke = with(LocalDensity.current) {
        Stroke(width = strokeWidth.toPx(), cap = strokeCap)
    }

    val transition = rememberInfiniteTransition()
    // The current rotation around the circle, so we know where to start the rotation from
    val currentRotation by transition.animateValue(
        0,
        ROTATIONS_PER_CYCLE,
        Int.VectorConverter,
        infiniteRepeatable(
            animation = tween(
                durationMillis = ROTATION_DURATION * ROTATIONS_PER_CYCLE,
                easing = LinearEasing,
            ),
        ),
    )
    // How far forward (degrees) the base point should be from the start point
    val baseRotation by transition.animateFloat(
        0f,
        BASE_ROTATION_ANGLE,
        infiniteRepeatable(
            animation = tween(
                durationMillis = ROTATION_DURATION,
                easing = LinearEasing,
            ),
        ),
    )
    // How far forward (degrees) both the head and tail should be from the base point
    val endAngle by transition.animateFloat(
        0f,
        JUMP_ROTATION_ANGLE,
        infiniteRepeatable(
            animation = keyframes {
                durationMillis = HEAD_AND_TAIL_ANIMATION_DURATION + HEAD_AND_TAIL_DELAY_DURATION
                0f at 0 using circularEasing
                JUMP_ROTATION_ANGLE at HEAD_AND_TAIL_ANIMATION_DURATION
            },
        ),
    )

    val startAngle by transition.animateFloat(
        0f,
        JUMP_ROTATION_ANGLE,
        infiniteRepeatable(
            animation = keyframes {
                durationMillis = HEAD_AND_TAIL_ANIMATION_DURATION + HEAD_AND_TAIL_DELAY_DURATION
                0f at HEAD_AND_TAIL_DELAY_DURATION using circularEasing
                JUMP_ROTATION_ANGLE at durationMillis
            },
        ),
    )
    Canvas(
        modifier
            .progressSemantics()
            .size(ProgressIndicatorDefaults.circularIndicatorDiameter),
    ) {
        drawCircularIndicatorBackground(backgroundColor, stroke)

        val currentRotationAngleOffset = (currentRotation * ROTATION_ANGLE_OFFSET) % 360f

        // How long a line to draw using the start angle as a reference point
        val sweep = abs(endAngle - startAngle)

        // Offset by the constant offset and the per rotation offset
        val offset = START_ANGLE_OFFSET + currentRotationAngleOffset + baseRotation
        drawIndeterminateCircularIndicator(startAngle + offset, strokeWidth, sweep, color, stroke)
    }
}

object ProgressIndicatorDefaults {
    const val INDICATOR_BACKGROUND_OPACITY: Float = 0.3f
    val circularIndicatorStrokeWidth: Dp = 4.dp
    val circularIndicatorDiameter: Dp = 60.dp
    val linearIndicatorHeight: Dp = 4.dp
    val linearIndicatorWidth: Dp = 240.dp
}

private fun DrawScope.drawLinearIndicatorBackground(color: Color, strokeWidth: Float, strokeCap: StrokeCap) =
    drawLinearIndicator(0f, 1f, color, strokeWidth, strokeCap)

private fun DrawScope.drawLinearIndicator(
    startFraction: Float,
    endFraction: Float,
    color: Color,
    strokeWidth: Float,
    strokeCap: StrokeCap,
) {
    val width = size.width
    val height = size.height
    // Start drawing from the vertical center of the stroke
    val yOffset = height / 2

    val isLtr = layoutDirection == LayoutDirection.Ltr
    val barStart = (if (isLtr) startFraction else 1f - endFraction) * width
    val barEnd = (if (isLtr) endFraction else 1f - startFraction) * width

    // if there isn't enough space to draw the stroke caps, fall back to StrokeCap.Butt
    if (strokeCap == StrokeCap.Butt || height > width) {
        // Progress line
        drawLine(color, Offset(barStart, yOffset), Offset(barEnd, yOffset), strokeWidth)
    } else {
        // need to adjust barStart and barEnd for the stroke caps
        val strokeCapOffset = strokeWidth / 2
        val coerceRange = strokeCapOffset..(width - strokeCapOffset)
        val adjustedBarStart = barStart.coerceIn(coerceRange)
        val adjustedBarEnd = barEnd.coerceIn(coerceRange)

        if (abs(endFraction - startFraction) > 0) {
            // Progress line
            drawLine(
                color,
                Offset(adjustedBarStart, yOffset),
                Offset(adjustedBarEnd, yOffset),
                strokeWidth,
                strokeCap,
            )
        }
    }
}

private fun DrawScope.drawDeterminateCircularIndicator(startAngle: Float, sweep: Float, color: Color, stroke: Stroke) =
    drawCircularIndicator(startAngle, sweep, color, stroke)

private fun DrawScope.drawIndeterminateCircularIndicator(
    startAngle: Float,
    strokeWidth: Dp,
    sweep: Float,
    color: Color,
    stroke: Stroke,
) {
    val strokeCapOffset = if (stroke.cap == StrokeCap.Butt) {
        0f
    } else {
        // Length of arc is angle * radius
        // Angle (radians) is length / radius
        // The length should be the same as the stroke width for calculating the min angle
        (180.0 / PI).toFloat() * (strokeWidth / (ProgressIndicatorDefaults.circularIndicatorDiameter / 2)) / 2f
    }

    // Adding a stroke cap draws half the stroke width behind the start point, so we want to
    // move it forward by that amount so the arc visually appears in the correct place
    val adjustedStartAngle = startAngle + strokeCapOffset

    // When the start and end angles are in the same place, we still want to draw a small sweep, so
    // the stroke caps get added on both ends and we draw the correct minimum length arc
    val adjustedSweep = max(sweep, 0.1f)

    drawCircularIndicator(adjustedStartAngle, adjustedSweep, color, stroke)
}

private fun DrawScope.drawCircularIndicatorBackground(color: Color, stroke: Stroke) =
    drawCircularIndicator(0f, 360f, color, stroke)

private fun DrawScope.drawCircularIndicator(startAngle: Float, sweep: Float, color: Color, stroke: Stroke) {
    // To draw this circle we need a rect with edges that line up with the midpoint of the stroke.
    // To do this we need to remove half the stroke width from the total diameter for both sides.
    val diameterOffset = stroke.width / 2
    val arcDimen = size.width - 2 * diameterOffset
    drawArc(
        color = color,
        startAngle = startAngle,
        sweepAngle = sweep,
        useCenter = false,
        topLeft = Offset(diameterOffset, diameterOffset),
        size = Size(arcDimen, arcDimen),
        style = stroke,
    )
}

// Indeterminate linear indicator transition specs
// Total duration for one cycle
private const val LINEAR_ANIMATION_DURATION = 1800

// Duration of the head and tail animations for both lines
private const val FIRST_LINE_HEAD_DURATION = 750
private const val FIRST_LINE_TAIL_DURATION = 850
private const val SECOND_LINE_HEAD_DURATION = 567
private const val SECOND_LINE_TAIL_DURATION = 533

// Delay before the start of the head and tail animations for both lines
private const val FIRST_LINE_HEAD_DELAY = 0
private const val FIRST_LINE_TAIL_DELAY = 333
private const val SECOND_LINE_HEAD_DELAY = 1000
private const val SECOND_LINE_TAIL_DELAY = 1267

private val FIRST_LINE_HEAD_EASING = CubicBezierEasing(0.2f, 0f, 0.8f, 1f)
private val FIRST_LINE_TAIL_EASING = CubicBezierEasing(0.4f, 0f, 1f, 1f)
private val SECOND_LINE_HEAD_EASING = CubicBezierEasing(0f, 0f, 0.65f, 1f)
private val SECOND_LINE_TAIL_EASING = CubicBezierEasing(0.1f, 0f, 0.45f, 1f)

// Indeterminate circular indicator transition specs

// The animation comprises of 5 rotations around the circle forming a 5 pointed star.
// After the 5th rotation, we are back at the beginning of the circle.
private const val ROTATIONS_PER_CYCLE = 5

// Each rotation is 1 and 1/3 seconds, but 1332ms divides more evenly
private const val ROTATION_DURATION = 1332

// When the rotation is at its beginning (0 or 360 degrees) we want it to be drawn at 12 o clock,
// which means 270 degrees when drawing.
private const val START_ANGLE_OFFSET = -90f

// How far the base point moves around the circle
private const val BASE_ROTATION_ANGLE = 286f

// How far the head and tail should jump forward during one rotation past the base point
private const val JUMP_ROTATION_ANGLE = 290f

// Each rotation we want to offset the start position by this much, so we continue where
// the previous rotation ended. This is the maximum angle covered during one rotation.
private const val ROTATION_ANGLE_OFFSET = (BASE_ROTATION_ANGLE + JUMP_ROTATION_ANGLE) % 360f

// The head animates for the first half of a rotation, then is static for the second half
// The tail is static for the first half and then animates for the second half
private const val HEAD_AND_TAIL_ANIMATION_DURATION = (ROTATION_DURATION * 0.5).toInt()
private const val HEAD_AND_TAIL_DELAY_DURATION = HEAD_AND_TAIL_ANIMATION_DURATION

// The easing for the head and tail jump
private val circularEasing = CubicBezierEasing(0.4f, 0f, 0.2f, 1f)

private fun Modifier.increaseSemanticsBounds(): Modifier {
    val padding = 10.dp
    return this
        .layout { measurable, constraints ->
            val paddingPx = padding.roundToPx()
            // We need to add vertical padding to the semantics bounds in other to meet
            // screenreader green box minimum size, but we also want to
            // preserve a visual appearance and layout size below that minimum
            // in order to maintain backwards compatibility. This custom
            // layout effectively implements "negative padding".
            val newConstraint = constraints.offset(0, paddingPx * 2)
            val placeable = measurable.measure(newConstraint)

            // But when actually placing the placeable, create the layout without additional
            // space. Place the placeable where it would've been without any extra padding.
            val height = placeable.height - paddingPx * 2
            val width = placeable.width
            layout(width, height) {
                placeable.place(0, -paddingPx)
            }
        }
        .semantics(mergeDescendants = true) {}
        .padding(vertical = padding)
}
