package com.brdgwtr.designsystem.foundation

import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.contentColorFor
import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.Stable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.takeOrElse
import com.brdgwtr.designsystem.tokens.internal.BwColorTokens
import dev.drewhamilton.poko.Poko

/**
 * BwColors holds all semantic colors for the BridgeWater design system.
 *
 * @param surfaceBackground - Used for primary background/screen color.
 * @param surfaceEmphasized - Used to emphasize/elevate content or text that is displayed over [surfaceBackground].
 * @param surfaceContainer - Used for cards, list items, and other interactive elements.
 * @param surfaceInverse - Used for cards, list items, and other interactive elements usually on focus/selected states.
 * @param onSurfaceBackground - Used for content such as text and icons on [surfaceBackground].
 * @param onSurfaceEmphasized - Used for content such as text and icons on [surfaceEmphasized].
 * @param onSurfaceContainer - Used for content such as text and icons on [surfaceContainer].
 * @param onSurfaceInverse - Used for content such as text and icons on [surfaceInverse].
 * @param primary - Use to highlight key actions in UI, buttons, links icons, and branded moments.
 * @param onPrimary- Use for content like text and icons on [primary].
 * @param secondary - Used to show important interactive elements not in focus.
 * @param onSecondary- Use for content like text and icons on [secondary].
 * @param statusSuccess - Use intentionally to show a success status.
 * @param statusError - Use intentionally to show an error status.
 * @param statusWarning - Use intentionally to show a warning status.
 * @param onStatusSuccess - Used for content such as text and icons on [statusSuccess].
 * @param onStatusError - Used for content such as text and icons on [statusError].
 * @param onStatusWarning - Used for content such as text and icons on [statusWarning].
 * @param dimmerHigh - Use to dim down the entire screen or content when a drawer, action tray or modal/dialog is
 * active. May also be used over image assets as part of background to help to properly display any UI element.
 * @param dimmerLow - Use to dim down individual image assets when there is text or other UI element on top,
 * specially if the asset has a brighter contrast.
 */
@Poko
class BwColors(
    /** Surface Colors **/
    val surfaceBackground: Color,
    val surfaceEmphasized: Color,
    val surfaceContainer: Color,
    val surfaceInverse: Color,

    /** Surface Foreground Colors **/
    val onSurfaceBackground: Color,
    val onSurfaceEmphasized: Color,
    val onSurfaceContainer: Color,
    val onSurfaceInverse: Color,

    /** Primary Colors **/
    val primary: Color,
    val onPrimary: Color,

    /** Secondary Colors **/
    val secondary: Color,
    val onSecondary: Color,

    /** Status Colors **/
    val statusSuccess: Color,
    val statusError: Color,
    val statusWarning: Color,
    val onStatusSuccess: Color,
    val onStatusError: Color,
    val onStatusWarning: Color,

    /** Dimmer Colors **/
    val dimmerHigh: Color,
    val dimmerLow: Color,
)

val BwDefaultTheme = BwColors(
    surfaceBackground = BwColorTokens.defaultSurfaceBackground,
    surfaceEmphasized = BwColorTokens.defaultSurfaceEmphasized,
    surfaceContainer = BwColorTokens.defaultSurfaceContainer,
    surfaceInverse = BwColorTokens.defaultSurfaceInverse,
    onSurfaceBackground = BwColorTokens.defaultOnSurfaceBackground,
    onSurfaceEmphasized = BwColorTokens.defaultOnSurfaceEmphasized,
    onSurfaceContainer = BwColorTokens.defaultOnSurfaceContainer,
    onSurfaceInverse = BwColorTokens.defaultOnSurfaceInverse,
    primary = BwColorTokens.defaultPrimary,
    onPrimary = BwColorTokens.defaultOnPrimary,
    secondary = BwColorTokens.defaultSecondary,
    onSecondary = BwColorTokens.defaultOnSecondary,
    statusSuccess = BwColorTokens.defaultStatusSuccess,
    statusError = BwColorTokens.defaultStatusError,
    statusWarning = BwColorTokens.defaultStatusWarning,
    onStatusSuccess = BwColorTokens.defaultOnStatusSuccess,
    onStatusError = BwColorTokens.defaultOnStatusError,
    onStatusWarning = BwColorTokens.defaultOnStatusWarning,
    dimmerHigh = BwColorTokens.defaultDimmerHigh,
    dimmerLow = BwColorTokens.defaultDimmerLow,
)

@Composable
@ReadOnlyComposable
fun contentColorFor(backgroundColor: Color): Color = BwTheme.colors.contentColorFor(backgroundColor)
    .takeOrElse { MaterialTheme.colorScheme.contentColorFor(backgroundColor) }
    .takeOrElse { LocalContentColor.current }

@Stable
fun BwColors.contentColorFor(backgroundColor: Color): Color = when (backgroundColor) {
    primary -> onPrimary
    secondary -> onSecondary
    surfaceContainer -> onSurfaceContainer
    surfaceBackground -> onSurfaceBackground
    surfaceEmphasized -> onSurfaceEmphasized
    surfaceInverse -> onSurfaceInverse
    statusSuccess -> onStatusSuccess
    statusError -> onStatusError
    statusWarning -> onStatusWarning
    else -> Color.Unspecified
}
