package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.ArrowLeft: ImageVector
    get() {
        if (_arrowLeft != null) {
            return _arrowLeft!!
        }
        _arrowLeft = Builder(name = "ArrowLeft", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(7.6f, 25.075f)
                arcToRelative(1.951f, 1.951f, 0.0f, false, true, 0.0f, -2.817f)
                lineTo(20.656f, 9.583f)
                arcToRelative(2.095f, 2.095f, 0.0f, false, true, 2.901f, 0.0f)
                arcToRelative(1.951f, 1.951f, 0.0f, false, true, 0.0f, 2.817f)
                lineToRelative(-9.553f, 9.275f)
                reflectiveCurveTo(35.5f, 21.5f, 38.0f, 21.5f)
                reflectiveCurveToRelative(2.5f, 2.0f, 2.5f, 2.0f)
                reflectiveCurveToRelative(0.0f, 2.0f, -2.5f, 2.0f)
                reflectiveCurveToRelative(-23.996f, 0.158f, -23.996f, 0.158f)
                lineToRelative(9.552f, 9.275f)
                arcToRelative(1.951f, 1.951f, 0.0f, false, true, 0.0f, 2.817f)
                curveToRelative(-0.801f, 0.778f, -2.1f, 0.778f, -2.9f, 0.0f)
                lineTo(7.6f, 25.075f)
                close()
            }
        }
        .build()
        return _arrowLeft!!
    }

private var _arrowLeft: ImageVector? = null
