package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Volume: ImageVector
    get() {
        if (_volume != null) {
            return _volume!!
        }
        _volume = Builder(name = "Volume", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(12.8891f, 32.5254f)
                horizontalLineTo(5.8182f)
                curveTo(5.336f, 32.5254f, 4.8735f, 32.3009f, 4.5325f, 31.9012f)
                curveTo(4.1916f, 31.5014f, 4.0f, 30.9593f, 4.0f, 30.3941f)
                verticalLineTo(17.6059f)
                curveTo(4.0f, 17.0407f, 4.1916f, 16.4985f, 4.5325f, 16.0988f)
                curveTo(4.8735f, 15.6991f, 5.336f, 15.4746f, 5.8182f, 15.4746f)
                horizontalLineTo(12.8891f)
                lineTo(22.5145f, 6.2415f)
                curveTo(22.6477f, 6.1136f, 22.8092f, 6.0325f, 22.9801f, 6.0079f)
                curveTo(23.1511f, 5.9833f, 23.3244f, 6.0162f, 23.4801f, 6.1026f)
                curveTo(23.6357f, 6.189f, 23.7672f, 6.3255f, 23.8593f, 6.4961f)
                curveTo(23.9513f, 6.6667f, 24.0001f, 6.8645f, 24.0f, 7.0664f)
                verticalLineTo(40.9336f)
                curveTo(24.0001f, 41.1355f, 23.9513f, 41.3333f, 23.8593f, 41.5039f)
                curveTo(23.7672f, 41.6745f, 23.6357f, 41.811f, 23.4801f, 41.8974f)
                curveTo(23.3244f, 41.9838f, 23.1511f, 42.0167f, 22.9801f, 41.9921f)
                curveTo(22.8092f, 41.9675f, 22.6477f, 41.8864f, 22.5145f, 41.7584f)
                lineTo(12.8909f, 32.5254f)
                horizontalLineTo(12.8891f)
                close()
                moveTo(37.4654f, 41.3364f)
                lineTo(34.8909f, 38.3184f)
                curveTo(36.6145f, 36.5207f, 37.9934f, 34.3151f, 38.9367f, 31.847f)
                curveTo(39.8801f, 29.3789f, 40.3664f, 26.7045f, 40.3636f, 24.0f)
                curveTo(40.3659f, 21.157f, 39.8279f, 18.349f, 38.7888f, 15.7802f)
                curveTo(37.7497f, 13.2113f, 36.2356f, 10.946f, 34.3564f, 9.1487f)
                lineTo(36.9382f, 6.1222f)
                curveTo(39.1536f, 8.3208f, 40.9332f, 11.0594f, 42.1528f, 14.1472f)
                curveTo(43.3725f, 17.2349f, 44.0029f, 20.5973f, 44.0f, 24.0f)
                curveTo(44.0f, 30.8694f, 41.48f, 37.0482f, 37.4654f, 41.3364f)
                close()
                moveTo(31.0236f, 33.785f)
                lineTo(28.4382f, 30.7543f)
                curveTo(29.3209f, 29.9586f, 30.0356f, 28.9357f, 30.5271f, 27.7645f)
                curveTo(31.0186f, 26.5933f, 31.2737f, 25.3052f, 31.2727f, 24.0f)
                curveTo(31.2727f, 20.9522f, 29.9091f, 18.2773f, 27.8545f, 16.7704f)
                lineTo(30.4709f, 13.7034f)
                curveTo(31.8481f, 14.891f, 32.9675f, 16.4419f, 33.7385f, 18.2307f)
                curveTo(34.5095f, 20.0194f, 34.9105f, 21.9959f, 34.9091f, 24.0f)
                curveTo(34.9091f, 27.926f, 33.4f, 31.4384f, 31.0236f, 33.785f)
                close()
            }
        }
        .build()
        return _volume!!
    }

private var _volume: ImageVector? = null
