package com.brdgwtr.designsystem.foundation

import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.graphics.Shape
import com.brdgwtr.designsystem.tokens.internal.BwRadiiTokens
import dev.drewhamilton.poko.Poko

@Poko
class BwShapes(
    val xxl: RoundedCornerShape = RoundedCornerShape(BwRadiiTokens.radiiXxl),
    val xl: RoundedCornerShape = RoundedCornerShape(BwRadiiTokens.radiiXl),
    val l: RoundedCornerShape = RoundedCornerShape(BwRadiiTokens.radiiL),
    val m: RoundedCornerShape = RoundedCornerShape(BwRadiiTokens.radiiM),
    val s: RoundedCornerShape = RoundedCornerShape(BwRadiiTokens.radiiS),
    val xs: RoundedCornerShape = RoundedCornerShape(BwRadiiTokens.radiiXs),
) {
    val circle: RoundedCornerShape
        get() = CircleShape

    val zero: Shape
        get() = RectangleShape
}
