package com.brdgwtr.designsystem.foundation

import com.brdgwtr.designsystem.tokens.internal.BwZoomScaleTokens
import dev.drewhamilton.poko.Poko

/**
 * Zoom scales typically used for animations.
 */
@Poko
class BwZoomScales(
    val s: Float,
    val m: Float,
    val l: Float,
)

internal val BwDefaultZoomScales = BwZoomScales(
    s = BwZoomScaleTokens.zoomS,
    m = BwZoomScaleTokens.zoomM,
    l = BwZoomScaleTokens.zoomL,
)
