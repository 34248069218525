package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.GeoShape7: ImageVector
    get() {
        if (_geoShape7 != null) {
            return _geoShape7!!
        }
        _geoShape7 = Builder(name = "GeoShape7", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(37.988f, 21.27f)
                curveToRelative(0.0f, 3.323f, 0.014f, 6.647f, -0.012f, 9.969f)
                arcToRelative(6.704f, 6.704f, 0.0f, false, true, -0.268f, 1.807f)
                curveToRelative(-0.698f, 2.358f, -1.462f, 4.696f, -2.157f, 7.054f)
                curveToRelative(-0.202f, 0.687f, -0.521f, 0.998f, -1.258f, 1.038f)
                curveToRelative(-4.97f, 0.271f, -9.936f, 0.601f, -14.907f, 0.86f)
                curveToRelative(-0.431f, 0.023f, -0.985f, -0.207f, -1.304f, -0.51f)
                arcToRelative(335.45f, 335.45f, 0.0f, false, true, -6.588f, -6.483f)
                arcToRelative(1.747f, 1.747f, 0.0f, false, true, -0.465f, -1.106f)
                curveToRelative(-0.027f, -6.993f, -0.015f, -13.986f, -0.029f, -20.98f)
                curveToRelative(0.0f, -0.582f, 0.183f, -0.933f, 0.705f, -1.199f)
                curveTo(15.3f, 9.877f, 18.88f, 8.0f, 22.48f, 6.163f)
                curveToRelative(0.316f, -0.161f, 0.77f, -0.208f, 1.114f, -0.118f)
                curveToRelative(4.512f, 1.171f, 9.016f, 2.383f, 13.525f, 3.57f)
                curveToRelative(0.643f, 0.169f, 0.884f, 0.538f, 0.881f, 1.209f)
                curveToRelative(-0.019f, 3.483f, -0.008f, 6.967f, -0.01f, 10.45f)
                lineToRelative(-0.002f, -0.003f)
                close()
                moveTo(19.233f, 40.46f)
                curveToRelative(0.389f, 0.0f, 0.677f, 0.015f, 0.966f, -0.002f)
                curveToRelative(4.336f, -0.26f, 8.671f, -0.543f, 13.01f, -0.762f)
                curveToRelative(0.746f, -0.038f, 0.97f, -0.232f, 0.965f, -1.008f)
                curveToRelative(-0.03f, -6.267f, -0.017f, -12.537f, -0.019f, -18.805f)
                verticalLineToRelative(-0.893f)
                lineToRelative(-14.922f, 1.34f)
                verticalLineToRelative(20.13f)
                close()
                moveTo(12.868f, 12.83f)
                curveToRelative(0.054f, 0.15f, 0.06f, 0.213f, 0.093f, 0.246f)
                curveToRelative(1.952f, 1.992f, 3.898f, 3.992f, 5.875f, 5.96f)
                curveToRelative(0.183f, 0.183f, 0.562f, 0.252f, 0.84f, 0.235f)
                curveToRelative(1.445f, -0.095f, 2.887f, -0.235f, 4.33f, -0.363f)
                curveToRelative(3.202f, -0.283f, 6.401f, -0.561f, 9.6f, -0.867f)
                curveToRelative(0.276f, -0.026f, 0.705f, -0.178f, 0.773f, -0.373f)
                curveToRelative(0.737f, -2.17f, 1.418f, -4.36f, 2.123f, -6.574f)
                curveToRelative(-0.183f, -0.078f, -0.297f, -0.142f, -0.42f, -0.173f)
                arcTo(3528.321f, 3528.321f, 0.0f, false, false, 23.558f, 7.59f)
                arcToRelative(1.016f, 1.016f, 0.0f, false, false, -0.668f, 0.058f)
                curveToRelative(-3.335f, 1.712f, -6.66f, 3.442f, -10.02f, 5.185f)
                lineToRelative(-0.001f, -0.002f)
                close()
                moveTo(18.161f, 39.384f)
                curveToRelative(0.028f, -0.284f, 0.058f, -0.462f, 0.058f, -0.638f)
                curveToRelative(0.003f, -6.103f, 0.01f, -12.207f, -0.017f, -18.31f)
                curveToRelative(0.0f, -0.35f, -0.207f, -0.766f, -0.445f, -1.034f)
                curveToRelative(-0.706f, -0.793f, -1.48f, -1.52f, -2.22f, -2.284f)
                curveToRelative(-0.952f, -0.986f, -1.895f, -1.981f, -2.95f, -3.089f)
                curveToRelative(-0.053f, 0.313f, -0.084f, 0.411f, -0.084f, 0.51f)
                curveToRelative(-0.004f, 6.29f, -0.01f, 12.582f, 0.012f, 18.872f)
                curveToRelative(0.0f, 0.3f, 0.187f, 0.667f, 0.4f, 0.885f)
                curveToRelative(1.55f, 1.565f, 3.127f, 3.1f, 4.7f, 4.644f)
                curveToRelative(0.14f, 0.138f, 0.307f, 0.252f, 0.543f, 0.444f)
                horizontalLineToRelative(0.003f)
                close()
                moveTo(36.436f, 29.56f)
                lineTo(35.21f, 29.56f)
                verticalLineToRelative(1.476f)
                horizontalLineToRelative(1.226f)
                lineTo(36.436f, 29.56f)
                close()
                moveTo(36.422f, 23.509f)
                verticalLineToRelative(-1.502f)
                horizontalLineToRelative(-1.197f)
                verticalLineToRelative(1.502f)
                horizontalLineToRelative(1.197f)
                close()
                moveTo(36.427f, 29.161f)
                verticalLineToRelative(-1.504f)
                horizontalLineToRelative(-1.204f)
                verticalLineToRelative(1.504f)
                horizontalLineToRelative(1.204f)
                close()
                moveTo(36.439f, 21.61f)
                verticalLineToRelative(-1.467f)
                horizontalLineToRelative(-1.214f)
                verticalLineToRelative(1.467f)
                horizontalLineToRelative(1.214f)
                close()
                moveTo(36.443f, 23.908f)
                lineTo(35.21f, 23.908f)
                verticalLineToRelative(1.454f)
                horizontalLineToRelative(1.233f)
                lineTo(36.443f, 23.91f)
                close()
                moveTo(36.431f, 27.251f)
                verticalLineToRelative(-1.44f)
                lineTo(35.2f, 25.811f)
                verticalLineToRelative(1.44f)
                curveToRelative(0.219f, 0.017f, 0.395f, 0.045f, 0.572f, 0.043f)
                curveToRelative(0.204f, 0.0f, 0.406f, -0.024f, 0.657f, -0.043f)
                horizontalLineToRelative(0.002f)
                close()
                moveTo(36.431f, 18.3f)
                curveToRelative(-1.301f, -0.218f, -1.425f, -0.07f, -1.187f, 1.384f)
                horizontalLineToRelative(1.187f)
                lineTo(36.431f, 18.3f)
                close()
                moveTo(36.426f, 31.449f)
                horizontalLineToRelative(-1.211f)
                verticalLineToRelative(1.435f)
                curveToRelative(1.105f, 0.1f, 1.287f, -0.115f, 1.21f, -1.435f)
                close()
                moveTo(35.385f, 17.837f)
                horizontalLineToRelative(1.047f)
                verticalLineToRelative(-1.541f)
                curveToRelative(-0.995f, 0.131f, -0.704f, 0.97f, -1.047f, 1.54f)
                close()
                moveTo(36.142f, 33.254f)
                lineTo(35.222f, 33.321f)
                verticalLineToRelative(1.472f)
                lineToRelative(0.26f, 0.078f)
                lineToRelative(0.66f, -1.615f)
                verticalLineToRelative(-0.002f)
                close()
            }
        }
        .build()
        return _geoShape7!!
    }

private var _geoShape7: ImageVector? = null
