package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoLiveFilled: ImageVector
    get() {
        if (_ruwidoLiveFilled != null) {
            return _ruwidoLiveFilled!!
        }
        _ruwidoLiveFilled = Builder(name = "RuwidoLiveFilled", defaultWidth = 48.0.dp, defaultHeight
                = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(31.549f, 14.87f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, -0.984f, -1.74f)
                lineToRelative(-6.677f, 3.773f)
                lineToRelative(-6.38f, -3.764f)
                arcToRelative(1.0f, 1.0f, 0.0f, false, false, -1.016f, 1.722f)
                lineToRelative(4.57f, 2.697f)
                lineTo(10.0f, 17.558f)
                verticalLineToRelative(17.0f)
                horizontalLineToRelative(28.0f)
                verticalLineToRelative(-17.0f)
                lineTo(26.794f, 17.558f)
                lineToRelative(4.755f, -2.687f)
                close()
                moveTo(12.014f, 32.658f)
                lineTo(12.014f, 19.459f)
                horizontalLineToRelative(23.972f)
                verticalLineToRelative(13.198f)
                lineTo(12.014f, 32.657f)
                close()
            }
        }
        .build()
        return _ruwidoLiveFilled!!
    }

private var _ruwidoLiveFilled: ImageVector? = null
