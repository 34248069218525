package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.Chevron: ImageVector
    get() {
        if (_chevron != null) {
            return _chevron!!
        }
        _chevron = Builder(name = "Chevron", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(13.593f, 6.8687f)
                curveTo(14.4752f, 5.8224f, 16.0209f, 5.7046f, 17.0454f, 6.6056f)
                lineTo(33.8091f, 21.3478f)
                curveTo(35.397f, 22.7441f, 35.397f, 25.2558f, 33.8091f, 26.6522f)
                lineTo(17.0454f, 41.3944f)
                curveTo(16.0209f, 42.2953f, 14.4752f, 42.1775f, 13.593f, 41.1313f)
                curveTo(12.7108f, 40.085f, 12.8262f, 38.5065f, 13.8507f, 37.6056f)
                lineTo(29.3219f, 24.0f)
                lineTo(13.8507f, 10.3944f)
                curveTo(12.8262f, 9.4935f, 12.7108f, 7.9149f, 13.593f, 6.8687f)
                close()
            }
        }
        .build()
        return _chevron!!
    }

private var _chevron: ImageVector? = null
