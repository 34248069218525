package com.brdgwtr.designsystem.components

import androidx.annotation.FloatRange
import androidx.compose.animation.EnterTransition
import androidx.compose.animation.ExitTransition
import androidx.compose.animation.core.spring
import androidx.compose.animation.expandHorizontally
import androidx.compose.animation.shrinkHorizontally
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.Stable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.foundation.BwTheme
import com.brdgwtr.designsystem.foundation.contentColorFor

object ButtonDefaults {
    internal val minHeight = 32.dp
    internal val minWidth = 120.dp

    /**
     * Provides a border for the [Button] state.
     *
     * @param color The default color border when the surface is unfocused.
     * @param focusedColor The border color when the surface is focused.
     * @param pressedColor The border color when the surface is pressed.
     * @param disabledColor The border color when the surface is disabled.
     * @param focusedDisabledColor The border color when the surface is focused but disabled.
     * @param width - The width of the border.
     * @param inset - The inset of the border.
     */
    @Composable
    @ReadOnlyComposable
    fun border(
        color: Color = BwTheme.colors.onSurfaceBackground,
        focusedColor: Color = Color.Unspecified,
        pressedColor: Color = focusedColor,
        disabledColor: Color = color.copy(alpha = 0.4f),
        focusedDisabledColor: Color = focusedColor.copy(alpha = 0.4f),
        shape: Shape = BorderDefaults.DefaultShape,
        width: Dp = 1.dp,
        inset: Dp = 0.dp,
    ): ButtonBorder = border(
        border = BorderDefaults.border(color = color, width = width, inset = inset, shape = shape),
        focusedBorder = BorderDefaults.border(color = focusedColor, width = width, inset = inset, shape = shape),
        pressedBorder = BorderDefaults.border(color = pressedColor, width = width, inset = inset, shape = shape),
        disabledBorder = BorderDefaults.border(
            color = disabledColor,
            width = width,
            inset = inset,
            shape = shape,
        ),
        focusedDisabledBorder = BorderDefaults.border(
            color = focusedDisabledColor,
            width = width,
            inset = inset,
            shape = shape,
        ),
    )

    /**
     * Provides a border for the [Button] state.
     *
     * @param border The default border when the surface is unfocused.
     * @param focusedBorder The border when the surface is focused.
     * @param pressedBorder The border when the surface is pressed.
     * @param disabledBorder The border when the surface is disabled.
     * @param focusedDisabledBorder The border when the surface is focused but disabled.
     */
    fun border(
        border: Border = Border.None,
        focusedBorder: Border = Border.None,
        pressedBorder: Border = Border.None,
        disabledBorder: Border = Border.None,
        focusedDisabledBorder: Border = Border.None,
    ): ButtonBorder = ButtonBorder(
        border = border,
        focusedBorder = focusedBorder,
        pressedBorder = pressedBorder,
        disabledBorder = disabledBorder,
        focusedDisabledBorder = focusedDisabledBorder,
    )
}

object PrimaryButtonDefaults {
    internal val iconSize = 16.dp
    val contentPadding = PaddingValues(
        vertical = 8.dp,
        horizontal = 16.dp,
    )

    val shape: RoundedCornerShape
        @Composable
        @ReadOnlyComposable
        get() = BwTheme.shapes.l

    /**
     * Creates [ButtonColors] for each provided state.
     *
     * @param backgroundColor - The main background color.
     * @param contentColor - The main content color use for items displayed on [backgroundColor]
     * @param focusedBackgroundColor - The background color used in the focused state.
     * @param focusedContentColor - The focused content color use for items displayed on [focusedBackgroundColor].
     * @param pressedBackgroundColor - The background color used in the pressed state.
     * @param pressedContentColor - The pressed content color use for items displayed on [pressedBackgroundColor].
     * @param disabledBackgroundColor - The background color used in the disabled state.
     * @param disabledContentColor - The disabled content color use for items displayed on [disabledBackgroundColor].
     * @param disabledFocusedBackgroundColor - The background color used when focused and disables.
     * @param disabledFocusedContentColor - The disabled content color use for items displayed on
     * [disabledFocusedBackgroundColor].
     */
    @Composable
    @ReadOnlyComposable
    fun colors(
        backgroundColor: Color = Color.Transparent,
        contentColor: Color = contentColorFor(BwTheme.colors.surfaceBackground),
        focusedBackgroundColor: Color = BwTheme.colors.surfaceInverse,
        focusedContentColor: Color = contentColorFor(focusedBackgroundColor),
        pressedBackgroundColor: Color = focusedBackgroundColor.copy(alpha = .6f),
        pressedContentColor: Color = contentColorFor(focusedBackgroundColor),
        disabledBackgroundColor: Color = backgroundColor.copy(alpha = 0.6f),
        disabledContentColor: Color = contentColorFor(BwTheme.colors.surfaceBackground).copy(alpha = 0.6f),
        disabledFocusedBackgroundColor: Color = focusedBackgroundColor.copy(alpha = 0.6f),
        disabledFocusedContentColor: Color = focusedContentColor.copy(alpha = 0.6f),
    ): ButtonColors = ButtonColors(
        backgroundColor = backgroundColor,
        contentColor = contentColor,
        focusedContentColor = focusedContentColor,
        focusedBackgroundColor = focusedBackgroundColor,
        pressedBackgroundColor = pressedBackgroundColor,
        pressedContentColor = pressedContentColor,
        disabledBackgroundColor = disabledBackgroundColor,
        disabledContentColor = disabledContentColor,
        disabledFocusedBackgroundColor = disabledFocusedBackgroundColor,
        disabledFocusedContentColor = disabledFocusedContentColor,
    )

    /**
     * Provides the scale for the [Button] state.
     *
     * @param scale - The default scale of the surface.
     * @param focusedScale - The scale when the surface is focused.
     */
    fun scale(
        @FloatRange(from = 0.0) scale: Float = 1f,
        @FloatRange(from = 0.0) focusedScale: Float = 1.05f,
    ): ButtonScale = ButtonScale(
        scale = scale,
        focusedScale = focusedScale,
        disabledScale = scale,
        pressedScale = scale,
    )

    /**
     * Provides a border for the [PrimaryButton] state.
     *
     * @param color The default color border when the surface is unfocused.
     * @param focusedColor The border color when the surface is focused.
     * @param pressedColor The border color when the surface is pressed.
     * @param disabledColor The border color when the surface is disabled.
     * @param focusedDisabledColor The border color when the surface is focused but disabled.
     * @param width - The width of the border.
     * @param inset - The inset of the border.
     */
    @Composable
    @ReadOnlyComposable
    fun border(
        color: Color = BwTheme.colors.onSurfaceBackground,
        focusedColor: Color = Color.Unspecified,
        pressedColor: Color = focusedColor,
        disabledColor: Color = color.copy(alpha = 0.4f),
        focusedDisabledColor: Color = focusedColor.copy(alpha = 0.4f),
        shape: Shape = PrimaryButtonDefaults.shape,
        width: Dp = 1.dp,
        inset: Dp = 0.dp,
    ): ButtonBorder = ButtonDefaults.border(
        color = color,
        focusedColor = focusedColor,
        pressedColor = pressedColor,
        disabledColor = disabledColor,
        focusedDisabledColor = focusedDisabledColor,
        shape = shape,
        width = width,
        inset = inset,
    )
}

object IconButtonDefaults {
    internal val iconButtonSize = 32.dp
    internal val iconSize = 16.dp
    internal val contentPadding = PaddingValues(horizontal = 8.dp)

    @Composable
    @ReadOnlyComposable
    internal fun colors(): ButtonColors = PrimaryButtonDefaults.colors()

    @Composable
    @ReadOnlyComposable
    internal fun border(): ButtonBorder = PrimaryButtonDefaults.border(shape = BwTheme.shapes.circle)

    @Stable
    internal val textEnterAnimation: EnterTransition
        get() = expandHorizontally(
            animationSpec = spring(stiffness = 3000f),
        )

    @Stable
    internal val textExitAnimation: ExitTransition
        get() = shrinkHorizontally(
            animationSpec = spring(stiffness = 3000f),
        )
}
