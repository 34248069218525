package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.RuwidoPowerFilled: ImageVector
    get() {
        if (_ruwidoPowerFilled != null) {
            return _ruwidoPowerFilled!!
        }
        _ruwidoPowerFilled = Builder(name = "RuwidoPowerFilled", defaultWidth = 48.0.dp,
                defaultHeight = 48.0.dp, viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(0.0f, 24.0f)
                curveTo(0.0f, 10.744f, 10.744f, 0.0f, 24.0f, 0.0f)
                reflectiveCurveToRelative(24.0f, 10.744f, 24.0f, 24.0f)
                reflectiveCurveToRelative(-10.744f, 24.0f, -24.0f, 24.0f)
                reflectiveCurveTo(0.0f, 37.256f, 0.0f, 24.0f)
                close()
                moveTo(25.0f, 10.0f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, -2.0f, 0.0f)
                verticalLineToRelative(11.295f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, 2.0f, 0.0f)
                lineTo(25.0f, 10.0f)
                close()
                moveTo(19.16f, 15.11f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, -1.021f, -1.72f)
                arcTo(11.764f, 11.764f, 0.0f, false, false, 12.4f, 23.517f)
                curveToRelative(0.0f, 6.47f, 5.175f, 11.733f, 11.601f, 11.733f)
                curveToRelative(6.427f, 0.0f, 11.601f, -5.264f, 11.601f, -11.733f)
                curveToRelative(0.0f, -4.192f, -2.165f, -7.861f, -5.424f, -9.948f)
                arcToRelative(1.0f, 1.0f, 0.0f, true, false, -1.079f, 1.684f)
                arcToRelative(9.782f, 9.782f, 0.0f, false, true, 4.503f, 8.264f)
                curveToRelative(0.0f, 5.389f, -4.303f, 9.733f, -9.6f, 9.733f)
                curveToRelative(-5.299f, 0.0f, -9.602f, -4.344f, -9.602f, -9.733f)
                arcToRelative(9.764f, 9.764f, 0.0f, false, true, 4.76f, -8.407f)
                close()
            }
        }
        .build()
        return _ruwidoPowerFilled!!
    }

private var _ruwidoPowerFilled: ImageVector? = null
