package com.brdgwtr.designsystem.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType.Companion.EvenOdd
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.brdgwtr.designsystem.Icons

public val Icons.WifiLocked: ImageVector
    get() {
        if (_wifiLocked != null) {
            return _wifiLocked!!
        }
        _wifiLocked = Builder(name = "WifiLocked", defaultWidth = 48.0.dp, defaultHeight = 48.0.dp,
                viewportWidth = 48.0f, viewportHeight = 48.0f).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = EvenOdd) {
                moveTo(7.934f, 20.283f)
                arcToRelative(1.55f, 1.55f, 0.0f, false, false, 1.086f, -0.439f)
                curveToRelative(8.26f, -8.054f, 21.7f, -8.055f, 29.96f, 0.0f)
                arcToRelative(1.56f, 1.56f, 0.0f, false, false, 2.17f, 0.0f)
                curveToRelative(0.6f, -0.584f, 0.6f, -1.532f, 0.0f, -2.116f)
                curveTo(36.569f, 13.26f, 30.478f, 10.8f, 24.0f, 10.8f)
                curveToRelative(-6.479f, 0.0f, -12.57f, 2.46f, -17.15f, 6.927f)
                arcToRelative(1.47f, 1.47f, 0.0f, false, false, 0.0f, 2.117f)
                curveToRelative(0.299f, 0.292f, 0.692f, 0.438f, 1.084f, 0.438f)
                close()
                moveTo(14.126f, 24.823f)
                arcToRelative(1.55f, 1.55f, 0.0f, false, true, -1.085f, 0.44f)
                arcToRelative(1.55f, 1.55f, 0.0f, false, true, -1.085f, -0.44f)
                arcToRelative(1.47f, 1.47f, 0.0f, false, true, 0.0f, -2.116f)
                curveToRelative(6.64f, -6.477f, 17.447f, -6.477f, 24.088f, 0.0f)
                curveToRelative(0.6f, 0.584f, 0.6f, 1.532f, 0.0f, 2.117f)
                arcToRelative(1.563f, 1.563f, 0.0f, false, true, -2.17f, 0.0f)
                curveToRelative(-5.445f, -5.31f, -14.304f, -5.31f, -19.748f, 0.0f)
                close()
                moveTo(18.146f, 30.241f)
                arcToRelative(1.55f, 1.55f, 0.0f, false, false, 1.085f, -0.438f)
                arcTo(6.785f, 6.785f, 0.0f, false, true, 24.0f, 27.877f)
                curveToRelative(1.801f, 0.0f, 3.495f, 0.684f, 4.768f, 1.926f)
                curveToRelative(0.6f, 0.584f, 1.572f, 0.584f, 2.17f, 0.0f)
                curveToRelative(0.6f, -0.585f, 0.6f, -1.533f, 0.0f, -2.117f)
                curveToRelative(-1.853f, -1.807f, -4.317f, -2.803f, -6.938f, -2.803f)
                curveToRelative(-2.62f, 0.0f, -5.085f, 0.995f, -6.938f, 2.803f)
                arcToRelative(1.47f, 1.47f, 0.0f, false, false, 0.0f, 2.117f)
                curveToRelative(0.3f, 0.292f, 0.692f, 0.438f, 1.085f, 0.438f)
                close()
                moveTo(23.998f, 37.201f)
                curveToRelative(1.557f, 0.0f, 2.818f, -1.231f, 2.818f, -2.749f)
                curveToRelative(0.0f, -1.517f, -1.261f, -2.748f, -2.817f, -2.748f)
                curveToRelative(-1.557f, 0.0f, -2.818f, 1.23f, -2.818f, 2.748f)
                curveToRelative(0.0f, 1.518f, 1.261f, 2.748f, 2.817f, 2.748f)
                close()
                moveTo(44.329f, 30.691f)
                verticalLineToRelative(1.383f)
                curveToRelative(0.847f, 0.0f, 1.572f, 0.668f, 1.571f, 1.484f)
                verticalLineToRelative(5.733f)
                curveToRelative(0.0f, 0.816f, -0.694f, 1.484f, -1.54f, 1.484f)
                horizontalLineToRelative(-7.918f)
                curveToRelative(-0.848f, 0.0f, -1.542f, -0.668f, -1.542f, -1.484f)
                verticalLineToRelative(-5.733f)
                curveToRelative(0.0f, -0.816f, 0.725f, -1.484f, 1.572f, -1.484f)
                verticalLineToRelative(-1.382f)
                curveToRelative(0.0f, -2.023f, 1.707f, -3.667f, 3.808f, -3.667f)
                horizontalLineToRelative(0.24f)
                curveToRelative(2.101f, 0.0f, 3.81f, 1.644f, 3.81f, 3.667f)
                close()
                moveTo(37.781f, 32.07f)
                horizontalLineToRelative(5.238f)
                verticalLineToRelative(-1.378f)
                curveToRelative(0.0f, -1.465f, -0.965f, -2.477f, -2.488f, -2.477f)
                horizontalLineToRelative(-0.262f)
                curveToRelative(-1.521f, 0.0f, -2.488f, 1.012f, -2.488f, 2.477f)
                verticalLineToRelative(1.378f)
                close()
            }
        }
        .build()
        return _wifiLocked!!
    }

private var _wifiLocked: ImageVector? = null
